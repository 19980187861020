import React, { useEffect, useState } from "react";
import Loader from "../../../../../../components/Loader";
import { useTranslation } from "react-i18next";

export default function TypePostCallQueue({ data, callQueue, people, analyseType, setDataGraph, setFormattedHeader }) {
    const [groupedCalls, setGroupedCalls] = useState([]);
    const [groupPostSurvey, setGroupPostSurvey] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (groupedCalls.length > 0) {
            handleGraphgroupPostSurvey(groupedCalls);
            setCurrentPage(1);
        }
    }, [groupedCalls]);

    const formatDuration = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
    };

    useEffect(() => {
        if (data && callQueue) {
            const groupementNumbers = Object.values(callQueue);
            // Fonction pour trier les appels dans chaque groupe
            const groupCallsByGroupement = (dataGroups) => {
                
                return dataGroups.map((dataGroup) => {
                    const { calls } = dataGroup;
            
                    // Filtrer les appels
                    const sortedCalls = calls.filter((call) => {
                        // Vérifier si l'appel a un usernumber dans un groupement mais pas le callednumber
                        const isValidCall = groupementNumbers.includes(call.usernumber) && !groupementNumbers.includes(call.callednumber);
            
                        // Retourner seulement les appels valides
                        return isValidCall;
                    });
            
                    // Si des appels triés sont trouvés, retourner le groupe avec les appels mis à jour
                    return sortedCalls.length > 0 ? { 
                        ...dataGroup, 
                        calls: sortedCalls 
                    } : null;
                })
                .filter(group => group !== null); // Filtrer les groupes nuls
            };         
    
            // Appliquer la logique pour trier et filtrer les groupes
            const result = groupCallsByGroupement(data);
            // Mettre à jour l'état avec les appels groupés
            setGroupedCalls(result);
        }
    }, [data, callQueue]);

    const calculateResponsePercentage = (incoming) => {
        const totalResponses = incoming.response + incoming.noresponse;
        return totalResponses > 0
        // to fix ici et % sinon le excel N/A
            ? ((incoming.response / totalResponses) * 100).toFixed(2) + '%'
            : '0.00%'; // Retourne 0.00% si aucun appel
    };

    const handleGraphgroupPostSurvey = (data) => {
        let callqueue = {};
        const peopleMap = people.flat().reduce((acc, person) => {
            const fullName = `${person.firstName} ${person.lastName}`;
            acc[person.phone.work] = fullName;
            if (person.phone.mobile) {
                acc[person.phone.mobile] = fullName;
            }
            return acc;
        }, {});

        data.map(item => {
            if (Array.isArray(item.calls)) {
                return item.calls.filter(call => {
                    const group = call.callednumber;
                    const person = peopleMap[group]; // Trouve la personne par numéro de téléphone
                    if (!person) return; // Ignore si personne n'est trouvée
                    if (!callqueue[person]) {
                        callqueue[person] = {
                            Incoming: {
                                total: 0,
                                durationTotal: 0,
                                averageduration: 0,
                                response: 0,
                                averageresponse: 0,
                                noresponse: 0,
                                ringduration: 0,
                                averageringduration: 0
                            },
                            calls: []
                        };
                    }
                    callqueue[person].calls.push(call);
                    callqueue[person].Incoming.total += 1;
                    callqueue[person].Incoming.durationTotal += Math.floor(parseInt(call.duration) || 0);
                    callqueue[person].Incoming.ringduration += Math.floor(parseInt(call.ringduration) || 0);
                    if (call.answerindicator === "Yes") {
                        callqueue[person].Incoming.response += 1;
                        callqueue[person].Incoming.averageresponse += Math.floor(parseInt(call.ringduration) || 0);
                    } else if (call.answerindicator === "No") {
                        callqueue[person].Incoming.noresponse += 1;
                    }
                });
            }
            return [];
        });

        for (let group in callqueue) {
            let Incoming = callqueue[group].Incoming;

            Incoming.averageduration = Incoming.total > 0 ? Incoming.durationTotal / Incoming.total : 0;
            Incoming.averageresponse = Incoming.response > 0 ? Incoming.averageresponse / Incoming.response : 0;
            Incoming.averageringduration = Incoming.ringduration > 0 ? Incoming.response + Incoming.noresponse / Incoming.ringduration : 0;
            Incoming.responsePercentage = calculateResponsePercentage(Incoming);
            Incoming.durationTotal = formatDuration(Incoming.durationTotal);
            Incoming.averageduration = formatDuration(Incoming.averageduration);
            Incoming.averageresponse = formatDuration(Incoming.averageresponse);
            Incoming.ringduration = formatDuration(Incoming.ringduration);
            Incoming.averageringduration = formatDuration(Incoming.averageringduration);
        }
        setGroupPostSurvey(callqueue);
        const formattedData = formatForExcel(callqueue);
        setDataGraph(formattedData);
    }

    const formatForExcel = (data) => {
        // En-têtes des colonnes
        const headers = ["Poste"];  // Le premier en-tête est "Poste"

        const keysMapping = [
            { key: "response", label: "Nombre d'appels répondu" },
            { key: "averageresponse", label: "Durée moyenne pour répondre" },
            { key: "noresponse", label: "Nombre d'appels non répondu" },
            { key: "durationTotal", label: "Durée totale d'appels" },
            { key: "averageduration", label: "Durée moyenne d'appels" },
            { key: "ringduration", label: "Durée totale d'attente" },
            { key: "averageringduration", label: "Durée moyenne d'attente" },
            { key: "responsePercentage", label: "Pourcentage de réponse" }
        ];

        // Ajouter les labels dans les en-têtes
        keysMapping.forEach(({ label }) => {
            headers.push(label);
        });

        // Format des données pour Excel : première ligne = headers
        const excelData = [headers];

        // Parcourir chaque groupe (Support, Standard, etc.)
        Object.keys(data).forEach(group => {
            // Créer une ligne pour chaque groupe
            const row = [group];  // La première colonne est le nom du groupe (poste)

            // Ajouter les valeurs correspondantes à chaque label (Incoming uniquement ici)
            keysMapping.forEach(({ key }) => {
                const value = data[group].Incoming[key] !== undefined ? data[group].Incoming[key] : "N/A";
                row.push(value);  // Ajouter la valeur à la ligne
            });

            // Ajouter la ligne complète à excelData
            excelData.push(row);
        });

        setFormattedHeader(true);

        return excelData;
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    return (
        <div className="callQueuePostSurvey">
            {groupPostSurvey ? (
                <>
                    <div className="pagination-callQueue-post">
                        <button
                            onClick={() => paginate(currentPage > 1 ? currentPage - 1 : currentPage)}
                            disabled={currentPage === 1}
                        >
                            {t("Précédent")}
                        </button>
                        <span>Page {currentPage} {t('sur')} {Math.ceil(Object.keys(groupPostSurvey).length / itemsPerPage)}</span>
                        <button
                            onClick={() =>
                                paginate(
                                    currentPage < Math.ceil(Object.keys(groupPostSurvey).length / itemsPerPage)
                                        ? currentPage + 1
                                        : currentPage
                                )
                            }
                            disabled={currentPage === Math.ceil(Object.keys(groupPostSurvey).length / itemsPerPage)}
                        >
                            {t("Suivant")}
                        </button>
                    </div>
                    <div className="table-callqueue">
                        <table className="callQueuePostSurvey-entrants">
                            <thead>
                                <tr>
                                    <th title="Poste">{t("Poste")}</th>
                                    <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                    <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                    <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                    <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                    <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                                    <th title="Durée totale d'attente">{t("Durée totale d'attente")}</th>
                                    <th title="Durée moyenne d'attente">{t("Durée moyenne d'attente")}</th>
                                    <th title="Taux de réponse">{t("Taux de réponse")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(groupPostSurvey)
                                    .slice(indexOfFirstItem, indexOfLastItem)
                                    .map((group, index) => (
                                        <tr key={index}>
                                            <td>{group}</td>
                                            <td>{groupPostSurvey[group].Incoming.response}</td>
                                            <td>{groupPostSurvey[group].Incoming.averageresponse}</td>
                                            <td>{groupPostSurvey[group].Incoming.noresponse}</td>
                                            <td>{groupPostSurvey[group].Incoming.durationTotal}</td>
                                            <td>{groupPostSurvey[group].Incoming.averageduration}</td>
                                            <td>{groupPostSurvey[group].Incoming.ringduration}</td>
                                            <td>{groupPostSurvey[group].Incoming.averageringduration}</td>
                                            <td>{groupPostSurvey[group].Incoming.responsePercentage}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <Loader />
            )}
        </div>
    );
}
