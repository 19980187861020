import { useTranslation } from "react-i18next";

export default function IncidentsFilterChart({
  setCurrentSlide,
  setTimeFilter,
  currentSlide,
  timeFilter,
  valuesType,
}) {

  const {t} = useTranslation();
  return (
    <div className="page-section_form">
      <form className="form">
        <div className="form-input_container">
          <label htmlFor="type">{t('Type de graphique')}</label>
          <select
            className="only only_incidents"
            id="type"
            onChange={(e) => {
              setCurrentSlide(e.target.value);
            }}
            defaultValue={currentSlide}
          >
            {valuesType.map((item, index) => {
              return (
                <option key={index} value={index}>
                  {t(item)}
                </option>
              );
            })}
          </select>
        </div>
      </form>
      <form className="form">
        <div className="form-input_container">
          <label htmlFor="type">{t('Filtrer par')}</label>
          <select
            className="only only_incidents"
            id="type"
            onChange={(e) => {
              setTimeFilter(e.target.value);
            }}
            defaultValue={timeFilter}
          >
            <option value="all">{t('Tous les tickets')} </option>
            <option value="twelve">{t('Tickets des 12 derniers mois')}</option>
            <option value="year">{t("Tickets de l'année")}</option>
            <option value="months">{t('Tickets du mois')}</option>
            <option value="week">{t('Tickets de la semaine')}</option>
          </select>
        </div>
      </form>
    </div>
  );
}
