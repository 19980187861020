
  import axios from "axios";
  import { useContext, useEffect, useState } from "react";
  import { AuthContext } from "../../context/AuthContext";
  import Loader from "../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
  
  export default function RowWebexIoT ({ item }) {
    //import context
    const { apiUrl } = useContext(AuthContext);
    const [metrics, setMetrics] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {t} = useTranslation();

    const metricsMap = {

      soundLevel: {name: "Niveau sonore", unit: "dbA"},
      ambientNoise: {name: "Bruit ambiant", unit: "dbA"},
      temperature: {name: "Température", unit: "°C"},
      humidity: {name: "Humidité", unit: "%"},
      tvoc: {name: "Qualité de l'air", unit: null},
      peopleCount: {name: "Nombre de personnes", unit: null}
    }
  
    const getMetricsOfWebex = () => {
        axios.get(`${apiUrl}webex/workspaces/metrics/${item.id}`,{ withCredentials: true,})
        .then(res => {
            const formatedData = res.data.map((metric) => ({
                name: metricsMap[metric.metricName].name,
                workspaceId: metric.workspaceId,
                supported: metric.items[0].min ? true : false,
                unit: metricsMap[metric.metricName].unit,
                min: metric.items[0].min, 
                max: metric.items[0].max,
                mean: metric.items[0].mean
            }))
    
            setMetrics(formatedData);
        })
        .catch(err => {})
    };
  
    useEffect(() => {
        getMetricsOfWebex()
    }, [item.id])

   useEffect(() => {
    if(metrics.length > 0) {
        setIsLoading(false);
    }
   }, [metrics])

    return (
      <>
        {!isLoading ?
        <div className="webexIot-container">

            {metrics.filter(metric => metric.supported === true).length > 0 ? metrics.map((metric, index) => {
                if(item.id === metric.workspaceId && metric.supported) {
                    return <div className="webexIot-item" key={index}>
                    <p className="webexIot-content">
                      <span className="webexIot-title">{t(metric.name)} {t('moyen')}</span>
                      <span className="webexIot-data">{metric.mean}{metric.unit}</span>
                      </p>

                    <div className="webexIot-value_container">
                    <p className="webexIot-value">
                    <span className="webexIot-value_title"> Min <FontAwesomeIcon icon={faArrowDown} /></span>
                    <span className="webexIot-value_data">{metric.min}{metric.unit}</span>
                    </p>
                    <p className="webexIot-value">
                      <span className="webexIot-value_title"> Max <FontAwesomeIcon icon={faArrowUp} /></span>
                      <span className="webexIot-value_data">{metric.max}{metric.unit}</span>
                      </p>
                  </div>
                  </div>
                }
            }) :
              <p className="webexIot-nodata">{t("Aucune donnée")}</p>
            }

        </div> : <Loader />}
      </>
    );
  }
  