import React, { useEffect, useState } from "react";
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from "@mui/icons-material";
import TreeChart from "../../TreeChart/TreeChart";
import { useTranslation } from "react-i18next";

export default function CallQueueTable({ groupSurvey, allCalls, group, index, itemsPerPage, paginationPerGroup, setPaginationPerGroup }) {
    const [groupDataFormatted, setGroupDataFormatted] = useState();
    const [expandedRows, setExpandedRows] = useState(new Set());
    const { t } = useTranslation();

    useEffect(() => {
        if (groupSurvey) {
            const processedGroups = processCallGroups(groupSurvey);
            setGroupDataFormatted(processedGroups);
        }
    }, [groupSurvey]);

    const processGroupCalls = (calls, groupNumber) => {
        const groupCalls = calls.filter(call => call.callednumber === groupNumber);

        const correlationIdsInGroupCalls = new Set(groupCalls.map(call => call.correlationid));

        const filteredOtherCalls = allCalls.filter(call => correlationIdsInGroupCalls.has(call.correlationid));

        const processedCalls = processGroupCallsHierarchy(groupCalls, filteredOtherCalls);

        return processedCalls;
    };

    const processGroupCallsHierarchy = (groupCalls, filteredOtherCalls) => {
        const buildHierarchyFromCall = (leg, targetStartTime, correlationid) => {
            const result = {
                calls: leg.calls || [],
                children: [] || leg.children,
                correlationId: correlationid || null,
            };

            // Filtrage des appels avec le bon starttime
            const matchingCalls = leg.calls.filter(call => call.starttime === targetStartTime);
            if (matchingCalls.length > 0) {
                result.calls.push(...matchingCalls);
            }

            // Parcourir les enfants si présents
            if (leg.children) {
                for (const [childLegId, childLeg] of Object.entries(leg.children)) {
                    const childHierarchy = buildHierarchyFromCall(childLeg, targetStartTime, correlationid);
                    // Ajouter uniquement les enfants non vides
                    if (childHierarchy) {
                        result.children.push(childHierarchy);
                    }
                }
            }

            return result;
        };

        // Construire la hiérarchie sans parents vides au niveau de la racine
        return groupCalls.map(groupCall => {
            const { correlationid, starttime } = groupCall;

            const matchingLegs = filteredOtherCalls
                .filter(call => call.correlationid === correlationid)
                .flatMap(call => call.legs);

            // Appliquer `buildHierarchyFromCall` et retirer les éléments vides dans legs
            const structuredMatchingCalls = matchingLegs.map(legs => {
                const result = {};
                for (const [legId, leg] of Object.entries(legs)) {
                    const structuredLeg = buildHierarchyFromCall(leg, starttime, correlationid);
                    if (structuredLeg) {
                        result[legId] = structuredLeg;
                    }
                }
                return result;
            }).filter(leg => Object.keys(leg).length > 0); // Filtre les legs vides

            return {
                correlationid,
                legs: structuredMatchingCalls // legs non vides
            };
        }).filter(item => item.legs.length > 0); // Exclure les groupes sans legs valides
    };

    const processCallGroups = (groupSurvey) => {
        const processedGroups = {};

        for (const group in groupSurvey) {
            const calls = groupSurvey[group].calls; // Appels de ce groupe
            processedGroups[group] = {
                calls: processGroupCalls(calls, group) // Traiter les appels et les remplacer par la version filtrée et triée
            };
        }

        return processedGroups;
    };

    const getCurrentPageData = (group, groupIndex) => {
        if (!groupDataFormatted[group]) return [];

        const groupCalls = groupDataFormatted[group].calls;
        const currentPage = paginationPerGroup[groupIndex] || 1;
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;

        // Fonction récursive pour trouver le premier appel avec des données
        const findFirstCallWithData = (legs) => {
            if (typeof legs !== 'object' || legs === null) {
                console.error("Input is not a valid object");
                return null; // Assurez-vous que le paramètre est un objet
            }

            // Parcourir chaque clé de l'objet
            for (const key in legs) {
                const leg = legs[key]; // Récupérer l'objet leg

                // Vérifier si 'calls' existe et s'il a des données
                if (Array.isArray(leg.calls) && leg.calls.length > 0) {
                    return leg.calls[0]; // Retourne le premier appel trouvé dans 'calls'
                }

                // Vérifier si 'children' existe et l'explorer
                if (leg.children) {
                    // Appel récursif sur les enfants
                    const firstCall = findFirstCallWithData(leg.children);
                    if (firstCall) return firstCall; // Retourner dès qu'un appel valide est trouvé
                }
            }

            return null; // Si aucun appel valide n'est trouvé
        };

        // Récupérer le premier appel avec des données de chaque 'groupCall'
        if (Array.isArray(groupCalls) && groupCalls.length > 0) {
            const firstCalls = groupCalls
                .map(groupCall => {
                    const legs = groupCall.legs; // Récupérer les 'legs' dans chaque 'groupCall'
                    // Récupérer le premier appel valide dans chaque 'leg'
                    return Object.values(legs) // Utiliser Object.values pour parcourir les 'legs'
                        .map(leg => findFirstCallWithData(leg)) // Appliquer la fonction pour chaque leg
                        .find(call => call !== null); // Trouver le premier appel non nul
                })
                .filter(call => call !== undefined); // Retirer les appels indéfinis

            // Appliquer la pagination
            return firstCalls.slice(indexOfFirstItem, indexOfLastItem);
        }
        return []; // Retourne un tableau vide si aucune donnée valide n'est trouvée
    };

    const handleExpandFirstCall = (group, index) => {
        const processedGroups = {};
        const groupCalls = groupDataFormatted[group].calls;

        // Vérifiez si l'index est valide
        if (index < 0 || index >= groupCalls.length) {
            console.error("Index is out of bounds.");
            return processedGroups; // Retourne un objet vide si l'index est invalide
        }

        // Obtenez les appels pour le groupe et l'index spécifiés
        const calls = groupCalls[index]; // Ici, `calls` devrait être un tableau d'appels

        // Vérifiez si `calls` est bien un tableau
        if (!calls) {
            console.error("Call to expand is not defined.");
            return processedGroups; // Retourne un objet vide si l'appel n'est pas défini
        }
        // Fonction pour filtrer les legs et les calls vides
        const cleanLegs = (legs) => {
            return Object.entries(legs).map(([legId, leg]) => {
                const filteredChildren = leg.children ? leg.children.map(cleanLegs).filter(child => child) : [];

                if (leg.calls && leg.calls.length > 0 || filteredChildren.length > 0) {
                    return {
                        calls: leg.calls.filter(call => call && Object.keys(call).length > 0),
                        children: leg.children,
                        correlationId: leg.correlationId,
                    };
                }
                return null;
            })
        };


        // Construire les groupes traités à partir des calls
        const processedCalls = calls.legs ? calls.legs.map(leg => cleanLegs(leg)) : [];

        function transformStructure(node) {
            // Si `node` est un tableau, on prend le premier élément
            if (Array.isArray(node) && node.length > 0) {
                node = node[0];
            }

            // Si l'objet a des `calls` non vides, on les garde, sinon on vérifie les enfants
            if (node.calls && node.calls.length > 0) {
                return {
                    calls: node.calls,
                    children: node.children ? node.children.map(transformStructure).filter(child => child !== null) : [],
                    correlationId: node.correlationId
                };
            } else if (node.children && node.children.length > 0) {
                // S'il n'y a pas de `calls`, mais qu'il y a des enfants, on récupère le premier enfant non vide
                return transformStructure(node.children);
            }

            // Si aucune des conditions n'est remplie, on retourne `null`
            return null;
        }

        // Exécuter la transformation sur `processedCalls[0]`
        const transformedData = transformStructure(processedCalls[0]);
        return transformedData;
    };
    
    const handleRowClick = (page, index) => {
        setExpandedRows(prevRows => {
            const newRows = { ...prevRows };
            if (!newRows[page]) newRows[page] = new Set();
            const pageRows = newRows[page];
    
            if (pageRows.has(index)) {
                pageRows.delete(index);
            } else {
                pageRows.add(index);
            }
    
            return newRows;
        });
    };  

    const paginate = (groupIndex, pageNumber) => {
        setPaginationPerGroup(prev => ({
            ...prev,
            [groupIndex]: pageNumber
        }));
    };

    return (
        <>
            {groupDataFormatted && paginationPerGroup && (
                <>
                    <table className="expanded-table-callqueue">
                        <thead>
                            <tr>
                                <th></th>
                                <th title="Date de début">{t("Date de début")}</th>
                                <th title="Localisation">{t("Localisation")}</th>
                                <th title="Numéro appelant">{t("Numéro appelant")}</th>
                                <th title="Numéro appelé">{t("Numéro appelé")}</th>
                                <th title="Ligne appelée">{t("Ligne appelée")}</th>
                                <th title="Résultat de l'appel">{t("Résultat de l'appel")}</th>
                                <th title="Type d'appel">{t("Type d'appel")}</th>
                                <th title="Durée (s)">{t("Durée (s)")}</th>
                                <th title="Indicateur de réponse">{t("Indicateur de réponse")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getCurrentPageData(group, index).map((call, idx) => {
                                const rowKey = `${(paginationPerGroup[index] || 1) * itemsPerPage - itemsPerPage + idx}-${call.correlationid}`;
                                const currentRowIndex = (paginationPerGroup[index] || 1) * itemsPerPage - itemsPerPage + idx;
                                const isExpanded = expandedRows[paginationPerGroup[index]]?.has(currentRowIndex);

                                return (
                                    <React.Fragment key={rowKey}>
                                        <tr>
                                            <td className='statistics-table-wrapper-content-element'>
                                                <div className='legsCallingOpen' onClick={() => handleRowClick(paginationPerGroup[index], currentRowIndex)}>
                                                    {isExpanded ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
                                                </div>
                                            </td>
                                            <td>{call.starttime}</td>
                                            <td>{call.location}</td>
                                            <td>{call.callingnumber}</td>
                                            <td>{call.callednumber}</td>
                                            <td>{call.calledlineid}</td>
                                            <td>
                                                {
                                                    call.calloutcome === "Success" ? "Succès" :
                                                        call.calloutcome === "Refusal" ? "Refusé" :
                                                            call.calloutcome === "Failure" ? "Échec" : 'NA'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    call.calltype === 'SIP_ENTERPRISE' ? 'Interne' :
                                                        call.calltype === 'SIP_NATIONAL' ? 'National' :
                                                            call.calltype === 'SIP_MOBILE' ? 'Mobile' :
                                                                call.calltype === 'UNKNOWN' ? 'Inconnu' :
                                                                    call.calltype === 'SIP_PREMIUM' ? 'Prenium' :
                                                                        call.calltype === 'SIP_INTERNATIONAL' ? 'International' :
                                                                            call.calltype === 'SIP_INBOUND' ? 'Entrant' :
                                                                                call.calltype === 'SIP_OPERATOR' ? 'Opérateur' :
                                                                                    call.calltype === 'SIP_TOLLFREE' ? 'Sans frais' :
                                                                                        call.calltype === 'SIP_MEETING' ? 'Réunion' :
                                                                                            call.calltype || 'NA'
                                                }
                                            </td>
                                            <td>{call.duration}</td>
                                            <td className={`${call.answerindicator === 'Yes' ? 'statistics-table-wrapper-content-element-color-green' : call.answerindicator === 'Yes-PostRedirection' ? 'statistics-table-wrapper-content-element-color-refusal' : 'statistics-table-wrapper-content-element-color-red'}`}>
                                                {
                                                    call.answerindicator === "Yes" ? "Oui" :
                                                        call.answerindicator === "No" ? "Non" : "Redirection-d'appel" || 'NA'
                                                }
                                            </td>
                                        </tr>
                                        {isExpanded && (
                                            <tr>
                                                <td colSpan="10" className="expanded-details">
                                                    <TreeChart data={handleExpandFirstCall(group, currentRowIndex)} />
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="pagination-callQueue-post">
                        <button
                            onClick={() => paginate(index, paginationPerGroup[index] > 1 ? paginationPerGroup[index] - 1 : paginationPerGroup[index])}
                            disabled={paginationPerGroup[index] === 1}
                        >
                            {t("Précédent")}
                        </button>
                        <span>
                            Page {paginationPerGroup[index]} {t('sur')} {Math.ceil(groupSurvey[group].calls.length / itemsPerPage)}
                        </span>
                        <button
                            onClick={() =>
                                paginate(
                                    index,
                                    paginationPerGroup[index] < Math.ceil(groupSurvey[group].calls.length / itemsPerPage)
                                        ? paginationPerGroup[index] + 1
                                        : paginationPerGroup[index]
                                )
                            }
                            disabled={paginationPerGroup[index] === Math.ceil(groupSurvey[group].calls.length / itemsPerPage)}
                        >
                            {t("Suivant")}
                        </button>
                    </div>
                </>
            )}

        </>
    );
}