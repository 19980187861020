import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import IconsStatus from "./IconStatus";

/**
 *
 * @param {array} data - values to display
 */
export default function MultipleStatus({ data }) {
  const [index, setIndex] = useState(0);

  const handleNext = () => {
    if (index === data.length - 1) {
      setIndex(0);
    } else setIndex(index + 1);
  };

  const handlePrev = () => {
    if (index === 0) {
      setIndex(data.length - 1);
    } else setIndex(index - 1);
  };

  return (
    <div className="multiple-card">
      {data.length > 1 && (
        <div className="multiple-card_caroussel">
          <button className="multiple-card_btn">
            <FontAwesomeIcon icon={faChevronLeft} onClick={handlePrev} />
          </button>

          <span>
            {data.length > 1 && `${index + 1} :`}{" "}
            {typeof data[index] === "object" ? data[index].name : null}
          </span>
          <button className="multiple-card_btn">
            <FontAwesomeIcon icon={faChevronRight} onClick={handleNext} />
          </button>
        </div>
      )}
      <IconsStatus
        value={
          typeof data[index] === "object" ? data[index].status : data[index]
        }
        icon
      />
    </div>
  );
}
