import { useContext } from "react"
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import Loader from "../../components/Loader";
import { useQuery } from "@tanstack/react-query";
import MerakiNetworkCard from "./MerakiNetworkCard";
import TopClients from "./TopClients";
import TopDevice from "./TopDevice";
import SummaryClient from "./SummaryClient";
import Alldevices from "./Alldevices";
import { BsArrowUpRightCircleFill } from 'react-icons/bs';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import ButtonArrow from "../../components/ButtonArrow";
import { useTranslation } from "react-i18next";


export default function Meraki() {

  const { apiUrl } = useContext(AuthContext);
  const { t } = useTranslation();

  const getNetworks = async () => {
    const res = await axios.get(`${apiUrl}meraki/networksMeraki`, {
      withCredentials: true
    })
    return res.data.filter((item) => (item.name !== "Disabled"))
  }

  const networks = useQuery({
    queryKey: ["networks"],
    queryFn: getNetworks
  })


  if (networks.isFetched && !networks.data) {
    return <article className="page">
      <h1 className="page-title"> Aucune donnée Meraki</h1>
    </article>
  }

  return (
    <>
      {!networks.isLoading ? <article className="page">
        <section className="page-logo">
          <img src="./assets/meraki-logo.png" alt="Logo de Cisco Meraki" />
          <h1 className="page-title">Meraki</h1>
          <ButtonArrow url={"https://account.meraki.com/login/"} />
        </section>
        <div className="page-link-meraki-alerts">
          <Link to={"/meraki/licenses"} className="button button-new">{t('Licenses')} <FontAwesomeIcon icon={faChevronRight} /></Link>
        </div>
        <section className="networks">
          <h2>Networks</h2>
          <section className="networks-container">
            {networks.data.sort((a, b) => { return a === b ? 0 : a.name < b.name ? -1 : 1 }).map((network) => (<MerakiNetworkCard key={network.id} network={network} />))}
          </section>
        </section>
        <section className="summary-part">
          <h2>{t('Résumé')}</h2>
          <div className="summary-container">
            <SummaryClient mode="organisation" />
          </div>
          <div className="summary-usage">
            <TopClients />
            <TopDevice />
          </div>
        </section>
        <Alldevices networks={networks.data} />

      </article> : <Loader />}
    </>
  )
}