export default function RowMerakiLicence({ item }) {

    return (
        <>
            <div className="meraki">
                {item.counts.map(count => {
                    return (
                        <div className="meraki-item" key={count.model}>
                            <span className="meraki-title">{count.model}</span>
                            <span className="meraki-content">{count.count}</span>
                        </div>
                    )
                })}
            </div>
        </>
    )
}