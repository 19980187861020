import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import Form from "../../components/Forms/Form";
import Loader from "../../components/Loader";
import { useLocation, Link } from "react-router-dom";
import { PaloSchema } from "../../components/Forms/schema/YupSchema";
import RowEquipementPalo from "./RowEquipementPalo";
import { PaloEquipementColumn } from "../../components/headerContent";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import TableComponent from "../../components/TableComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberCard from "../../components/Card/NumberCard";
import { faShield, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function EquipementPalo() {
  const { apiUrl, user } = useContext(AuthContext);
  const queryClient = useQueryClient()
  const [eqtWithSupport, setEqtWithSupport] = useState([]);
  const [firewall, setFirewall] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const equipementId = searchParams.get("equipementId");
  const [filteredData, setFilteredData] = useState([]);
  const { t } = useTranslation();

  const getCiscoFirewall = async () => {
    const res = await axios.get(`${apiUrl}equipement/index`, {
      withCredentials: true,
    });
    return res.data.filter((item) => item.famille && item.famille.nom === "FIREWALL");;
  };

  const ciscoFireWall = useQuery({
    queryKey: ["ciscoFireWall"],
    queryFn: getCiscoFirewall,
  });

  const getAllPalo = async () => {
    const response = await axios.get(`${apiUrl}eqptpalo/`, {
      method: "GET",
      withCredentials: true,
    });

    return response.data;
  };

  const eqtPalo = useQuery({
    queryKey: ["eqtPalo"],
    queryFn: getAllPalo,
  });

  const getAllLicences = async () => {
    const res = await axios.get(`${apiUrl}license/palo/`, {
      method: "GET",
      withCredentials: true,
    })
    return res.data;
  };

  const licencesPalo = useQuery({
    queryKey: ["licences"],
    queryFn: getAllLicences,
  });

  const getAllSites = async (entreprise) => {
    if (user.entreprise) {
      const res = await axios(`${apiUrl}equipement/sites/${entreprise}`, {
        method: "GET",
        withCredentials: true,
      })
      return res.data;
    }
  };

  const sites = useQuery({
    queryKey: ['sites', user.entreprise],
    queryFn: () => getAllSites(user.entreprise)
  });

  useEffect(() => {
    queryClient.invalidateQueries(["sites", user.entreprise])
  }, [user.entreprise]);

  useEffect(() => {
    if (!licencesPalo.isLoading && !eqtPalo.isLoading && !ciscoFireWall.isLoading) {
      addSupportEqt();
    }
  }, [licencesPalo.isLoading, eqtPalo.isLoading, ciscoFireWall.isLoading]);

  useEffect(() => {
    if (!ciscoFireWall.isLoading && eqtWithSupport.length > 0) {
      setFirewall([...eqtWithSupport, ...ciscoFireWall.data]);
    }
  }, [eqtWithSupport, ciscoFireWall.isLoading]);

  useEffect(() => {
    if (firewall) {
      if (equipementId) {
        const filteredData = firewall.filter(cont => cont.serial_number === equipementId);
        setFilteredData(filteredData.length > 0 ? filteredData : firewall);
      } else {
        setFilteredData(firewall);
      }
    }
  }, [firewall, equipementId]);

  const addSupportEqt = () => {
    if (licencesPalo.data) {
      const eqtWithSupport = eqtPalo.data.map(item => {
        const relevantLicences = licencesPalo.data.filter(
          licence =>
            item.serial_number === licence.serial_number &&
            licence.nom_license === "Premium Partner Support"
        );
        const date_fin = relevantLicences.length > 0 ? relevantLicences[0].date_fin : "Non défini";
        return { ...item, end_of_support: date_fin }
      })
      setEqtWithSupport(eqtWithSupport);
    } else {
      const eqtWithSupport = eqtPalo.data.map(item => {
        return { ...item, support: "Non Defini", }
      })
      setEqtWithSupport(eqtWithSupport);
    }
  };

  const formContentNew = [
    { id: "serial_number", type: "text", title: "Numéro de série" },
    { id: "device_name", type: "text", title: "Nom de l'appareil" },
    { id: "ip", type: "text", title: "Adresse Ip" },
    {
      id: "site",
      type: "select",
      values: sites.data && sites.data.map((site) => {
        return { value: site.id, label: site.nom };
      }),
      title: "Site",
    },
  ];

  const createNewEquipement = async (data) => {
    const updatedData = {
      ...data,
      entreprise: user.entreprise,
      holding: user.holding
    };

    const res = await axios(`${apiUrl}eqptpalo/new`, {
      method: "post",
      withCredentials: true,
      data: updatedData,
    });
    try {
      queryClient.resetQueries({ queryKey: ["eqtPalo"] });
      return res.data;
    } catch (err){}
  };

  return (
    <>
      {!ciscoFireWall.isLoading && firewall.length > 0 ? (
        <>
          <article className="page">
              <>
                <h1 className="page-title">{t('Firewall')}</h1>
                <section className="page-section">
                  <NumberCard title="Firewall" number={filteredData.length} logo={faShield} color="#FA8334" stylesIcons="logo-firewall" />
                </section>
                  <div className="content-buttons-firewall-container">
                    {equipementId && (
                      <div className="page-link-equipement">
                        <Link to={"/Smartnet"} className="button button-new"><FontAwesomeIcon icon={faChevronLeft} /> {t('Retour')}</Link>
                      </div>
                    )}
                    <div>
                      <Form
                        formContent={formContentNew}
                        schema={PaloSchema}
                        submitFunction={createNewEquipement}
                        title="Ajouter un pare-feu"
                        action="new"
                      />
                    </div>
                  </div>
                <TableComponent
                  tableData={filteredData}
                  tableColumns={PaloEquipementColumn}
                  rowComponent={RowEquipementPalo}
                  sites={sites.data}
                  licences={licencesPalo.data}
                />
              </>
          </article>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
