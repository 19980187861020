import React, { forwardRef, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

// text search input
export function TextSearchFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const { t } = useTranslation();
  return (
    <input
      className="table-filter_input"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={t('Rechercher...')}
    />
  );
}

// a dropdown list filter
export function DropdownFilter({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {

  const { t } = useTranslation();

  // Reset value of status for DB
  const statusValues = [
    { value: "1", label: "En cours" },
    { value: "2", label: "En attente fournisseur" },
    { value: "4", label: "En attente client" },
    { value: "5", label: "Problème résolu" },
  ]
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      className="table-filter_select"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">{t('Tous')}</option>
      {options.sort(function (a, b) {
        return a === b ? 0 : a < b ? -1 : 1;
      }).map((option, i) => {

        if (id === "statuscode") {
          const statusLabel = statusValues.find((status) => status.value === option)?.label;
          return statusLabel ? (<option key={i} value={option}>
            {t(statusLabel)}
          </option>) : (<option key={i} value={option}>
            {t('Recherche avancée')}
          </option>)
        } else {
          return (<option key={i} value={option}>
            {t(option)}
          </option>)
        }

      })}
    </select>
  );
}

export const CheckBoxTest = forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);