import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faCheck,
  faDownload,
  faImage,
  faFileLines,
  faFilePdf,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import pdfIcon from "../../assets/pdf.svg";
import imageIcon from "../../assets/image.svg";
import fileIcon from "../../assets/file.svg";
import { ReactSVG } from 'react-svg'
import { useTranslation } from "react-i18next";

/**
 * 
 * @param {object} file - file or directory
 * @param {useQuery object} files - to refetch files
 * @param {string} path - path of the file
 */
export default function File({ files, file, path }) {
  const {t} = useTranslation();
  // import context
  const { apiUrl, user } = useContext(AuthContext);

  // toggle to confirm deleted file
  const [isDelete, setIsDelete] = useState(false);

  const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    return windowWidth;
  };
  
  const downloadFile = (fileName) => {
    const filePath = path.length > 0 ? "/" + path : "";

    axios({
      method: "get",
      url: `${apiUrl}files/download${filePath}/${fileName}`,
      responseType: "blob",
      withCredentials: true,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {});
  };

  const deleteFile = (fileName) => {
    axios(
      `${apiUrl}files/delete${path.length > 0 ? "/" + path : ""}/${fileName}`,
      {
        method: "delete",
        withCredentials: true,
      }
    )
      .then((res) => {
        files.refetch();
        setIsDelete(false);
      })
      .catch((err) => {
        throw err;
      });
  };

  const extensions = file.name.split('.').pop();

  const truncateFileName = (name, maxLength) => {
    const screenWidth = useWindowWidth();
    const shouldTruncate = screenWidth < 600;
    const beforeTruncate = screenWidth > 600;
    if (shouldTruncate && name.length > maxLength) {
      return name.substring(0, maxLength).trim() + "." + extensions;
    }
    if (beforeTruncate && name.length > 38) {
      return name.substring(0, 35).trim() + "." + extensions;
    }
    return name;
  };

  return (
    <div className="file-files_item">
      <div className="file-files_item-info">
        <span className="file-files_item-info-name">
          <div className="file-files_item-info-name-container">
            {
              <div className="file-files_item-info-icon">
                {
                  file.extension === "pdf"
                    ? <ReactSVG src={pdfIcon}/>
                    : file.extension === "jpg" || file.extension === "png"
                    ? <ReactSVG src={imageIcon}/>
                    : <ReactSVG src={fileIcon}/>
                }
              </div>
            }
          </div>
          {truncateFileName(file.name, 10)}
        </span>
      </div>
      <div className="file-files_item-size">
        <span>
          {file.size > 1000000
            ? `${(Math.round((file.size / 1048576) * 100) / 100).toString()} Mo`
            : `${(
                Math.round((file.size / 1024) * 100) / 100
              ).toString()} Ko`}{" "}
        </span>
      </div>
      <div className="file-files_item-button">
        <div className="file-files_item-button-container">
          <button
            className="button file-button-new"
            onClick={() => downloadFile(file.name)}
          >
            <FontAwesomeIcon icon={faDownload} />
          </button>
          {user.roles.includes("ROLE_SUPERADMIN") && (
            <button
              className="button file-button-delete"
              onClick={() => setIsDelete(true)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          )}
        </div>
      </div>
      {isDelete && (
        <div className="file-files_modal">
          <p>
            {t("Vous êtes sur le point de supprimer le fichier")}, {t(" Etes-vous sûr")}?
            
          </p>
          <div>
            <button
              className="button button-update"
              onClick={() => deleteFile(file.name)}
            >
              <FontAwesomeIcon icon={faCheck} />
            </button>
            <button
              className="button button-delete"
              onClick={() => setIsDelete(false)}
            >
              <FontAwesomeIcon icon={faCancel} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
