import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AdministratorSvgInterfaces from "./AdministratorSvgInterfaces.jsx";

export default function TypeSwitch({ tenantId, interfaces, interfaceModel }) {
    const interfacesId = interfaceModel.id;
    const [interfacesChunks, setInterfacesChunks] = useState([]);
    const [statusSwitch, setStatusSwitch] = useState([]);
    const [pathSwitch, setPathSwitch] = useState([]);
    const [textSwitch, setTextSwitch] = useState([]);
    const [dataHoverInterface, setDataHoverInterface] = useState({ data: null, interfaceNumber: null });
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [filteredInterfaces, setFilteredInterfaces] = useState();
    const navigate = useNavigate();
    const { t } = useTranslation();

    //Permet de paramétrer les informations à afficher.
    useEffect(() => {
        if (interfaces && interfaces.data && interfacesId) {
            const dataInterfacesFiltered = interfaces.data.filter(item =>
                item.interfaceName.toLowerCase().match(/\bethernet/)
                || item.interfaceName.toLowerCase().includes('gigabit')
                || item.interfaceName.toLowerCase().includes('fastethernet')
                || item.interfaceName.toLowerCase().includes('port-channel')
            );

            if (dataInterfacesFiltered.length > 0) {
                const modifiedInterfaces = dataInterfacesFiltered.map(item => ({
                    ...item,
                    interfaceName: item.interfaceName.replace(/(\D*)(\d+\/\d+|\d+)/, '$1 Port $2'),
                }));
                const groupedInterfaces = groupByInterfaceName(modifiedInterfaces);
                const interfacesChunk = sliceGroupedInterfaces(groupedInterfaces, 24);
                const dataHoverInterfaces = interfacesChunk.flat();

                setFilteredInterfaces(dataHoverInterfaces);
                setInterfacesChunks(interfacesChunk);
            } else {
                setInterfacesChunks({ error: "Data not found" });
            }
        }
    }, [interfaces, interfacesId]);

    //Permet de récupérer les différents emplacements des informations.
    useEffect(() => {
        if (interfacesChunks.length > 0) {
            setStatusSwitch(Array.from(document.getElementsByClassName(`${interfacesId}-status-switch`)));
            setPathSwitch(Array.from(document.getElementsByClassName(`${interfacesId}-path-switch`)));
            setTextSwitch(Array.from(document.getElementsByClassName(`${interfacesId}-text-switch`)));
        }
    }, [interfacesChunks, interfacesId]);

    //Permet, lorsque toutes les informations sont paramétrées et récupérées, de mettre à jour le SVG.
    useEffect(() => {
        if (interfacesChunks.length > 0 && pathSwitch.length > 0) {
            updateSvgContent(filteredInterfaces, tenantId);
        }
    }, [interfacesChunks, statusSwitch, pathSwitch, textSwitch, interfacesId, tenantId]);

    const groupByInterfaceName = (interfaces) => {
        const keywords = ["fastethernet", "tengigabit", "fortygigabit", "appgigabit", "gigabitethernet", "ethernet", "port-channel"];
        const groupedInterfaces = interfaces.reduce((acc, item) => {
            const key = keywords.find(keyword => item.interfaceName.toLowerCase().includes(keyword)) || "others";
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        }, {});

        if(interfaceModel.makeModel === "C9200L-24P-4G") {
            Object.keys(groupedInterfaces).forEach(key => {
                const group = groupedInterfaces[key];
                const index = group.findIndex(item => item.interfaceName.toLowerCase().includes("port 0/0"));
                if (index !== -1) {
                    const [portItem] = group.splice(index, 1);
                    group.push(portItem);
                }
            });
        }
    
        return groupedInterfaces;
    };

    //Permet de définir et de calculer le nombre d'interfaces à afficher par SVG.
    const sliceGroupedInterfaces = (groupedInterfaces, size) => {
        const chunks = [];

        Object.keys(groupedInterfaces).forEach(key => {
            const group = groupedInterfaces[key];

            for (let i = 0; i < group.length; i += size) {
                chunks.push(group.slice(i, i + size));
            }
        });

        return chunks;
    };

    //Permet de se rendre à une interface quand celle-ci est cliquée
    const handleLinkClick = (to) => {
        navigate(to);
    };

    //Permet de mettre à jour le contenu des SVG.
    const updateSvgContent = (interfaces, tenantId) => {
        interfaces.forEach((data, index) => {
            const interfaceId = data.id;
            // const statusElement = statusSwitch[index];
            const linkElement = pathSwitch[index];
            const textElement = textSwitch[index];
            const interfaceNumber = parseFloat(index + 1);

            if (textElement !== undefined) {
                const interfaceName = data.interfaceName;
                const match = interfaceName.match(/(?:.*\s)?Port\D*(\d+(?:\/\d+)*)/);
                if (match) {
                    const portNumbers = match[1].match(/\d+/g);
                    const lastPortNumber = portNumbers[portNumbers.length - 1];
                    textElement.textContent = lastPortNumber;
                } else {
                    textElement.innerHTML = 0;
                }
            }

            if (index < pathSwitch.length) {
                if (data.operationalStatus === 'online') {
                    // statusElement.classList.add('online-status');
                    linkElement.classList.add('online-status');
                } else if (data.operationalStatus === 'offline') {
                    // statusElement.classList.add('offline-status');
                    linkElement.classList.add('offline-status-switch');
                } else {
                    // statusElement.classList.add('other-status');
                    linkElement.classList.add('other-status');
                }
            }

            if (index < pathSwitch.length) {
                linkElement.classList.add('path-element');
                linkElement.addEventListener('click', () => handleLinkClick(`/auvik/${tenantId}/${interfaceId}`));
            }

            if (linkElement) {
                linkElement.addEventListener('mousemove', (event) => handleMouseMove(event, data, interfaceNumber));
                linkElement.addEventListener('mouseleave', handleMouseLeave);
            }
        });
    };

    //Permet d'afficher et de calculer la position du tooltip à afficher par rapport à la position de l'interface survolée avec la souris.
    const handleMouseMove = (event, data, interfaceNumber) => {
        setDataHoverInterface({ data, interfaceNumber });
        const svgElement = document.getElementById(`${interfacesId}-svg-interface`).getBoundingClientRect();
        const svgContainer = document.getElementsByClassName(`content-svg-interface`)[0].getBoundingClientRect();

        var yMousePos = event.clientY - svgElement.top - 35;
        var xMousePos = event.clientX - svgContainer.left - 59.5;

        const tooltipPosition = {
            top: yMousePos,
            left: xMousePos
        };

        setTooltipPosition(tooltipPosition);
        setIsTooltipVisible(true);
    };

    //Permet de réinitialiser les informations du tooltip lorsque le survol de la souris n'est plus sur une interface.
    const handleMouseLeave = () => {
        setDataHoverInterface({ data: null, interfaceNumber: null });
        setIsTooltipVisible(false);
    };

    if (interfacesChunks.error) {
        return <article className="page">
            <h1 className="page-title"> {t('Pas de données disponibles')}</h1>
        </article>
    }
    
    return (
        <>
            {interfaces && interfaces.data && interfacesChunks.length > 0 ? (
                <div className="content-svg-interfaces content-svg-interface">
                    <div className="grid-interfaces">
                        <AdministratorSvgInterfaces interfacesChunks={interfacesChunks} interfaceModel={interfaceModel} interfaceHover={dataHoverInterface} tooltipPosition={tooltipPosition} isTooltipVisible={isTooltipVisible} />
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
}