import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronLeft,faChevronRight} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function RefreshParcTable ({ title, data, columns, itemsPerPage }) {
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = useTranslation();

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const currentData = data.slice(startIndex, endIndex);
    
    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    return (
        <div className="content-devices-parc">
            <h2 className="title-refreshParc">{title} :</h2>
            <div className="content-table-refreshParc">
                <table className="table-container">
                    <thead>
                        <tr>
                            {columns.map((column, index) => (
                                <th key={index}>{column}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {currentData.map((rowData, rowIndex) => (
                            <tr key={rowIndex}>
                                {Object.values(rowData).map((value, colIndex) => (
                                    <td key={colIndex}>{value}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                <button onClick={() => handlePrevPage()} className="button button-new">
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <button onClick={() => handleNextPage()} className="button button-new">
                    <FontAwesomeIcon icon={faChevronRight} />
                 </button>
                <span>Page<strong>{" "}{currentPage} {t("sur")} {totalPages}</strong>
              </span>
            </div>
        </div>
    );
};