import {
  faAddressCard,
  faCalendar,
  faCheckCircle,
  faComment,
  faComments,
  faHourglassStart,
  faPause,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Incidentschema } from "../../components/Forms/schema/YupSchema";
import Update from "../../components/Update";
import { convertDateAndReturn, convertDateHoursMn, convertMinutesToHoursAndMinutes } from "../../components/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export default function RowIncidents({
  item,
}) {
  //import context
  const { apiUrl, user } = useContext(AuthContext)
  const queryClient = useQueryClient()
  const { t } = useTranslation();

  const statusValues = [
    { value: "1", label: "En cours" },
    { value: "2", label: "En attente fournisseur" },
    { value: "4", label: "En attente client" },
    { value: "5", label: "Problème résolu" },
    { value: "749690000", label: "Recherche avancée" },
    { value: "17", label: "En attente d'approbation" },
    { value: "18", label: "Approuvé" },
    { value: "19", label: "Rejeté" },
    { value: "20", label: "Terminé" },
    { value: "21", label: "En attente" },
    { value: "22", label: "Mis à jour" },
    { value: "23", label: "Planifié" },
    { value: "24", label: "Qualifié" },
    { value: "25", label: "En attente de révision des modifications" },
    { value: "26", label: "Devis émis" },
    { value: "27", label: "Devis envoyé" },
    { value: "28", label: "Ciblé" },
    { value: "29", label: "Surveillance" },
    { value: "2500", label: "Nouveau" }
  ]

  // Object for display update form

  const formContentUpdate = [
    {
      id: "is_active",
      type: "select",
      values: [
        { value: "0", label: "Inactif" },
        { value: "1", label: "Actif" },
      ],
      title: "Actif",
      value: item.is_active && item.is_active,
    },
    {
      id: "ticketnumber",
      type: "text",
      title: "Numéro de ticket",
      value: item.ticketnumber && item.ticketnumber,
    },
    {
      id: "title",
      type: "text",
      title: "Titre",
      value: item.title && item.title,
    },
    {
      id: "subject",
      type: "text",
      title: "Sujet",
      value: item.subject && item.subject,
    },
    {
      id: "description",
      type: "text",
      title: "Description",
      value: item.description && item.description,
    },
    {
      id: "prioritycode",
      type: "select",
      values: [
        { value: "Critique S1", label: "Critique S1" },
        { value: "Haute S2", label: "Haute S2" },
        { value: "Moyenne S3", label: "Moyenne S3" },
        { value: "Basse S4", label: "Basse S4" },
      ],
      title: "Sévérité",
      value: item.prioritycode && item.prioritycode,
    },
    {
      id: "createdon",
      type: "datetime-local",
      title: "Créé le",
      value:
        item.createdon && new Date(item.createdon).toISOString().slice(0, 16),
    },
    {
      id: "datepriseencharge",
      type: "datetime-local",
      title: "Prise en charge le",
      value:
        item.datepriseencharge &&
        new Date(item.datepriseencharge).toISOString().slice(0, 16),
    },
    {
      id: "resolveby",
      type: "datetime-local",
      title: "Résolu le",
      value: item.resolveby && item.resolveby !== "0000-00-00 00:00:00"
        ? new Date(item.resolveby).toISOString().slice(0, 16)
        : null,
    },
    {
      id: "statuscode",
      type: "select",
      values: statusValues,
      title: "Status",
      value: item.statuscode
    },
    {
      id: "casetypecode",
      type: "select",
      values: [
        { value: "Incident", label: "Incident" },
        { value: "Alerte", label: "Alerte" },
        { value: "Service Request", label: "Service Request" },
        { value: "Problème", label: "Problème" },
        { value: "Changement mineur", label: "Changement Mineur" },
        { value: "Changement majeur", label: "Changement Majeur" },
        { value: "Question", label: "Question" },
        { value: "Incident hors-contrat", label: "Incident hors-contrat" },
        { value: "Autre", label: "Autre" },
      ],
      title: "Type",
      value: item.casetypecode && item.casetypecode,
    },
    {
      id: "customerid_account_name",
      type: "text",
      title: "Compte",
      value: item.customerid_account_name && item.customerid_account_name,
    },
    {
      id: "primarycontactid_yomifullname",
      type: "text",
      title: "Contact",
      value:
        item.primarycontactid_yomifullname &&
        item.primarycontactid_yomifullname,
    },
    {
      id: "owninguser_fullname",
      type: "text",
      title: "Suivi par",
      value: item.owninguser_fullname && item.owninguser_fullname,
    },
    {
      id: "pdw_dureederesolutiondelincident",
      type: "number",
      title: "Durée de résolution (minutes)",
      value:
        item.pdw_dureederesolutiondelincident &&
        item.pdw_dureederesolutiondelincident,
    },
    {
      id: "onholdtime",
      type: "number",
      title: "Temps de suspension(minutes)",
      value: item.onholdtime && item.onholdtime,
    },
    {
      id: "HO",
      type: "number",
      title: "Temps écoulé (minutes)",
      value: item.HO && item.HO,
    },
    {
      id: "site_id",
      type: "hidden",
      value: item.site_id,
    },
  ];

  const updateIncident = async (data) => {
    const createdon = convertDateHoursMn(data.createdon, true);
    const resolveby = convertDateHoursMn(data.resolveby, true);
    const datepriseencharge = convertDateHoursMn(data.datepriseencharge, true);

    // Update the data object with converted date strings
    const updatedData = {
      ...data,
      entreprise: user.entreprise,
      createdon: createdon,
      resolveby: resolveby,
      datepriseencharge: datepriseencharge,
    };

    const res = await axios(`${apiUrl}incident/${item.ticketnumber}/edit`, {
      method: "put",
      withCredentials: true,
      data: updatedData,
    })

    return res.data
  };


  const fetchDeleteIncident = async () => {
    const res = await axios(`${apiUrl}incident/${item.ticketnumber}/delete`, {
      method: "delete",
      withCredentials: true,
    })

    return res.data
  };

  const deleteIncident = useMutation({
    mutationFn: fetchDeleteIncident,
    onSuccess: () => {
      queryClient.invalidateQueries(["incidents"])
    }
  })

  const updateSla = () => {
    axios(`${apiUrl}incident/${item.ticketnumber}/sla`, {
      method: "get",
      withCredentials: true
    })
      .then(res => { queryClient.invalidateQueries(["incidents"]) })
      .catch(err => {})
  }

  return (
    <div className="incidents">
      {item.description && (
        <div className="incidents-item">
          <span className="incidents-title"> {t('Description')}
            <FontAwesomeIcon icon={faComment} className="incidents-icon" />
          </span>
          <span className="incidents-content"> {item.description}</span>
        </div>
      )}
      <div className="incidents-container" >
        {item.datepriseencharge && (
          <div className="incidents-item">
            <span className="incidents-title">
              {t('Date de prise en charge')}
              <FontAwesomeIcon
                icon={faCalendar}
                className="incidents-icon"
              />
            </span>
            <span className="incidents-content">
              {convertDateAndReturn(item.datepriseencharge)}
            </span>
          </div>
        )}
        {item.resolveby && (
          <div className="incidents-item">
            <span className="incidents-title">
              {t("Date de résolution")}
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="incidents-icon"
              />
            </span>
            <span className="incidents-content">
              {convertDateAndReturn(item.resolveby)}
            </span>
          </div>
        )}
        {item.date_last_exec && (
          <div className="incidents-item">
            <span className="incidents-title">
              {t('Date du dernier échange')}
              <FontAwesomeIcon
                icon={faComments}
                className="incidents-icon"
              />
            </span>
            <span className="incidents-content">
              {convertDateAndReturn(item.date_last_exec)}
            </span>
          </div>
        )}
        {item.primarycontactid_yomifullname !== null && (
          <div className="incidents-item">
            <span className="incidents-title">
              {t('Contact')}
              <FontAwesomeIcon
                icon={faAddressCard}
                className="incidents-icon"
              />
            </span>
            <span className="incidents-content">
              {item.primarycontactid_yomifullname}
            </span>
          </div>
        )}
        {item.pdw_dureederesolutiondelincident && (
          <div className="incidents-item">
            <span className="incidents-title">
              {t('Durée de résolution')}
              <FontAwesomeIcon
                icon={faHourglassStart}
                className="incidents-icon"
              />
            </span>
            <span className="incidents-content">
              {convertMinutesToHoursAndMinutes(
                item.pdw_dureederesolutiondelincident
              )}
            </span>
          </div>
        )}
        {item.onholdtime && (
          <div className="incidents-item">
            <span className="incidents-title">
              {t('Durée de suspension')}
              <FontAwesomeIcon
                icon={faPause}
                className="incidents-icon"
              />
            </span>
            <span className="incidents-content">
              {convertMinutesToHoursAndMinutes(item.onholdtime)}
            </span>
          </div>
        )}
      </div>
      <Update
        schema={Incidentschema}
        submitFunction={updateIncident}
        deleteFunction={deleteIncident.mutate}
        updateSla={updateSla}
        formContent={formContentUpdate}
      />
    </div>

  );
}

