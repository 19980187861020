import { useContext, useEffect } from "react";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import IconsStatus from "./IconStatus";
import { useTranslation } from "react-i18next";


/**
 * @param {array} data
 * @param {object} countObject - an object with name key and title : const statusCount = {name: "Status"}
 * @param {string} objectKey - name of the key to find value in data
 * @param {string} secondKey - if data[objectKey] is a object, add a secondKey
 * @param {function} setValueType - to filter data when user click
 * @param {boolean} click - active function onClik to filter data
 */
export default function Globalview({
  data,
  countObject,
  objectKey,
  secondKey,
  setValueType,
  click,
  resetValue,
  setResetValue,
}) {
  const [globalView, setGlobalView] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);
  const {t} = useTranslation();

  const consolidateSwitch = (countObject) => {
    const switchTypes = ['switch', 'stack', 'l3Switch', 'controller'];
    const totalSwitches = switchTypes.reduce((total, switchType) => {
      if (countObject[switchType]) {
        total += countObject[switchType];
        delete countObject[switchType];
      }
      return total;
    }, 0);

    countObject['switch'] = totalSwitches;
    return countObject;
  };

  // Get numbers of device according to status
  const getGlobalView = () => {
    data.forEach((item) => {
      let keyToDisplay = secondKey ? item[objectKey][secondKey] : item[objectKey];
      if (objectKey === "statuscode") {
        switch (true) {
          case keyToDisplay === "5":
            countObject["Résolu"]++;
            break;

          case keyToDisplay !== "5":
            countObject["Non résolu"]++;
            break;
        }
      } else {
        if (countObject[keyToDisplay]) {
          countObject[keyToDisplay]++;
        } else {
          countObject[keyToDisplay] = 1;
        }
      }
    });
    
    if(objectKey === "deviceType") {
      countObject = consolidateSwitch(countObject);
    }
    setGlobalView([countObject]);
  };

  useEffect(() => {
    if (resetValue === true) {
      setActiveFilter(null);
      setResetValue(null);
    }
  }, [resetValue]);

  const handleFilterClick = (key) => {
    setValueType(key);
    setActiveFilter(key);
  };

  useEffect(() => {
    getGlobalView();
  }, [data]);  

  return (
    <>
      {globalView.map((item, index) => (
        <div className="global-view" key={index}>
          <h2> {objectKey === "statuscode" ? t("Statuts des tickets") : `${t(item.name)}`}</h2>
          <div className="global-view_content">
            {/* Converting globalView in array to map in and display data */}
            {Object.entries(item).map(([key, number]) => {
              if (key !== "name") {
                return (
                  <div key={uuidv4()} className="global-view_value">
                    {click ? (
                      <button
                        onClick={() => {
                          handleFilterClick(key);
                        }}
                        className={`button button-white ${activeFilter === key ? 'active-filter' : ''}`}
                      >
                        <IconsStatus value={key} icon />
                        <span className="global-view_value-number">{number}</span>
                      </button>
                    ) : (
                      <>
                        <IconsStatus value={key} icon />
                        <span className="gloabl-view_value-number">{number}</span>
                      </>
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      ))}
    </>
  );
}
