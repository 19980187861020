import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../context/AuthContext"
import axios from "axios";
import Loader from "../../components/Loader";
import Globalview from "../../components/Globalview";
import { MerakiDevicecolumns } from "../../components/headerContent";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import TableComponent from "../../components/TableComponent";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import MerakiClients from "./MerakiClients";
import SummaryClient from "./SummaryClient";
import NumberCard from "../../components/Card/NumberCard";
import NetworkAlert from "./NetworkAlert";
import { useTranslation } from "react-i18next";


export default function MerakiDevice() {
  const params = useParams();
  const networkId = params.networkId;
  const queryClient = useQueryClient();
  const { apiUrl, user } = useContext(AuthContext);
  const [valueType, setValueType] = useState("all");
  const [deviceFiltered, setDeviceFiltered] = useState([]);
  const [devicesByType, setDevicesByType] = useState([]);
  const [statusDevices, setStatusDevices] = useState({ online: 0, offline: 0, alerting: 0, dormant: 0, autres: 0 });
  const typeCounts = { name: "Type" };
  const { t } = useTranslation();
  const [resetValue, setResetValue] = useState(false);

  const getNetwork = async () => {
    const res = await axios.get(`${apiUrl}meraki/networksMeraki`, {
      withCredentials: true
    })
    return res.data.filter(item => {
      return item.id === networkId;
    })
  }

  const network = useQuery({
    queryKey: ["network"],
    queryFn: getNetwork
  })

  const getDevicesStatus = async () => {
    const res = await axios.get(`${apiUrl}meraki/devices_status`, {
      withCredentials: true
    })
    return res.data
  }

  const devicesStatus = useQuery({
    queryKey: ["merakiDeviceStatus"],
    queryFn: getDevicesStatus,
  })

  const getNetworkAlerts = async () => {
    const res = await axios.get(`${apiUrl}meraki/health/${networkId}/alerts`, { withCredentials: true })
    return res.data;
  }

  const networkAlerts = useQuery({
    queryKey: ["networkAlerts", networkId],
    queryFn: getNetworkAlerts
  })

  const handleFilters = () => {

    const devicesFiltered = devicesStatus.data.filter(item => {
      return item.networkId === networkId;
    });

    setDeviceFiltered(devicesFiltered);
  }


  useEffect(() => {

    if (devicesStatus.isFetched) {
      handleFilters()
    }
  }, [networkId, devicesStatus.isFetched]);

  const devicesFilterByType = () => {
    const filterByType = deviceFiltered.filter(item => {
      return item.productType === valueType;
    })
    setDevicesByType(filterByType);
  }

  useEffect(() => {
    if (valueType !== "all") {
      devicesFilterByType();
    }
  }, [valueType])

  useEffect(() => {
    devicesStatus.refetch()
  }, [networkId])

  useEffect(() => {
    if (!network.isLoading && !devicesStatus.isLoading) {
      const updatedStatus = deviceFiltered.reduce((statusDevice, device) => {
        const deviceStatus = device.status;
        if (deviceStatus === "online") {
          statusDevice.online++;
        } else if (deviceStatus === "offline") {
          statusDevice.offline++;
        } else if (deviceStatus === "alerting") {
          statusDevice.alerting++;
        } else if (deviceStatus === "dormant") {
          statusDevice.dormant++;
        } else {
          statusDevice.autres++;
        }
        return statusDevice;
      }, { online: 0, offline: 0, alerting: 0, dormant: 0, autres: 0 });
      setStatusDevices(updatedStatus);
    }
  }, [networkId, !network.isLoading,  devicesStatus.isFetched, deviceFiltered])

  return (
    <>
      {(!network.isLoading && !devicesStatus.isLoading) ? <article className="page">
        <Link to={"/meraki"} className="button button-new"> <FontAwesomeIcon icon={faChevronLeft} /> {t('Retour')}</Link>
        <h1 className="page-title">{!network.isFetching && network.data[0].name}</h1>
        <section className="page-section">
          {!networkAlerts.isLoading ? <NetworkAlert statusDevices={statusDevices} alerts={networkAlerts.data} networkId={networkId} /> : <Loader />}
        </section>
        {!devicesStatus.isLoading ? <section className="meraki-alldevices">
          <h2 className="meraki-alldevices_title">Devices</h2>
          <section className="page-section">
            <NumberCard title="Nombre d'appareils" number={deviceFiltered.length} />
            {deviceFiltered.length > 0 && <Globalview data={deviceFiltered} setValueType={setValueType} countObject={typeCounts} objectKey="productType" click resetValue={resetValue} setResetValue={setResetValue} />}
            {valueType !== "all" && <button className="button button-new page-reset" onClick={() => { setValueType("all"), setResetValue(true); setDevicesByType([]) }}> {t('Réinitialiser')}</button>}
          </section>
          {deviceFiltered.length > 0 && <TableComponent tableData={devicesByType.length > 0 ? devicesByType : deviceFiltered} tableColumns={MerakiDevicecolumns} checkbox={false} />}
        </section> : <Loader />}
        <section className="summary-part summary-clientside">
          <h2> Clients </h2>
          <div className="summary-container">
            <SummaryClient mode="network" networkId={networkId} />
          </div>
          <MerakiClients networkId={networkId} />
        </section>
      </article> : <Loader />}
    </>
  )
}