import axios from "axios";
import { useContext, useEffect, useState } from "react";
import ChartData from "../../components/ChartData";
import Loader from "../../components/Loader";
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export default function Activite() {

    // import context
    const { apiUrl} = useContext(AuthContext);
    const {t} = useTranslation();

     // Trafic Part
     const [dataForChart, setDataForChart] = useState({});
     const [allLabels, setAllLabels] = useState([]);
     const [label, setLabel] = useState(true);
     const [titleChart, setTitleChart] = useState(false);

    
    const [days, setDays] = useState(7);
    const [limit, setLimit] = useState(10);
 
     
 
     //Request
     const getActivity = async () => {
         const res = await axios(`${apiUrl}umbrella/identity_graph/${days}/${limit}`, {
             method: "get", 
             withCredentials: true
         })

         return res.data.data

        
     }

     const activite = useQuery({
        queryKey: ["activite"],
        queryFn: getActivity
     })

     useEffect(() => {
        activite.refetch()
     }, [days])
 
 
     const filterActivityForChart = () => {
        const activityForChart = {};
        activite.data && activite.data.forEach(item => {
            if(!activityForChart[item.identity_type.type]) activityForChart[item.identity_type.type] = {}
                activityForChart[item.identity_type.type]["Requêtes bloquées"] = item.counts.blockedrequests;
        })

         setAllLabels(["Requêtes bloquées"]);
         setDataForChart(activityForChart);
         setTitleChart("Nombre de requêtes bloquées par type d'identités")
     }
 
    
 
     useEffect(() => {
         filterActivityForChart()
     }, [activite.data])

     return (
         <>
            <section className="page-section">
          <div className="page-section_form">
            <form className="form">
              <div className="form-input_container limit">
                <select
                  className="only"
                  id="days"
                  onChange={(e) => setDays(e.target.value)}
                >
                  <option value="7">7 </option>
                  <option value="15">15 </option>
                  <option value="30"> 30 </option>
                </select>
                <label htmlFor="days">{t('derniers jours')}</label>
              </div>
            </form>
          </div>
      </section>
               {!activite.isFetching ? <ChartData
                 dataForChart={dataForChart}
                 label={label}
                 titleChart={titleChart}
                 allLabels={allLabels}
                 /> : <Loader />}
         </>
     )
}