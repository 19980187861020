import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { CertificateSchema } from "../../components/Forms/schema/YupSchema";
import Update from "../../components/Update";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function RowCertificats({ item }) {
  //import context
  const { apiUrl, user } = useContext(AuthContext);
  const queryClient = useQueryClient();

  // Delete certificat
  const deleteCertificate = async () => {
    const res = await axios(`${apiUrl}certificats/${item.id}/delete`, {
      method: "delete",
      withCredentials: true,
    });
    return res.data;
  };

  const deletingCertificat = useMutation({
    mutationFn: deleteCertificate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["certificats"] });
    },
  });

  // Object for display update form
  const formContentUpdate = [
    {
      id: "certificat",
      type: "text",
      title: "Certificat",
      value: item.certificat,
    },
    { id: "type", type: "text", title: "Type", value: item.type },
    { id: "fonction", type: "text", title: "Fonction", value: item.fonction },
    { id: "plaque", type: "text", title: "Plaque", value: item.plaque },
    {
      id: "expiration",
      type: "date",
      title: "Expiration",
      value: item.expiration && item.expiration.split("T")[0],
    },
  ];

  const UpdateCertificat = async (data) => {
    const res = await axios(`${apiUrl}certificats/${item.id}/edit`, {
      method: "put",
      withCredentials: true,
      data: {
        holding: user.holding,
        entreprise: user.entreprise,
        ...data,
      },
    });
    return res.data;
  };

  return (
    <>
      <div className="table-update" key={item.id}>
        <Update
          formContent={formContentUpdate}
          schema={CertificateSchema}
          submitFunction={UpdateCertificat}
          deleteFunction={deletingCertificat.mutate}
        />
      </div>
    </>
  );
}
