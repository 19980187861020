import React, { useEffect, useState  } from 'react';
import xlsx from 'node-xlsx';
import { saveAs } from 'file-saver';
import { useTranslation } from "react-i18next";
import { FileDownload } from "@mui/icons-material";

export default function FileSaverExcel({ data, name, formattedHeader }) {
    const { t } = useTranslation();
    const [formattedFileName, setFormattedFileName] = useState('');

    useEffect(() => {
        // Formater le nom du fichier lorsque le composant est monté ou lorsque le nom change
        setFormattedFileName(formatFileName(name));
    }, [name]);

   const formatFileName = (fileName) => {
        const maxLength = 31; // Limite Excel pour le nom de la feuille
        const invalidChars = /[\\/*?[\]]/g; // Caractères interdits dans Excel
        let formattedName = fileName.replace(invalidChars, '-');
        if (formattedName.length > maxLength) {
            formattedName =  `${formattedName.substring(0, maxLength - 3)}...`;
        }
        return formattedName; // Retourne le nom formaté
    };

    const handleConfirmImport = async () => {
        const blob = generateExcelData(data, formattedFileName, formattedHeader);
        saveAs(blob, `${formatFileName(name)}.xlsx`);
    }

    const generateExcelData = (devices, fileName, formattedHeader) => {
        //Avoir toute les données du tableau
        const combinedData = [];
        devices.forEach(device => {
            Object.values(device).forEach(callDetail => {
                combinedData.push(callDetail);
            });
        });
    
        // Transform data for Excel export (assuming some excel export logic here)
        const dataExcel = combinedData.map(callDetail => {
            const { source, ...data } = callDetail;
            return data;
        });

        const columnNames = Object.keys(dataExcel[0]);

        const excelData = [];

        if(!formattedHeader) {
            excelData.push(columnNames);
        }
    
        excelData.push(
            ...dataExcel.map(device => {
                const rowData = columnNames.map(col => {
                    // Récupérer la valeur de la colonne ou la date si c'est une clé correspondante
                    const dateKeys = ["starttime", "endtime", "releasetime", "answertime", "reporttime"];
                    return dateKeys.includes(col) && device[col] ? device[col].date : device[col] || "";
                });
                return rowData;
            })
        );
    
        // Créer le contenu du fichier Excel
        const excelBuffer = xlsx.build([{ name: fileName, data: excelData }]);
        // Convertir le contenu en Blob
        return new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    };

    return (
        <div onClick={handleConfirmImport} className="saveCSVWebexCalling"><FileDownload/> {t("Save as Excel")}</div>
    )
}