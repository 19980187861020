import { useEffect, useState } from "react";
import ChartData from "../../../components/ChartData";
import { exportHoursDate } from "../../../components/utils";
import { useTranslation } from "react-i18next";

export default function ChartUnicast({ packetUnicast, packetMulticast, packetBroadcast }) {
    const [dataForChart, setDataForChart] = useState(null);
    const [average, setAverage] = useState({ averageUnicast: 0, averageMulticast: 0, averageBroadcast: 0 });
    const [allLabels, setAllLabels] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (packetUnicast !== undefined && packetMulticast !== undefined && packetBroadcast !== undefined) {
            getUnicastGraph();
        }
    }, [packetUnicast, packetMulticast, packetBroadcast]);

    const getUnicastGraph = () => {
        let dataPacketUnicast = null;
        let dataPacketMulticast = null;
        let dataPacketBroadcast = null;

        dataPacketUnicast = packetUnicast.filter(itemUnicast => { return itemUnicast })
        dataPacketMulticast = packetMulticast.filter(itemMulticast => { return itemMulticast })
        dataPacketBroadcast = packetBroadcast.filter(itemBroadcast => { return itemBroadcast })

        handleDataForChart(dataPacketUnicast, dataPacketMulticast, dataPacketBroadcast);
    }

    const handleDataForChart = (dataPacketUnicast, dataPacketMulticast, dataPacketBroadcast) => {
        let groupedData = {};
        const allLabels = new Set();
        const totals = { Unicast: 0, Multicast: 0, Broadcast: 0 };

        const dataFormatedPacketUnicast = dataPacketUnicast.map(itemUnicast => ({ ...itemUnicast }))
        const dataFormatedPacketMulticast = dataPacketMulticast.map(itemMulticast => ({ ...itemMulticast }))
        const dataFormatedPacketBroadcast = dataPacketBroadcast.map(itemBroadcast => ({ ...itemBroadcast }))

        dataFormatedPacketUnicast.forEach(itemUnicast => {
            const filterByTime = exportHoursDate(itemUnicast.RecordedAt);
            ['Transmit', 'Receive'].forEach(key => {
                const newKey = `Unicast`;
                if (!groupedData[filterByTime]) groupedData[filterByTime] = {};
                groupedData[filterByTime][newKey] = (groupedData[filterByTime][newKey] || 0) + Number(itemUnicast[key]);
                totals[newKey] += Number(itemUnicast[key]);
                if (!allLabels.has(newKey)) allLabels.add(newKey);
            });
        });

        dataFormatedPacketMulticast.forEach(itemMulticast => {
            const filterByTime = exportHoursDate(itemMulticast.RecordedAt);
            ['Transmit', 'Receive'].forEach(key => {
                const newKey = `Multicast`;
                if (!groupedData[filterByTime]) groupedData[filterByTime] = {};
                groupedData[filterByTime][newKey] = (groupedData[filterByTime][newKey] || 0) + Number(itemMulticast[key]);
                totals[newKey] += Number(itemMulticast[key]);
                if (!allLabels.has(newKey)) allLabels.add(newKey);
            });
        });

        dataFormatedPacketBroadcast.forEach(itemBroadcast => {
            const filterByTime = exportHoursDate(itemBroadcast.RecordedAt);
            ['Transmit', 'Receive'].forEach(key => {
                const newKey = `Broadcast`;
                if (!groupedData[filterByTime]) groupedData[filterByTime] = {};
                groupedData[filterByTime][newKey] = (groupedData[filterByTime][newKey] || 0) + Number(itemBroadcast[key]);
                totals[newKey] += Number(itemBroadcast[key]);
                if (!allLabels.has(newKey)) allLabels.add(newKey);
            });
        });

        setAllLabels(Array.from(allLabels));
        setDataForChart(groupedData);
        setAverage({
            averageUnicast: totals.Unicast / dataFormatedPacketUnicast.length || 0,
            averageMulticast: totals.Multicast / dataFormatedPacketMulticast.length || 0,
            averageBroadcast: totals.Broadcast / dataFormatedPacketBroadcast.length || 0,
        });
    }

    return (
        <>
            {dataForChart ?
                <div className="auvik-contents">
                    <ChartData
                        dataForChart={dataForChart}
                        allLabels={allLabels}
                        isStacked={false}
                        titleChart={t("Paquets de l'appareil")}
                        typeOfChart={"line"}
                        measurementUnit={"/s"}
                        measurementUnitBandwidth={[true, null, null, null]}
                        minValueLeft={0}
                        tension={0.1}
                        radiusGraph={1}
                        setNumberTicksX={7}
                        setNumberTicksY={6}
                        setNumberRotate={0}
                    />
                                        <div className="average-container">
                        <div className="average average1">
                            <div>
                                <span>{t("Moyenne")}</span>
                                <span>{t("Monodiffusion")}</span>
                            </div>
                            <h2>{Math.round(average.averageUnicast)}/s</h2>
                        </div>
                        <div className="average average2">
                            <div>
                                <span>{t("Moyenne")}</span>
                                <span>{t("Multidiffusion")}</span>
                            </div>
                            <h2>{Math.round(average.averageMulticast)}/s</h2>
                        </div>
                        <div className="average average3">
                            <div>
                                <span>{t("Moyenne")}</span>
                                <span>{t("Radiodiffusion")}</span>
                            </div>
                            <h2>{Math.round(average.averageBroadcast)}/s</h2>
                        </div>
                    </div>
                </div>
                : <h2 className="page-title">{t("Aucune donnée")}</h2>
            }
        </>
    );
}