import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import RowUsers from "./RowUsers";
import Form from "../../components/Forms/Form";
import {UserSchema, passwordSchema} from "../../components/Forms/schema/YupSchema";
import Loader from "../../components/Loader";
import { UserColumn } from "../../components/headerContent";
import { useQuery } from "@tanstack/react-query";
import TableComponent from "../../components/TableComponent";
import { useTranslation } from "react-i18next";

export default function User() {
  const { apiUrl, user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [holdingSelected, setHoldingSelected] = useState(null);

  const getAllUsers = async () => {
    const res = await axios.get(`${apiUrl}user/findAll`, {
      withCredentials: true,
    });

    return res.data.map((user) => ({
      ...user,
      entreprise_name: user.entreprise.nom,
      holding_id: user.holding.id,
      rolesDisplay: user.roles,
    }));
  };

  const users = useQuery({
    queryKey: ["users"],
    queryFn: getAllUsers,
  });

  const allRoles = [
    { label: "Superadmin", value: "ROLE_SUPERADMIN" },
    { label: "Commercial", value: "ROLE_COMMERCIAL" },
  ];

  // get all holding
  const getHolding = async () => {
    if (user) {
      const res = await axios(`${apiUrl}commerce/holding`, {
        method: "get",
        withCredentials: true,
      });

      setHoldingSelected(user.holding);
      return res.data;
    }
  };

  const holding = useQuery({
    queryKey: ["holding"],
    queryFn: getHolding,
  });

  // get all entreprise
  const getEntreprise = async () => {
    const res = await axios(`${apiUrl}commerce/${holdingSelected}/entreprise`, {
      method: "get",
      withCredentials: true,
    });
    return res.data;
  };

  const entreprise = useQuery({
    queryKey: ["entreprises"],
    queryFn: getEntreprise,
    enabled: holdingSelected !== null,
  });

  useEffect(() => {
    if (holdingSelected !== null) {
      entreprise.refetch();
    }
  }, [holdingSelected]);

  const formContentNew = [
    { id: "email", type: "email", title: "Email" },
    { id: "prenom", type: "text", title: "Prénom" },
    { id: "nom", type: "text", title: "Nom" },
    { id: "password", type: "password", title: "Mot de passe" },
    {
      id: "holding",
      type: "select",
      values: holding.data
        ? [
          { value: "", label: " - Sélectionnez une holding - " },
          ...holding.data.map((holding) => {
            return { value: holding.id, label: holding.nom };
          })
          .sort((a, b) => {
            return a === b ? 0 : a.label < b.label ? -1 : 1;
          })
        ]: [],
      title: "Holding",
      change: async (e) => {
        await setHoldingSelected(e.target.value);
      },
    },
    {
      id: "entreprise",
      type: "select",
      values: entreprise.data
        ? [
          { value: "", label: "Sélectionnez une entreprise" },
          ...entreprise.data.map((entreprise) => ({
            value: entreprise.id,
            label: entreprise.nom,
          })),
        ]
        : [],
      title: "Entreprise",
    },
    {
      id: "roles",
      type: "select",
      values: allRoles.map((role) => role),
      multipleSelected: true,
      title: "Rôles",
    },
  ];

  const createNewUser = async (data) => {
    const res = await axios(`${apiUrl}user/new`, {
      method: "post",
      withCredentials: true,
      data: data,
    });
    return res.data;
  };

  const formPassword = [
    { id: "first", type: "password", title: "Nouveau mot de passe" },
    { id: "second", type: "password", title: "Confirmer le mot de passe" },
  ];

  const editPassword = async (data) => {
    const res = await axios(`${apiUrl}user/${user.id}/editPassword`, {
      method: "put",
      withCredentials: true,
      data: {
        password: {
          first: data.first,
          second: data.second,
        },
      },
    });
    return res.data;
  };

  return (
    <>
      {!users.isLoading ? (
        <article className="page">
          {user.roles.includes("ROLE_SUPERADMIN") ? (
            <>
              <h1 className="page-title">{t("Utilisateurs")}</h1>
              <div className="table-leftbtn">
                <Form
                  formContent={formContentNew}
                  submitFunction={createNewUser}
                  schema={UserSchema}
                  setHoldingSelected={setHoldingSelected}
                  title="Ajouter un utilisateur"
                  action="new"
                />
              </div>
              <TableComponent
                tableColumns={UserColumn}
                rowComponent={RowUsers}
                tableData={users.data}
                holding={holding.data}
                allRoles={allRoles}
              />
            </>
          ) : (
            <>
              <h1 className="page-title">Utilisateur</h1>
              <div className="user">
                <p className="user-name">{user.firstName}</p>
                <p className="user-mail">{user.username}</p>
                <Form
                  formContent={formPassword}
                  schema={passwordSchema}
                  submitFunction={editPassword}
                  title={"Modifier le mot de passe"}
                  action="update"
                />
              </div>
            </>
          )}
        </article>
      ) : (
        <Loader />
      )}
    </>
  );
}