import { useContext } from "react"
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import TableComponent from "../../components/TableComponent";
import Loader from "../../components/Loader";
import { microsoftColumn } from "../../components/headerContent";
import RowMicrosoftOverview from "./RowMicrosoftOverview";
import { AuthContext } from "../../context/AuthContext";


export default function MicrosoftOverview() {
    const { apiUrl } = useContext(AuthContext);

    const statusMap = {
        serviceOperational: "Opérationnel",
        investigating: "Investigation",
        restoringService: "Restauration",
        verifyingService: "Vérification",
        serviceRestored: "Service restauré",
        postIncidentReviewPublished: "Rapport d'incident publié",
        serviceDegradation: "Dégradation",
        serviceInterruption: "Interruption",
        extendedRecovery: "Correction",
        falsePositive: "Faux positif",
        investigationSuspended: "Investigation suspendue",
        resolved: "Résolu",
        confirmed: "Confirmé",
        reported: "Reporté"
    }

    const getOverview = async () => {
        const res = await axios.get(`${apiUrl}microsoft/healthOverview`, { withCredentials: true })
        return res.data.value.map((item) => ({ ...item, status: statusMap[item.status] }))
    }

    const healthOverview = useQuery({
        queryKey: ["healthOverview"],
        queryFn: getOverview
    })

    return (
        <>
            {!healthOverview.isLoading ?
                <section className="page-table page-table_microsoft">
                    <TableComponent
                        tableData={healthOverview.data}
                        tableColumns={microsoftColumn}
                        rowComponent={RowMicrosoftOverview}
                        checkbox={false}
                    />
                </section>
            : <Loader />}
        </>
    )
}