import { useTranslation } from "react-i18next";

export default function IncidentsActiveFilter({
  setIsActive,
  updateActiveIncidents,
  isActive,
  selectedIncidents,
}) {

  const {t} = useTranslation();

  return (
    <div className="page-section_form">
      <form className="form">
        <div className="form-input_container">
          <label>{t('Afficher les incidents')}</label>
          <select
            className="only"
            id="activation"
            defaultValue={isActive === null ? "null" : isActive}
            onChange={(e) =>
              setIsActive(e.target.value === "null" ? null : e.target.value)
            }
          >
            <option value="null">{t('Tous')}</option>
            <option value={1}>{t('Actif')}</option>
            <option value={0}>{t('Inactif')}</option>
          </select>
        </div>
        {isActive && selectedIncidents.length > 0 && (
          <button
            className="button button-new"
            onClick={(e) => {
              e.preventDefault(), updateActiveIncidents.mutate();
            }}
          >
            {isActive === "0"
              ? t("Activer les incidents")
              : t("Désactiver les incidents")}
          </button>
        )}
      </form>
    </div>
  );
}
