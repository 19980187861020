import axios from "axios"
import { useContext } from "react"
import { AuthContext } from "../../context/AuthContext"
import { useQuery } from "@tanstack/react-query";
import TableComponent from "../../components/TableComponent";
import { MerakiAllDevicesColumns } from "../../components/headerContent";
import NumberCard from "../../components/Card/NumberCard";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";


export default function Alldevices ({networks}) {

    const {apiUrl} = useContext(AuthContext);
    const {t} = useTranslation();

    const getAllDevices = async () => {
        const res = await axios.get(`${apiUrl}meraki/devices_statuses`, {withCredentials: true})
        return res.data.map((item) => {
            const networkName = networks.filter((network) => network.id === item.networkId)
            return {
                ...item,
                networkName: networkName[0].name
            }
        })
    }

    const merakiDevices = useQuery({
        queryKey: ["merakiDevices"],
        queryFn: getAllDevices
    })

    return <>{!merakiDevices.isLoading ? <section className="meraki-alldevices">
        <h2 className="meraki-alldevices_title">{t('équipements')}</h2>
        <section className="page-section">
            <NumberCard title="Nombre d'équipements" number={merakiDevices.data.length} />
        </section>
        <TableComponent tableData={merakiDevices.data} tableColumns={MerakiAllDevicesColumns} checkbox={false} />
    </section> : <Loader />}</>
}