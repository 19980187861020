import React from "react";

export default function InterfacesChunks({ interfaceId, interfaceData, interfaceHover, tooltipPosition, isTooltipVisible }) {
    const interfacesArray = Object.values(interfaceData);
    
    return (
        <>
            <div className="container-interfaces-grid">
                {interfacesArray.map((item, index) => (
                    <div key={index} className={`container-interfaces-grid-content`}>
                        <div className={`${interfaceId}-text-switch`}></div>
                        <div className="container-interfaces-grid-content-data">
                            {item.interfaceName.toLowerCase().includes('port-channel') ? (
                                <svg id={`${interfaceId}-svg-interface`} className="container-interfaces-grid-content-data-svg" xmlns="http://www.w3.org/2000/svg" width="398" height="356" viewBox="0 0 398 356" fill="none">
                                    <a className={`${interfaceId}-path-switch path-switch`}>
                                        <path d="M296.5 4V48.7861H394V200.393V352H199H4V196.459V48.7861H101.5V4H199H296.5Z" stroke="black" stroke-width="8" />
                                    </a>
                                </svg>
                            ) : (
                                <svg id={`${interfaceId}-svg-interface`} className="container-interfaces-grid-content-data-svg" xmlns="http://www.w3.org/2000/svg" width="398" height="356" viewBox="0 0 398 356" fill="transparent">
                                    <a className={`${interfaceId}-path-switch path-switch`}>
                                        <path d="M345.75 49V91H394V178V228.731V352H345.75H297.034H272.677H199H123.905H100.353H53.25H4V225.532V178V89.4264H53.25V49H100.353H123.905V4H197.5H272.677V49H297.034H345.75Z" />
                                    </a>
                                </svg>
                            )}
                            <div className={`${interfaceId}-tooltip tooltip`} style={{ top: tooltipPosition.top, left: tooltipPosition.left, display: isTooltipVisible ? 'flex' : 'none', }}>
                                {interfaceHover && interfaceHover.data ? (
                                    <>
                                        <p>{interfaceHover.data.interfaceName}</p>
                                        <p>{interfaceHover.data.operationalStatus}</p>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}