import React from "react";
import { DatePicker } from 'rsuite';
import { isBefore, isAfter } from 'date-fns';
import { useTranslation } from "react-i18next";

export default function ReservationPopup({ placeInfo, handleReserved, handleCloseReserved, handlePlaqueChange, setReserveDate, today }) {
    const { t } = useTranslation();
    
    return (
        <div id="popupParking" className="dialog-form_open">
            <div className="button-close-parking">
                <button onClick={handleCloseReserved} className="button button-delete dialog-form_close">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" className="svg-inline--fa fa-xmark " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"></path>
                    </svg>
                </button>
            </div>
            <h1>Réservation</h1>
            <p>Place de parking : {placeInfo.place_parking}</p>
            {placeInfo.pir_trigger === 0 ? (
                <>
                    <form className="auvik-charts-container-page-section-form form-parking" onSubmit={handleReserved}>
                        <div className="form-input_container">
                            <p>Réserver une place jusqu'à :</p>
                            <DatePicker
                                cleanable={false}
                                character=" / "
                                format="yyyy-MM-dd HH:mm"
                                defaultValue={today}
                                shouldDisableDate={date => isBefore(date, new Date(today.getTime() - 60 * 1000)) || isAfter(date, new Date(today.getTime() + 24 * 60 * 60 * 1000))}
                                onChange={setReserveDate}
                                locale={{
                                    sunday: t('Su'),
                                    monday: t('Mo'),
                                    tuesday: t('Tu'),
                                    wednesday: t('We'),
                                    thursday: t('Th'),
                                    friday: t('Fr'),
                                    saturday: t('Sa'),
                                    hours: t('Hours'),
                                }}
                            />
                            <p>Numéro de votre plaque :</p>
                            <input type="text" className="form-input-plaque" onChange={handlePlaqueChange} />
                        </div>
                        <button type="submit" className="button button-new">Réserver</button>
                    </form>
                </>
            ) : placeInfo.pir_trigger === 2 && (
                <button onClick={handleReserved} className="button button-new delete-reserved">Supprimer la réservation</button>
            )}
        </div>
    );
};