import axios from "axios";
import { useContext } from "react";
import Loader from "../../components/Loader";
import IconsStatus from "../../components/IconStatus";
import { AuthContext } from "../../context/AuthContext";
import { UmbrellaRoamingColumn} from "../../components/headerContent";
import { useQuery } from "@tanstack/react-query";
import TableComponent from "../../components/TableComponent";
import NumberCard from "../../components/Card/NumberCard";
import { useTranslation } from "react-i18next";

export default function Roaming() {
  const { apiUrl} = useContext(AuthContext);
  

  // Request
  const getRoamingComputers = async () => {
    const res = await axios(`${apiUrl}umbrella/roaming_computers`, {
      method: "get",
      withCredentials: true,
    })
      return res.data
  };

  const roaming = useQuery({
    queryKey: ["roaming"],
    queryFn: getRoamingComputers
  })

  return (
    <>
      {!roaming.isLoading ? (
        <>
          <section className="page-section">
            <NumberCard title="Client en ligne" 
            number={Math.round((roaming.data.filter((item) => item.status !== "Off").length * 100) / roaming.data.length) + "%" } />
          </section>
            <TableComponent
              tableColumns={UmbrellaRoamingColumn}
              tableData={roaming.data}
              checkbox={false}
            />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
