import { faGears, faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertDateAndReturn } from "../../components/utils";
import { useTranslation } from "react-i18next";

export default function RowAmpTable({ item }) {

  const {t} = useTranslation();
  return (
    <>
      <div className="amp" key={item.id}>
        {item.av_update_definitions.version && (
          <div className="amp-item">
            <span className="amp-title">
              Version
              <FontAwesomeIcon icon={faGears} className="amp-icon" />
            </span>
            <span className="amp-content">
              {item.av_update_definitions.version}
            </span>
          </div>
        )}
        {item.av_update_definitions.updated_at && (
          <div className="amp-item">
            <span className="amp-title">
              {t('Mise à jour le')}
              <FontAwesomeIcon icon={faRotate} className="amp-icon" />
            </span>
            <span className="amp-content">
              {convertDateAndReturn(item.av_update_definitions.updated_at)}
            </span>
          </div>
        )}
      </div>
    </>
  );
}
