import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Loader from "../../components/Loader";
import TableComponent from "../../components/TableComponent";
import { webexIoTColumn } from "../../components/headerContent";
import RowWebexIoT from "./RowWebexIoT";
import { useTranslation } from "react-i18next";

export default function WebexIot() {
    const { apiUrl, user } = useContext(AuthContext);
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const getWorkspacesWebex = async () => {
        try {
            const res = await axios.get(`${apiUrl}webex/workspaces`, {
                withCredentials: true
            });
            return res.data;
        } catch (error) {
        }
    }

    const webexWorkspaces = useQuery({
        queryKey: ["webexWorkspaces"],
        queryFn: getWorkspacesWebex
    })

    useEffect(() => {
        queryClient.invalidateQueries(["webexWorkspaces", webexWorkspaces.data]);
    }, [webexWorkspaces.data]);

    if (webexWorkspaces.isFetched) {
        if (webexWorkspaces.data[0] === 'No data') {
            return <section>
                <h1 className="page-title">
                    {t('Aucun équipements détectées')}
                </h1>
            </section>
        }
    }

    return (
        <>
            {!webexWorkspaces.isLoading ?
                <section>
                    <TableComponent tableColumns={webexIoTColumn} tableData={webexWorkspaces.data.items} checkbox={false} rowComponent={RowWebexIoT} />
                </section>
                : <Loader />
            }
        </>
    )
}