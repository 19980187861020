import {
    Chart,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
} from "chart.js/auto";
import { useEffect, useRef, useState } from "react";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import { convertDateFormat } from '../../../../components/utils.js';
import Loader from "../../../../components/Loader";
import { useTranslation } from "react-i18next";

export default function ChartsWebex({ dataForChart, allLabels, allDates, labelTitle, typeOfChart, titleChart, date, handleGraphClick, mesurementUnit }) {
    const chartRef = useRef();

    Chart.register(
        Title,
        Tooltip,
        Legend,
        CategoryScale,
        LinearScale,
        BarElement,
        TimeScale,
    );

    const { t } = useTranslation();
    const [chartDataDoughnut, setChartDataDoughnut] = useState({});
    const [optionsDoughnut, setOptionsDoughnut] = useState({});
    const [chartDataBar, setChartDataBar] = useState({});
    const [optionsBar, setOptionsBar] = useState({});
    const [chartData, setChartData] = useState({});
    const [options, setOptions] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const colors = {
        "Redirection-d\'appel": "#997adb",
        "Call redirection": "#997adb",
        "Oui": "#AEC353",
        "Non": "#124188",
        "Yes": "#AEC353",
        "No": "#124188",
        "Remote": "#997adb",
        "Interne": "#997adb",
        "National": "#CC8C6E",
        "Entrant": "#AEC353",
        "Sortant": "#997adb",
        "Incoming": "#AEC353",
        "Outgoing": "#997adb",
        "Mobile": "#EB5C5C",
        "Prenium": "#FFE033",
        "Opérateur": "#EAE1B2",
        "Sans frais": "#004299",
        "Inconnu": "#e3e4ed",
        "International": "#55C3FC",
        "Réunion": "#124188",
        "Courbevoie": "#997adb",
        "Sortant - Nombre d'appels répondus": "rgba(170 130 255 / 50%)",
        "Sortant - Nombre d'appels non répondus": "rgba(170 130 255 / 50%)",
        "Sortant - Durée totale des appels sortants": "rgba(170 130 255 / 50%)",
        "Entrant - Nombre d'appels répondus": "rgba(174 195 83 / 50%)",
        "Entrant - Nombre d'appels non répondus": "rgba(174 195 83 / 50%)",
        "Entrant - Durée totale des appels entrants": "rgba(174 195 83 / 50%)",
        "Total - Durée totale des appels": "rgba(101, 175, 232, 0.5)",
        "Total - Réponse totale des appels": "rgba(101, 175, 232, 0.5)",
    };

    const textCenter = {
        id: 'textCenter',
        beforeDatasetsDraw(chart) {
            const { ctx, data, chartArea: { left, right, top, bottom } } = chart;
            ctx.save();
    
            // Récupérer les données visibles seulement
            const meta = chart.getDatasetMeta(0); // Meta du premier dataset
            
            const visibleData = data.datasets[0].data.filter((value, index) => !meta.data[index].hidden);

            // Recalculer le total des segments visibles
            const total = visibleData.reduce((acc, value) => acc + value, 0);
            const secondLineText = `${total}`;
    
            // Style du texte et position centrale
            ctx.font = '35px sans-serif';
            ctx.fillStyle = '#000';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
    
            const centerX = (left + right) / 2;
            const centerY = (top + bottom) / 2;
            ctx.fillText(secondLineText, centerX, centerY - 10);
    
            const firstLineText = 'Total';
            ctx.font = '20px sans-serif';
            ctx.fillText(firstLineText, centerX, centerY + 20);
    
            ctx.restore();
        }
    };

    const getDifferentShade = (baseColor, variation) => {
        // Convertir la couleur de base en un nombre entier
        const color = parseInt(baseColor.substring(1), 16);
    
        // Extraire les composantes RGB
        let r = (color >> 6) & 0xFF;
        let g = (color >> 6) & 0xFF;
        let b = color & 0xFF;
    
        // Modifier les composantes en fonction de la variation pour obtenir une couleur différente
        r = (r + variation * 50) % 255;
        g = (g + variation * 70) % 255;
        b = (b + variation * 90) % 255;
    
        // Retourner la nouvelle couleur en format `rgb()`
        return `rgb(${r}, ${g}, ${b})`;
    };
    
    const createChart = () => {
        if (typeOfChart === "doughnut") {
            const defaultColors = ['#00BCEB', '#005072', '#6EBE4A'];
            const labels = allLabels;

            // Générer les nouvelles couleurs
            const newColors = labels.map((label, index) => {
                if (index < defaultColors.length) {
                    return defaultColors[index]; // Utiliser la couleur par défaut si disponible
                }
                
                // Utiliser une variation de la couleur de base
                const baseColor = defaultColors[index % defaultColors.length];
                const variation = Math.floor(index / defaultColors.length); // Calculer une variation unique pour chaque nouveau label
                
                return getDifferentShade(baseColor, variation); // Générer une couleur différente
            });

            const datasets = [
                {
                    label: typeof labelTitle !== 'string' ? labels.map(label => labelTitle[label][1] || 0) : t(`${labelTitle}`),
                    data: typeof labelTitle !== 'string' ? labels.map(label => dataForChart[label][0] || 0) : labels.map(label => dataForChart[label] || 0),
                    backgroundColor: typeof labelTitle !== 'string' ? newColors : labels.map(label => colors[label]), 
                    hoverBackgroundColor: typeof labelTitle !== 'string' ? newColors: labels.map(label => colors[label]), 
                    borderColor: '#fff',
                    borderWidth: 2,
                    untranslatedLabel: labelTitle
                }
            ];

            setChartDataDoughnut({ labels, datasets });

            setOptionsDoughnut({
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'right',
                        labels: {
                            padding: 15,
                            color: "#000",
                            font: {
                                family: "Inter",
                                size: 11,
                                weight: 700
                            },
                            usePointStyle: true,
                            generateLabels: (chart) => {
                                const dataset = chart.data.datasets[0];
            
                                return chart.data.labels.map((label, index) => {
                                    const meta = chart.getDatasetMeta(0);
                                    const hidden = meta.data[index].hidden;
                                    return {
                                        text: label,
                                        fillStyle: dataset.backgroundColor[index],
                                        strokeStyle: '#fff',
                                        lineWidth: 2,
                                        hidden: hidden,
                                        index: index,
                                        // Ajouter le style rayé si l'élément est masqué
                                        textDecoration: hidden ? 'line-through' : ''
                                    };
                                });
                            },
                        },
                        onClick: (e, legendItem, legend) => {
                            const index = legendItem.index;
                            const chart = legend.chart;
                            const meta = chart.getDatasetMeta(0);
                            meta.data[index].hidden = !meta.data[index].hidden;
                            chart.update();
                        }
                    },
                    textCenter: {
                        enabled: true,
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                if(typeof labelTitle !== 'string') {
                                    let label = [];
                                    const currentLabelData = dataForChart[context.label];
                                    if (currentLabelData) {
                                        label.push(`${currentLabelData[1]} : ${currentLabelData[0]}`);
                                    }
                                    return label
                                } 
                            }
                        },
                        displayColors: false
                    }
                },
                hover: {
                    mode: null,
                },
                cutout: '75%',
                animation: {
                    animateRotate: true,
                    animateScale: true,
                },
            });
        } else if (typeOfChart === "bar") {
            const labels = dataForChart ? Object.keys(dataForChart)
                .filter((item) => item !== "")
                .sort((a, b) => new Date(a) - new Date(b)) : [];

            const datasets = allLabels.map((label) => ({
                label: label,
                data: labels.map((name) => dataForChart[name][label] || 0),
                borderRadius: 0,
                borderColor: colors[label],
                backgroundColor: colors[label],
                untranslatedLabel: labelTitle
            }));

            setChartDataBar({ labels, datasets });

            setOptionsBar({
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: typeOfChart === 'bar',
                        text: t(titleChart),
                        align: "center",
                        color: "#000",
                        font: {
                            family: "Inter",
                            size: 12,
                        },
                    },
                    legend: {
                        position: "bottom",
                        labels: {
                            padding: 10,
                            color: "#000",
                            font: {
                                family: "Inter",
                                size: 11,
                                weight: 700
                            },
                            usePointStyle: true,
                        },
                    },
                    tooltip: {
                        callbacks: {
                            title: function (context) {
                                const starttime = convertDateFormat(date[0]);
                                const endtime = convertDateFormat(date[1]);
                                const customTitle = `${starttime} - ${endtime}`;
                              
                                return customTitle; 
                            },
                            label: function (context) {
                                let label = [];

                                const currentLabelData = dataForChart[context.label];
                                Object.keys(currentLabelData).forEach(key => {
                                    if (key !== context.dataset.label) {
                                        label.push(`${key} : ${currentLabelData[key]} (100.00%)`);
                                    }
                                });

                                const percentage = ((context.raw / currentLabelData.Total) * 100).toFixed(2); // Calculate the percentage

                                label.push(`${context.dataset.label} : ${context.raw} (${percentage}%)`);

                                return label;
                            }
                        },
                        displayColors: false
                    }
                },
                scales: {
                    x: {
                        display: false,
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        stacked: true,
                        grid: {
                            display: false,
                        },
                    },
                },
            });
        } else if (typeOfChart === "trends-bar" || typeOfChart === "trends-line") {
            const dataset = {
                label: labelTitle ? labelTitle.map(label => label) : t(titleChart),
                data: allLabels.map((label) => dataForChart[label] || 0),
                borderColor: colors[titleChart],
                backgroundColor: colors[titleChart],
                borderWidth: 2,
                pointRadius: 0,
            };

            setChartData({
                labels: allLabels,
                datasets: [dataset],
            });

            setOptions({
                responsive: true,
                indexAxis: "x",
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        position: "top",
                        text: t(titleChart),
                        align: "center",
                        padding: 20,
                        color: "#00000",
                        font: {
                            family: "Inter",
                            size: "15",
                        },
                    },
                    legend: {
                        display: false,
                        position: "bottom",
                        labels: {
                            padding: 10,
                            color: "#00000",
                            font: {
                                family: "Inter",
                                size: 11,
                                weight: 700
                            },
                            usePointStyle: true,
                        },
                    },
                    tooltip: {
                        callbacks: {
                            title: function (context) {
                                if (labelTitle) {
                                    return context[0].dataset.label[context[0].dataIndex];
                                } else {
                                    if(allDates) {
                                        return allDates[context[0].dataIndex];
                                    } else {
                                        return context[0].label;
                                    }
                                }
                            },
                            label: function (context) {
                                // Convertir les secondes en HH:mm:ss
                                const valueInSeconds = context.raw;
                                const hours = Math.floor(valueInSeconds / 3600);
                                const minutes = Math.floor((valueInSeconds % 3600) / 60);
                                const seconds = valueInSeconds % 60;
                                const formattedValue = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                                let label = `${context.label} : ${mesurementUnit === 'formatDuration' ? formattedValue : context.formattedValue} ${mesurementUnit !== 'formatDuration' && mesurementUnit ? '/' + mesurementUnit : ''}`;
                                return label;
                            }
                        },
                        displayColors: false
                    }
                },
                interaction: {
                    intersect: false,
                },
                scales: {
                    x: {
                        ticks: {
                            color: "#00000",
                            font: {
                                size: 10,
                                family: "Inter",
                                weight: 800
                            },
                            maxTicksLimit: 8,
                        },
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        ticks: {
                            color: "#00000",
                            font: {
                                size: 10,
                                family: "Inter",
                                weight: 800
                            },
                            callback: function(value) {
                                if(mesurementUnit === 'formatDuration') {
                                    let displayValue;
                                    // On arrondit pour simplifier l'affichage
                                    if (value >= 3600) { // Si c'est supérieur à 1 heure
                                        const hours = Math.floor(value / 3600);
                                        const minutes = Math.round((value % 3600) / 60);
                                        displayValue = `${hours}h${minutes}`;
                       
                                    } else if (value >= 60) { // Si c'est supérieur à 1 minute
                                        const minutes = Math.floor(value / 60);
                                        displayValue = `${minutes}m`;
                                    } else { // Sinon on affiche en secondes
                                        displayValue = `${Math.floor(value)}s`;
                                    }
                                 
                                    return displayValue; // Return the current value for display
                                    
                                } else {
                                    return value;
                                }
                            },
                            maxTicksLimit: 10,
                        },
                        grid: {
                            color: "#00000",
                            lineWidth: 0.2,
                        },
                    },
                },
            });
        } else if (typeOfChart === "line-bar") {
            const dataset = {
                label: labelTitle === "Average_Hour" ? 'Moyenne d\'appels' : "Total d\'appels",
                data: allLabels.map((label) => dataForChart[label] || 0),
                backgroundColor: '#124188',
                borderColor: '#124188',
                borderWidth: 2,
                pointRadius: 0,
            };

            setChartData({
                labels: allLabels,
                datasets: [dataset],
            });

            setOptions({
                responsive: true,
                indexAxis: "x",
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        position: "top",
                        text: t(titleChart),
                        align: "center",
                        padding: 20,
                        color: "#00000",
                        font: {
                            family: "Inter",
                            size: "15",
                        },
                    },
                    legend: {
                        position: "bottom",
                        labels: {
                            padding: 10,
                            color: "#00000",
                            font: {
                                family: "Inter",
                                size: 11,
                                weight: 700
                            },
                            usePointStyle: true,
                        },
                    },
                },
                interaction: {
                    intersect: false,
                },
                scales: {
                    x: {
                        ticks: {
                            color: "#00000",
                            font: {
                                size: 10,
                                family: "Inter",
                                weight: 800
                            },
                            maxTicksLimit: 6,
                        },
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        ticks: {
                            color: "#00000",
                            font: {
                                size: 10,
                                family: "Inter",
                                weight: 800
                            },
                        },
                        grid: {
                            color: "#00000",
                            lineWidth: 0.2,
                        },
                    },
                },
            });
        } else if (typeOfChart === "line") {
            const dataset = {
                label: labelTitle === "Average_Hour" ? 'Moyenne d\'appels' : "Total d\'appels",
                data: allLabels.map((label) => dataForChart[label] || 0),
                backgroundColor: '#124188',
                borderColor: '#124188',
                borderWidth: 2,
                pointRadius: allLabels.length === 1 ? 3 : 0,
            };

            setChartData({
                labels: allLabels,
                datasets: [dataset],
            });

            setOptions({
                responsive: true,
                indexAxis: "x",
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        position: "top",
                        text: t(titleChart),
                        align: "center",
                        padding: 20,
                        color: "#00000",
                        font: {
                            family: "Inter",
                            size: "15",
                        },
                    },
                    legend: {
                        position: "bottom",
                        labels: {
                            padding: 10,
                            color: "#00000",
                            font: {
                                family: "Inter",
                                size: 11,
                                weight: 700
                            },
                            usePointStyle: true,
                        },
                    },
                },
                interaction: {
                    intersect: false,
                },
                scales: {
                    x: {
                        ticks: {
                            color: "#00000",
                            font: {
                                size: 10,
                                family: "Inter",
                                weight: 800
                            },
                            maxTicksLimit: 6,
                        },
                        grid: {
                            display: false,
                        },
                    },
                    y: {
                        ticks: {
                            color: "#00000",
                            font: {
                                size: 10,
                                family: "Inter",
                                weight: 800
                            },
                        },
                        grid: {
                            color: "#00000",
                            lineWidth: 0.2,
                        },
                    },
                },
            });
        };
    };

    useEffect(() => {
        createChart();
        setIsLoading(false);
    }, [dataForChart, allLabels]);

    return (
        <>
            {!isLoading ? (
                <>
                    {typeOfChart === "doughnut" ? (
                        <Doughnut
                            data={chartDataDoughnut}
                            options={optionsDoughnut}
                            ref={chartRef}
                            plugins={[textCenter]}
                            onClick={(event) =>
                                handleGraphClick
                                  ? handleGraphClick(event, chartRef.current)
                                  : undefined
                            }
                        />
                    ) : typeOfChart === "bar" ? (
                        <Bar
                            data={chartDataBar}
                            options={optionsBar}
                            ref={chartRef}
                            onClick={(event) =>
                                handleGraphClick
                                  ? handleGraphClick(event, chartRef.current)
                                  : undefined
                            }
                        />
                    ) : typeOfChart === "trends-bar" ? (
                        <Bar
                            data={chartData}
                            options={options}
                            ref={chartRef}
                        />
                    ): typeOfChart === "trends-line" ? (
                        <Line
                            data={chartData}
                            options={options}
                            ref={chartRef}
                        />
                    )  : typeOfChart === "line-bar" ? (
                        <Bar
                            data={chartData}
                            options={options}
                            ref={chartRef}
                        />
                    ) : typeOfChart === "line" && (
                        <Line
                            data={chartData}
                            options={options}
                            ref={chartRef}
                        />
                    )}
                </>
            ) : (
                <Loader />
            )}
        </>
    );
}
