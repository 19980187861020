import { useState } from "react";
import CarousselNav from "../../components/Card/CarousselNav";
import Activite from "./Activite";
import CategoryTop from "./CategoryTop";
import DestinationTop from "./DestinationTop";
import EventTop from "./EventTop";
import IdentitieTop from "./IdentitieTop";
import Roaming from "./Roaming";
import Threat from "./Threat";
import Trafic from "./Trafic";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import Loader from "../../components/Loader";
import ButtonArrow from "../../components/ButtonArrow";
import { useTranslation } from "react-i18next";

export default function Umbrella() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const {apiUrl} = useContext(AuthContext)
  const {t} = useTranslation();

    //Request
    const getSummary = async () => {
    
      const res = await axios(`${apiUrl}umbrella/summary/7/10`, {
        method: "get",
        withCredentials: true,
      })
       return res.data.data
    };
  
    const summary = useQuery({
      queryKey: ["summary"],
      queryFn: getSummary
    })

  const valuesType = ["Activité", "Client Umbrella", "Trafic bloqué", "Menaces", "Top events", "Top identités", "Top destinations", "Top catégories"];


  
  if(summary.isError) {
    return  <article className="page">
        <h1 className="page-title">
            {t("Aucun équipement Umbrella")}
        </h1>
        </article>
  }


  return (
    <>
     {!summary.isLoading ? <article className="page">
    <section className="page-section">
     <section className="page-logo">
          <h1>
            <img src="./assets/cisco_umbrella.png" alt="logo de cisco Umbrella" style={{height: "7rem"}} />
            <ButtonArrow url={"https://login.umbrella.com/"} />
            </h1>
      </section>
      <CarousselNav arrayValues={valuesType} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} />
      </section>
        {/* {valuesType[currentSlide] === "Résumé" && <Summary />} */}
        {valuesType[currentSlide] === "Activité" && <Activite />}
        {valuesType[currentSlide] === "Client Umbrella" && <Roaming />}
        {valuesType[currentSlide] === "Trafic bloqué" && <Trafic />}
        {valuesType[currentSlide] === "Menaces" && <Threat />}
        {valuesType[currentSlide] === "Top events" && <EventTop />}
        {valuesType[currentSlide] === "Top identités" && <IdentitieTop />}
        {valuesType[currentSlide] === "Top destinations" && <DestinationTop />}
        {valuesType[currentSlide] === "Top catégories" && <CategoryTop />}
      </article> : <Loader />}
    </>
  );
}
