import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AuvikStatisticsCard({ firstTenant, statisticsAuvik, alerts }) {
    const domainPrefix = statisticsAuvik.prefix;
    const firstDomainPrefix = firstTenant ? firstTenant.prefix : null;
    const [result, setResult] = useState('');
    const [alertStatusLength, setAlertStatusLength] = useState({});
    const {t} = useTranslation();

    useEffect(() => {
        if (domainPrefix.includes("2")) {
            setResult(domainPrefix.replace(/.*?2/, ''));
        } else if (domainPrefix.includes("suntory") && !domainPrefix.includes("2")) {
            setResult(domainPrefix.replace(/suntory/, ''));
        } else {
            setResult(domainPrefix);
        }
    }, [domainPrefix]);
    

    useEffect(() => {
        const alertData = Object.entries(alerts).find(([name]) => name.replace(/\s/g, '').toLocaleLowerCase() === result);

        if (alertData) {
            const [alertName, alertValue] = alertData;
            setAlertStatusLength({ [alertName.replace(/\s/g, '').toLocaleLowerCase()]: alertValue });
        } else if (result === 'nly' && alerts['Neuilly']) {
            setAlertStatusLength({ 'nly': alerts['Neuilly'] });
        } else if (result === 'elithq' && alerts['Elit-Technologies Headquarters']) {
            setAlertStatusLength({ 'elithq': alerts['Elit-Technologies Headquarters'] });
        } else if(domainPrefix === firstDomainPrefix && alerts['all']) {
            setAlertStatusLength({ 'all': alerts['all'] });
        } 
    }, [result, alerts]);

    return (
        <>
            <div className="auvik-container">
                {alertStatusLength[result]  ? (
                    <div className="alert-status-length">
                        {alertStatusLength[result]}
                    </div>
                ) : (alertStatusLength['all'] && (
                    <div className="alert-status-length">
                        {alertStatusLength['all']}
                    </div>
                ))}
                <Link to={`/auvik/${statisticsAuvik.auvik_id}`}>   
                    <h2>
                        {domainPrefix === firstDomainPrefix ? t('TOUS') : result}
                    </h2>
                </Link>
            </div>
        </>
    )
}