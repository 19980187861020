import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import TableComponent from "../../components/TableComponent";
import { MerakiDevicecolumns } from "../../components/headerContent";
import Loader from "../../components/Loader";
import RowMerakiIot from "./RowMerakiIoT";
import { useTranslation } from "react-i18next";

export default function MerakiIoT() {
  const { apiUrl, user } = useContext(AuthContext);
  const {t} = useTranslation();

  const getDevicesStatus = async () => {
    const res = await axios.get(`${apiUrl}meraki/devices_status`, {
      withCredentials: true,
    });
    return res.data.filter(device => device.productType === "camera");
  };

  const devicesStatus = useQuery({
    queryKey: ["merakiDeviceStatus"],
    queryFn: getDevicesStatus,
  });


  if(devicesStatus.isFetched) {

    if(devicesStatus.data.length === 0) {
        return <section>
        <h1 className="page-title">
            {t('Aucune caméra détectée')}
        </h1>
    </section>
    }
  }


  return (
    <>
      {!devicesStatus.isLoading ? (
        <>
        <TableComponent
          tableColumns={MerakiDevicecolumns}
          tableData={devicesStatus.data}
          checkbox={false}
          rowComponent={RowMerakiIot}
        />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
