import * as Yup from "yup";

export const CertificateSchema = Yup.object().shape({
  certificat: Yup.string().required("Merci de saisir un nom de certificat"),
  type: Yup.string(),
  fonction: Yup.string(),
  plaque: Yup.string(),
  expiration: Yup.string().required("Merci de saisir une date"),
});

export const EquipementSchema = Yup.object().shape({
  device_name: Yup.string(),
  serial_number: Yup.string().required("Merci de saisir un numéro de série"),
  ip: Yup.string(),
  software_version: Yup.string(),
  BU: Yup.string(),
  site: Yup.string(),
});

export const Incidentschema = Yup.object().shape({
  is_active: Yup.string(),
  ticketnumber: Yup.string(),
  title: Yup.string(),
  subject: Yup.string(),
  description: Yup.string(),
  prioritycode: Yup.string(),
  createdon: Yup.date().nullable().transform((value, originalValue) => {
    if (originalValue === '') {
      return null;
    }
    return value;
  }),
  datepriseencharge: Yup.date().nullable().transform((value, originalValue) => {
    if (originalValue === '') {
      return null;
    }
    return value;
  }),
  resolveby: Yup.date().nullable().transform((value, originalValue) => {
    if (originalValue === '') {
      return null;
    }
    return value;
  }),
  statuscode: Yup.string(),
  casetypecode: Yup.string(),
  customerid_account_name: Yup.string(),
  primarycontactid_yomifullname: Yup.string(),
  owninguser_fullname: Yup.string(),
  pdw_dureederesolutiondelincident: Yup.number().nullable().transform((value, originalValue) =>
  originalValue === '' ? null : value),
  onholdtime: Yup.number().nullable().transform((value, originalValue) =>
  originalValue === '' ? null : value),
});

export const LicenceSchema = Yup.object().shape({
  nom_license: Yup.string().required("Merci de saisir un nom de License"),
  auth_code: Yup.string().required("Merci de saisir un code d'authentification"),
  date_fin: Yup.date().nullable().transform((value, originalValue) =>
  originalValue === '' ? null : value).required("Merci de saisir une date d'expiration"),
});

export const PaloSchema = Yup.object().shape({
  serial_number: Yup.string().required("Merci de saisir un numéro de série"),
  device_name: Yup.string().required("Merci de saisir le nom de l'appareil"),
  ip: Yup.string().required("Merci de saisir une adresse Ip"),
  site: Yup.string().required("Merci d'ajouter un site"),
});

export const SubstitutionSchema = Yup.object().shape({
  holding: Yup.string().required("Merci de sélectionner une holding"),
  entreprise: Yup.string().required("Merci de sélectionner une entreprise"),
});

export const UserSchema = Yup.object().shape({
  prenom: Yup.string().required("Merci de saisir votre prénom"),
  nom: Yup.string().required("Merci de saisir votre nom"), 
  email: Yup.string()
  .required("Merci de saisir votre email")
  .email("Merci de saisir un email valide"),
  password: Yup.string().required("Merci de saisir un mot de passe"),
  holding: Yup.string().required("Sélectionnez une holding"),
  entreprise: Yup.string().required("Sélectionnez une entreprise"),
  roles: Yup.array().required("Sélectionnez au moins un rôle"),
});

export const UserEditSchema = Yup.object().shape({
  prenom: Yup.string().required("Merci de saisir votre prénom"),
  nom: Yup.string().required("Merci de saisir votre nom"), 
  email: Yup.string()
  .required("Merci de saisir votre email")
  .email("Merci de saisir un email valide"),
  roles: Yup.array().required("Merci de sélectionner un rôle"),
  holding: Yup.string().required("Merci de sélectionner une holding"),
  entreprise: Yup.string().required("Merci de sélectionner une entreprise"),
});

export const passwordSchema = Yup.object().shape({
  first: Yup.string()
    .required('Le mot de passe est obligatoire'),
  second: Yup.string()
    .required('La confirmation du mot de passe est obligatoire')
    .oneOf([Yup.ref('first'), null], 'Les mots de passe doivent être identiques')
});
