import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


export default function Error404 () {

    const {t} = useTranslation();

    return (
        <article className="page">
            <h1 className="page-title">
                {t("Cette page n'existe pas")}
            </h1>
            <p className="page-link_home">
                <Link to="/"> {t("Revenir à l'accueil")}</Link>
            </p> 
        </article>
    )
}