import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import {
  faCancel,
  faCheck,
  faFolderOpen,
  faPen,
  faTrash
} from "@fortawesome/free-solid-svg-icons";

export default function Directory({ file, files, path, handlePath }) {
  const [isInput, setIsInput] = useState(false);
  const { apiUrl } = useContext(AuthContext);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [error, setError] = useState(null);
  // Fonction pour renommer un dossier
  const renameFolder = async ({ apiUrl, path, folderName, newFolderName }) => {
    const formData = new FormData();
    formData.append("current_folder", folderName);
    formData.append("new_folder_name", newFolderName);
    const response = await axios.post(
      `${apiUrl}files/renameFolder${path.length > 0 ? "/" + path : ""}`,
      formData,
      { withCredentials: true }
    );
    return response.data;
  };

  // Fonction pour supprimer un dossier
  const removeFolder = async ({ apiUrl, path }) => {
    const response = await axios.delete(
      `${apiUrl}files/deleteFolder${path.length > 0 ? "/" + path : ""}${"/" + file.name}`,
      { withCredentials: true }
    );
    return response.data;
  };

  // Mutation pour renommer un dossier
  const renameFolderMutation = useMutation(renameFolder, {
    onSuccess: () => {
      files.refetch(); // Recharger les fichiers après succès
      setIsInput(false); // Fermer l'input après succès
    },
    onError: (err) => {
      setError(err); // Stocker l'erreur en cas de problème
    },
  });

  // Mutation pour supprimer un dossier
  const deleteFolderMutation = useMutation(removeFolder, {
    onSuccess: () => {
      files.refetch(); // Recharger les fichiers après succès
      setConfirmDelete(false);
    },
    onError: (err) => {
      setError(err); // Stocker l'erreur en cas de problème
    },
  });

  // Fonction pour mettre à jour le nom du dossier
  const updateFolder = (e) => {
    e.preventDefault();
    const input = document.querySelector(".input-updateFolder");
    const newFolderName = input.value;
    const folderName = input.defaultValue;

    // Appel de la mutation pour renommer le dossier
    renameFolderMutation.mutate({ apiUrl, path, folderName, newFolderName });
  };

  // Fonction pour supprimer le dossier
  const deleteFolder = () => {
    deleteFolderMutation.mutate({ apiUrl, path });
  };

  // Truncate folder name if too long
  const truncateFolderName = (name, maxLength) => {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    }
    return name;
  };

  return (
    <>
      {!isInput && !confirmDelete ? (
        <div className="file-directory">
          <div onClick={() => handlePath(file)} className="file-directory_info">
            <FontAwesomeIcon icon={faFolderOpen} className="file-directory_icon" />
            <span>{truncateFolderName(file.name, 10)}</span>
          </div>
          <div className="content-file-directory">
          <button
            onClick={() => setIsInput(!isInput)}
            className="button button-white file-directory_update"
          >
            <FontAwesomeIcon icon={faPen} />
          </button>
          <button
              onClick={() => setConfirmDelete(true)} // Affiche le message de confirmation
              className="button button-white file-directory_update"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </div>
      ) : isInput ? (
        <div className="file-directory">
          <FontAwesomeIcon icon={faFolderOpen} className="file-directory_icon file-directory_new" />
          <form
            className="file-directory_form"
            onSubmit={(e) => updateFolder(e)}
          >
            <input
              defaultValue={file.name}
              className="input-updateFolder file-directory_form-input"
            />
            <div className="file-directory_form-button">
              <button type="submit" className="button button-new" disabled={renameFolderMutation.isLoading}>
                <FontAwesomeIcon icon={faCheck} />
              </button>
              <button
                type="button"
                className="button button-delete"
                onClick={() => setIsInput(false)}
              >
                <FontAwesomeIcon icon={faCancel} />
              </button>
            </div>
          </form>
        </div>
      ) : confirmDelete && (
        <div className="file-directory">
          <FontAwesomeIcon icon={faFolderOpen} className="file-directory_icon file-directory_new" />
          <div className="confirm-delete">
          <span>Êtes-vous sûr?</span>
          <div className="file-directory_form-button">
            <button
              onClick={deleteFolder}
              className="button button-new"
              disabled={deleteFolderMutation.isLoading}
            >
              <FontAwesomeIcon icon={faCheck} />
            </button>
              <button
                type="button"
                className="button button-delete"
                onClick={() => setConfirmDelete(false)}
              >
                <FontAwesomeIcon icon={faCancel} />
              </button>
          </div>
        </div>
        </div>
      )}
    </>
  );
}