import C9200L_24P_4G from '../SvgInterfaces/C9200L-24P-4G';
import InterfacesChunks from './InterfacesChunks';

export default function AdministratorSvgInterfaces({ interfacesChunks, interfaceModel, interfaceHover, tooltipPosition, isTooltipVisible }) {
    const model = interfaceModel.makeModel;

    return(
        <>
            {model === 'C9200L-24P-4G' ? (
                <C9200L_24P_4G interfaceId={interfaceModel.id} interfaceHover={interfaceHover} tooltipPosition={tooltipPosition} isTooltipVisible={isTooltipVisible}/>
            ) : (
                <>
                {interfacesChunks.map((data, index) => (
                    <div>
                        <InterfacesChunks key={index} interfaceId={interfaceModel.id} interfaceData={data} interfaceHover={interfaceHover} tooltipPosition={tooltipPosition} isTooltipVisible={isTooltipVisible} />
                    </div>
                ))} 
                </>
            )}
        </>
    )
}