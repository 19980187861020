import React from "react";
import TimelineSubscription from "./TimelineSubscription";
import TimelineContractsFirewall from "./TimelineContractsFirewall";
import TimelineCertificates from "./TimelineCertificates";
import TimelineContractsCisco from "./TimelineContractsCisco";

export default function RowTimelineEcp({ item, setState, stateQueryRow, setStateQueryRow }) {
    return (
        <>
            {item.source === 'subscription' ? (
                <TimelineSubscription item={item} setStateQueryRow={setStateQueryRow}/>
            ) : item.source === 'pare-feu' ? (
                <TimelineContractsFirewall item={item} setState={setState} stateQueryRow={stateQueryRow} setStateQueryRow={setStateQueryRow}/>
            ) : item.source === 'certificat' ? (
                <TimelineCertificates item={item} setState={setState} stateQueryRow={stateQueryRow} setStateQueryRow={setStateQueryRow}/>
            ) : item.source === 'contract' && (
                <TimelineContractsCisco item={item}/>
            )}
        </>
    );
}
