import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import Loader from "../../components/Loader";
import ChartData from "../../components/ChartData";
import RowEquipements from "../Equipements/RowEquipements";
import FileSaverExcel from "../../components/FileSaverExcel";
import { Equipementscolumns } from "../../components/headerContent";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import TableComponent from "../../components/TableComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import NumberCard from "../../components/Card/NumberCard";
import { MoreVert } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function Reporting({ visio }) {
  const { user, apiUrl } = useContext(AuthContext);
  const { t } = useTranslation();

  // stock equipements sites and bu
  const [allBU, setAllBU] = useState([]);
  const [isFilter, setIsFilter] = useState(false);

  // stock all differents type of product
  const [typeOfProduct, setTypeOfProduct] = useState([]);

  // Chart Options
  const [typeOfSort, setTypeOfSort] = useState("equipements");
  const [typeOfFilter, setTypeOfFilter] = useState("site");
  const [typeOfDevice, setTypeOfDevice] = useState("");
  const [typeOfSite, setTypeOfSite] = useState("all");

  // Chart filters
  const [bu, setBu] = useState("");

  // array to display Table
  const [equipementsArray, setEquipementsArray] = useState([]);
  const [equipementsArrayFiltered, setEquipementsArrayFiltered] = useState([]);
  const [displayArray, setDisplayArray] = useState(false);
  const [isBu, setIsBU] = useState(false);
  const [label, setLabel] = useState(null);

  // To create Chart
  const [dataForChart, setDataForChart] = useState({});
  const [allLabels, setAllLabels] = useState([]);
  const [titleChart, setTitleChart] = useState("");
  const [horizontal, setHorizontal] = useState(true);

  const originalLabels = {
    "OUI": "YES",
    "NON": "NO",
    "Server": "Serveur",
    "Router": "Routeur",
  };

  // Request to get all equipements
  const getAllEquipements = async () => {
    const res = await axios(`${apiUrl}equipement/index`, {
      method: "GET",
      withCredentials: true,
    });
    setAllBU(
      Array.from(new Set(res.data.map((equipement) => equipement.bu))).filter(
        Boolean
      )
    );
    setTypeOfProduct(
      Array.from(
        new Set(
          res.data
            .map((equipement) => equipement.famille)
            .filter((famille) => famille && famille.nom)
            .flatMap((famille) => famille.nom)
        )
      ).filter(Boolean)
    );
    setIsBU(res.data.some((equipment) => equipment.bu !== null));

    return res.data.map((equipement) => {
      return {
        ...equipement,
        product: equipement.famille ? equipement.famille.product : "Not found",
        site: equipement.site ? equipement.site.nom : "Not found",
        site_id: equipement.site ? equipement.site.id : "Not found",
      };
    }).filter((item) => {
      if (visio) {
        return item.famille && item.famille.nom === "Visio"
      } else {
        return item.famille ? item.famille.nom !== "Visio" && item.famille.nom !== "FIREWALL" : item
      }
    });
  };

  const equipements = useQuery({
    queryKey: ["equipements"],
    queryFn: getAllEquipements,
  });

  // Request to get all sites
  const getAllSites = async (entreprise) => {
    if (user.entreprise) {
      const res = await axios(`${apiUrl}equipement/sites/${entreprise}`, {
        method: "GET",
        withCredentials: true,
      });
      return res.data;
    }
  };

  const sites = useQuery({
    queryKey: ["sites", user.entreprise],
    queryFn: () => getAllSites(user.entreprise),
  });

  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.invalidateQueries(["sites", user.entreprise]);
  }, [user.entreprise]);

  // Set new data when user's click
  const handleGraphClick = (event, chartRef) => {
    const chartInstance = chartRef;
    const activePoints = chartInstance.getElementsAtEventForMode(
      event,
      "nearest",
      { intersect: true },
      true
    );
    if (activePoints.length > 0) {
      let label;
      const firstPoint = activePoints[0];
      const untranslatedLabel = chartInstance.data.datasets[firstPoint.datasetIndex].label;
      const filter = chartInstance.data.labels[firstPoint.index];
      if (untranslatedLabel === "Non annoncé" ||
        untranslatedLabel === "Not announced" ||
        untranslatedLabel === "Undefined product"
        || untranslatedLabel === "Produit non défini") {
        label = null;
      } else {
        label = originalLabels[untranslatedLabel] || untranslatedLabel;
      }
      let equipementByFilter = [];
      switch (typeOfSort) {
        case "equipements":
          equipementByFilter = equipements.data.filter(
            (equipement) => equipement[typeOfFilter] === filter
          );
          break;
        case "end_of_support":
          equipementByFilter = equipements.data.filter((equipement) => {
            if (equipement.famille && equipement.famille.end_of_support) {
              if (typeOfFilter !== "product") {
                return (
                  equipement[typeOfFilter] === filter &&
                  new Date(equipement.famille.end_of_support).getFullYear() === Number(label)
                );
              } else {
                return (
                  equipement.famille.product === filter &&
                  new Date(equipement.famille.end_of_support).getFullYear() === Number(label)
                );
              }
            } else {
              return false;
            }
          });
          break;
        case "produits":
          equipementByFilter = equipements.data.filter((equipement) => {
            if (equipement.famille) {
              if (typeOfFilter !== "product") {
                return (
                  equipement.famille.nom === label &&
                  equipement[typeOfFilter] === filter
                );
              } else {
                return (
                  equipement.famille.nom === label &&
                  equipement[typeOfFilter] === filter
                );
              }
            } else {
              return false;
            }
          }
          );
          break;
        case "is_covered":
          equipementByFilter = equipements.data.filter((equipement) => {
            if (typeOfFilter !== "produits") {
              return (
                equipement[typeOfFilter] === filter &&
                equipement.is_covered === label
              );
            } else {
              return (
                equipement.famille.product === filter &&
                equipement.is_covered === label
              );
            }
          });
          break;
        default:
          break;
      }

      if (typeOfSort === "end_of_support" && label === null) {
        equipementByFilter = equipements.data.filter((equipement) => {
          if (equipement.famille) {
            if (typeOfFilter !== "product") {
              return (
                equipement[typeOfFilter] === filter &&
                equipement.famille.end_of_support === null
              );
            } else {
              return (
                equipement.famille.product === filter &&
                equipement.famille.end_of_support === null
              );
            }
          }
        });
      }

      const equipementByFilterOptions = equipementByFilter.filter((equipement) => {
        if (typeOfDevice && equipement.famille?.nom !== typeOfDevice) {
          return false;
        }

        if (typeOfSite !== "all" && typeOfSite !== `${equipement.site_id}`) {
          return false;
        }

        if (bu && equipement.bu !== bu) {
          return false;
        }

        return true;
      });
      setLabel(label);
      setEquipementsArray(equipementByFilterOptions);
      setDisplayArray(true);
    }
  };


  // Filter equipements array by options
  const filterTable = (key) => {
    const filteredEquipements = equipements.data
      ? equipements.data.filter((equipement) => {
        if (typeOfDevice && equipement.famille?.nom !== typeOfDevice) {
          return false;
        }

        if (typeOfSite !== "all" && typeOfSite !== `${equipement.site_id}`) {
          return false;
        }

        if (bu && equipement.bu !== bu) {
          return false;
        }

        return true;
      })
      : [];

    const sortArrayByFilter = {};
    const allLabels = [];

    setEquipementsArrayFiltered(filteredEquipements)

    filteredEquipements.forEach((equipement) => {
      let setFilter =
        typeof equipement[typeOfFilter] !== "object"
          ? equipement[typeOfFilter]
          : equipement[typeOfFilter] !== null
            ? equipement[typeOfFilter][key]
            : null;
      let label = "Nombre d'équipements";
      if (typeOfSort === "end_of_support") {
        label =
          equipement.famille?.[typeOfSort]?.split("-")[0] || "Non annoncé";
      } else if (typeOfSort === "produits") {
        label = equipement.famille?.[key] || "Produit non défini";
      } else if (typeOfSort === "is_covered") {
        label = equipement[typeOfSort] ?? "Non annoncé";
      }

      sortArrayByFilter[setFilter] = sortArrayByFilter[setFilter] || {};
      sortArrayByFilter[setFilter][label] =
        (sortArrayByFilter[setFilter][label] || 0) + 1;

      if (!allLabels.includes(label)) {
        allLabels.push(label);
      }
    });

    const titleMap = {
      is_covered: {
        product: "Répartition des assurances par produits",
        default: `Répartition des assurances par ${typeOfFilter}`,
      },
      end_of_support: {
        product: "Répartition des EoS par produits",
        default: `Répartition des EoS par ${typeOfFilter}`,
      },
      produits: {
        default: `Répartition des ${typeOfSort} par ${typeOfFilter}`,
      },
      equipements: {
        product: `Répartition des ${typeOfSort} par produits`,
        default: `Répartition des ${typeOfSort} par ${typeOfFilter}`,
      },
    };

    const titleChart =
      titleMap[typeOfSort]?.[typeOfFilter] ||
      titleMap[typeOfSort]?.default ||
      titleMap.equipements.default;
    setTitleChart(titleChart);

    setAllLabels(allLabels.filter(Boolean));
    setDataForChart(sortArrayByFilter);
  };

  useEffect(() => {
    filterTable("nom");
  }, [
    equipements.data,
    typeOfSort,
    typeOfFilter,
    typeOfDevice,
    typeOfSite,
    bu,
  ]);

  if (equipements.data && equipements.data.length === 0) {
    return (
      <article className="page">
        <h1 className="page-title">Aucun équipement</h1>
      </article>
    );
  }

  return (
    <>
      {!equipements.isLoading ? (
        <article className="page">
          <h1 className="page-title">Reporting Hardware</h1>
          <section className="page-section">
            <NumberCard
              title={"Equipements"}
              number={equipementsArray.length > 0 ? equipementsArray.length : equipements.data.length}
            />
            {equipementsArray.length > 0 &&
              (typeOfFilter === "site" && equipementsArray[0].site !== "Not found" ? (
                <>
                  <h1>Site {equipementsArray[0].site}</h1>
                  {typeOfSort === "end_of_support" &&
                    <>
                      <h1>{label ? label : t("Non annoncé")}</h1>
                    </>
                  }
                  {typeOfSort === "produits" &&
                    <>
                      <h1>{label ? label : t("Produit non défini")}</h1>
                    </>
                  }
                </>
              ) : typeOfFilter === "bu" ? (
                <>
                  <h1>BU {equipementsArray[0].bu}</h1>
                  {typeOfSort === "end_of_support" &&
                    <>
                      <h1>{label ? label : t("Non annoncé")}</h1>
                    </>
                  }
                  {typeOfSort === "produits" &&
                    <>
                      <h1>{label ? label : t("Produit non défini")}</h1>
                    </>
                  }
                </>
              ) : typeOfFilter === "product" && (
                <>
                  <h1>{t('Produit')} {equipementsArray[0].product}</h1>
                  {typeOfSort === "end_of_support" &&
                    <>
                      <h1>{label ? label : t("Non annoncé")}</h1>
                    </>
                  }
                  {typeOfSort === "produits" &&
                    <>
                      <h1>{label ? label : t("Produit non défini")}</h1>
                    </>
                  }
                </>
              ))
            }
            {!displayArray && (
              <div className="page-section_form page-section_form-reporting">
                <form className="form">
                  <div className="form-input_container">
                    <label htmlFor="type">{t('Répartition des')}</label>
                    <select
                      className="only"
                      id="type"
                      onChange={(e) => {
                        setTypeOfSort(e.target.value);
                        setTypeOfFilter("site");
                      }}
                      value={typeOfSort}
                    >
                      <option value="equipements">{t('Equipements')}</option>
                      <option value="end_of_support">{t('Fin de support')}</option>
                      <option value="produits">{t('Produits')}</option>
                      <option value="is_covered">{t('Contrats')}</option>
                    </select>
                  </div>
                </form>
                <form className="form">
                  <div className="form-input_container">
                    <label htmlFor="filter">{t('Par')}</label>
                    <select
                      className="only"
                      id="filter"
                      onChange={(e) => setTypeOfFilter(e.target.value)}
                      value={typeOfFilter}
                    >
                      {[
                        "equipements",
                        "produits",
                        "product",
                        "end_of_support",
                        "is_covered",
                      ].includes(typeOfSort) && (
                          <option value="site">Sites</option>
                        )}
                      {allBU.length > 0 &&
                        [
                          "equipements",
                          "end_of_support",
                          "is_covered",
                        ].includes(typeOfSort) && (
                          <option value="bu">BU</option>
                        )}
                      {["is_covered", "end_of_support"].includes(
                        typeOfSort
                      ) && <option value="product">{t('Produits')}</option>}
                    </select>
                  </div>
                </form>
              </div>
            )}
          </section>
          <section>
            {!displayArray ? (
              <div className="container-file-saver-csv">
                <div className='tooltip-csv-tableWebex'>
                  <MoreVert />
                  <span className='tooltip-csv-tableWebex-button'><FileSaverExcel data={[equipementsArrayFiltered]} name={'Reporting Hardware'} /></span>
                </div>
                <button
                  className="button button-new"
                  onClick={() => setIsFilter(!isFilter)}
                >
                  <FontAwesomeIcon icon={faFilter} /> {t('filtrer')}
                </button>
              </div>
            ) : (
              <div className="table-leftbtn">
                <div className="container-file-saver-csv">
                  <div className='tooltip-csv-tableWebex'>
                    <MoreVert />
                    <span className='tooltip-csv-tableWebex-button'><FileSaverExcel data={[equipementsArray]} name={'Reporting Hardware'} /></span>
                  </div>
                  <button
                    className="button button-new page-table_return"
                    onClick={() => {
                      setDisplayArray(false);
                      setEquipementsArray([]);
                      setLabel(null);
                    }}
                  >
                    {t("Retour au graphique")}
                  </button>
                </div>
              </div>
            )}
            {!displayArray &&
              (isFilter ? (
                <>
                  <div className="page-section_form">
                    <form className="form">
                      <div className="form-input_container">
                        <label htmlFor="filter">{t('Type d\'équipement')}</label>
                        <select
                          className="only"
                          id="filter"
                          onChange={(e) => setTypeOfDevice(e.target.value)}
                          defaultValue={typeOfDevice}
                        >
                          <option value="">{t("Tous les types")}</option>
                          {typeOfProduct
                            .filter(Boolean)
                            .map((product, index) => {
                              return (
                                <option value={product} key={index}>
                                  {t(product)}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </form>
                    {sites.data && sites.data.length > 1 && (
                      <>
                        <form className="form">
                          <div className="form-input_container">
                            <label htmlFor="filter">Sites</label>
                            <select
                              className="only"
                              id="filter"
                              onChange={(e) => setTypeOfSite(e.target.value)}
                            >
                              <option value="all">{t("Tous les sites")}</option>
                              {sites.data &&
                                sites.data.filter(Boolean).map((site) => {
                                  return (
                                    <option value={site.id} key={site.id}>
                                      {" "}
                                      {site.nom}{" "}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </form>
                      </>
                    )}
                    {allBU.length > 0 && (
                      <>
                        <form className="form">
                          <div className="form-input_container">
                            <label htmlFor="filter">BU</label>
                            <select
                              className="only"
                              id="filter"
                              onChange={(e) => setBu(e.target.value)}
                              defaultValue={bu}
                            >
                              <option value="">{t('Toutes les BU')}</option>
                              {allBU.map((bu) => {
                                return (
                                  <option value={bu} key={bu}>
                                    {" "}
                                    {bu}{" "}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                </>
              ) : null)}
          </section>
          {!displayArray ? (
            <ChartData
              dataForChart={dataForChart}
              allLabels={allLabels}
              isStacked={true}
              titleChart={titleChart}
              handleGraphClick={handleGraphClick}
              horizontal={horizontal}
              reporting={true}
            />
          ) : (
            <TableComponent
              tableData={equipementsArray}
              tableColumns={Equipementscolumns}
              rowComponent={RowEquipements}
              sites={sites.data}
            />
          )}
        </article>
      ) : (
        <Loader />
      )}
    </>
  );
}