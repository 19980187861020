import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "../../context/AuthContext";
import TableComponent from "../../components/TableComponent";
import { ArrowSphereAllColumn } from "../../components/headerContent";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";

export default function ArrowSphereAll() {
    const { apiUrl } = useContext(AuthContext);
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const getArrowSphereAll = async () => {
        try {
          const res = await axios.get(`${apiUrl}microsoft/arrow/licenses/all`, {
            withCredentials: true,
          });
          return res.data;
        } catch (error) {
        }
    };
    
    const arrowSphereAll = useQuery({
        queryKey: ["arrowSphereAll"],
        queryFn: getArrowSphereAll,
    });

    useEffect(() => {
        queryClient.invalidateQueries(["arrowSphereAll", arrowSphereAll.data]);
    }, [arrowSphereAll.data]);

    if (arrowSphereAll.data && arrowSphereAll.data === undefined) {
        return <article className="page">
          <h1 className="page-title"> Pas de données ArrowSphere disponibles</h1>
        </article>
    }

    return (
        <>
            {!arrowSphereAll.isLoading ? (
                <>
                    {arrowSphereAll.data && arrowSphereAll.data.length > 0 ? (
                        <TableComponent
                            tableData={arrowSphereAll.data}
                            tableColumns={ArrowSphereAllColumn}
                            checkbox={false}
                        />
                    ) : !arrowSphereAll.data || arrowSphereAll.data.length === 0 ? (
                        <article className="page">
                            <h1 className="page-title">Pas de données ArrowSphere disponibles</h1>
                        </article>
                    ) : (
                        <Loader/>
                    )}
                </>
            ) : (
                <Loader/>
            )}
        </>
    );
}