import { useTranslation } from "react-i18next";
import TableComponent from "../../components/TableComponent";
import { NetworkAlertscolumns } from "../../components/headerContent";
import HealthCard from "./HealthCard";


export default function NetworkAlert({ alerts, networkId, statusDevices }) {
    const statusOnline = { type: "Online", data: statusDevices.online };
    const statusOffline = { type: "Offline", data: statusDevices.offline };
    const statusAlerting = { type: "Alerting", data: statusDevices.alerting };
    const statusDormant = { type: "Dormant", data: statusDevices.dormant };
    const statusAutres = { type: "Autres", data: statusDevices.autres };
    const { t } = useTranslation();

    return (
        <>
            <section className="health">
                <h2 className="health-title">{t('Santé')}</h2>
                <div className="health-container">
                    <HealthCard type={"switch"} networkId={networkId} />
                    <HealthCard type={"wireless"} networkId={networkId} />
                    <HealthCard type={"cam"} networkId={networkId} />
                    <HealthCard status={statusOnline} />
                    <HealthCard status={statusOffline} />
                    <HealthCard status={statusAlerting} />
                    <HealthCard status={statusDormant} />
                    <HealthCard status={statusAutres} />
                </div>
                {!alerts.includes("No data") && <TableComponent tableColumns={NetworkAlertscolumns} tableData={alerts} checkbox={false} />}
            </section>
        </>
    )
}