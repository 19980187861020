import { useTranslation } from "react-i18next";

/**
 * 
 * @param {object} item - object of formData
 * @param {function} register - register of react hook form
 * @param {function} setHoldingSelected - to stock holding select for header form
 */
export default function Select({ item, register}) {
  const {t} = useTranslation();

  return (
    <select
      multiple={item.multipleSelected}
      id={item.id}
      {...register(item.id)}
      onChange={item.change !== false && item.change}
      defaultValue={
        Array.isArray(item.value)
          ? item.value
          : item.value && item.value
      }
    >
      {item.values &&
        item.values.map((value) => {
          return (
            <option
              key={value.value}
              value={value.value}
            >
              {t(value.label)}
            </option>
          );
        })}
    </select>
  );
}
