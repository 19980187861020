import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import WebexData from "./WebexData";
import Loader from "../../components/Loader";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

/**
 * 
 * @param {object} item - device from webex devices 
 */
export default function RowWebex({item}) {

  const {apiUrl} = useContext(AuthContext);

  const {t} = useTranslation();

    // Device live data
    const [deviceLive, setDeviceLive] = useState([]);

     // Loading data
    const [isLoading, setIsLoading] = useState(true);

     // fetch data to have status of every datas
    async function fetchDeviceLive(deviceId, deviceName) {

    // Define an object for stock values
    let deviceStatus = {
      id: deviceId,
      name: deviceName,
    };

    // request
    const responses = await axios.all([
      axios.get(`${apiUrl}webex/devices_status/${deviceId}`, { withCredentials: true}),
      axios.get(`${apiUrl}webex/booking_availible_status/${deviceId}`, { withCredentials: true}),
      axios.get(`${apiUrl}webex/camera/${deviceId}`, { withCredentials: true}),
      axios.get(`${apiUrl}webex/cameras_speakertrack_status/${deviceId}`, { withCredentials: true}),
      axios.get(`${apiUrl}webex/roomAnalytics_peopleCount_current/${deviceId}`, { withCredentials: true}),
      axios.get(`${apiUrl}webex/systemUnit_state_numberOfActiveCalls/${deviceId}`, { withCredentials: true}),
      axios.get(`${apiUrl}webex/miracast_status/${deviceId}`, { withCredentials: true}),
      axios.get(`${apiUrl}webex/airplay_status/${deviceId}`, { withCredentials: true}),
      axios.get(`${apiUrl}webex/microsoftTeams/${deviceId}`, { withCredentials: true}),
      axios.get(`${apiUrl}webex/googleMeet/${deviceId}`, { withCredentials: true}),
      axios.get(`${apiUrl}webex/guestToken/${deviceId}`, { withCredentials: true}),
      axios.get(`${apiUrl}webex/webEngine_features/${deviceId}`, { withCredentials: true}),
      axios.get(`${apiUrl}webex/webEngine_features_signagne/${deviceId}`, { withCredentials: true}),
      axios.get(`${apiUrl}webex/ambientNoise/${deviceId}`, { withCredentials: true}),
      axios.get(`${apiUrl}webex/peripherals/${deviceId} `, { withCredentials: true}),
      axios.get(`${apiUrl}webex/video/${deviceId} `, { withCredentials: true}),
      axios.get(`${apiUrl}webex/webEngine_features_webRTC/${deviceId} `, { withCredentials: true}),
      axios.get(`${apiUrl}webex/peopleCount_capacity/${deviceId} `, { withCredentials: true}),
      axios.get(`${apiUrl}webex/systemUnit_uptime/${deviceId} `, { withCredentials: true}),
      axios.get(`${apiUrl}webex/video_monitors/${deviceId} `, { withCredentials: true}),
      axios.get(`${apiUrl}webex/airQuality/${deviceId} `, { withCredentials: true}),
      axios.get(`${apiUrl}webex/relativeHumidity/${deviceId} `, { withCredentials: true}),
      axios.get(`${apiUrl}webex/ambientTemperature/${deviceId} `, { withCredentials: true}),
    ]);
      // Stock data in device status request
      deviceStatus.micro= JSON.stringify(responses[0].data.result) !== "[]" ? responses[0].data.result.Audio.Input.Connectors.Microphone : "indisponible";
      deviceStatus.booking= JSON.stringify(responses[1].data.result) !== "[]"?responses[1].data.result.Bookings.Availability.Status : "indisponible";
      deviceStatus.camera= JSON.stringify(responses[2].data.result) !== "[]" ?responses[2].data.result.Cameras.Camera : "indisponible";
      deviceStatus.cameraSpeakerTrack= JSON.stringify(responses[3].data.result) !== "[]" ?responses[3].data.result.Cameras.SpeakerTrack.Status : "indisponible";
      deviceStatus.rooms= JSON.stringify(responses[4].data.result) !== "[]" ?responses[4].data.result.RoomAnalytics.PeopleCount.Current : "indisponible";
      deviceStatus.calls= JSON.stringify(responses[5].data.result) !== "[]" ?responses[5].data.result.SystemUnit.State.NumberOfActiveCalls : "indisponible";
      deviceStatus.miracast= JSON.stringify(responses[6].data.result) !== "[]" ?responses[6].data.result.Video.Input.Miracast.Status : "indisponible";
      deviceStatus.airplay= JSON.stringify(responses[7].data.result) !== "[]" ?responses[7].data.result.Video.Input.AirPlay.Status : "indisponible";
      deviceStatus.teams= JSON.stringify(responses[8].data.result) !== "[]" ?responses[8].data.result.WebRTC.Provider.MicrosoftTeams.Availability : "indisponible";
      deviceStatus.google= JSON.stringify(responses[9].data.result) !== "[]" ?responses[9].data.result.WebRTC.Provider.GoogleMeet.Availability : "indisponible";
      deviceStatus.webexToken= JSON.stringify(responses[10].data.result) !== "[]" ? responses[10].data.result.Webex.Services.Proximity.GuestToken : "indisponible";
      deviceStatus.webEngine= JSON.stringify(responses[11].data.result) !== "[]" ? responses[11].data.result.WebEngine.Features.WebEngine : "indisponible";
      deviceStatus.webEngineSignage = JSON.stringify(responses[12].data.result) !== "[]" ? responses[12].data.result.WebEngine.Features.Signage : "indisponible";
      deviceStatus.ambientNoise = JSON.stringify(responses[13].data.result) !== "[]" ? responses[13].data.result.RoomAnalytics.AmbientNoise.Level.A: "indisponible";
      deviceStatus.peripherals = JSON.stringify(responses[14].data.result) !== "[]"  ? responses[14].data.result.Peripherals.ConnectedDevice : "indisponible";
      deviceStatus.videoInputConnector = JSON.stringify(responses[15].data.result) !== "[]" ? responses[15].data.result.Video.Input.Connector : "indisponible";
      deviceStatus.videoOuputConnector = JSON.stringify(responses[15].data.result) !== "[]" ? responses[15].data.result.Video.Output.Connector : "indisponible";
      deviceStatus.webRtc = JSON.stringify(responses[16].data.result) !== "[]" ? responses[16].data.result.WebEngine.Features.WebRTC : "indisponible";
      deviceStatus.roomsCapacity = JSON.stringify(responses[17].data.result) !== "[]" ? responses[17].data.result.RoomAnalytics.PeopleCount.Capacity : "indisponible";
      deviceStatus.systemUptime = JSON.stringify(responses[18].data.result) !== "[]" ? responses[18].data.result.SystemUnit.Uptime : "indisponible";
      deviceStatus.videoMonitors = JSON.stringify(responses[19].data.result) !== "[]" ? responses[19].data.result.Video.Monitors : "indisponible";
      deviceStatus.airQuality = JSON.stringify(responses[20].data.result) !== "[]" ? responses[20].data.result.Peripherals.ConnectedDevice[0].RoomAnalytics.AirQuality.Index : "indisponible";
      deviceStatus.relativeHumidity = JSON.stringify(responses[21].data.result) !== "[]" ? responses[21].data.result.Peripherals.ConnectedDevice[0].RoomAnalytics.RelativeHumidity : "indisponible";
      deviceStatus.ambientTemperature = JSON.stringify(responses[22].data.result) !== "[]" ? responses[22].data.result.Peripherals.ConnectedDevice[0].RoomAnalytics.AmbientTemperature : "indisponible";
    ;
    
    return deviceStatus;
  }

    async function handleDeviceLive() {
      let deviceStatus;
      
      // fetch status if device is compatible
      if(item.capabilities.includes('xapi') && item.permissions.includes('xapi')) {
        deviceStatus = await fetchDeviceLive(item.id, item.name);
      } else {
        deviceStatus = {name: item.name, id: item.id, error: t("Pas de statuts") };
      }
      setDeviceLive([deviceStatus])
      setIsLoading(false)

    }

    // send request only if user click on "see more" button
    useEffect(() => {
        handleDeviceLive(item.id, item.name);
    }, [item])

    return (
      <>
        {!isLoading ? deviceLive.map((device, index) => {
          if (device.id === item.id) {
            return (
              <WebexData data={device} key={index} name={item.name} />
            );
          }
        }) : <Loader /> }
      </>
    );
  };