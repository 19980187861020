import React, { useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { convertTimestampFormatZ, convertDateAndReturn } from "../../components/utils";
import { startOfDay, endOfDay, addDays } from 'date-fns';
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

export default function AuvikAlertsDevice({ device, refreshData, setDisplayAlerts }) {
    const { apiUrl } = useContext(AuthContext);
    const params = useParams();
    const queryClient = useQueryClient();
    const tenantId = params.tenantId;
    const today = convertTimestampFormatZ(endOfDay(new Date(), 23));
    const yesterday = convertTimestampFormatZ(startOfDay(addDays(new Date(), -1), 0));
    const { t } = useTranslation();

    const getAlertsAuvik = async () => {
        try {
            const res = await axios.get(`${apiUrl}auvik/alerts/${tenantId}/${yesterday}/${today}`, {
                withCredentials: true,
            });
            return res.data;
        } catch (error) {
        }
    };

    const alertsAuvik = useQuery({
        queryKey: ["alertsAuvik", device.id],
        queryFn: () => getAlertsAuvik(),
        enabled: false, 
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (refreshData === true) {
            setDisplayAlerts(false);
            queryClient.invalidateQueries(["alertsAuvik", alertsAuvik.data]);
            alertsAuvik.refetch();
        }
    }, [alertsAuvik.data, refreshData]);

    const filteredAlerts = alertsAuvik.data && alertsAuvik.data.data !== "No alerts found" ? alertsAuvik.data.filter((alert) => alert["Entity Name"] === device.deviceName) : [];

    useEffect(() => {
        if (filteredAlerts.length > 0) {
            const handleResize = () => {
                const scrollWidth = document.querySelector('.alerts-table-content').offsetWidth - document.querySelector('.alerts-table-content table').offsetWidth;
                document.querySelector('.alerts-table-header').style.paddingRight = `${scrollWidth}px`;
            };

            window.addEventListener('load', handleResize);
            window.addEventListener('resize', handleResize);

            handleResize();

            return () => {
                window.removeEventListener('load', handleResize);
                window.removeEventListener('resize', handleResize);
            };
        }
    }, []);

    return (
        <>
            {!alertsAuvik.isFetching ? (
                <section className="alerts-device-container">
                    {filteredAlerts.length > 0 ? (
                        <div className="alerts-table">
                            <div className="alerts-table-header">
                                <table cellPadding="0" cellSpacing="0" border="0">
                                    <thead className="alerts-device-container-content-thead">
                                        <th className="alerts-device-container-content-thead-th">{t('Nom')}</th>
                                        <th className="alerts-device-container-content-thead-th">{t('Description')}</th>
                                        <th className="alerts-device-container-content-thead-th">{t('Sévérité')}</th>
                                        <th className="alerts-device-container-content-thead-th">{t('Status')}</th>
                                        <th className="alerts-device-container-content-thead-th">{t('Détecté le')}</th>
                                    </thead>
                                </table>
                            </div>
                            <div className="alerts-table-content">
                                <table cellPadding="0" cellSpacing="0" border="0">
                                    <tbody>
                                        {filteredAlerts.map((alert, index) => (
                                            <tr key={index}>
                                                <td>{alert["Alert name"]}</td>
                                                <td>{alert.Description}</td>
                                                <td className={`alert-status-${alert.Severity}`}>{alert.Severity}</td>
                                                <td className={`alert-status-${alert.Status}`}>{alert.Status}</td>
                                                <td>{convertDateAndReturn(alert["Detected On"])}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : alertsAuvik.data !== undefined && (
                        <h2>{t('Aucune alerte détectée')}</h2>
                    )}
                </section>
            ) : (
                <Loader />
            )}
        </>
    );
}