import axios from "axios";
import { useContext} from "react";
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import {
  faBarcode,
  faBox,
  faLocation,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export default function RowContract({ item }) {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const { apiUrl} = useContext(AuthContext);

  const getAllEquipements = async () => {
    const res = await axios.get(`${apiUrl}equipement/index`, {
      withCredentials: true,
    });

    return res.data;
  };

  const equipements = useQuery({
    queryKey: ["equipements"],
    queryFn: getAllEquipements,
  });

  const handleClickEquipement = (item) => {
    if (item.famille.nom === "Visio") {
      navigate(`/visio?equipementId=${item.serial_number}`)
    } else if (item.famille.nom === "FIREWALL") {
      navigate(`/firewall?equipementId=${item.serial_number}`)
    } else {
      navigate(`/equipements?equipementId=${item.serial_number}`)
    }
  }

  return (
    <>
      {!equipements.isLoading ? (
        <>
          <div className="contract">
            <div className="contract-header">
              <span className="contract-title">
                <FontAwesomeIcon icon={faBarcode} className="contract-icon" />
               {t('Numéro de série')}
              </span>
              <span className="contract-title">
                <FontAwesomeIcon icon={faTag} className="contract-icon" />
               {t('Nom')}
              </span>
              <span className="contract-title">
                <FontAwesomeIcon icon={faBox} className="contract-icon" />
               {t('Produit')}
              </span>
              <span className="contract-title">
                <FontAwesomeIcon icon={faLocation} className="contract-icon" />
               {t('Site')}
              </span>
            </div>
            {equipements.data.map((equipement, index) => {
                if (item.serial_numbers.split(",").includes(equipement.serial_number)) {
                  return <div className="contract-body" key={index}>
                    <a className="contract-content equipement-serial_number-contract" id={`${equipement.serial_number}`} onClick={() => handleClickEquipement(equipement)}>
                      {equipement.serial_number}
                    </a>
                    <span className="contract-content">
                      {equipement.device_name}
                    </span>
                    <span className="contract-content">
                        {equipement.famille && equipement.famille.product}
                    </span>
                    <span className="contract-content">
                      {equipement.site && equipement.site.nom}
                    </span>
                  </div>;
                }
              })}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
