import { useEffect, useState } from "react";
import ChartData from "../../../components/ChartData";
import { exportHoursDate } from "../../../components/utils";
import { useTranslation } from "react-i18next";

export default function ChartBandwidth({ dataBandwidth }) {
    const [dataForChart, setDataForChart] = useState(null);
    const [average, setAverage] = useState({ averageBandwidth: 0, averageTransmit: 0, averageReceive: 0 });
    const [allLabels, setAllLabels] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (dataBandwidth !== undefined) {
            getBandwidthGraph();
        }
    }, [dataBandwidth]);

    const getBandwidthGraph = () => {
        let data = null;

        data = dataBandwidth.filter(item => { return item });

        handleDataForChart(data);
    }

    const handleDataForChart = (data) => {
        const groupedData = {};
        const allLabels = new Set();
        const totals = { Bandwidth: 0, Transmit: 0, Receive: 0 };
    
        const dataFormatted = data.map(item => ({ ...item }));
    
        dataFormatted.forEach(item => {
            const filterByTime = exportHoursDate(item.RecordedAt);
            
            ['Bandwidth', 'Transmit', 'Receive'].forEach(key => {
                if (!groupedData[filterByTime]) groupedData[filterByTime] = {};

                groupedData[filterByTime][key] = (groupedData[filterByTime][key] || 0) + Number(item[key]);
                                
                if (!allLabels.has(key)) allLabels.add(key);

                totals[key] += Number(item[key]);
            });
        });
        setAllLabels(Array.from(allLabels));
        setDataForChart(groupedData);
        setAverage({
            averageBandwidth: totals.Bandwidth / dataFormatted.length || 0,
            averageTransmit: totals.Transmit / dataFormatted.length || 0,
            averageReceive: totals.Receive / dataFormatted.length || 0,
        });
    };

    const formatNumber = (number) => {
        if (number >= 1000000) {
            return (number / 1000000).toLocaleString('fr-FR', { maximumFractionDigits: 1 }) + ' Gbit/s';
        } else if(number >= 1000) {
            return (number / 1000).toLocaleString('fr-FR', { maximumFractionDigits: 0 }) + ' Mbit/s';
        } else {
            return number.toLocaleString('fr-FR', { maximumFractionDigits: 0 }) + ' Kbit/s';
        }
    };

    return (
        <>
            {dataForChart ?
                <div className="auvik-contents">
                    <ChartData
                        dataForChart={dataForChart}
                        allLabels={allLabels}
                        isStacked={false}
                        titleChart={t("Largeur de la bande passante de l'appareil")}
                        typeOfChart={"line"}
                        measurementUnitBandwidth={[true, "Gbit/s", "Mbit/s", "Kbit/s"]}
                        minValueLeft={0}
                        tension={0.1}
                        radiusGraph={1}
                        setNumberTicksX={7}
                        setNumberTicksY={6}
                        setNumberRotate={0}
                    />
                    <div className="average-container">
                        <div className="average average1">
                            <div>
                                <span>{t("Moyenne")}</span>
                                <span>{t("Bande passante")}</span>
                            </div>
                            <h2>{formatNumber(Math.trunc(average.averageBandwidth))}</h2>
                        </div>
                        <div className="average average2">
                            <div>
                                <span>{t("Moyenne")}</span>
                                <span>{t("Transmission")}</span>
                            </div>
                            <h2>{formatNumber(Math.trunc(average.averageTransmit))}</h2>
                        </div>
                        <div className="average average3">
                            <div>
                                <span>{t("Moyenne")}</span>
                                <span>{t("Réception")}</span>
                            </div>
                            <h2>{formatNumber(Math.trunc(average.averageReceive))}</h2>
                        </div>
                    </div>
                </div>
                : <h2 className="page-title">{t("Aucune donnée")}</h2>
            }
        </>
    );
}