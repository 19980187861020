import React, { useState, useEffect, useRef } from 'react';
import { FilterList, KeyboardArrowRightRounded, KeyboardArrowLeftRounded, ClearRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function SearchBarWebex({ dataWebex, dataWebexColumns, filters, setFilters, statusAnswered }) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState({ accessor: null, header: null });
    const [selectedValues, setSelectedValues] = useState([]);
    const [initialAvailableValues, setInitialAvailableValues] = useState({});
    const [searchTerm, setSearchTerm] = useState("");
    const searchBarRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        let filteredData = dataWebex;
        filters.forEach(filter => {
            filteredData = filteredData.filter(item => filter.values.includes(item[filter.column]));
        });
        const newAvailableValues = {};
        dataWebexColumns.forEach(col => {
            newAvailableValues[col.accessor] = Array.from(new Set(dataWebex.map(item => item[col.accessor])));
        });
        setInitialAvailableValues(newAvailableValues);
    }, [filters, dataWebex, dataWebexColumns]);

    const handleFilterChange = (column, values) => {
        setFilters(prevFilters => {
            const existingFilter = prevFilters.find(f => f.column === column);
            if (existingFilter) {
                const updatedValues = Array.from(new Set([...existingFilter.values, ...values]));
                return prevFilters.map(f => f.column === column ? { ...f, values: updatedValues } : f);
            } else {
                return [...prevFilters, { column, values }];
            }
        });
    };

    const handleCheckboxChange = (column, value) => {
        const updatedValues = selectedValues[column] || [];
        const isValueSelected = updatedValues.includes(value);
        if (isValueSelected) {
            setSelectedValues({
                ...selectedValues,
                [column]: updatedValues.filter(v => v !== value),
            });
        } else {
            setSelectedValues({
                ...selectedValues,
                [column]: [...updatedValues, value],
            });
        }
    };

    const handleSearch = (searchTerm) => {
        setSearchTerm(searchTerm);
    };

    const handleResetSearch = () => {
        setSearchTerm("");
    };

    const handleApplyFilters = () => {
        Object.keys(selectedValues).forEach(column => {
            handleFilterChange(column, selectedValues[column]);
        });
        setSelectedColumn({ accessor: null, header: null });
        setSelectedValues({});
        setSearchTerm("");
        setMenuOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchBarRef]);

    return (
        <div className="search-bar" ref={searchBarRef}>
            <div className="filters">
                <div className="searchbar-statistics">
                    <label className="searchbar-statistics-label"><FilterList />{t('Filtres')}</label>
                    {filters.map((filter, index) => (
                        <div key={index} className="filter">
                            {filter.column === 'isAnswered' ? 'answerindicator' : filter.column}: {filter.values.join(', ')}
                            <button onClick={() => setFilters(filters.filter(f => f !== filter))}><ClearRounded /></button>
                        </div>
                    ))}
                    <input
                        type="text"
                        onFocus={() => setMenuOpen(true)}
                        readOnly={true}
                        placeholder={`${!filters.length > 0 ? t('Select a filter by clicking here') : ''}`}
                    />
                </div>
            </div>
            {menuOpen && (
                <div className="filter-menu">
                    {!selectedColumn.accessor ? (
                        <div className="columns">
                            {dataWebexColumns.filter(col => col.filterable).map((col, index) => (
                                <div
                                    key={index}
                                    onClick={() => setSelectedColumn({ accessor: col.accessor === "answerindicator" && statusAnswered === true ? "isAnswered" : col.accessor, header: col.Header })}
                                    className={selectedColumn.accessor === col.accessor ? 'selected' : ''}
                                >
                                    {col.Header}
                                    <span className='open-btn'><KeyboardArrowRightRounded /></span>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="submenu">
                            <div className="filter-header" onClick={() => { setSelectedColumn({ accessor: null, header: null }); setSearchTerm(""); }}>
                                <span className="close-btn"><KeyboardArrowLeftRounded /></span>
                                <span>{selectedColumn.header}</span>
                            </div>
                            <div className="filter-content">
                                {initialAvailableValues[selectedColumn.accessor]
                                    .filter(value => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase()))
                                    .filter(value => !filters.some(filter => filter.column === selectedColumn.accessor && filter.values.includes(value)))
                                    .map((value, index) => {
                                        const isValueFiltered = filters.some(filter => filter.column === selectedColumn.accessor && filter.values.includes(value));
                                        if (!isValueFiltered) {
                                            return (
                                                <div className="input-content-checkbox-filter-statistics" key={index} onClick={() => handleCheckboxChange(selectedColumn.accessor, value)}>
                                                    <input
                                                        id={`checkbox-${index}`}
                                                        className='checkbox-statistics'
                                                        type="checkbox"
                                                        checked={selectedValues[selectedColumn.accessor]?.includes(value) || false}
                                                        readOnly
                                                    />
                                                    <span>
                                                        {
                                                            value === "Success" ? "Succès" :
                                                            value === "Refusal" ? "Refusé" :
                                                            value === "Failure" ? "Échec" :
                                                            value === 'SIP_ENTERPRISE' ? 'Interne' :
                                                            value === 'SIP_NATIONAL' ? 'National' :
                                                            value === 'SIP_MOBILE' ? 'Mobile' :
                                                            value === 'UNKNOWN' ? 'Inconnu' :
                                                            value === 'SIP_PREMIUM' ? 'Prenium' :
                                                            value === 'SIP_INTERNATIONAL' ? 'International' :
                                                            value === 'SIP_INBOUND' ? 'Entrant' :
                                                            value === 'SIP_OPERATOR' ? 'Opérateur' :
                                                            value === 'SIP_TOLLFREE' ? 'Sans frais' :
                                                            value === 'SIP_MEETING' ? 'Réunion' : 
                                                            value === "Yes" ? "Oui" :
                                                            value === "No" ? "Non" : 
                                                            value ===  "Yes-PostRedirection" ? "Redirection-d'appel" :
                                                            value
                                                        }
                                                    </span>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                                <div className='statistics-content-buttons-filters'>
                                    <div className='statistics-content-buttons-filters-search'>
                                        <input
                                            type="text"
                                            placeholder={`${t("Search...")}`}
                                            value={searchTerm}
                                            onChange={(e) => handleSearch(e.target.value)}
                                            className="search-input"
                                        />
                                        <button onClick={handleResetSearch}><ClearRounded /></button>
                                    </div>
                                    <div>
                                        <button
                                            className="close"
                                            onClick={() => setSelectedColumn({ accessor: null, header: null })}
                                        >
                                            {t('Close')}
                                        </button>
                                        <button
                                            className="apply"
                                            onClick={handleApplyFilters}
                                        >
                                            {t('Apply Filter')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}