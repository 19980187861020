import React, { useContext, useEffect, useState, useTransition } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import Loader from "../components/Loader";
import { useQueries } from "@tanstack/react-query";
import HomeLine from "../components/HomeLine";
import { useNavigate } from "react-router-dom";
import NumberCard from "../components/Card/NumberCard";
import { faShield, faCircleCheck, faEye, faKey } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";


export default function Home() {

  // import context
  const { apiUrl, user } = useContext(AuthContext);

  // stock percentage to display homeline component
  const [percentageEquipement, setPercentageEquipement] = useState([]);
  const [percentageWebex, setPercentageWebex] = useState([]);
  const [allEquipements, setAllEquipements] = useState({});
  const [firewall, setFireWall] = useState([]);
  const { t } = useTranslation();

  // use navigate from react router
  const navigate = useNavigate();

  // Request to get all equipements
  const getAllEquipements = async () => {

    const res = await axios(`${apiUrl}equipement/index`, {
      method: "GET",
      withCredentials: true,
    });

    return res.data;
  };

  // Get data for table
  const getCertificatList = async () => {
    const res = await axios(`${apiUrl}certificats`, {
      method: "GET",
      withCredentials: true,
    });

    if (user.roles.includes("ROLE_SUPERADMIN")) {
      return res.data;
    } else {
      return res.data.filter((incident) => incident.is_active === "1");
    }
  };

  // get all devices and stock in devices array
  async function getDevices() {
    // define an empty array
    const allDevices = await axios.get(`${apiUrl}webex/devices_live`, {
      withCredentials: true,
    });

    if (allDevices.data.errors) {
      return [];
    }

    return allDevices.data.items;
  }

  // Request to get all equipements palo
  const getAllPalo = async () => {
    const response = await axios.get(`${apiUrl}eqptpalo/`, {
      method: "GET",
      withCredentials: true,
    });

    return response.data;
  };

  // Request to get all licences and set a new array
  const getAllLicences = async () => {
    try {
      const responses = await axios.all([
        axios.get(`${apiUrl}license/palo/`, {
          method: "GET",
          withCredentials: true,
        }),
        axios.get(`${apiUrl}eqptpalo/`, {
          method: "GET",
          withCredentials: true,
        }),
      ]);

      // set array
      const licences = responses[0].data;
      const eqpt = responses[1].data;

      // set an empty array to stock famille object
      let family = [];
      eqpt.forEach((equipement) => {
        family.push(equipement.famille);
      });

      // create an array by set the equipement id in key and famille who matched
      const familiesById = eqpt.reduce((result, equipement) => {
        result[equipement.id] = equipement.famille;
        return result;
      }, {});

      // add famille in licences by match palo.id
      const licenceFamily = licences.map((licence) => {
        const famille = familiesById[licence.palo_id];
        return { ...licence, famille };
      });

      return licenceFamily;
    }catch (error){
    }
  };


  // create queries 
  const data = useQueries({
    queries: [
      { queryKey: ["equipements"], queryFn: getAllEquipements },
      { queryKey: ["certificats"], queryFn: getCertificatList },
      { queryKey: ["webex"], queryFn: getDevices },
      { queryKey: ["palo"], queryFn: getAllPalo },
      { queryKey: ["paloLicence"], queryFn: getAllLicences },
    ],
  });

  // array for each request
  const equipements = data[0];
  const certificats = data[1];
  const webex = data[2];
  const palo = data[3];
  const paloLicence = data[4];

  useEffect(() => {

    if (equipements.isFetched) {
      let allequipements = {
        reseau: equipements.data.filter((item) => {
          return item.famille ? item.famille.nom !== "Visio" && item.famille.nom !== "FIREWALL" : item
        }),

        visio: equipements.data.filter((item) => {
          return item.famille && item.famille.nom === "Visio"
        }),
      }

      setAllEquipements(allequipements);
    }

  }, [equipements.data, equipements.isFetched])

  useEffect(() => {

    if (allEquipements.reseau) {
      const percentageEquipement = [
        {
          percentage:
            allEquipements &&
            allEquipements.reseau.filter(
              (equipement) =>
                equipement.famille &&
                ["WLC", "AP"].includes(equipement.famille.nom)
            ).length,
          color: "#124188",
          value: "Wireless",
        },
        {
          percentage:
            allEquipements &&
            allEquipements.reseau.filter(
              (equipement) =>
                equipement.famille && ["SWITCH"].includes(equipement.famille.nom)
            ).length,
          color: "#997adb",
          value: "LAN",
        },
        {
          percentage:
            allEquipements &&
            allEquipements.reseau.filter(
              (equipement) =>
                !equipement.famille || equipement.famille &&
                !["WLC", "AP", "SWITCH"].includes(equipement.famille.nom)
            ).length || 0,
          color: "#AEC353",
          value: "Autres",
        },
      ];
      setPercentageEquipement(percentageEquipement);
    }

    const percentageWebex = [
      {
        percentage:
          webex.data &&
          webex.data.filter(
            (device) =>
              device.connectionStatus === "connected"
          ).length,
        color: "#86CB92",
        value: "Connected",
      },
      {
        percentage:
          webex.data &&
          webex.data.filter(
            (device) =>
              device.connectionStatus === "connected_with_issues"
          ).length,
        color: "#F18805",
        value: "Issues",
      },
      {
        percentage:
          webex.data &&
          webex.data.filter(
            (device) =>
              device.connectionStatus === "disconnected"
          ).length,
        color: "#DF2935",
        value: "Disconnected",
      },
      {
        percentage:
          webex.data &&
          webex.data.filter(
            (device) =>
              device.connectionStatus === "unknown"
          ).length,
        color: "#E9E9EC",
        value: "Unknown",
      },
      {
        percentage:
          webex.data &&
          webex.data.filter(
            (device) =>
              device.connectionStatus === "offline_expired"
          ).length,
        color: "grey",
        value: "Offline expired",
      },
      {
        percentage:
          webex.data &&
          webex.data.filter((device) =>
              !device.connectionStatus||
              device.connectionStatus &&
              !["connected", "connected_with_issues", "disconnected", "unknown", "offline_expired"].includes(device.connectionStatus)
          ).length,
        color: "#8977B0",
        value: "Autres",
      },
    ];

    setPercentageWebex(percentageWebex);
  }, [webex.data, allEquipements]);


  useEffect(() => {
    if (!equipements.isLoading && !palo.isLoading) {
      const equipementFirewall = equipements.data.filter(item => item.famille && item.famille.nom === "FIREWALL");
      setFireWall([...palo.data, ...equipementFirewall]);
    }
  }, [equipements.data, palo.data])

  return (
    <article className="page">
      <h1 className="page-title">{t('Bienvenue')} {user.firstName},</h1>
      <section className="page-homeline-section page-section">
        {!equipements.isLoading ? (
          allEquipements.reseau && allEquipements.reseau.length > 0 && (
            <HomeLine data={allEquipements.reseau} percentage={percentageEquipement} type={"Equipements"} />
          )
        ) : (
          <div className="homeline" style={{ border: "none" }}>
            <Loader />
          </div>
        )}
        {!webex.isLoading ? (
          !webex.isError && webex.data.length > 0 && (<>
            <HomeLine data={webex.data} percentage={percentageWebex} type={"webex"} /></>
          )
        ) : (
          <div className="homeline" style={{ border: "none" }}>
            <Loader />
          </div>
        )}
      </section>

      <section className="page-section">
        {!equipements.isLoading ? (
          allEquipements.visio && allEquipements.visio.length > 0 && (
            <div onClick={() => {
              navigate("/visio");
            }} >
              <NumberCard title={"Visio"} number={allEquipements.visio.length} logo={faEye} color="#997ADB" stylesIcons="logo-visio"/>
            </div>
          )
        ) : (
          <div className="homeline" style={{ border: "none" }}>
            <Loader />
          </div>
        )}
        {!certificats.isLoading ? (
          !certificats.isError &&
          certificats.data.length > 0 && (
            <div onClick={() => {
              navigate("/certificats");
            }} >
              <NumberCard title="Certificats" number={certificats.data.length} logo={faCircleCheck} color="#AEC353" stylesIcons="logo-certificats"/>
            </div>
          )
        ) : (
          <div className="card" style={{ border: "none" }}>
            <Loader />
          </div>
        )}
        {firewall.length > 0 && (
          <div onClick={() => {
            navigate("/firewall");
          }} >
            <NumberCard title="Firewall" number={firewall.length} logo={faShield} color="#FA8334" stylesIcons="logo-firewall"></NumberCard>
          </div>
        )}
        {!paloLicence.isLoading ? (
          !paloLicence.isError && paloLicence.data[0].data !== "No license palo found" && (

            <div onClick={() => {
              navigate("/licences/paloalto");
            }} >
              <NumberCard title="Licences" number={paloLicence.data.length} logo={faKey} color="#124188" stylesIcons="logo-license"/>
            </div>
          )
        ) : (
          <div className="card" style={{ border: "none" }}>
            <Loader />
          </div>
        )}
      </section>
    </article>
  );
}
