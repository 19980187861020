import React, { useContext, useState, useEffect } from "react"
import { AuthContext } from "../../context/AuthContext"
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useLocation, Link } from "react-router-dom";
import Loader from "../../components/Loader";
import NumberCard from "../../components/Card/NumberCard";
import { SubscriptionColumn } from "../../components/headerContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import TableComponent from "../../components/TableComponent";
import RowSubscription from "./RowSubscriptions";
import { useTranslation } from "react-i18next";

export default function Subscription() {
  const { apiUrl } = useContext(AuthContext);
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const subscriptionId = searchParams.get("subscriptionId");
  const [filteredData, setFilteredData] = useState([]);
  const { t } = useTranslation();

  const getDuration = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const months = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
    return months;
  }

  const getSubs = async () => {
    const res = await axios.get(`${apiUrl}subscription/index`, { withCredentials: true });
    if (res.data && res.data !== 'No data') {
      return res.data.map(sub => ({
        ...sub,
        duration: getDuration(sub.startDate, sub.endDate)
      }));
    } else {
      return [res.data];
    }
  }

  const subscription = useQuery({
    queryKey: ["subscription"],
    queryFn: getSubs
  })

  useEffect(() => {
    if (subscription.data !== undefined && !subscription.data.includes("No data")) {
      if (subscriptionId) {
        const filteredData = subscription.data.filter(sub => sub.subscriptionId.toLowerCase() === subscriptionId);
        setFilteredData(filteredData);
      } else {
        setFilteredData(subscription.data);
      }
    }
  }, [subscription.data, subscriptionId]);

  if (subscription.data && subscription.data.includes("No data")) {
    return (
      <article className="page">
        <h1 className="page-title">Aucune Subscription</h1>
      </article>
    );
  }

  return (
    <>
      {!subscription.isLoading ? (
        <>
          <article className="page">
            <div className="page-logo">
              <img src="./assets/cisco_logo.png" />
              <h1 className="page-title">Subscription</h1>
            </div>
            <section className="page-section">
              <NumberCard
                title="Nombre de subscriptions"
                number={
                  filteredData.length
                }
              />
            </section>
            {subscriptionId && (
              <div className="page-title page-link-timeline">
              <Link to={"/timeline"} className="button button-new"><FontAwesomeIcon icon={faChevronLeft}/> {t('Retour')}</Link>
              </div>
            )}
            <TableComponent
              tableData={
                filteredData
              }
              tableColumns={SubscriptionColumn}
              rowComponent={RowSubscription}
              checkbox={false}
              prefill={subscriptionId}
            />
          </article>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}