import axios from "axios";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useContext, useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import Loader from "../../components/Loader";
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import NumberCard from "../../components/Card/NumberCard";
import { useTranslation } from "react-i18next";

export default function EventTop() {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const { apiUrl } = useContext(AuthContext);
  const [dataForChart, setDataForChart] = useState({});
  const [totalCount, setTotalCount] = useState("");
  const [options, setOptions] = useState({});
  const {t} = useTranslation();

  const [days, setDays] = useState(7);
  const [limit, setLimit] = useState(10);

  //Request
  const getTopEvent = async () => {
    const res = await axios(`${apiUrl}umbrella/events_graph/${days}/${limit}`, {
      method: "get",
      withCredentials: true,
    })
        let count = 0;
        res.data.data.forEach((event) => {
          count += event.count;
        });
        setTotalCount(count);
        return res.data.data
  };

  const eventType = useQuery({
    queryKey: ["eventType"],
    queryFn: getTopEvent
  })

  useEffect(() => {
    eventType.refetch()
  }, [ days, limit]);

  const filterEventForChart = () => {
    const labels = eventType.data && eventType.data.map((event) => event.eventtype);
    const datasets = [
      {
        label: "count",
        data: eventType.data && eventType.data.map((event) => event.count),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
      },
    ];

    setDataForChart({ labels, datasets });
    // Set options in datachart
    setOptions({
      plugins: {
        colors: {
          forceOverride: true,
        },
        title: {
          display: true,
          position: "top",
          align: "start",
          text: t("Type des évènements"),
          color: "#124188",
          font: {
            family: "Inter",
            size: "20",
          },
        },
        legend: {
          position: "top",
          labels: {
            padding: 20,
            color: "#124188",
            font: {
              family: "Inter",
              size: "10",
            },
            usePointStyle: true,
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    });
  };

  useEffect(() => {
    filterEventForChart();
  }, [eventType.data]);

  return (
    <>
        <section className="page-section">
          <NumberCard title="Nombres d'évènements" number={totalCount} />
          <div className="page-section_form">
            <form className="form">
              <div className="form-input_container limit">
                <select
                  className="only"
                  id="days"
                  onChange={(e) => setDays(e.target.value)}
                >
                  <option value="7">7 </option>
                  <option value="15">15 </option>
                  <option value="30"> 30 </option>
                </select>
                <label htmlFor="days">{t('derniers jours')}</label>
              </div>
            </form>
            <form className="form">
              <div className="form-input_container limit">
                <select
                  className="only"
                  id="days"
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
                <label htmlFor="days">{t('derniers évènements')}</label>
              </div>
            </form>
          </div>
      </section>
      {!eventType.isFetching ? (
        <section className="page-chart">
          <Pie data={dataForChart} options={options} width={300} height={500} />
        </section>
      ) : (
        <Loader />
      )}
    </>
  );
}
