import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import RowEquipements from "./RowEquipements";
import Form from "../../components/Forms/Form";
import Loader from "../../components/Loader";
import { useLocation, Link } from "react-router-dom";
import { EquipementSchema } from "../../components/Forms/schema/YupSchema";
import CarousselNav from "../../components/Card/CarousselNav";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import TableComponent from "../../components/TableComponent";
import { Equipementscolumns } from "../../components/headerContent";
import NumberCard from "../../components/Card/NumberCard";
import RefreshParc from "../Auvik/RefreshParc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faEye, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function Equipement({ visio }) {
  const { apiUrl, user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const [isBU, setIsBU] = useState(false);
  const [resStatus, setResStatus] = useState(null);
  const [equipementsByType, setEquipementsByType] = useState([]);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const valuesType = ["Tous", "Wireless", "LAN", "Autres"];
  const [filteredColumns, setFilteredColumns] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const equipementId = searchParams.get("equipementId");
  const [filteredData, setFilteredData] = useState([]);

  const getAllEquipements = async () => {
    const res = await axios.get(`${apiUrl}equipement/index`, {
      withCredentials: true,
    });
    setIsBU(res.data.some((equipment) => equipment.bu !== null));

    return res.data.filter((item) => {
      if (visio) {
        return item.famille && item.famille.nom === "Visio"
      } else {
        return item.famille ? item.famille.nom !== "Visio" && item.famille.nom !== "FIREWALL" : item
      }
    }).map((item) => ({
      ...item,
      is_covered: item.is_covered === "YES" ? "OUI" : item.is_covered === "NO" ? "NON" : "",
    }));
  };

  const equipements = useQuery({
    queryKey: ["equipements"],
    queryFn: getAllEquipements
  });

  // Request to get all sites
  const getAllSites = async (entreprise) => {
    if (user.entreprise) {
      const res = await axios(`${apiUrl}equipement/sites/${entreprise}`, {
        method: "GET",
        withCredentials: true,
      });
      return res.data;
    }
  };

  const sites = useQuery({
    queryKey: ["sites", user.entreprise],
    queryFn: () => getAllSites(user.entreprise)
  });

  const getTenantsLive = async () => {
    try {
      const res = await axios.get(`${apiUrl}auvik/tenants_live`, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
    }
  };

  const tenantsLive = useQuery({
    queryKey: ["auvik"],
    queryFn: getTenantsLive,
  });

  useEffect(() => {
    queryClient.invalidateQueries(["sites", user.entreprise]);
  }, [user.entreprise]);

  useEffect(() => {
    equipements.refetch();
  }, [visio, user.entreprise]);

  useEffect(() => {
    filterEquipmentByType();
  }, [currentSlide, equipements.data]);

  useEffect(() => {
    // Filtrer les colonnes en fonction de la valeur de isBU
    setFilteredColumns(
      Equipementscolumns.filter((column) => {
        // Exclure la colonne "BU" si isBU est false
        if (column.accessor === "bu" && !isBU) {
          return false;
        }
        return true;
      })
    );
  }, [isBU, equipements.data]);

  useEffect(() => {
    if (!equipements.isFetching || equipements.data && equipements.data.length > 0) {
      if (equipementId) {
        const filteredData = equipements.data.filter(cont => cont.serial_number === equipementId);
        setFilteredData(filteredData.length > 0 ? filteredData : equipements.data);
      } else {
        setFilteredData(equipements.data);
      }
    }
  }, [equipements.data, equipementId, visio]);

  // setEquipmentsByType when user click on button tag
  const filterEquipmentByType = () => {
    let equipementsFiltered;

    switch (valuesType[currentSlide]) {
      case "Wireless":
        equipementsFiltered = equipements.data.filter(
          (equipement) =>
            equipement.famille && ["WLC", "AP"].includes(equipement.famille.nom)
        );
        break;

      case "LAN":
        equipementsFiltered = equipements.data.filter(
          (equipement) =>
            equipement.famille && equipement.famille.nom === "SWITCH"
        );
        break;

      case "Autres":
        equipementsFiltered = equipements.data.filter(
          (equipement) =>
            equipement.famille &&
            !["WLC", "AP", "SWITCH"].includes(equipement.famille.nom)
        );
        break;

      default:
        equipementsFiltered = equipements.data;
        break;
    }
    setEquipementsByType(equipementsFiltered);
  };

  useEffect(() => {
    queryClient.invalidateQueries(["auvik", tenantsLive.data]);
  }, [tenantsLive.data]);

  const handleRefresh = () => {
    setRefreshStatus(true);
  };

  // Content to create new equipement
  const formContentNew = [
    { id: "serial_number", type: "text", title: "Numéro de série" },
    { id: "ip", type: "text", title: "IP" },
    { id: "device_name", type: "text", title: "Nom de l'appareil" },
    { id: "software_version", type: "text", title: "Version du logiciel" },
    { id: "BU", type: "text", title: "BU" },
    {
      id: "site",
      type: "select",
      values: !sites.isLoading
        ? sites.data.map((site) => {
          return { value: site.id, label: site.nom };
        })
        : [],
      title: "Site",
    },
  ];

  // Request to create new equipement
  const createNewEquipement = async (data) => {
    // Update the data object with converted date strings
    const updatedData = {
      ...data,
      holding: user.holding,
      entreprise: user.entreprise,
      is_active: 1,
      sn_valid: 1,
      source: "ECP"
    };


    const res = await axios(`${apiUrl}equipement/new`, {
      method: "post",
      withCredentials: true,
      data: updatedData,
    });

    return res.data;
  };

  //Ajout du filtrage par tenantId sur Elit afin de récupérer Elit-HQ au lieu de tous les sites.
  const tenantId = tenantsLive.data && (user.entreprise !== 18 ? tenantsLive.data[1]?.auvik_id : user.entreprise !== 27 ? tenantsLive.data[1]?.auvik_id : tenantsLive.data[0]?.auvik_id);

  // Show loader until all data is fetched
  if (equipements.isLoading || equipements.isFetching  || sites.isLoading || tenantsLive.isLoading || !filteredData.length) {
    return <Loader />;
  }

  if (equipements.data && equipements.data.length === 0) {
    return (
      <article className="page">
        <h1 className="page-title">Aucun équipements</h1>
      </article>
    );
  }

  return (
    <>
      <article className="page">
        <div className="page-logo">
          <h1 className="page-title">{t("Equipements")} {visio && "Visio"}</h1>
          {!visio && <CarousselNav
            arrayValues={valuesType}
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
          />}
        </div>
        <section className="page-section">
          <NumberCard
            title={`${t("Nombre d'équipements")} ${currentSlide !== 0 ? valuesType[currentSlide] : visio ? "visio" : ""
              }`}
            number={
              currentSlide !== 0
                ? equipementsByType.length
                : filteredData.length
            }
            logo={visio ? faEye : faGear}
            color={visio ? "#997ADB" : "#124188"}
            stylesIcons={visio ? "logo-visio" : "logo-equipements"}
          />
        </section>
        <div className="content-buttons-equipements">
          <div className="content-buttons-equipements-container">
            {equipementId && (
              <div className="page-title page-link-equipement">
                <Link to={"/Smartnet"} className="button button-new"><FontAwesomeIcon icon={faChevronLeft} /> {t('Retour')}</Link>
              </div>
            )}
            {!visio && user.roles.includes('ROLE_SUPERADMIN') &&
              <div className="refreshParc-button-refresh-content">
                <button className="button button-new" onClick={handleRefresh}>{t("Rafraîchir le parc")}</button>
              </div>
            }
          </div>
        </div>
        {!visio &&
          <div className="table-leftbtn">
            <Form
              resStatus={resStatus}
              setResStatus={setResStatus}
              formContent={formContentNew}
              schema={EquipementSchema}
              submitFunction={createNewEquipement}
              title="Ajouter un équipement"
              action="new"
            />
          </div>
        }
        <TableComponent
          tableData={
            currentSlide !== 0 ? equipementsByType : filteredData
          }
          rowComponent={RowEquipements}
          tableColumns={filteredColumns}
          sites={sites.data}
          referrer={visio ? 'visio' : 'equipements'}
          checkbox={false}
          holding={user.entreprise}
        />
      </article>
      <RefreshParc setRefreshStatus={setRefreshStatus} tenantId={tenantId} refreshStatus={refreshStatus} />
    </>
  );
}
