import { useContext, useState } from "react";
import CarousselNav from "../../components/Card/CarousselNav";
import WebexIot from "./WebexIoT";
import MerakiIoT from "./MerakiIoT";
import { AuthContext } from "../../context/AuthContext";
import { useEffect } from "react";

export default function IoT() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const { user } = useContext(AuthContext);
    const [valuesType, setValuesType] = useState([]);

    useEffect(() => {

        if (user.roles.includes("ROLE_WEBEX") && user.roles.includes("ROLE_MERAKI")) {
            setValuesType(["Webex", "Meraki"])
        }

    }, [user.roles])

    return (
        <article className="page">
            <h1 className="page-title">IoT</h1>
            <CarousselNav arrayValues={valuesType} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} />
            {valuesType.length === 1 ?
                (user.roles.includes("ROLE_WEBEX") ? <WebexIot /> : <MerakiIoT />) :
                (currentSlide === 0 ? <WebexIot /> : <MerakiIoT />)
            }
        </article>
    )
}