import {
  faCancel,
  faCheck,
  faFolderOpen
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useContext } from "react";
import { useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

/**
 * 
 * @param {useQuery object} files - to refetch files
 * @param {string} path - path of the file
 */
export default function NewFolder({ path, files, onClose }) {
  const {t} = useTranslation();
  const { apiUrl } = useContext(AuthContext);
  const [createError, setCreateError] = useState(null);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const createFolder = (e) => {
    const input = document.querySelector(".input-newFolder");
    const folderName = input.value;

    const formData = new FormData();
    formData.append("folder_name", folderName);

    axios(`${apiUrl}files/createFolder${path.length > 0 ? "/" + path : ""}`, {
      method: "post",
      withCredentials: true,
      data: formData,
    })
      .then((res) => {
        files.refetch();
        setCreateError(null);
        onClose();
      })
      .catch((err) => {
        setCreateError(err.response.data);
      });
  };

  return (
    <>
      <div className="file-directory">
        <FontAwesomeIcon icon={faFolderOpen} className="file-directory_icon file-directory_new"/>
        <form
          className="file-directory_form"
          onSubmit={handleSubmit(createFolder)}
        >
          <input
            type="text"
            className="input-newFolder file-directory_form-input"
          />
          <div className="file-directory_form-button">
            <button type="submit" className="button button-new">
              <FontAwesomeIcon icon={faCheck} />
            </button>
            <button
              type="button"
              className="button button-delete"
              onClick={() => onClose()} 
            >
              <FontAwesomeIcon icon={faCancel} />
            </button>
          </div>
          {createError && <small className="error">{createError}</small>}
        </form>
      </div>
    </>
  );
}
