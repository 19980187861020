import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "./Forms/Form";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useTranslation } from "react-i18next";

/**
 *
 * @param {array} formContent
 * @param {object} schema - Yup Schema
 * @param {function} submitFunction
 * @param {function} deleteFunction
 * @param {function} setHoldingSelected
 * @param {function} updateSla
 * @param {function} updateEquipment
 * @returns
 */
export default function Update({
  formContent,
  schema,
  submitFunction,
  deleteFunction,
  setHoldingSelected,
  updateSla,
  updateEquipment,
  stateQueryRow
}) {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  return (
    <div className="table-update">
      {user.roles.includes("ROLE_SUPERADMIN") && updateEquipment && (
        <button className="button button-new" onClick={updateEquipment}>
          {t("Mettre à jour")}
        </button>
      )}
      {user.roles.includes("ROLE_SUPERADMIN") && updateSla && (
        <button className="button button-new" onClick={updateSla}>
          {t("Recalculer SLA")}
        </button>
      )}
      <Form
        formContent={formContent}
        schema={schema}
        submitFunction={submitFunction}
        title="Modifier"
        action="update"
        setHoldingSelected={setHoldingSelected}
        stateQueryRow={stateQueryRow}
      />
      <button className="button button-delete" onClick={deleteFunction}>
        {" "}
        <FontAwesomeIcon icon={faTrash} /> {t("Supprimer")}{" "}
      </button>
    </div>
  );
}
