import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import Loader from "../../components/Loader";
import RowPoly from "./RowPoly";
import { PolyColumn } from "../../components/headerContent";
import { useQuery } from "@tanstack/react-query";
import TableComponent from "../../components/TableComponent";
import NumberCard from "../../components/Card/NumberCard";
import { useTranslation } from "react-i18next";
export default function Poly() {

    const { apiUrl } = useContext(AuthContext)
    const { t } = useTranslation();


    const getPolyDevice = async () => {
        const res = await axios(`${apiUrl}poly`, {
            method: "get",
            withCredentials: true
        })

        return res.data

        //    const polycomDevices = res.data.map(item => {
        //     return {
        //         id: item.node.id,
        //         name: item.node.name ? item.node.name : "Non défini",
        //         room: item.node.room.name ? item.node.room.name : "Non défini" ,
        //         model: item.node.model.name ? item.node.model.name : "Non défini" ,
        //         description: item.node.model.description,
        //         software: item.node.softwareVersion
        //     }
        //    })
        //   return polycomDevices
    }

    const polyDevice = useQuery({
        queryKey: ["polycom"],
        queryFn: getPolyDevice
    })



    if (polyDevice.isError) {
        return <article className="page">
            <h1 className="page-title">{t('Aucun équipement Poly')}</h1>
        </article>
    }


    return (

        <>
            {!polyDevice.isLoading ?
                <article className="page">
                    <section className="page-logo">
                        <img src="./assets/poly_logo.png" alt="Logo de Polycom" />
                        <h1 className="page-title">Poly</h1>
                    </section>
                    <section className="page-section">
                        <NumberCard title="Nombre d'équipements" number={polyDevice.data.length} />
                    </section>
                    <TableComponent tableColumns={PolyColumn} tableData={polyDevice.data} rowComponent={RowPoly} />
                </article> : <Loader />
            }
        </>

    )
}
