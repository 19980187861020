import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import Loader from "../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import Directory from "./Directory";
import File from "./File";
import NewFolder from "./newFolder";
import ImportFile from "./ImportFile";
import { useTranslation } from "react-i18next";

export default function FileManager() {
  
  // import context
  const {t} = useTranslation();
  const { user, apiUrl } = useContext(AuthContext);
  const {
    formState: { errors },
  } = useForm();

  // files informations
  const [path, setPath] = useState([]);
  const [directory, setDirectory] = useState([]);
  const [allfiles, setAllFiles] = useState([]);
  const [isNewFolderVisible, setIsNewFolderVisible] = useState(false);
  const [userHolding, setUserHolding] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [filesPerPage] = useState(10);

  const getAllHolding = useQuery({
    queryKey: ["holding"],
    queryFn: async () => {
      if (user) {
        try {
          const res = await axios(`${apiUrl}commerce/holding`, {
            method: "get",
            withCredentials: true,
          });
          return res.data;
        } catch (error) {
        }
      }
    },
  });

  useEffect(() => {
    if (user.roles.includes("ROLE_SUPERADMIN")) {
      const holdingFiltered = getAllHolding.data && getAllHolding.data.find((getAllHolding) => getAllHolding.id === user.holding);
      setUserHolding(holdingFiltered);
    }
  }, [getAllHolding.data, user.holding, user.roles]);

  const getFiles = async () => {
    const res = await axios.get(`${apiUrl}files/show/${path.join("/")}`, {
      withCredentials: true,
    });
    setDirectory(res.data.filter((item) => item.type === "directory"));
    setAllFiles(res.data.filter((item) => item.type === "file"));
    return res.data;
  };

  const files = useQuery({
    queryKey: ["files"],
    queryFn: getFiles,
  });

  const handlePath = (file) => {
    if (path.includes(file.name)) {
      setPath(path.filter((item) => item !== file.name));
    }

    if (path === "") {
      setPath([file.name]);
    } else {
      setPath((prev) => [...prev, file.name]);
    }
  };

  useEffect(() => {
    files.refetch();
  }, [path]);

    const indexOfLastFile = currentPage * filesPerPage;
    const indexOfFirstFile = indexOfLastFile - filesPerPage;
    const currentFiles = allfiles.slice(indexOfFirstFile, indexOfLastFile);
  
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
  

  return (
    <>
      {!files.isLoading ? (
        <article className="page">
          <div className="file-container">
            <h1 className="page-title">{t("Gestionnaire de fichier")}</h1>
            {user.roles.includes("ROLE_SUPERADMIN") && (
              <div className="file-container-buttons">
                <button className="button file-folder-button-new" onClick={() => setIsNewFolderVisible(true)}>
                  {t("Créer un dossier")}
                </button>
                {user.roles.includes("ROLE_SUPERADMIN") && (
                  <ImportFile path={path} files={files} />
                )}
              </div>
            )}
          </div>
          <section className="file-manager">
            <div className="file-path">
              {userHolding && (
                <span className="file-path_item_holding">{userHolding.nom} : \</span>
              )}
              {path.length > 0 &&
                path.map((item, index) => {
                  return (
                    <div key={index}>
                      <span className="file-path_item">{item}</span>
                      {path.length >= 2 && item !== path[path.length - 1] && (
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="file-path_icon"
                        />
                      )}
                    </div>
                  );
                })}
            </div>
            <div className="file-dashboard">
              {path.length > 0 && (
                <button
                  className="button file-path-button_arrow"
                  onClick={() => {
                    setPath(
                      path.filter((el, index) => el !== path[path.length - 1])
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
              )}
              <div className="file-directory_container">
                <div className="file-directory_list">
                  {directory.map((file, index) => {
                    return (
                      <Directory
                        file={file}
                        files={files}
                        path={path}
                        handlePath={handlePath}
                        key={index}
                      />
                    );
                  })}
                  {/* {} */}
                  {isNewFolderVisible && (
                    user.roles.includes("ROLE_SUPERADMIN") && (
                      <NewFolder path={path} files={files} onClose={() => setIsNewFolderVisible(false)}/>
                    )
                  )}
                </div>
              </div>
              <div className="file-files_container">
                {currentFiles.map((file) => (
                  <File key={file.name} file={file} files={files} path={path} />
                ))}
              </div>
              {allfiles.length > 10 && (
                <div className="file-pagination">
                  <button
                    className="button file-pagination-button"
                    disabled={currentPage === 1}
                    onClick={() => paginate(currentPage - 1)}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} className="pagination-icon" />
                  </button>
                  <button
                    className="button file-pagination-button"
                    disabled={currentPage === Math.ceil(allfiles.length / filesPerPage)}
                    onClick={() => paginate(currentPage + 1)}
                  >
                    <FontAwesomeIcon icon={faChevronRight} className="pagination-icon" />
                  </button>
                  <span className="file-pagination-info">
                    Page <strong>{currentPage} sur {Math.ceil(allfiles.length / filesPerPage)}</strong>
                  </span>
                </div>
              )}
            </div>
          </section>
        </article>
      ) : (
        <Loader />
      )}
    </>
  );
}
