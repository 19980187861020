import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

export default function TopClients() {
  const { apiUrl } = useContext(AuthContext);
  const { t } = useTranslation();

  const getTopClients = async () => {
    const res = await axios.get(`${apiUrl}meraki/topClients`, {
      withCredentials: true,
    });
    return res.data;
  };

  const topClients = useQuery({
    queryKey: ["topClients"],
    queryFn: getTopClients,
  });

  return (
    <>
      {!topClients.isLoading ? (
        <section className="client">
          <h2 className="client-title">
            {t('Top clients par usage')}
            <span> {t('(sur le dernier jour)')} </span>
          </h2>
          <table className="client-table">
            <thead>
              <tr>
                <th>Client</th>
                <th>Network</th>
                <th>{t('Usage')}</th>
                <th>{t('% Usage')}</th>
              </tr>
            </thead>
            <tbody>
              {topClients.data.map((client, index) => (
                <tr key={index}>
                  <td>{client.name}</td>
                  <td>{client.network.name}</td>
                  <td>
                    {client.usage.total / 1000 >= 1
                      ? `${(client.usage.total / 1000).toFixed(2)} Go`
                      : `${client.usage.total.toFixed(2)} Mo`}
                  </td>
                  <td>
                    <div className="client-usage">
                      <div
                        className="client-percentage"
                        style={{ width: `${Math.floor(client.usage.percentage)}%` }}
                      ></div>
                      <h3>{Math.round(client.usage.percentage * 100) / 100} %</h3>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      ) : (
        <Loader />
      )}
    </>
  );
}
