import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import Loader from "../../components/Loader";
import { useQuery } from "@tanstack/react-query";
import ChartIncidents from "./ChartIncidents";
import IncidentsFilterChart from "./IncidentsFilterChart";
import TableIncidents from "./TableIncidents";
import NumberCard from "../../components/Card/NumberCard";
import { useTranslation } from "react-i18next";

const monthNamesMap = {
  "Janvier": "01",
  "Février": "02",
  "Mars": "03",
  "Avril": "04",
  "Mai": "05",
  "Juin": "06",
  "Juillet": "07",
  "Août": "08",
  "Septembre": "09",
  "Octobre": "10",
  "Novembre": "11",
  "Décembre": "12"
};

export default function Incidents() {
  const { user, apiUrl } = useContext(AuthContext);
  const [incidentsFilteredByGraph, setIncidentsFilteredByGraph] = useState([]);
  const [isShowAllTickets, setisShowAllTickets] = useState(false);
  const [isChartClicked, setisChartClicked] = useState(false);
  const [currentSlide, setCurrentSlide] = useState("0");
  const [timeFilter, setTimeFilter] = useState("all");
  const [incidentsFilteredByTime, setIncidentsFilteredByTime] = useState([]);
  const [ticketNumber, setTicketNumber] = useState(0);
  const [filter, setFilter] = useState(null);
  const [label, setLabel] = useState(null);
  const monthNames = Object.keys(monthNamesMap);
  const currentMonth = new Date().getMonth() + 1;
  const formattedMonth = currentMonth.toString().padStart(2, '0');
  const [monthsFilter, setMonthsFilter] = useState(formattedMonth);
  const { t } = useTranslation();

  const originalLabels = {
    "Issue": "Probleme",
    "Minor change": "Changement mineur",
    "Major change": "Changement majeur",
    "Incident outside of contract": "Incident hors-contrat",
    "Alerts": "Alerte",
    "High S2": "Haute S2",
    "Medium S3": "Moyenne S3",
    "Low S4": "Basse S4",
    "Critical S1": "Critique S1",
    "SLA respected": "SLA RESPECTE",
    "SLA exceeded": "SLA DEPASSE"
  };

  const valuesType = [
    "Demande",
    "Sévérité",
    "SLA",
    "Temps de résolution",
    "Sujet des incidents par contact",
    "Sujet des demandes par contact",
    "Sujet des incidents par date",
    "Sujet des demandes par date",
    "Changements ouverts par mois",
    "Changements clos par mois",
    "Incidents ouverts par mois",
    "Incidents clos par mois",
    "Tickets par sites"
  ];

  const getIncidentsGraph = async () => {
    try {
      const res = await axios(`${apiUrl}incident`, {
        method: "GET",
        withCredentials: true,
      });

      const data = res.data;
      const sortedAndFilteredData = {};
      let totalTickets = 0; // Compteur total de tickets

      // Vérifiez si l'utilisateur a des rôles
      if (user.roles.length > 0) {
        // Itérez sur chaque clé de l'objet data
        Object.keys(data).forEach((key) => {
          const value = data[key];
          // Vérifiez si la valeur associée à la clé est un tableau
          if (Array.isArray(value)) {
            // Tri et filtre selon le rôle de l'utilisateur
            let sortedData = [];
            if (user.roles.includes("ROLE_SUPERADMIN")) {
              // Trier les incidents par date pour les superadmins
              sortedData = value.sort((a, b) => new Date(b.createdon) - new Date(a.createdon));
            } else {
              // Filtrer et trier les incidents pour les autres rôles
              sortedData = value
                .filter((incident) => incident.is_active === "1")
                .sort((a, b) => new Date(b.createdon) - new Date(a.createdon));
            }
            // Ajouter les données triées et/ou filtrées au nouvel objet
            sortedAndFilteredData[key] = sortedData;
            // Ajouter le nombre de tickets à totalTickets
            totalTickets += sortedData.length;
          }
        });
      }
      // Ajouter le nombre total de tickets
      sortedAndFilteredData.ticketsnumber = totalTickets;
      return sortedAndFilteredData; // Retourner l'objet trié et filtré avec le nombre total de tickets
    } catch (error) {
      console.error("Error fetching incidents:", error);
      return {}; // Retourner un objet vide en cas d'erreur
    }
  };

  const incidents = useQuery({
    queryKey: ["incidents"],
    queryFn: getIncidentsGraph,
  });

  const getIncidentsList = async () => {
    try {
      const res = await axios(`${apiUrl}incident/index`, {
        method: "GET",
        withCredentials: true,
      });

      if (user.roles.length > 0) {
        if (user.roles.includes("ROLE_SUPERADMIN")) {
          return res.data.sort((a, b) => new Date(b.createdon) - new Date(a.createdon))
        } else {
          return res.data.filter((incident) => incident.is_active === "1").sort((a, b) => new Date(b.createdon) - new Date(a.createdon));
        }
      }
    } catch (error) {
      console.error("Error fetching incidents:", error);
      return {}; // Retourner un objet vide en cas d'erreur
    }
  };

  const incidentsList = useQuery({
    queryKey: ["incidentsList"],
    queryFn: getIncidentsList,
  });

  const handleIncidentsFilteredByTime = () => {
    const todayDate = new Date();
    // Définir les différentes périodes de temps pour le filtrage
    const filterDates = {
      year: new Date(todayDate.getFullYear(), 0, 1),
      twelve: new Date(todayDate.getFullYear(), todayDate.getMonth() - 12, 1),
      months: new Date(todayDate.getFullYear(), todayDate.getMonth(), 1),
      week: new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 7),
    };

    // Filtrer les incidents en fonction du temps et de l'état actif pour chaque tableau d'objet
    const incidentsFilteredByTime = Object.keys(incidents.data).reduce((acc, key) => {
      const value = incidents.data[key];
      if (Array.isArray(value)) { // Vérifier que la valeur est un tableau
        const filteredData = value.filter((item) => {
          if (key === "graph_camembert" && timeFilter !== "months") {
            return item;
          } else if (key === "graph_camembert_date" && timeFilter === "months") {
            if (timeFilter === "months" && monthsFilter !== null) {
              const itemMonth = item.date.split('-')[1];
              return itemMonth === monthsFilter;
            } else {
              return item;
            }
          } else if (key === "graph_ho") {
            const filterDate = new Date(item.resolveby);
            if (timeFilter === "all") {
              return filterDate; // Retourner tous les incidents actifs
            }
            // Filtrer les incidents actifs par date en fonction du filtre de temps sélectionné
            return filterDate >= filterDates[timeFilter];
          } else {
            const filterDate = new Date(item.resolveby || item.createdon);
            const isActive = item.is_active === "1";
            if (timeFilter === "all") {
              return isActive; // Retourner tous les incidents actifs
            }
            // Filtrer les incidents actifs par date en fonction du filtre de temps sélectionné
            return filterDate >= filterDates[timeFilter] && isActive;
          }
        });

        acc[key] = filteredData;
      }
      return acc;
    }, {});

    // Mettre à jour l'état avec les incidents filtrés
    setIncidentsFilteredByTime(incidentsFilteredByTime);
  };


  useEffect(() => {
    !incidents.isLoading && handleIncidentsFilteredByTime();
  }, [incidents.data, timeFilter, monthsFilter]);

  // Set new data when user's click
  const handleGraphClick = (event, chartRef) => {
    const chartInstance = chartRef;
    const activePoints = chartInstance.getElementsAtEventForMode(
      event,
      "nearest",
      { intersect: true },
      true
    );
    if (activePoints.length > 0) {
      let label;
      const firstPoint = activePoints[0];
      const untranslatedLabel = chartInstance.data.datasets[firstPoint.datasetIndex].label;
      const filter = chartInstance.data.labels[firstPoint.index];
      label = originalLabels[untranslatedLabel] || untranslatedLabel;
      setFilter(filter);
      setLabel(label);
    }
  };

  const handleIncidentsClickOnGraph = async () => {
    if (label !== null && filter !== null) {
      try {
        let filterFn;
        let statuscode;

        switch (currentSlide) {
          case "0":
            statuscode = "casetypecode";
            filterFn = (incident) =>
              incident.casetypecode === label &&
              incident.createdon.includes(filter) &&
              incident.statuscode === "5";
            break;
          case "1":
            statuscode = "prioritycode"
            filterFn = (incident) =>
              incident.prioritycode === label &&
              incident.createdon.includes(filter) &&
              incident.casetypecode === "Incident";
            break;
          case "2":
            statuscode = "sla";
            filterFn = (incident) =>
              incident.sla === label &&
              incident.resolveby.includes(filter) &&
              incident.casetypecode === "Incident" &&
              incident.subject !== "Cybersécurité";
            break;
          default:
            return;
        }

        const res = await axios.get(
          `${apiUrl}incident/index?${statuscode}=${label}&${statuscode === "sla" ? "resolveby" : "createdon"}=${filter}`,
          { withCredentials: true }
        );

        const filteredIncidents = res.data.filter(filterFn);
        setIncidentsFilteredByGraph(filteredIncidents);

        if (!isShowAllTickets) {
          setisShowAllTickets(true);
          setisChartClicked(true);
        }
      } catch (error) {
      }
    }
  };

  useEffect(() => {
    if (filter !== null && label !== null) {
      handleIncidentsClickOnGraph();
    }
  }, [filter, label, incidents.data]);

  if (incidents.data && incidents.data.length === 0) {
    return (
      <article className="page">
        <h1 className="page-title">Aucun ticket</h1>
      </article>
    );
  }

  const handleDisplayAllTickets = () => {
    setisShowAllTickets(!isShowAllTickets);
    setisChartClicked(false);
    setIncidentsFilteredByGraph([]);
    setLabel(null);
    setFilter(null);
  };

  return (
    <>
      {!incidents.isLoading ? (
        <>
          <article className="page">
            <h1 className="page-title">Tickets</h1>
            {isShowAllTickets ? (
              <>
                <TableIncidents
                  incidents={incidentsList}
                  handleDisplayAllTickets={handleDisplayAllTickets}
                  incidentsFilteredByGraph={incidentsFilteredByGraph}
                  isChartClicked={isChartClicked}
                />
              </>
            ) : (
              <>
                <section className="page-section container_ticket_per_site">
                  <NumberCard
                    title="Tickets"
                    number={!incidents.isLoading ? ticketNumber : null}
                  />
                  <IncidentsFilterChart
                    setCurrentSlide={setCurrentSlide}
                    setTimeFilter={setTimeFilter}
                    timeFilter={timeFilter}
                    currentSlide={currentSlide}
                    valuesType={valuesType}
                  />
                  {currentSlide === "12" && timeFilter === "months" && (
                    <div className="page-section_form form_months_sites">
                      <form className="form">
                        <div className="form-input_container">
                          <label htmlFor="type">{t('Sélectionnez le mois')}</label>
                          <select
                            className="only only_incidents"
                            id="type"
                            onChange={(e) => {
                              setMonthsFilter(e.target.value);
                            }}
                            value={monthsFilter}
                          >
                            {monthNames.map((month) => (
                              <option key={monthNamesMap[month]} value={monthNamesMap[month]}>
                                {t(month)}
                              </option>
                            ))}
                          </select>
                        </div>
                      </form>
                    </div>
                  )}
                </section>
                <button
                  className="button button-new"
                  onClick={() => {
                    handleDisplayAllTickets();
                  }}
                >
                  {t('Afficher tous les tickets')}
                </button>
                <ChartIncidents
                  incidents={incidentsFilteredByTime}
                  currentSlide={currentSlide}
                  handleGraphClick={handleGraphClick}
                  timeFilter={timeFilter}
                  monthsFilter={monthsFilter}
                  setTicketNumber={setTicketNumber}
                />
              </>
            )}
          </article>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
