import React from "react";
import AccordionItem from "../../../../components/AccordionItem";
import { useTranslation } from "react-i18next";

export default function AccordionReportWebex({ setLinkPath }) {
    const handleNavigation = (path) => {
        setLinkPath(path);
    };

    const { t, i18n } = useTranslation();

    return (
        <div className="statistics-report-container">
            <h1>{t("Rapports publics")}</h1>
            <AccordionItem title={t("1- Écran Appels")}>
                <ul className="accordion-item-container">
                    <li><a onClick={() => handleNavigation('Enquête générale')}>{t("Enquête générale")}</a> - <span>{t("Quelle est la situation générale ?")}</span></li>
                    <li><a onClick={() => handleNavigation('Enquête par sites')}>{t("Enquête par site")}</a> - <span>{t("Comment se distribuent les appels en fonction des sites ?")}</span></li>
                    <li><a onClick={() => handleNavigation('Enquête par poste')}>{t("Enquête par poste")}</a> - <span>{t("Combien d'appels effectue et reçois chaque poste ?")}</span></li>
                    <li><a onClick={() => handleNavigation('Enquête par origine d\'appels')}>{t("Enquête par origine d\'appels")}</a> - <span>{t("Comment les appels sont-ils répartis en fonction de leur origine ?")}</span></li>
                </ul>
            </AccordionItem>
            <AccordionItem title={t("2- Analyse des tendances")}>
                <ul className="accordion-item-container">
                    {/* Information : La tendance est déterminée en analysant les appels pour chaque heure de chaque jour et en comparant les heures avec le plus grand nombre d'appels à travers les jours. Cela permet d’identifier les heures les plus fréquentes et de révéler les tendances horaires. */}
                    <li><a onClick={() => handleNavigation('Analyse des tendances')}>{t("Analyse des tendances")}</a> - <span>{t("Comment varient les appels dans le temps ?")}</span></li>
                    <li><a onClick={() => handleNavigation('Analyse des tendances par postes')}>{t("Analyse des tendances par postes")}</a> - <span>{t("Comment varient les appels dans le temps regroupés par postes ?")}</span></li>
                    <li><a onClick={() => handleNavigation('Analyse des tendances par sites')}>{t("Analyse des tendances par sites")}</a> - <span>{t("Comment varient les appels dans le temps regroupés par sites ?")}</span></li>
                    <li><a onClick={() => handleNavigation('Analyse temporelle (HH, JJ...)')}>{t("Analyse temporelle (HH, JJ...)")}</a> - <span>{t("Comment sont distribués les appels regroupés selon les heures, jours, mois ou trimestres ?")}</span></li>
                </ul>
            </AccordionItem>
        </div>
    );
}