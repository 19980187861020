import { useContext, useEffect, useState, useRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import dataJsonParking from './data_test.json';
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import ParkingSvg from "./ParkingSvg/ParkingSvg";
import ReservationPopup from "./ReservationPopup";
import StatusMessage from "./StatusMessage";
import ReservationTable from "./ReservationTable";
import { convertDateHoursMnSec } from "../../components/utils";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

export default function Parking() {
    const { apiUrl, user } = useContext(AuthContext);
    const queryClient = useQueryClient();
    const parkingPlaces = useRef([]);
    const [placeInfo, setPlaceInfo] = useState(null);
    const [popupState, setPopupState] = useState(false);
    const [dataHoverInterface, setDataHoverInterface] = useState({ data: null });
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [statusReservation, setStatusReservation] = useState(null);
    const [allPlacesParking, setAllPlacesParking] = useState([]);
    const today = new Date();
    const [reserveDate, setReserveDate] = useState([today]);
    const [reservePlaque, setReservePlaque] = useState(null);
    const [stateReservedPlace, setStateReservedPlace] = useState(false);
    const [filterAvailable, setFilterAvailable] = useState(false);
    const [filterElectric, setFilterElectric] = useState(false);
    const { t } = useTranslation();

    const getDataParking = async () => {
        try {
            const res = await axios.get(`${apiUrl}parking/listDevices`, {
                withCredentials: true,
            });
            return res.data;
        } catch (error) {
        }
    };

    const getDeviceStatusParking = async () => {
        try {
            const res = await axios.get(`${apiUrl}parking/AllDevicesStatus`, {
                withCredentials: true,
            });
            return res.data;
        } catch (error) {
        }
    };

    const dataParking = useQuery({
        queryKey: ["dataParking"],
        queryFn: getDataParking
    });

    const deviceStatusParking = useQuery({
        queryKey: ["deviceStatusParking"],
        queryFn: getDeviceStatusParking
    });

    const reserveParkingMutation = useMutation(async () => {
        if (!placeInfo) return;

        let pir_trigger = placeInfo.pir_trigger === 2 ? 0 : 2;

        const dataReserved = {
            pir_trigger: pir_trigger,
            date: convertDateHoursMnSec(Date.now()),
            dateend: placeInfo.pir_trigger === 2 ? null : convertDateHoursMnSec(reserveDate),
            light_status: placeInfo.electric,
            plaque: placeInfo.pir_trigger === 2 ? null : reservePlaque,
            user: placeInfo.pir_trigger === 2 ? null : user.id,
        };

        const res = await axios.post(`${apiUrl}parking/reservation/${placeInfo.capteur_id}`, dataReserved, {
            withCredentials: true
        }
        );

        return res.data;
    }, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["dataParking"]);
            queryClient.invalidateQueries(["deviceStatusParking"]);
            dataParking.refetch();
            deviceStatusParking.refetch();
            handleCloseReserved();
            setStatusReservation(true);
        },
        onError: (error) => {
            setStatusReservation(false);
            setPopupState(false);
        }
    });

    const handleReserved = (event) => {
        if (!placeInfo) return;
        event.preventDefault();
        try {
            reserveParkingMutation.mutate();
        } catch (error) {
            console.error("Error reserving parking place:", error);
        }
    };

    useEffect(() => {
        if (dataParking && dataParking.data && dataParking.data.capteurs.length > 0 && deviceStatusParking.data && deviceStatusParking.data.latest_pir_trigger) {
            parkingPlaces.current = Array.from(document.getElementsByClassName('path-parking'));
            stateReserved();
        }
    }, [dataParking.data, deviceStatusParking.data]);

    useEffect(() => {
        if (deviceStatusParking.data && deviceStatusParking.data.latest_pir_trigger) {
            const formattedParkingData = formatParkingData(dataJsonParking.data, deviceStatusParking.data.latest_pir_trigger);
            setAllPlacesParking(formattedParkingData);
        }
    }, [deviceStatusParking.data]);

    useEffect(() => {
        if (dataParking.data && dataParking.data.capteurs.length > 0 && parkingPlaces.current.length > 0 && allPlacesParking.length > 0) {
            updateSvgContent(dataParking.data.capteurs, allPlacesParking);
        }
    }, [dataParking.data, allPlacesParking]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setStatusReservation(null);
        }, 5000);

        return () => clearTimeout(timeout);
    }, [statusReservation]);

    const formatParkingData = (jsonData, capteursData) => {
        const updatedData = [...jsonData];
        const indexPlace20 = updatedData.findIndex(item => item.place_parking === 20);
        const indexPlace26 = updatedData.findIndex(item => item.place_parking === 26);

        if (indexPlace20 !== -1 && indexPlace26 !== -1) {
            updatedData[indexPlace20] = capteursData.find(item => item.place_parking === 20);
            updatedData[indexPlace26] = capteursData.find(item => item.place_parking === 26);
        }

        return updatedData;
    };

    const updateSvgContent = (parkingData, deviceStatusParking) => {
        parkingData.forEach((element, index) => {
            const elementParking = parkingPlaces.current[index];
            if (elementParking) {
                elementParking.id = `${element.place_parking}-path-parking`;
            }
        });

        deviceStatusParking.forEach((element, index) => {
            const elementParking = parkingPlaces.current[index];
            const elementPath = elementParking.querySelector('path');

            if (elementPath) {
                if (element.pir_trigger === 0) {
                    if (elementPath.classList.contains('path-unavailable') || elementPath.classList.contains('path-reserved')) {
                        elementPath.classList.replace('path-unavailable', 'path-available');
                        elementPath.classList.replace('path-reserved', 'path-available');
                    } else {
                        elementPath.classList.add('path-available');
                    }
                } else if (element.pir_trigger === 1) {
                    if (elementPath.classList.contains('path-available') || elementPath.classList.contains('path-reserved')) {
                        elementPath.classList.replace('path-available', 'path-unavailable');
                        elementPath.classList.replace(`path-reserved`, `path-unavailable`);
                    } else {
                        elementPath.classList.add('path-unavailable');
                    }
                } else if (element.pir_trigger === 2) {
                    if (elementPath.classList.contains('path-available')) {
                        elementPath.classList.replace(`path-available`, `path-reserved`);
                    } else {
                        elementPath.classList.add('path-reserved');
                    }
                } else {
                    elementPath.classList.add(`path-available`);
                }
            }

            if (elementParking) {
                elementParking.addEventListener('mousemove', (event) => handleMouseMove(event, element));
                elementParking.addEventListener('mouseleave', handleMouseLeave);
                elementParking.addEventListener('click', () => handleClickPlaceParking(element));
            }
        });
    }

    const handleMouseMove = (event, data) => {
        setDataHoverInterface({ data });

        var yMousePos = event.offsetY + 170;
        var xMousePos = event.clientX - 89;

        const tooltipPosition = {
            top: yMousePos,
            left: xMousePos
        };

        setTooltipPosition(tooltipPosition);
        setIsTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        setDataHoverInterface({ data: null });
        setIsTooltipVisible(false);
    };

    const handleClickPlaceParking = (element) => {
        if (element.pir_trigger === 0 || element.pir_trigger === 2) {
            setPlaceInfo(element);
            setPopupState(true);
        } else {
            setPopupState(false);
        }
    }

    const handleCloseReserved = () => {
        setPopupState(false);
        setPlaceInfo(null);
    }

    const handlePlaqueChange = (event) => {
        setReservePlaque(event.target.value);
    };

    const stateReserved = () => {
        let found = false;
        deviceStatusParking.data.latest_pir_trigger.map((element, index) => {
            if (element.pir_trigger === 2) {
                found = true;
            }
            setStateReservedPlace(found);
        })
    }

    const handleFilterChange = (filterName) => {
        if (filterName === 'available') {
            setFilterAvailable(!filterAvailable);
        } else if (filterName === 'electric') {
            setFilterElectric(!filterElectric);
        }
    };

    const filteredPlaces = allPlacesParking.filter(place => {
        if (filterAvailable && place.pir_trigger !== 0) {
            return false;
        }
        if (filterElectric && !place.electric) {
            return false;
        }
        return true;
    });

    if (parkingPlaces === undefined && deviceStatusParking === undefined || dataParking.data !== undefined && dataParking.data.capteurs.length === 0) {
        return <article className="page">
            <h1 className="page-title"> {t('Pas de données disponibles')}</h1>
        </article>
    }

    return (
        <>
            {!dataParking.isLoading && !deviceStatusParking.isLoading && dataParking.data.capteurs.length > 0 ? (
                <div className="parking-container">
                    <StatusMessage statusReservation={statusReservation} placeInfo={placeInfo} />
                    <h1>Parking</h1>
                    <div className="parking-leftinfos">
                        <h2 className="card-title">{t(`Légende`)} :</h2>
                        <div className="legend-status">
                            <div><span className="dispo-legend-status-parking"></span><p>Disponible</p></div>
                            <div><span className="indispo-legend-status-parking"></span><p>Indisponible</p></div>
                            <div><span className="reserve-legend-status-parking"></span><p>Réserver</p></div>
                            <div><p className="ve-legend-status-parking">VE</p><p>Véhicule électrique</p></div>
                        </div>
                        <div className="filters">
                            <label>
                                <input type="checkbox" checked={filterAvailable} onChange={() => handleFilterChange('available')} />
                                Places disponibles
                            </label>
                            <label>
                                <input type="checkbox" checked={filterElectric} onChange={() => handleFilterChange('electric')} />
                                Places électriques
                            </label>
                        </div>
                    </div>
                    <div id="parking">
                        <ParkingSvg parkingData={dataHoverInterface} tooltipPosition={tooltipPosition} isTooltipVisible={isTooltipVisible} />
                    </div>
                    {popupState && (
                        <ReservationPopup
                            placeInfo={placeInfo}
                            handleReserved={handleReserved}
                            handleCloseReserved={handleCloseReserved}
                            handlePlaqueChange={handlePlaqueChange}
                            setReserveDate={setReserveDate}
                            today={today}
                        />
                    )}
                    {deviceStatusParking.data.latest_pir_trigger && deviceStatusParking.data.latest_pir_trigger.length > 0 && stateReservedPlace &&
                        <ReservationTable deviceStatusParking={deviceStatusParking} />
                    }
                </div>
            ) : (
                <Loader />
            )}
        </>
    )
}
