import { useEffect, useState } from "react";
import ChartData from "../../../components/ChartData";
import { exportHoursDate } from "../../../components/utils";
import { useTranslation } from "react-i18next";

export default function ChartInterfaces({ datasInterface, measurementUnit, titleChartInterface }) {
    const [dataForChart, setDataForChart] = useState(null);
    const [average, setAverage] = useState({ averageTotal: 0, averageTransmit: 0, averageReceive: 0 });
    const [allLabels, setAllLabels] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (datasInterface !== undefined) {
            getUtilizationInterfaceGraph();
        }
    }, [datasInterface]);

    const getUtilizationInterfaceGraph = () => {
        let data = null;

        data = datasInterface.filter(item => { return item });

        handleDataForChart(data);
    }

    const handleDataForChart = (data) => {
        const groupedData = {};
        const allLabels = new Set();
        const totals = { Total: 0, Transmit: 0, Receive: 0 };
    
        const dataFormatted = data.map(item => ({ ...item }));
    
        dataFormatted.forEach(item => {
            const filterByTime = exportHoursDate(item.RecordedAt);
            
            ['Total', 'Transmit', 'Receive'].forEach(key => {
                if (!groupedData[filterByTime]) groupedData[filterByTime] = {};

                groupedData[filterByTime][key] = (groupedData[filterByTime][key] || 0) + Number(item[key]);
                                
                if (!allLabels.has(key)) allLabels.add(key);

                totals[key] += Number(item[key]);
            });
        });
        setAllLabels(Array.from(allLabels));
        setDataForChart(groupedData);
        setAverage({
            averageTotal: totals.Total / dataFormatted.length || 0,
            averageTransmit: totals.Transmit / dataFormatted.length || 0,
            averageReceive: totals.Receive / dataFormatted.length || 0,
        });
    };

    return (
        <>
            {dataForChart ?
                <div className="auvik-contents">
                    <ChartData
                        dataForChart={dataForChart}
                        allLabels={allLabels}
                        isStacked={false}
                        titleChart={t(titleChartInterface)}
                        typeOfChart={"line"}
                        measurementUnit={measurementUnit}
                        maxValueLeft={100}
                        minValueLeft={0}
                        tension={0.1}
                        radiusGraph={1}
                        setNumberTicksX={7}
                        setNumberTicksY={6}
                        setNumberRotate={0}
                    />
                    <div className="average-container">
                        <div className="average average1">
                            <div>
                                <span>{t("Total")} ({(measurementUnit)})</span>
                            </div>
                            <h2>{Math.round(average.averageTotal)}{(measurementUnit)}</h2>
                        </div>
                        <div className="average average2">
                            <div>
                                <span>{t("Transmit")} ({(measurementUnit)})</span>
                            </div>
                            <h2>{Math.round(average.averageTransmit)}{(measurementUnit)}</h2>
                        </div>
                        <div className="average average3">
                            <div>
                                <span>{t("Receive")} ({(measurementUnit)})</span>
                            </div>
                            <h2>{Math.round(average.averageReceive)}{(measurementUnit)}</h2>
                        </div>
                    </div>
                </div>
                : <h2 className="page-title">{t("Aucune donnée")}</h2>
            }
        </>
    );
}