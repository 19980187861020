import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { convertTimestamp } from "../../components/utils";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

export default function RowMerakiIot({item}) {
  const { apiUrl } = useContext(AuthContext);
  const [cameraOverview, setCameraOverview] = useState([]);
  const [cameraLive, setCameraLive] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const {t} = useTranslation();

  const getCameraOverwiew = async () => {
    axios
      .get(`${apiUrl}meraki/camera/overview/${item.serial}`, {
        withCredentials: true,
      })
      .then((res) => {
        setCameraOverview(res.data);
      })
      .catch((err) => {
      });
  };

  const getCameraLive = async () => {
    axios
      .get(`${apiUrl}meraki/camera/live/${item.serial}`, {
        withCredentials: true,
      })
      .then((res) => {
        setCameraLive(res.data);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    getCameraOverwiew();
    getCameraLive();
    setIsLoading(false);
  }, []);

  return (
    <>
      {!isLoading ? (
        <div className="webexIot-container merakiIot">
          {cameraOverview.map((camera, index) => {
            return (
              <div className="webexIot-item merakiIot-item" key={index}>
                <p className="webexIot-content">
                  <span className="webexIot-title">
                    Zone Id
                  </span>
                  <span className="webexIot-data">
                    {camera.zoneId}
                  </span>
                </p>
                {cameraLive.zones && cameraLive.zones[camera.zoneId] ? (
                  <p className="webexIot-content">
                    <span className="webexIot-title">
                      Live
                    </span>
                    <span className="webexIot-data">
                      {cameraLive.zones[camera.zoneId].person}
                    </span>
                  </p>
                ) : (
                  <p className="webexIot-content">
                    <span className="webexIot-title">
                      Live
                    </span>
                    <span className="webexIot-data">
                      0
                    </span>
                  </p>
                )}
                <p className="webexIot-content">
                  <span className="webexIot-title">
                    {t('Entrées détectées')}
                  </span>
                  <span className="webexIot-data">
                    {camera.entrances}
                  </span>
                </p>
                <p className="webexIot-content">
                  <span className="webexIot-title">
                    {t('Début')}
                  </span>
                  <span className="webexIot-data">
                    {convertTimestamp(camera.startTs)}
                  </span>
                </p>
                <p className="webexIot-content">
                  <span className="webexIot-title">
                    {t('Fin')}
                  </span>
                  <span className="webexIot-data">
                    {convertTimestamp(camera.endTs)}
                  </span>
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}
