import { useContext } from "react";
import { useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useForm } from "react-hook-form";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

/**
 * 
 * @param {useQuery object} files - to refetch files
 * @param {string} path - path of the file
 */
export default function ImportFile({ path, files }) {
  const { apiUrl } = useContext(AuthContext);

  const [fileName, setFileName] = useState(null);
  const [importError, setImportError] = useState(null);
  const {t} = useTranslation();
  
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const importFile = () => {
    const input = document.querySelector("input[type='file']");
    const file = input.files[0];
    const formData = new FormData();
    file !== null && formData.append("file", file);

    axios(`${apiUrl}files/upload${path.length > 0 ? "/" + path : ""}`, {
      method: "post",
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res) => {
        files.refetch();
        setImportError(null);
        setFileName(null);
      })
      .catch((err) => {
        setImportError(err.response.data);
      });
  };

  return (
    <>
      <form className="file-files_form" onSubmit={handleSubmit(importFile)}>
        <div className="form-input_container form-file">
          <input
            type="file"
            id="file"
            name="file"
            accept=".pdf, text/plain, image/*"
            className="input-file"
            onChange={(e) => setFileName(e.target.files[0])}
          />
          <label htmlFor="file" className="button button-new label-file">
            {" "}
            {t("Importer un fichier")}
            {/* <FontAwesomeIcon icon={faUpload} /> */}
          </label>
          {fileName && <span className="file-name">{fileName.name}</span>}
        </div>
        {fileName && (
          <div className="file-files_button">
            <button className="button button-new" type="submit">
              {" "}
              Importer
            </button>
            <button
              className="button button-delete"
              type="button"
              onClick={() => setFileName(null)}
            >
              {" "}
              Annuler
            </button>
          </div>
        )}
        {importError && <small className="error">{importError}</small>}
      </form>
    </>
  );
}
