import { useContext } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../../context/AuthContext"
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loader";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";


export default function MerakiNetworkCard({network}) {

    // // import context
    // const {apiUrl} = useContext(AuthContext);
    
    // // get network's alerts
    // const getNetworkAlerts = async () => {
    //     const res = await axios.get(`${apiUrl}meraki/health/${network.id}/alerts`, {withCredentials: true})

    //     if(res.data.errors) {
    //         return res.data.errors
    //     } else {
    //         return res.data;
    //     }
    // }

    // const networkAlerts = useQuery({
    //     queryKey: ["networkAlerts", network.id],
    //     queryFn: getNetworkAlerts
    // })
    
    return (
        <>
        <div className="network-container">
        <Link to={`/meraki/${network.id}`}>
            <h2>
                {network.name}
            </h2>
            <p>{network.timeZone}</p>
        </Link>
        </div>
        </>
    )
}