import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import RefreshParcTable from "./RefreshParcTable";
import xlsx from 'node-xlsx';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";

export default function RefreshParc({ tenantId, refreshStatus, setRefreshStatus }) {
    const { apiUrl, user } = useContext(AuthContext);
    const [createLoadingDevices, setCreateLoadingDevices] = useState(false);
    const [createSuccessDevices, setCreateSuccessDevices] = useState(false);
    const [createErrorDevices, setCreateErrorDevices] = useState(false);
    const [completionPercentage, setCompletionPercentage] = useState(0);
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const getRefreshParc = async () => {
        try {
            const res = await axios.get(`${apiUrl}auvik/refreshParc/devices/${tenantId}`, {
                withCredentials: true,
            });
            return res.data;
        } catch (error) {
        }
    };

    const refreshParc = useQuery({
        queryKey: ["refreshParc", tenantId],
        queryFn: () => getRefreshParc(),
        enabled: false,
        refetchOnWindowFocus: false
    });

    const devicesNotInECP = refreshParc.data ? refreshParc.data["Devices not in ECP"] : [];
    const devicesNotInAuvik = refreshParc.data ? refreshParc.data["Devices not in Auvik"] : [];

    const getCreateNewDevices = async (devicesData) => {
        const updatedData = {
            holding: user.holding,
            entreprise: user.entreprise,
            is_active: 1,
            sn_valid: 1,
            site: devicesData.site_id,
            serial_number: String(devicesData.serial_number),
            source: "AUVIK"
        };

        try {
            const res = await axios.post(`${apiUrl}equipement/new`, updatedData, {
                withCredentials: true,
            });
            return res.data;
        } catch (error) {
        }
    };

    const createNewDevicesMutation = useMutation({
        mutationFn: getCreateNewDevices,
        enabled: false,
        refetchOnWindowFocus: false,
        onMutate: async () => {
            setCreateLoadingDevices(true);
        },
        onError: () => {
            setCreateLoadingDevices(false);
            setCreateErrorDevices(true);
        },
    });

    useEffect(() => {
        if (refreshStatus === true) {
            queryClient.invalidateQueries(["refreshParc", tenantId]);
            refreshParc.refetch();
        }
    }, [tenantId, refreshParc.data, refreshStatus]);

    const handleRefresh = () => {
        setRefreshStatus(false);
        setCreateSuccessDevices(false);
        setCreateErrorDevices(false);
        setCompletionPercentage(0);
    };

    const handleConfirmRefresh = async () => {
        if (devicesNotInECP.length > 0 && createErrorDevices === false) {
            const devicesData = devicesNotInECP.map((device) => ({
                site_id: device.site_id,
                serial_number: device.serialNumber,
            }));

            const totalRequests = devicesData.length;
            let successfulRequests = 0;

            const results = await Promise.allSettled(devicesData.map(async (data) => {
                try {
                    await createNewDevicesMutation.mutateAsync(data);
                    successfulRequests++;
                    const completionPercentage = (successfulRequests / totalRequests) * 100;
                    setCompletionPercentage(completionPercentage);
                } catch (error) {
                    setCreateLoadingDevices(false);
                    setCreateErrorDevices(true);
                }
            }));

            if (createErrorDevices === false) {
                const allSuccessful = results.every(result => result.status === 'fulfilled');

                if (allSuccessful && createErrorDevices === false) {
                    setCreateLoadingDevices(false);
                    setCreateSuccessDevices(true);
                }
            }
        }
    }

    const handleConfirmImport = async () => {
        if (user.roles.includes('ROLE_SUPERADMIN')) {
            const blobECP = generateExcelData(devicesNotInECP, "Devices Not in ECP");
            const blobAuvik = generateExcelData(devicesNotInAuvik, "Devices Not in Auvik");
    
            // Télécharger le fichier Excel
            saveAs(blobECP, "devices_not_in_ecp.xlsx");
            saveAs(blobAuvik, "data_not_in_auvik.xlsx");
        }
    }

    const generateExcelData = (devices, fileName) => {
        const data = devices.map(device => {
            const { source, ...data } = device;
            return data;
        });
    
        const columnNames = Object.keys(data[0]);
        const excelData = [
            columnNames, // Ajouter les noms de colonnes en première ligne
            ...data.map(device => {
                const rowData = columnNames.map(col => {
                    // Récupérer la valeur de la colonne ou la date si c'est une clé correspondante
                    const dateKeys = ["end_of_support", "date_end_covered", "product_eox"];
                    return dateKeys.includes(col) && device[col] ? device[col].date : device[col] || "";
                });
                return rowData;
            })
        ];
    
        // Créer le contenu du fichier Excel
        const excelBuffer = xlsx.build([{ name: fileName, data: excelData }]);
        // Convertir le contenu en Blob
        return new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    };

    const dataFormatted = (data) => {
        if (data.length > 0) {
            const columns = Object.keys(data[0]);
            const formattedData = data.map((item) => {
                const formattedItem = {};
                columns.forEach((key) => {
                    formattedItem[key] =
                        item[key] !== null && typeof item[key] === 'object' && item[key].hasOwnProperty('date')
                            ? new Date(item[key].date).toLocaleDateString()
                            : item[key];
                });
                return formattedItem;
            });
            return { columns, dataFormatted: formattedData };
        }
        return { columns: [], dataFormatted: [] };
    };

    return (
        <>
            <div className={`popupRefreshParc dialog-form ${refreshStatus ? 'dialog-form_open' : ''}`}>
                <button onClick={handleRefresh} className="button button-delete dialog-form_close">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" className="svg-inline--fa fa-xmark " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"></path>
                    </svg>
                </button>
                {!refreshParc.isLoading && createLoadingDevices === false && !refreshParc.isFetching ? (
                    <>
                        {createErrorDevices !== true ? (
                            createSuccessDevices !== true ? (
                                refreshParc.data !== 'No new devices found !' && refreshParc.data !== undefined ? (
                                    <section>
                                        <div id="table-1">
                                            {devicesNotInECP.length > 0 ?
                                                <RefreshParcTable columns={dataFormatted(devicesNotInECP).columns} data={dataFormatted(devicesNotInECP).dataFormatted} title={t("Equipements inconnu par l'ECP")} itemsPerPage={10} />
                                                : null}
                                        </div>
                                        <div id="table-2">
                                            {devicesNotInAuvik.length > 0 ?
                                                <RefreshParcTable columns={dataFormatted(devicesNotInAuvik).columns} data={dataFormatted(devicesNotInAuvik).dataFormatted} title={t("Equipements inconnu par Auvik")} itemsPerPage={10} />
                                                : null}
                                        </div>
                                        <div id="container-buttons-refresh-import">
                                            {devicesNotInECP.length > 0 &&
                                                <button onClick={handleConfirmRefresh} className="button button-new button-confirm-refresh">{t("Importer")}</button>
                                            }
                                            {devicesNotInAuvik.length > 0 && user.roles.includes('ROLE_SUPERADMIN') &&
                                                <button onClick={handleConfirmImport} className="button button-new button-confirm-import">{t("Exporter")}</button>
                                            }
                                        </div>

                                    </section>
                                ) : (
                                    <div className="devices">
                                        <div>
                                            <h1>{t("Aucun nouveau dispositif n'a été trouvé")} !</h1>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className="devices">
                                    <div>
                                        <FontAwesomeIcon
                                            icon={faCircleCheck}
                                            className="dialog-form_success-icon"
                                        />
                                        <h1>{t("Données importées avec succès")}</h1>
                                    </div>
                                </div>
                            )
                        ) : (
                            <div className="devices">
                                <div>
                                    <h1>{t("Un problème est survenu lors de l'ajout des données")}</h1>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className="contentLoader"><Loader /></div>
                    </>
                )}
                {refreshParc.data !== 'No new devices found !' && devicesNotInECP.length > 0 &&
                    <div className={`refreshParc-progress-bar ${!refreshStatus ? 'refreshParc-progress-bar-none' : ''}`}>
                        <div className={`${createSuccessDevices === true ? 'refreshParc-progress-bar-success' : ''} ${createErrorDevices === true ? 'refreshParc-progress-bar-error' : ''} refreshParc-progress-bar-content`} style={{ width: `${completionPercentage}%` }}>{Math.round(completionPercentage.toFixed(2))}%</div>
                    </div>
                }
            </div>
        </>
    );
}