import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { AuthContext } from "../../context/AuthContext";
import TableComponent from "../../components/TableComponent";
import { ArrowSphereClientColumn } from "../../components/headerContent";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";

export default function ArrowSphereClient() {
    const { apiUrl } = useContext(AuthContext);
    const { t } = useTranslation();

    const getArrowSphereClient = async () => {
        try {
            const res = await axios.get(`${apiUrl}microsoft/arrow/licenses/client`, {
                withCredentials: true,
            });
            return res.data;
        } catch (error) {
        }
    };

    const arrowSphereClient = useQuery({
        queryKey: ["arrowSphereClient"],
        queryFn: getArrowSphereClient,
    });

    if (arrowSphereClient.data && arrowSphereClient.data.message === "Ce client ne possède pas d'id Arrow") {
        return <article className="page">
            <h1 className="page-title"> Pas de données ArrowSphere disponibles</h1>
        </article>
    }

    return (
        <>
            {!arrowSphereClient.isLoading ? (
                <>
                    {!arrowSphereClient.data.message && arrowSphereClient.data.length > 0 ? (
                        <TableComponent
                            tableData={arrowSphereClient.data}
                            tableColumns={ArrowSphereClientColumn}
                            checkbox={false}
                        />
                    ) : arrowSphereClient.data.message || arrowSphereClient.data.length === 0 ? (
                        <article className="page">
                            <h1 className="page-title">Pas de données ArrowSphere disponibles</h1>
                        </article>
                    ) : (
                        <Loader />
                    )}
                </>
            ) : (
                <Loader />
            )}
        </>
    );
}