import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import TableComponent from "../../components/TableComponent";
import NumberCard from "../../components/Card/NumberCard";
import Globalview from "../../components/Globalview";
import RowAuvik from "./RowAuvik";
import { Auvikcolumns } from "../../components/headerContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";

export default function AllDevices({ tenantId }) {
    const { apiUrl } = useContext(AuthContext);
    const [devicesByType, setDevicesByType] = useState([]);
    const [resetValue, setResetValue] = useState(false);
    const [valueType, setValueType] = useState("all");
    const typeCounts = { name: "Type" };
    const { t } = useTranslation();

    const getDevices = async () => {
        try {
            const res = await axios.get(`${apiUrl}auvik/devices/${tenantId}`, {
                withCredentials: true,
            });
            return res.data;
        } catch (error) {
        }
    };

    const devices = useQuery({
        queryKey: ["devices"],
        queryFn: getDevices,
    });

    if (devices.isFetched && devices.data.errors) {
        return null;
    }

    const devicesFilterByType = () => {
        const filterByType = devices.data.filter(item => {
            return (
                item.deviceType === valueType || 
                (valueType === "switch" && ["switch", "stack", "l3Switch", "controller"].includes(item.deviceType)) // Si le filtre est "switch", inclure également ces types d'équipements
            );
        });
        setDevicesByType(filterByType);
        return filterByType;
    }

    useEffect(() => {
        if (valueType !== "all") {
            devicesFilterByType();
        }
    }, [valueType])

    return (
        <>
            {!devices.isFetching ?
                <>
                    <div className="page-title page-link-auvik-tenant">
                        <Link to={"/auvik"} className="button button-new"><FontAwesomeIcon icon={faChevronLeft}/> {t('Retour')}</Link>
                        <Link to={`/auvik/${tenantId}/alerts`} className="button button-new">{t('Alert history')} <FontAwesomeIcon icon={faChevronRight}/></Link>
                    </div>
                    <section className="auvik-alldevices">
                        <h2 className="auvik-alldevices-title">Interfaces</h2>
                        <section className="page-section">
                            <NumberCard number={devices.data.length} title="Nombre d'équipements" />
                            {devices.data.length > 0 && <Globalview data={devices.data} setValueType={setValueType} countObject={typeCounts} objectKey="deviceType" click resetValue={resetValue} setResetValue={setResetValue} />}
                            {valueType !== "all" && <button className="button button-new page-reset" onClick={() => { setValueType("all"), setResetValue(true); setDevicesByType([]) }}> {t('Réinitialiser')}</button>}
                        </section>
                        <div className="table-interfaces-leftbtn">
                            <h2 className="card-title">{t(`Légende des statuts d'interface`)} :</h2>
                            <div className="legend-status">
                                <div><span className="online-legend-status"></span><p>Online</p></div>
                                <div><span className="offline-legend-status"></span><p>Offline</p></div>
                                <div><span className="dormant-legend-status"></span><p>{t(`Autres`)}</p></div>
                                <div><FontAwesomeIcon icon={faCircleExclamation} color="rgb(255, 187, 0)"/><p>{t(`Afficher les alertes d'un équipement`)}</p></div>
                            </div>
                        </div>
                        {devices.data.length > 0 && <TableComponent tableData={devicesByType.length > 0 ? devicesByType : devices.data} tableColumns={Auvikcolumns} checkbox={false} rowComponent={RowAuvik} />}
                    </section>
                </>
                : <Loader />}
        </>
    );
}