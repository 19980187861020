import React, { useContext, useEffect, useState, useRef } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import TableComponent from "../../components/TableComponent";
import RowTeamAgentInsight from "./RowTeamAgentInsight.jsx";
import ChartTeamAgentInsight from "./ChartTeamAgentInsight.jsx";
import { AgentInsightcolumns, TicketsInsightcolumns } from "../../components/headerContent";
import AgentInsightHistory from './AgentInsightHistory.jsx'
import { useLocation, Link } from "react-router-dom";
import NumberCard from "../../components/Card/NumberCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

export default function AgentInsight() {
    const { apiUrl, user } = useContext(AuthContext);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const agentId = searchParams.get("agentId");
    const [teamArray, setTeamArray] = useState([]);
    const [ticketsClicked, setTicketsClicked] = useState([]);
    const [ticketsByAgents, setTicketsByAgents] = useState({});
    const [ticketsFiltered, setTicketsFiltered] = useState([]);
    const [nameAgent, setNameAgent] = useState();
    const { t } = useTranslation();

    const getHaloTeams = async () => {
        const res = await axios.get(`${apiUrl}halo`, {
            withCredentials: true
        });
        return res.data;
    };

    const haloTeams = useQuery({
        queryKey: ["haloTeams"],
        queryFn: getHaloTeams
    });

    useEffect(() => {
        if (agentId && haloTeams.data) {
            const updatedTeams = haloTeams.data
                .filter(team =>
                    team.agents.some(agent => parseInt(agent.agent_id, 10) === parseInt(agentId, 10))
                )
                .map(team => team.name);

            setTeamArray(updatedTeams);
        }
    }, [agentId, haloTeams.data]);

    useEffect(() => {
        if(ticketsByAgents.length > 0 && ticketsClicked) {
            const filteredTickets = ticketsByAgents
            .filter(agent => agent.day === ticketsClicked.day) // Trouver le jour
            .flatMap(agent => agent.tickets) // Extraire les tickets pour ce jour
            .filter(ticket => ticket.team === ticketsClicked.groupements); // Filtrer par team
            setTicketsFiltered(filteredTickets);
        }
    }, [ticketsByAgents, ticketsClicked]);

    if(!haloTeams.isLoading && haloTeams.data === undefined || !haloTeams.isLoading &&  haloTeams.data.length === 0) {
        return(
            <h1>Aucune données disponible</h1>
        )
    }

    return (
        <section className="agent-insight">
            {haloTeams.data ? (
                <>
                    <h1 className="page-title agent-insight-title">{ ticketsClicked.day || ticketsClicked.groupements ? "Statistiques des tickets de l'agent" : agentId && teamArray ? "Statistiques de l'agent" : 'Statistiques des agents'} <span>{agentId && teamArray && nameAgent}</span></h1>
                    {ticketsFiltered.length > 0 && (
                        <NumberCard
                            title="Tickets"
                            number={ticketsFiltered ? ticketsFiltered.length : null}
                        />
                    )}
                    {agentId && teamArray ? (
                        <>
                            {ticketsClicked.day || ticketsClicked.groupements ? (
                                <>
                                    <div>
                                        <a onClick={() => setTicketsClicked({})} className="button button-new"><FontAwesomeIcon icon={faChevronLeft} /> {t('Retour')}</a>
                                    </div>
                                    <div>
                                        {ticketsFiltered && <TableComponent tableData={ticketsFiltered} tableColumns={TicketsInsightcolumns}/>}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div>
                                        <Link onClick={() => setNameAgent('')} to={`/agentinsight`} className="button button-new"><FontAwesomeIcon icon={faChevronLeft} /> {t('Retour')}</Link>
                                    </div>
                                    <div>
                                        <AgentInsightHistory data={haloTeams.data} agentId={agentId} teamArray={teamArray} setTicketsByAgents={setTicketsByAgents} setTicketsClicked={setTicketsClicked} setNameAgent={setNameAgent}/>
                                    </div>
                                </>
                            )}

                        </>
                    ) : (
                        <>
                            <div>
                                <ChartTeamAgentInsight data={haloTeams.data} />
                            </div>
                            <div>
                                {haloTeams.data && <TableComponent tableData={haloTeams.data} tableColumns={AgentInsightcolumns} rowComponent={RowTeamAgentInsight} />}
                            </div>
                        </>

                    )}
                </>
            ) : (
                <Loader />
            )}
        </section>
    )
}