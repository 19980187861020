import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js/auto";
import { useEffect, useRef, useState } from "react";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";

/**
 * 
 * @param {boolean} horizontal - bar chart horizontal if true 
 * @param {object} dataForChart
 * @param {array} allLabels - labels in axis
 * @param {boolean} isStacked
 * @param {string} titleChart
 * @param {function} handleGraphClick - function to handle when user click on chart bar
 * @param {boolean} reporting - to handle when user is in reporting page
 * @param {string} typeOfChart - change chart type
 * @param {boolean} measurementUnit  - unit of measurement
 * @param {number} maxValueLeft - number max of left chart elements
 * @param {number} minValueLeft - number min of left chart elements
 * @param {number} tension - number of tension ticks
 * @param {array} measurementUnitBandwidth - if comparaison unit bandwidth
 * @param {number} radiusGraph - number of radius point graph
 * @param {number} setNumberTicksX - number of ticks bottom graph
 * @param {number} setNumberTicksY - number of ticks left graph
 * @param {number} setNumberRotate - number of rotate items bottom graph
 * @returns 
 */
export default function ChartData({
  horizontal,
  dataForChart,
  allLabels,
  isStacked,
  titleChart,
  handleGraphClick,
  reporting,
  typeOfChart,
  measurementUnit,
  measurementUnitBandwidth,
  maxValueLeft,
  minValueLeft,
  tension,
  radiusGraph,
  setNumberTicksX,
  setNumberTicksY,
  setNumberMaxTicksLimitY,
  setNumberRotate,
  maxHeight,
  addUnit,
  automaticColors,
  interactionStatus
}) {

  // Chart setting
  const chartRef = useRef();
  Chart.register(
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    TimeScale
  );

  const { t } = useTranslation();
  const [chartData, setChartData] = useState({});
  const [options, setOptions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [autoColors, setAutoColors] = useState(true);

  const colors = {
    Incident: "rgba(255, 149, 5, 0.8)",
    "Changement mineur": "rgba(18,65,136, 0.7)",
    "Changement majeur": "rgba(102, 102, 102, 1)",
    "Incident hors-contrat": "#FF0800",
    Question: "#E8EBED",
    "Service Request": "#8977B0",
    "Alerte": "#FF5D57",
    Autre: "#9f5927",
    "Basse S4": "#E8EBED",
    "Critique S1": "rgba(255, 96, 84, 1) ",
    "Haute S2": "rgba(255, 188, 44, 1)",
    "Moyenne S3": "rgba(255, 231, 93, 1)",
    "SLA RESPECTE": "rgba(174, 195, 83, 1)",
    "SLA DEPASSE": "rgba(153, 122, 219, 1)",
    YES: "rgba(144, 227, 154, 1)",
    NO: "rgba(255,0,0,0.5)",
    "Heures ouvrées Elit": "rgba(18,65,136, 0.7)",
    "Heures ouvrées totales": "rgba(174,195,83)",
    "Bandwidth": "#997adb",
    "Transmit": "#AEC353",
    "Receive": "#124188",
    "Total": "#997adb",
    Probleme: "rgba(246, 227, 19, 0.8)",
    "CpuUtilization": "#997adb",
    "MemoryUtilization": "#AEC353",
    "StorageUtilization": "#124188",
    "Unicast": "#997adb",
    "Multicast": "#AEC353",
    "Broadcast": "#124188"
  };

  const fixedColors = [
    "#997adb",
    "#AEC353",
    "#939599",
    "#124188",
    "#C9AE9D",
    "#BD4735",
    "#EDDC47"
  ];

  const generateRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgba(${r}, ${g}, ${b}, 0.8)`; // couleur avec opacité 0.8
  };
  

  const getColorForLabel = (index) => {
    if (automaticColors && index >= fixedColors.length) {
      return generateRandomColor(); // Génère une couleur aléatoire si l'indice dépasse la limite
    }
    return fixedColors[index]; // Utilise la couleur fixe si l'indice est inférieur à 5
  };

  const textInSegment = {
    id: 'textInSegment',
    afterDatasetsDraw(chart) {
      const { ctx, data } = chart;
      ctx.save();

      data.datasets[0].data.forEach((value, index) => {
        const meta = chart.getDatasetMeta(0).data[index];
        const x = meta.tooltipPosition().x;
        const y = meta.tooltipPosition().y;

        ctx.fillStyle = '#ffff';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = 'bold 14px sans-serif';
        ctx.fillText(value, x, y);
      });

      ctx.restore();
    }
  };

  const createChart = () => {

    let ticksLimit;

    if (setNumberMaxTicksLimitY) {
      const sumsByDate = Object.entries(dataForChart)
        .filter(([key, value]) => key !== "" && value !== null && Object.keys(value).length > 0)
        .reduce((acc, [date, values]) => {
          const sum = Object.values(values).reduce((total, value) => {
            if (value !== undefined) {
              return total + value;
            }
            return total;
          }, 0);
          acc[date] = sum;
          return acc;
        }, {});

      const Ticks = Object.values(sumsByDate).reduce((max, sum) => Math.max(max, sum), 0);

      const maxTicksToShow = 10;

      ticksLimit = Math.min(Ticks <= 1 ? Ticks + 1 : Ticks, maxTicksToShow);

      if (Ticks > maxTicksToShow) {
        ticksLimit = undefined;
      }
    }

    const maxTicksLimit = setNumberMaxTicksLimitY ? ticksLimit : undefined;

    if (typeOfChart === "doughnut") {
      const labels = allLabels;
      const colorsLabels = Object.values(colors);
      const backgroundColor = labels.map((_, index) => colorsLabels[index % colorsLabels.length]);

      const datasets = [
        {
          label: t('Nombre de tickets'),
          data: labels.map(label => dataForChart[label] || 0),
          backgroundColor: backgroundColor,
          borderColor: '#fff',
          borderWidth: 2
        }
      ];

      setChartData({ labels, datasets });

      setOptions({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            position: "top",
            text: t(titleChart),
            align: "center",
            padding: 20,
            color: "#00000",
            font: {
              family: "Inter",
              size: "15",
            },
          },
          legend: {
            labels: {
              padding: 15,
              color: "#000",
              font: {
                family: "Inter",
                size: 11,
                weight: 700
              },
              usePointStyle: true,
            },
          },
          textInSegment: {
            enabled: true,
          }
        },
      })
    } else {
      //set labels and datasets
      const labels = Object.keys(dataForChart)
        .filter((item) => item !== "")
        .sort(function (a, b) {
          return new Date(a) - new Date(b);
        });


      const datasets = allLabels.map((label, index) => {
        colors[label] !== undefined ? setAutoColors(false) : setAutoColors(true);

        return {
          label: t(label),
          data: labels.map((name) => dataForChart[name][label] || 0),
          borderRadius: 5,
          borderColor: automaticColors ? getColorForLabel(index) : colors[label],
          backgroundColor: automaticColors ? getColorForLabel(index) : colors[label],
        };
      });
      // Stock data charts
      setChartData({ labels, datasets });
      // Set options in datachart
      setOptions({
        responsive: true,
        indexAxis: horizontal ? "y" : "x",
        maintainAspectRatio: false,
        tension: tension ? tension : null,
        plugins: {
          colors: {
            forceOverride: automaticColors ? false : autoColors,
          },
          title: {
            display: true,
            position: "top",
            text: t(titleChart),
            align: "center",
            padding: 20,
            color: "#00000",
            font: {
              family: "Inter",
              size: "15",
            },
          },
          legend: {
            labels: {
              padding: 10,
              color: "#00000",
              font: {
                family: "Inter",
                size: 11,
                weight: 700
              },
              usePointStyle: true,
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                const value = context.raw; // Obtient la valeur brute
                let formattedValue = '';

                if (value >= 60) {
                  // Conversion en heures et minutes
                  const hours = Math.floor(value / 60);
                  const minutes = value % 60;

                  if (minutes > 0) {
                    formattedValue = `${hours}h${minutes}min`;
                  } else {
                    formattedValue = `${hours}h`;
                  }
                } else {
                  // Garde les minutes si inférieur à 60
                  formattedValue = `${value}min`;
                }

                // Ajout de l'unité uniquement si `addUnit` est vrai
                return addUnit ? `${label}${formattedValue}` : `${label}${value}`;
              }
            }
          }
        },
        interaction: interactionStatus && {
          mode: 'index',
          intersect: false
        },
        scales: {
          x: {
            stacked: !!isStacked,
            ticks: {
              color: "#00000",
              font: {
                size: 10,
                family: "Inter",
                weight: 800
              },
              maxRotation: setNumberRotate ? setNumberRotate : undefined,
              maxTicksLimit: setNumberTicksX ? setNumberTicksX : undefined,
            },
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
            stacked: !!isStacked,
            max: maxValueLeft ? maxValueLeft : undefined,
            min: minValueLeft === Number() ? minValueLeft : undefined,
            ticks: {
              color: "#00000",
              font: {
                size: 10,
                family: "Inter",
                weight: 800
              },
              maxTicksLimit: setNumberTicksY ? setNumberTicksY : maxTicksLimit,
              callback: function (value) {
                if (measurementUnitBandwidth) {
                  const [useUnits, unitGbit, unitMbit, unitKbit] = measurementUnitBandwidth;
                  const valueDecimal = this.getLabelForValue(value).replace(/,/g, '');
                  const labelValue = parseFloat(this.getLabelForValue(value).replace(/\s/g, ''));
                  if (useUnits && unitGbit || unitMbit || unitKbit) {
                    return valueDecimal >= 1000000 ? labelValue + ' ' + unitGbit : (valueDecimal >= 1000 ? labelValue + ' ' + unitMbit : labelValue + ' ' + unitKbit);
                  } else {
                    return measurementUnit ? valueDecimal + ' ' + measurementUnit : this.getLabelForValue(value);
                  }
                } else {
                  const intValue = this.getLabelForValue(Math.trunc(value));
                  return measurementUnit ?
                    (value % 1 === 0 ? intValue + ' ' + measurementUnit : '') :
                    (value % 1 === 0 ? intValue : '');
                }
              },
            },
            grid: {
              color: "#00000",
              lineWidth: 0.2,
            },
          },
        },
        elements: {
          point: {
            radius: radiusGraph ? radiusGraph : undefined,
          },
        },
      });
    };
  }

  useEffect(() => {
    createChart();
    setIsLoading(false);
  }, [dataForChart, allLabels, autoColors]);

  return (
    <>
      {reporting ? (
        !isLoading ? (
          <div className="page-chart">
            <Bar
              data={chartData}
              width={30}
              height={400}
              options={options}
              ref={chartRef}
              onClick={(event) =>
                handleGraphClick
                  ? handleGraphClick(event, chartRef.current)
                  : undefined
              }
            />
          </div>
        ) : (
          <Loader />
        )
      ) : (
        <> {!isLoading ? <>
          <section className="page-chart">
            {typeOfChart === "bar" ? (
              <Bar
                data={chartData}
                width={100}
                height={600}
                options={options}
                ref={chartRef}
                onClick={(event) =>
                  handleGraphClick
                    ? handleGraphClick(event, chartRef.current)
                    : undefined
                }
              />
            ) : typeOfChart === "doughnut" ? (
              <Doughnut data={chartData}
                width={100}
                height={600}
                options={options}
                ref={chartRef}
                plugins={[textInSegment]}
                onClick={(event) =>
                  handleGraphClick
                    ? handleGraphClick(event, chartRef.current)
                    : undefined
                }
              />
            ) : (
              <Line
                data={chartData}
                width={100}
                height={maxHeight ? maxHeight : 600}
                options={options}
              />
            )}
          </section>
        </> : <Loader />}</>
      )}
    </>
  );
}