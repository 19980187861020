import AccordionItem from "../../../../../components/AccordionItem";
import { useTranslation } from "react-i18next";

export default function FiltersGraphsCallQueue({ data, setLinkPath }) {
    const handleNavigation = (path) => {
        setLinkPath(path);
    };

    const { t, i18n } = useTranslation();

    return (
        <div className="statistics-report-container">
            <h1>{t("Rapports par groupes")}</h1>
            <AccordionItem title={t("Analyse des appels par groupe")}>
                <ul className="accordion-item-container">
                    <li><a onClick={() => handleNavigation('Analyse par groupement')}>{t("Analyse par groupement")}</a> - <span>{t("Comment varient les appels par groupement ?")}</span></li>
                    <li><a onClick={() => handleNavigation('Analyse des postes au sein des groupements')}>{t("Analyse des postes au sein des groupements")}</a> - <span>{t("Comment varient les appels par postes dans les groupements ?")}</span></li>
                </ul>
            </AccordionItem>
        </div>
    )
}