import React, { useEffect, useState, useCallback } from "react";
import Loader from "../../../../../../components/Loader";
import { useTranslation } from "react-i18next";
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from "@mui/icons-material";
import CallQueueTable from "../CallQueueTable";
import ChartsWebex from "../../../Charts/ChartsWebex";

export default function TypeCallQueue({ data, allCalls, callQueue, setDataGraph, setFormattedHeader }) {
    const [groupSurvey, setGroupSurvey] = useState();
    const [transformedData, setTransformedData] = useState();
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [paginationPerGroup, setPaginationPerGroup] = useState({});
    const itemsPerPage = 15;
    const { t } = useTranslation();

    useEffect(() => {
        if (data) {
            handleGraphGroupSurvey(data);
            const resetPagination = {};
            Object.keys(data).forEach((groupIndex) => {
                resetPagination[groupIndex] = 1;
            });
            setPaginationPerGroup(prev => {
                if (JSON.stringify(prev) !== JSON.stringify(resetPagination)) {
                    return resetPagination;
                }
                return prev;
            });
        }
    }, [data]);
    
    useEffect(() => {
        if(groupSurvey){
            setTransformedData(transformData(groupSurvey))
        }
    }, [groupSurvey]);

    const formatDuration = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
    };

    const handleGraphGroupSurvey = (data) => {
        let callqueue = {};
        data.map(item => {
            if (Array.isArray(item.calls)) {
                return item.calls.filter(call => {
                    const group = call.callednumber;
                    if (!callqueue[group]) {
                        callqueue[group] = {
                            Incoming: {
                                total: 0,
                                durationTotal: 0,
                                averageduration: 0,
                                response: 0,
                                averageresponse: 0,
                                noresponse: 0,
                                ringduration: 0,
                                averageringduration: 0
                            },
                            calls: []
                        };
                    }
                    
                    if (call.direction === "TERMINATING") {
                        callqueue[group].Incoming.total += 1;
                        callqueue[group].Incoming.durationTotal += Math.floor(parseInt(call.duration) || 0);
                        callqueue[group].Incoming.ringduration += Math.floor(parseInt(call.ringduration) || 0);
                        callqueue[group].call;
                        if (call.answerindicator === "Yes") {
                            callqueue[group].Incoming.response += 1;
                            callqueue[group].Incoming.averageresponse += Math.floor(parseInt(call.ringduration) || 0);
                        } else if (call.answerindicator === "No" || call.answerindicator === "Yes-PostRedirection") {
                            callqueue[group].Incoming.noresponse += 1;
                        }
                        callqueue[group].calls.push(call);
                    }
                });
            }
            return [];
        });
        for (let group in callqueue) {
            let Incoming = callqueue[group].Incoming;

            Incoming.averageduration = Incoming.total > 0 ? Incoming.durationTotal / Incoming.total : 0;
            Incoming.averageresponse = Incoming.response > 0 ? Incoming.averageresponse / Incoming.response : 0;
            Incoming.averageringduration = Incoming.ringduration > 0 ? Incoming.response + Incoming.noresponse / Incoming.ringduration : 0;

            Incoming.durationTotal = formatDuration(Incoming.durationTotal);
            Incoming.averageduration = formatDuration(Incoming.averageduration);
            Incoming.averageresponse = formatDuration(Incoming.averageresponse);
            Incoming.ringduration = formatDuration(Incoming.ringduration);
            Incoming.averageringduration = formatDuration(Incoming.averageringduration);
        }
        if (JSON.stringify(callqueue) !== JSON.stringify(groupSurvey)) {
            setGroupSurvey(callqueue);
        }    
        const formattedData = formatForExcel(callqueue);
        setDataGraph(formattedData);
    }

    const formatForExcel = (data) => {
        const formattedData = [];

        // Itérer sur chaque groupe (group)
        Object.entries(data).forEach(([groupKey, groupData]) => {
            // 2ème ligne: Nom du groupe (callinglineid ou group)
            formattedData.push([`${Object.keys(callQueue).find(key => callQueue[key] === groupKey)} : ${groupKey}`, "", "", "", "", ""]);

            formattedData.push([
                "Nombre d'appels répondu",
                groupData.Incoming.response || 0, // Entrant
                "" // Pas de valeur de total ici
            ]);

            formattedData.push([
                "Durée moyenne pour répondre",
                groupData.Incoming.averageresponse || "00:00:00", // Entrant
                "" // Pas de valeur pour le total
            ]);

            formattedData.push([
                "Nombre d'appels non répondu",
                groupData.Incoming.noresponse || 0, // Entrant
                "" // Pas de valeur de total ici
            ]);

            formattedData.push([
                "Durée totale d'appels",
                groupData.Incoming.durationTotal || "00:00:00", // Entrant
                "" // Pas de total global disponible
            ]);

            formattedData.push([
                "Durée moyenne d'appels",
                groupData.Incoming.averageduration || "00:00:00", // Entrant
                "" // Pas de valeur pour le total
            ]);

            formattedData.push([
                "Durée totale de d'attente",
                groupData.Incoming.ringduration || "00:00:00", // Entrant
                "" // Pas de valeur pour le total
            ]);

            formattedData.push([
                "Durée moyenne de d'attente",
                groupData.Incoming.averageringduration || "00:00:00", // Entrant
                "" // Pas de valeur pour le total
            ]);

            // Ajouter une ligne vide pour séparer les groupes
            formattedData.push([]);
        });
        setFormattedHeader(true);
        return formattedData;
    };

    const transformData = (data) => {
        const result = {};
        for (const index in data) {
            const nameQueue = Object.keys(callQueue).find(key => callQueue[key] === index);
            result[nameQueue] = [data[index].Incoming.total, index];
        }
        return result;
    };

    const handleThClick = () => {
        setExpandedRows(prevRows => {
            if (prevRows.size === 0) {
                const allRows = new Set(Object.keys(groupSurvey).map((_, index) => index));
                return allRows;
            } else {
                return new Set();
            }
        });
    };

    const toggleRowExpansion = useCallback((groupIndex) => {
        setExpandedRows(prevRows => {
            const newRows = new Set(prevRows);
            if (newRows.has(groupIndex)) {
                newRows.delete(groupIndex);
            } else {
                newRows.add(groupIndex);
                if (!paginationPerGroup[groupIndex]) {
                    setPaginationPerGroup(prev => (prev[groupIndex] ? prev : { ...prev, [groupIndex]: 1 }));
                }
            }
            return newRows;
        });
    }, [paginationPerGroup]);

    return (
        <div className="callQueueGroupSurvey">
            {groupSurvey && transformedData && expandedRows && paginationPerGroup ? (
                <>
                    {transformedData && (
                        <div className="containerChartsCallQueue">
                            <div className="callQueueGroupSurveyCharts">
                                <h2 className="page-chart-webex-title">Nombre total d'appels par groupement</h2>
                                <ChartsWebex
                                    dataForChart={transformedData}
                                    allLabels={Object.keys(transformedData)}
                                    typeOfChart={"doughnut"}
                                    labelTitle={transformedData}
                                />
                            </div>
                        </div>
                    )}
                    <div className="table-callqueue">
                        <table className="callQueueGroupSurvey-entrants">
                            <thead>
                                <tr>
                                    <th className="thclickexpanded"></th>
                                    <th title="Groupement">{t("Groupe")}</th>
                                    <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                    <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                    <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                    <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                    <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                                    <th title="Durée totale d'attente">{t("Durée totale d'attente")}</th>
                                    <th title="Durée moyenne d'attente">{t("Durée moyenne d'attente")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(groupSurvey).map((group, index) => (
                                    <React.Fragment key={index}>
                                        <tr key={index}>
                                            <td className='statistics-table-wrapper-content-element'>
                                                <div className='legsCallingOpen' onClick={() => toggleRowExpansion(index)}>
                                                    {expandedRows.has(index) ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
                                                </div>
                                            </td>
                                            <td>{Object.keys(callQueue).find(key => callQueue[key] === group)}</td>
                                            <td>{groupSurvey[group].Incoming.response}</td>
                                            <td>{groupSurvey[group].Incoming.averageresponse}</td>
                                            <td>{groupSurvey[group].Incoming.noresponse}</td>
                                            <td>{groupSurvey[group].Incoming.durationTotal}</td>
                                            <td>{groupSurvey[group].Incoming.averageduration}</td>
                                            <td>{groupSurvey[group].Incoming.ringduration}</td>
                                            <td>{groupSurvey[group].Incoming.averageringduration}</td>
                                        </tr>
                                        {expandedRows.has(index) && (
                                            <tr key={`expanded-row-${index}`}>
                                                <td colSpan={9} className="container-list-post-callqueue">
                                                    <CallQueueTable groupSurvey={groupSurvey} allCalls={allCalls} group={group} index={index} itemsPerPage={itemsPerPage} paginationPerGroup={paginationPerGroup} setPaginationPerGroup={setPaginationPerGroup} />
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <Loader />
            )}
        </div>
    );
}