import axios from "axios";
import { useContext, useEffect, useState } from "react";
import ChartData from "../../components/ChartData";
import Loader from "../../components/Loader";
import { AuthContext } from "../../context/AuthContext";
import AmpTable from "./AmpTable";
import { useQuery } from "@tanstack/react-query";
import NumberCard from "../../components/Card/NumberCard";
import ButtonArrow from "../../components/ButtonArrow";
import { useTranslation } from "react-i18next";

export default function AmpEvents() {
  // import context
  const { apiUrl } = useContext(AuthContext);

  // all events
  const [totalEvents, setTotalEvents] = useState();

  // charts props
  const [dataForChart, setDataForChart] = useState({});
  const [allLabels, setAllLabels] = useState([]);
  const [titleChart, setTitleChart] = useState("");
  const [label, setLabel] = useState(false);
  const { t } = useTranslation();

  // toggle to display computer list
  const [displayArray, setDisplayArray] = useState(false);

  const getAmpEvents = async () => {
    const res = await axios(`${apiUrl}amp/events`, {
      method: "GET",
      withCredentials: true,
    });
    setTotalEvents(
      Object.values(res.data.count).reduce((acc, val) => acc + val, 0)
    );
    return res.data.count;
  };

  const events = useQuery({
    queryKey: ["ampEvents"],
    queryFn: getAmpEvents,
  });

  // function to create an object for the chart
  const setEventsForChart = () => {
    let dataChart = {};
    let allKeys = Object.keys(events.data);
    allKeys.forEach((item) => {
      const label = "Evénements";
      if (!dataChart[item]) dataChart[item] = {};

      if (!dataChart[item][label]) {
        dataChart[item][label] = events.data[item];
      }
    });

    setDataForChart(dataChart);
    setAllLabels(["Evénements"]);
    setTitleChart("Evénements Secure Endpoint");
  };

  useEffect(() => {
    events.isFetched && setEventsForChart();
  }, [events.data]);

  if (events.isError) {
    return (
      <article className="page">
        <h1 className="page-title">{t('Aucun évènement secure endpoint')}</h1>
      </article>
    );
  }

  return (

    <>
      {!events.isLoading ? (
        <article className="page">
          <section className="page-logo">
            <img src="/assets/cisco_logo.png" alt="Logo de Cisco" />
            <h1 className="page-title">Cisco Secure Endpoint</h1>
            <ButtonArrow url={"https://id.cisco.com/"} />
          </section>
          <section className="page-section">
            <NumberCard title="Nombre d'évènements" number={totalEvents} />
          </section>
          <button
            className="button button-new"
            onClick={() => setDisplayArray(!displayArray)}
          >
            {displayArray
              ? t("Afficher les évènements secure endpoint")
              : t("Afficher les équipements secure endpoint")}
          </button>
          {!displayArray ? (
            <ChartData
              dataForChart={dataForChart}
              allLabels={allLabels}
              label={label}
              titleChart={titleChart}
              typeOfChart="bar"
            />
          ) : (
            <AmpTable displayArray={displayArray} />
          )}
        </article>
      ) : (
        <Loader />
      )}
    </>
  );
}
