import React, { useState, useEffect } from 'react';

export default function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Fonction pour mettre à jour la taille de l'écran
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        // Ajouter l'écouteur d'événement de redimensionnement
        window.addEventListener('resize', handleResize);

        // Exécuter la fonction immédiatement pour obtenir la taille initiale de l'écran
        handleResize();

        // Nettoyer l'écouteur d'événement lors du démontage du composant
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};
