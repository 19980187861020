import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loader";
import { faBarcode, faBox, faLocation, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export default function TimelineContractsCisco({ item }) {
    const { t } = useTranslation();
    const { apiUrl } = useContext(AuthContext);

    const getAllEquipements = async () => {
        const res = await axios.get(`${apiUrl}equipement/index`, {
            withCredentials: true,
        });
        return res.data;
    };

    const equipements = useQuery({
        queryKey: ["equipements"],
        queryFn: getAllEquipements,
    });

    return (
        <>
            {!equipements.isLoading ? (
                <>
                    <div className="contract">
                        <div className="contract-header">
                            <span className="contract-title">
                                <FontAwesomeIcon icon={faBarcode} className="contract-icon" />
                                {t('Numéro de série')}
                            </span>
                            <span className="contract-title">
                                <FontAwesomeIcon icon={faTag} className="contract-icon" />
                                {t('Nom')}
                            </span>
                            <span className="contract-title">
                                <FontAwesomeIcon icon={faBox} className="contract-icon" />
                                {t('Produit')}
                            </span>
                            <span className="contract-title">
                                <FontAwesomeIcon icon={faLocation} className="contract-icon" />
                                {t('Site')}
                            </span>
                        </div>
                        {equipements.data.map((equipement, index) => {
                            if (item.serial_number.split(",").includes(equipement.serial_number)) {
                                return <div className="contract-body" key={index}>
                                    <span className="contract-content">
                                        {equipement.serial_number}
                                    </span>
                                    <span className="contract-content">
                                        {equipement.device_name}
                                    </span>
                                    <span className="contract-content">
                                        {equipement.famille && equipement.famille.product}
                                    </span>
                                    <span className="contract-content">
                                        {equipement.site && equipement.site.nom}
                                    </span>
                                </div>;
                            }
                        })}
                    </div>
                </>
            ) : (
                <Loader />
            )}
        </>
    );
}