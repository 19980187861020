import React, { useEffect, useState } from "react";
import StatisticsNumber from "../../../components/StatisticsNumber";
import { useTranslation } from "react-i18next";

export default function StatisticsBarWebex({ data, dataAll, filters, date }) {
    const [totalCalls, setTotalCalls] = useState({ total: null, bestCallsSeller: null, pourcentage: null, status: null });
    const [directionCalls, setDirectionCalls] = useState({ originatingCalls: null, terminatingCalls: null, pourcentage: null, status: null });
    const [externalCalls, setExternalCalls] = useState({ inboundCalls: null, outboundCalls: null, pourcentage: null, status: null });
    const [locationCalls, setLocationCalls] = useState({ location: null, bestLocation: null, pourcentage: null, status: null });
    const [activeUsersCalls, setactiveUsersCalls] = useState({ activeUsers: null, activeUsersTotal: null, pourcentage: null, status: null });
    const startDate = date[0];
    const endDate = date[1];
    const numberOfDays = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1);
    const { t, i18n } = useTranslation();
    
    useEffect(() => {
        if (data) {
            let dataFiltered = data;
            let allDataFiltered = dataAll;
            if (filters.length > 0) {
                filters.forEach(filter => {
                    dataFiltered = dataFiltered.filter(item => filter.values.includes(item[filter.column]));
                    allDataFiltered = allDataFiltered.filter(item => filter.values.includes(item[filter.column]));
                });
            }
            filteredStatisticsNumberTotal(dataFiltered, allDataFiltered);
            filteredStatisticsNumberDirection(dataFiltered, allDataFiltered);
            filteredStatisticsNumberExternal(dataFiltered, allDataFiltered);
            filteredStatisticsNumberLocation(dataFiltered, allDataFiltered);
            filteredStatisticsActiveUsersCalling(dataFiltered, allDataFiltered);
        }
    }, [data, filters]);

    const filteredStatisticsNumberTotal = (data, allDataFiltered) => {
        let totalCount = 0; // Nombre total d'éléments dans dataFormatted
        let callsPerHour = {}; // Objet pour stocker le nombre d'appels par heure
        let busiestHourCount = 0;
        let busiestHour = '';

        data.forEach(item => {
            totalCount += 1;
            const startTime = new Date(item.starttime);
            const hour = startTime.getHours();
            const date = `${startTime.getDate()}/${startTime.getMonth() + 1}/${startTime.getFullYear()}`;
            const hourKey = `${hour}-${date}`;

            if (!callsPerHour[hourKey]) {
                callsPerHour[hourKey] = 0;
            }
            callsPerHour[hourKey] += 1;

            if (callsPerHour[hourKey] > busiestHourCount) {
                busiestHourCount = callsPerHour[hourKey];
                busiestHour = `${hour}h-${hour + 1}h, ${date}`;
            }
        });

        const previousWeekStartDate = new Date(startDate.getTime() - numberOfDays * 24 * 60 * 60 * 1000);
        const previousWeekEndDate = new Date(startDate.getTime() - 1);
        const previousWeekData = allDataFiltered.filter(item => {
            const itemDate = new Date(item.starttime);
            return itemDate >= previousWeekStartDate && itemDate <= previousWeekEndDate;
        });

        // Calculate total calls for previous week
        let previousWeekTotalCount = 0;
        previousWeekData.forEach(item => previousWeekTotalCount += 1);

        let percentageChange = 0;
        if (previousWeekData.length > 0) {
            percentageChange = ((totalCount - previousWeekTotalCount) / previousWeekTotalCount) * 100;
        } else {
            percentageChange = 0;
        }

        // Determine status
        let status = '';
        if (percentageChange > 0) {
            status = 'hausse';
        } else if (percentageChange < 0) {
            status = 'baisse';
        } else {
            status = 'stable';
        }
        // Mettre à jour l'état avec les valeurs calculées
        setTotalCalls({
            total: totalCount,
            bestCallsSeller: `${busiestHourCount} ${t('appels aux heures les plus chargées')} : ${busiestHour}`,
            pourcentageVariation: percentageChange === 0 ? '--' : percentageChange.toFixed(2),
            status: status
        });
    };


    const filteredStatisticsNumberDirection = (data, allDataFiltered) => {
        let originatingCount = 0;
        let terminatingCount = 0;

        data.forEach(item => {
            const direction = item.direction;
            if (direction === 'ORIGINATING') {
                originatingCount += 1;
            } else if (direction === 'TERMINATING') {
                terminatingCount += 1;
            }
        });

        // Calculer les pourcentages
        const total = originatingCount + terminatingCount;
        const originatingCallsPercentage = total > 0 ? (originatingCount / total) * 100 : 0;
        const terminatingCallsPercentage = total > 0 ? (terminatingCount / total) * 100 : 0;

        const previousWeekStartDate = new Date(startDate.getTime() - numberOfDays * 24 * 60 * 60 * 1000);
        const previousWeekEndDate = new Date(startDate.getTime() - 1);

        const previousWeekData = allDataFiltered.filter(item => {
            const itemDate = new Date(item.starttime);
            return itemDate >= previousWeekStartDate && itemDate <= previousWeekEndDate;
        });

        let previousOriginatingCount = 0;
        let previousTerminatingCount = 0;

        previousWeekData.forEach(item => {
            const direction = item.direction;
            if (direction === 'ORIGINATING') {
                previousOriginatingCount += 1;
            } else if (direction === 'TERMINATING') {
                previousTerminatingCount += 1;
            }
        });

        // Calculer les pourcentages pour la période précédente
        const previousTotal = previousOriginatingCount + previousTerminatingCount;
        const previousOriginatingCallsPercentage = previousTotal > 0 ? (previousOriginatingCount / previousTotal) * 100 : 0;

        let originatingVariation = 0;

        if (previousTotal > 0) {
            originatingVariation = ((originatingCallsPercentage - previousOriginatingCallsPercentage) / previousOriginatingCallsPercentage) * 100;
        }

        // Calculer le pourcentage total de variation
        const totalVariation = originatingVariation;

        // Déterminer le statut
        let status = 'stable';
        if (totalVariation > 0) {
            status = 'hausse';
        } else if (totalVariation < 0) {
            status = 'baisse';
        }

        setDirectionCalls({
            originatingCalls: originatingCallsPercentage.toFixed(2),
            terminatingCalls: terminatingCallsPercentage.toFixed(2),
            pourcentageVariation: totalVariation === 0 ? '--' : totalVariation.toFixed(2),
            status: status
        });
    };

    const filteredStatisticsNumberExternal = (data, allDataFiltered) => {
        let inboundCount = 0;
        let outboundCount = 0;

            data.forEach(item => {
                const typeTraffic = item.calltype;
                if (typeTraffic !== "SIP_ENTERPRISE") {
                    inboundCount += 1;
                } else {
                    outboundCount += 1;
                }
            });

        const previousWeekStartDate = new Date(startDate.getTime() - numberOfDays * 24 * 60 * 60 * 1000);
        const previousWeekEndDate = new Date(startDate.getTime() - 1);

        // Filtrer les données pour la semaine précédente
        const previousWeekData = allDataFiltered.filter(item => {
            const itemDate = new Date(item.starttime);
            return itemDate >= previousWeekStartDate && itemDate <= previousWeekEndDate;
        });

        let previousInboundCount = 0;

        previousWeekData.forEach(item => {
            const typeTraffic = item.callinglineid;
            if (typeTraffic === 'NA') {
                previousInboundCount += 1;
            }
        });

        let totalPourcentageVariation = 0;
        if (previousInboundCount > 0) {
            totalPourcentageVariation = ((inboundCount - previousInboundCount) / previousInboundCount) * 100;
        }

        let status = 'stable';
        if (totalPourcentageVariation > 0) {
            status = 'hausse';
        } else if (totalPourcentageVariation < 0) {
            status = 'baisse';
        }

        setExternalCalls({
            inboundCalls: inboundCount,
            outboundCalls: outboundCount,
            pourcentageVariation: totalPourcentageVariation === 0 ? '--' : totalPourcentageVariation.toFixed(2),
            status: status
        });
    }

    const filteredStatisticsNumberLocation = (data, allDataFiltered) => {
        let locationCounts = {};
        let totalLocations = 0;
        let bestLocation = '';
        let maxCount = 0;

        data.forEach(item => {
            const location = item.location;
            if (!locationCounts[location]) {
                locationCounts[location] = 0;
            }
            locationCounts[location]++;
        });

        Object.keys(locationCounts).forEach(location => {
            if (locationCounts[location] > maxCount) {
                maxCount = locationCounts[location];
                bestLocation = location;
            }
            totalLocations += locationCounts[location];
        });
        
        const previousWeekStartDate = new Date(startDate.getTime() - numberOfDays * 24 * 60 * 60 * 1000);
        const previousWeekEndDate = new Date(startDate.getTime() - 1);

        const previousWeekData = allDataFiltered.filter(item => {
            const itemDate = new Date(item.starttime);
            return itemDate >= previousWeekStartDate && itemDate <= previousWeekEndDate;
        });

        let previousLocationCounts = {};
        let previousTotalLocations = 0;
        let previousMaxCount = 0;

        previousWeekData.forEach(item => {
            const location = item.location;
            if (!previousLocationCounts[location]) {
                previousLocationCounts[location] = 0;
            }
            previousLocationCounts[location]++;
        });

        Object.keys(previousLocationCounts).forEach(location => {
            if (previousLocationCounts[location] > previousMaxCount) {
                previousMaxCount = previousLocationCounts[location];
            }
            previousTotalLocations += previousLocationCounts[location];
        });

        let pourcentageVariation = 0;
        let status = 'stable';

        if (previousTotalLocations > 0) {
            pourcentageVariation = ((maxCount - previousTotalLocations) / previousTotalLocations) * 100;
        }

        if (pourcentageVariation > 0) {
            status = 'hausse';
        } else if (pourcentageVariation < 0) {
            status = 'baisse';
        } else {
            status = 'stable';
        }

        setLocationCalls({
            location: maxCount,
            bestLocation: bestLocation,
            pourcentageVariation: pourcentageVariation === 0 ? '--' : pourcentageVariation.toFixed(2),
            status: status
        });
    }

    const filteredStatisticsActiveUsersCalling = (data, allDataFiltered) => {
        let callinglineCounts = {};
        let totalCallingline = 0;
        let maxCount = 0;

        data.forEach(item => {
            const callinglineid = item.callinglineid;
            if (callinglineid !== 'NA') {
                if (!callinglineCounts[callinglineid]) {
                    callinglineCounts[callinglineid] = 0;
                }
                callinglineCounts[callinglineid]++;
            }
        });

        maxCount = Object.keys(callinglineCounts).length;

        Object.keys(callinglineCounts).forEach(callinglineid => {
            totalCallingline += callinglineCounts[callinglineid];
        });

        const total = `${totalCallingline}`;

        const previousWeekStartDate = new Date(startDate.getTime() - numberOfDays * 24 * 60 * 60 * 1000);
        const previousWeekEndDate = new Date(startDate.getTime() - 1);

        const previousWeekData = allDataFiltered.filter(item => {
            const itemDate = new Date(item.starttime);
            return itemDate >= previousWeekStartDate && itemDate <= previousWeekEndDate;
        });

        let previousCallinglineCounts = {};
        let previousTotalCallingline = 0;
        let previousMaxCount = 0;

        previousWeekData.forEach(item => {
            const callinglineid = item.callinglineid;
            if (callinglineid !== 'NA') {
                if (!previousCallinglineCounts[callinglineid]) {
                    previousCallinglineCounts[callinglineid] = 0;
                }
                previousCallinglineCounts[callinglineid]++;
            }
        });

        previousMaxCount = Object.keys(previousCallinglineCounts).length;

        Object.keys(previousCallinglineCounts).forEach(callinglineid => {
            previousTotalCallingline += previousCallinglineCounts[callinglineid];
        });

        let pourcentageVariation = 0;
        let status = 'stable';

        if (previousTotalCallingline > 0) {
            pourcentageVariation = ((maxCount - previousMaxCount) / maxCount) * 100;
        }

        if (pourcentageVariation > 0) {
            status = 'hausse';
        } else if (pourcentageVariation < 0) {
            status = 'baisse';
        } else {
            status = 'stable';
        }

        setactiveUsersCalls({
            activeUsers: maxCount,
            activeUsersTotal: total,
            pourcentageVariation: pourcentageVariation === 0 ? '--' : pourcentageVariation.toFixed(2),
            status: status
        });
    }

    return (
        <section>
            <StatisticsNumber title={t("Total des appels")} secondTitle={totalCalls.bestCallsSeller} status={totalCalls.status} firstData={totalCalls.total} pourcentage={totalCalls.pourcentageVariation} mesure={'K'} />
            <StatisticsNumber title={t("Appels externes")} secondTitle={`${t('Internal Calls')} : `} status={externalCalls.status} firstData={externalCalls.inboundCalls} secondData={externalCalls.outboundCalls} pourcentage={externalCalls.pourcentageVariation} mesure={'K'} />
            <StatisticsNumber title={t("Appels traités")} secondTitle={`${t('Inbound Call')} : `} status={directionCalls.status} firstData={directionCalls.originatingCalls} secondData={directionCalls.terminatingCalls} pourcentage={directionCalls.pourcentageVariation} mesure={'%'} />
            <StatisticsNumber title={t("Appels dans l'endroit le plus occupé")} secondTitle={locationCalls.bestLocation} status={locationCalls.status} firstData={locationCalls.location} pourcentage={locationCalls.pourcentageVariation} mesure={'K'} />
            <StatisticsNumber title={t("Utilisateurs actifs de Webex Calling")} secondTitle={`${t('Points de terminaison actifs')} : `} status={activeUsersCalls.status} firstData={activeUsersCalls.activeUsers} secondData={activeUsersCalls.activeUsersTotal} pourcentage={activeUsersCalls.pourcentageVariation} mesure={'K'} />
        </section>
    )
}