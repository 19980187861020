import {BsArrowUpRightCircleFill} from 'react-icons/bs';

export default function ButtonArrow ({url}) {

    return (

        <button className="button button-arrow">
            <a href={url} target="_blank">
                <BsArrowUpRightCircleFill />
            </a>
        </button>
    )
}