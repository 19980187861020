import React, { useContext, useState, useEffect } from "react"
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import Form from "../../components/Forms/Form";
import { useLocation, Link } from "react-router-dom";
import Loader from "../../components/Loader";
import RowLicence from "./RowLicence";
import { PaloLicenceColumn} from "../../components/headerContent";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import TableComponent from "../../components/TableComponent";
import { useTranslation } from "react-i18next";

export default function Licence() {
  const { apiUrl, user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const paloaltoId = searchParams.get("paloaltoId");
  const [filteredData, setFilteredData] = useState([]);
  const {t} = useTranslation();

  // Request to get all licences and set a new array
  const getAllLicences = async () => {

      const responses = await axios.all([
        axios.get(`${apiUrl}license/palo/`, {
          method: "GET",
          withCredentials: true,
        }),
        axios.get(`${apiUrl}eqptpalo/`, {
          method: "GET",
          withCredentials: true,
        }),
      ]);


      // set array
      const licences = responses[0].data;
      const eqpt = responses[1].data;

      // set an empty array to stock famille object
      let family = [];
      eqpt.forEach((equipement) => {
        family.push(equipement.famille);
      });

      // create an array by set the equipement id in key and famille who matched
      const familiesById = eqpt.reduce((result, equipement) => {
        result[equipement.id] = equipement.famille;
        return result;
      }, {});

      // add famille in licences by match palo.id
      const licenceFamily = licences.map((licence) => {
        const famille = familiesById[licence.palo_id];
        return { ...licence, famille };
      });

      return licenceFamily
  };

  const licences = useQuery({
    queryKey: ["licences"],
    queryFn: getAllLicences
  });

  useEffect(() => {
    if (licences.data !== undefined && !licences.data.includes("No data")) {
      if (paloaltoId) {
        const filteredData = licences.data.filter(licence => licence.license_id === paloaltoId);
        setFilteredData(filteredData);
      } else {
        setFilteredData(licences.data);
      }
    }
  }, [licences.data, paloaltoId]);

   // Request to get all sites
   const getAllSites = async (entreprise) => {

    if(user.entreprise) {
      const res = await axios(`${apiUrl}equipement/sites/${entreprise}`, {
        method: "GET",
        withCredentials: true,
      })
       return res.data;
    }
  };

  const sites = useQuery({
    queryKey: ['sites', user.entreprise],
    queryFn: () => getAllSites(user.entreprise)
  })

  useEffect(() => {
    queryClient.invalidateQueries(["sites", user.entreprise])
  }, [user.entreprise])

  if(licences.isError) {
    return !licences.isLoading ? <article className="page">
      <h1 className="page-title"> Aucune données</h1>
    </article> : <Loader /> 
  }

  return (
    <>
      {!licences.isLoading ? (
        <>
          <article className="page">
            <h1 className="page-title">{t('Licences Palo Alto')}</h1>
              {paloaltoId && (
                <div className="page-title page-link-timeline">
                  <Link to={"/timeline"} className="button button-new"><FontAwesomeIcon icon={faChevronLeft}/> {t('Retour')}</Link>
                </div>
              )}
              <TableComponent
                tableData={filteredData}
                tableColumns={PaloLicenceColumn}
                rowComponent={RowLicence}
                sites={sites}
                checkbox={false}
              />
          </article>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

