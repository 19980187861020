import { faLocationDot, faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { LicenceSchema } from "../../components/Forms/schema/YupSchema";
import Update from "../../components/Update";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Loader from "../../components/Loader";

export default function TimelineContractsFirewall({ item, setState, stateQueryRow, setStateQueryRow }) {
    const { apiUrl } = useContext(AuthContext);
    const queryClient = useQueryClient()
    const [showLicence, setShowLicence] = useState([]);

    const getLicenceData = () => {
        axios(`${apiUrl}license/palo/${item.license_id}`,
            {
                method: "GET",
                withCredentials: true,
            }
        )
            .then((res) => {
                setShowLicence([res.data])
            })
            .catch((err) => { });
    }

    useEffect(() => {
        getLicenceData();
    }, [item])

    const deleteLicence = async () => {
        const res = await axios(`${apiUrl}license/palo/${item.license_id}/delete`, {
            method: "delete",
            withCredentials: true
        })
        return res.data
    }

    const deletingLicence = useMutation({
        mutationFn: deleteLicence,
        onSuccess: () => {
            setState(false);
            queryClient.resetQueries({ queryKey: ["licences"] });
            setStateQueryRow({ state: true, name: item.name, source: item.source, type: 'delete' });
        }
    })

    const formContentUpdate = [
        { id: "nom_license", type: "text", title: "Licence", value: item.type },
        { id: "auth_code", type: "text", title: "Auth code", value: item.auth_code },
        { id: "date_fin", type: "date", title: "date d'expiration", value: item.endDate },
        { id: "palo", type: "number", title: "Palo Id", value: +item.palo_id }
    ];

    const UpdateLicence = async (data) => {
        const res = await axios(`${apiUrl}license/palo/${item.license_id}/edit`, {
            method: "put",
            withCredentials: true,
            data: data
        })
        queryClient.resetQueries({ queryKey: ["licences"] });
        setStateQueryRow({ state: true, name: item.name, source: item.source, type: 'update' });
        return res.data;
    };

    return (
        <>
            {!deletingLicence.isLoading ? (
                showLicence.map((licence) => {
                    if (licence.id === Number(item.license_id)) {
                        return (
                            <div key={item.id}>
                                <div className="licencepalo">
                                    <div className="licencepalo-item">
                                        <span className="licencepalo-title">IP
                                            <FontAwesomeIcon icon={faNetworkWired} className="licencepalo-icon" />
                                        </span>
                                        <span className="licencepalo-content">{licence.palo.ip}</span>
                                    </div>
                                    <div className="licencepalo-item">
                                        <span className="licencepalo-title">Site
                                            <FontAwesomeIcon icon={faLocationDot} className="licencepalo-icon" />
                                        </span>
                                        <span className="licencepalo-content">{licence.palo.site.nom}</span>
                                    </div>
                                </div>
                                <Update formContent={formContentUpdate} schema={LicenceSchema} stateQueryRow={stateQueryRow} submitFunction={UpdateLicence} deleteFunction={deletingLicence.mutate} />
                            </div>
                        );
                    }
                })
            ) : (
                <Loader />
            )}
        </>
    )
}