  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import IconsStatus from "../../components/IconStatus";
  import MultipleStatus from "../../components/MultipleStatus";
import { useTranslation } from "react-i18next";
  
  /**
   * 
   * @param {array} array - contains every status for on category
   * @param {string} categorie title 
   */
  export default function WebexCategorie({array, categorie}) {

    const {t} = useTranslation();
  
    return (
      <div className="webex-cards_container">
              <h3>{t(categorie)}</h3>
              <div className="webex-cards">
              { array.map(item => {
                return <div className="webex-card" key={item.title}>

                  <div className="webex-card_header">
                {item.iconType === "fontAwesome" ? <FontAwesomeIcon icon={item.icon} className="webex-card_icon"/>
                : item.icon }
                <span className="webex-card_title">{t(item.title)}</span>
                </div>
                {!item.multiple && !item.number ? <IconsStatus value={item.value} icon /> : item.multiple && item.value.length !== 0 ? <MultipleStatus data={item.value} /> : 
                item.number && item.value !== -1 && item.value !== "indisponible" ? <span className="webex-card_span">{t(item.value)} {item.metrics ? item.metrics : null}</span> : <IconsStatus value={"indisponible"} icon /> }
                </div>
                })}
          </div>  
      </div>
    );
  }
  