import { faChevronDown, faChevronUp, faLocationDot, faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, TableCell, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { LicenceSchema } from "../../components/Forms/schema/YupSchema";
import Update from "../../components/Update";
import IconsStatus from "../../components/IconStatus";
import { convertDate } from "../../components/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function RowLicence({ item }) {

  //import context
  const { apiUrl } = useContext(AuthContext);
  const queryClient = useQueryClient()
  // stock data for accordion
  const [showLicence, setShowLicence] = useState([]);


  // Get data for only one certificat
  const getLicenceData = () => {
    axios(`${apiUrl}license/palo/${item.license_id}`,
      {
        method: "GET",
        withCredentials: true,
      }
    )
      .then((res) => {
        setShowLicence([res.data])
      })
      .catch((err) => { });
  }

  // send request only if user click on "see more" button
  useEffect(() => {
    getLicenceData();
  }, [item])


  // Delete licence
  const deleteLicence = async () => {
    const res = await axios(`${apiUrl}license/palo/${item.license_id}/delete`, {
      method: "delete",
      withCredentials: true
    })
    return res.data
  }

  const deletingLicence = useMutation({
    mutationFn: deleteLicence,
    onSuccess: queryClient.invalidateQueries(["licences"])
  })


  // Content to create new licence
  const formContentUpdate = [
    { id: "nom_license", type: "text", title: "Licence", value: item.nom_license },
    { id: "auth_code", type: "text", title: "Auth code", value: item.auth_code },
    { id: "date_fin", type: "date", title: "date d'expiration", value: item.date_fin },
    { id: "palo", type: "number", title: "Palo Id", value: +item.palo_id }
  ];


  const UpdateLicence = async (data) => {
    const res = await axios(`${apiUrl}license/palo/${item.license_id}/edit`, {
      method: "put",
      withCredentials: true,
      data: data
    })
    return res.data
  }


  return (
    <>
      {showLicence.map((licence) => {
        if (licence.id === Number(item.license_id)) {
          return (
            <div key={item.id}>
              <div className="licencepalo">
                <div className="licencepalo-item">
                  <span className="licencepalo-title">IP
                    <FontAwesomeIcon icon={faNetworkWired} className="licencepalo-icon" />
                  </span>
                  <span className="licencepalo-content">{licence.palo.ip}</span>
                </div>
                <div className="licencepalo-item">
                  <span className="licencepalo-title">Site
                    <FontAwesomeIcon icon={faLocationDot} className="licencepalo-icon" />
                  </span>
                  <span className="licencepalo-content">{licence.palo.site.nom}</span>
                </div>
              </div>
              <Update formContent={formContentUpdate} schema={LicenceSchema} submitFunction={UpdateLicence} deleteFunction={deletingLicence.mutate} />
            </div>
          );
        }
      })}
    </>
  )
}