import React from "react";
import { useTranslation } from "react-i18next";

export default function ReservationTable({ deviceStatusParking }) {
    const { t } = useTranslation();
    
    return (
        <>
            <div className="parking-container-popup-listingreservation">
                <h2 className="card-title">Liste des places réservées :</h2>
                <div className="legend-status list-reserved-places">
                    <div className="alerts-table">
                        <div className="alerts-table-header">
                            <table cellPadding="0" cellSpacing="0" border="0">
                                <thead className="alerts-device-container-content-thead">
                                    <tr>
                                        <th className="alerts-device-container-content-thead-th">{t('Place')}</th>
                                        <th className="alerts-device-container-content-thead-th">{t('Nom')}</th>
                                        <th className="alerts-device-container-content-thead-th">{t('Date')}</th>
                                        <th className="alerts-device-container-content-thead-th">{t('Plaque')}</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="alerts-table-content">
                            <table cellPadding="0" cellSpacing="0" border="0">
                                <tbody>
                                    {deviceStatusParking.data.latest_pir_trigger.map((element, index) => {
                                        if (element.pir_trigger === 2) {
                                            return (
                                                <tr key={index}>
                                                    <td>{element.place_parking} <span className="ve-table">{element.electric === 1 && 'VE'}</span></td>
                                                    <td>{element.nom} {element.prenom}</td>
                                                    <td>{element.dateend}</td>
                                                    <td>{element.plaque !== null ? element.plaque : ''}</td>
                                                </tr>
                                            );
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};