
import {  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import ChartData from "../../components/ChartData";
import Loader from "../../components/Loader";
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import NumberCard from "../../components/Card/NumberCard";
import { useTranslation } from "react-i18next";

export default function Threat() {
  const {apiUrl} = useContext(AuthContext);
  const [dataForChart, setDataForChart] = useState({});
  const [allLabels, setAllLabels] = useState([]);
  const [label, setLabel] = useState(false);
  const [titleChart, setTitleChart] = useState(false);
  const {t} = useTranslation();

  const [days, setDays] = useState(7);
  const [limit, setLimit] = useState(10);

  //Request
  const getTopThreat = async () => {
    const res = await axios(`${apiUrl}umbrella/top_threat/${days}/${limit}`, {
      method: "get",
      withCredentials: true,
    })
     return res.data.data
  };

  const threat = useQuery({
    queryKey: ["threat"],
    queryFn: getTopThreat
  })


  const filterThreatForChart = () => {
    const threatForChart = {};
    threat.data && threat.data.forEach((item) => {
      if (!threatForChart[item.threattype])
        threatForChart[item.threattype] = {};
      if (!threatForChart[item.threattype]["Nombres"]) {
        threat[item.threattype]["Nombres"] = item.count;
      } else {
        threat[item.threattype]["Nombres"] += item.count;
      }
    });
    setAllLabels(["Nombres de menaces"]);
    setDataForChart(threatForChart);
    setTitleChart("Top menace par type");
  };

  useEffect(() => {
    filterThreatForChart();
  }, [threat.data]);

  useEffect(() => {
    threat.refetch()
  }, [days, limit]);


  return (
    <>
        <section className="page-section">
          <NumberCard title="Nombre de menaces" number={threat.data ? threat.data.length : "0" } />
          <div className="page-section_form">
            <form className="form">
              <div className="form-input_container limit">
                <select
                  className="only"
                  id="days"
                  onChange={(e) => setDays(e.target.value)}
                >
                  <option value="7">7 </option>
                  <option value="15">15 </option>
                  <option value="30"> 30 </option>
                </select>
                <label htmlFor="days">{t("derniers jours")}</label>
              </div>
            </form>
            <form className="form">
              <div className="form-input_container limit">
                <select
                  className="only"
                  id="days"
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
                <label htmlFor="days">{t("derniers évènements")}</label>
              </div>
            </form>
          </div>
      </section>
      {!threat.isFetching ? (
        threat.length > 0 ? (
          <ChartData
            dataForChart={dataForChart}
            label={label}
            titleChart={titleChart}
            allLabels={allLabels}
          />
        ) : (
          <section className="page-section">
            <h2 className="umbrella-threat">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="umbrella-threat_icon"
              />
              {t("Aucune menace détectée")}
            </h2>
          </section>
        )
      ) : (
          <Loader />
      )}
    </>
  );
}
