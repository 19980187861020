import React from "react";
import TypeGeneralSurvey from "./Type/TypeGeneralSurvey";
import TypeLocalitySurvey from "./Type/TypeLocalitySurvey";
import TypePostSurvey from "./Type/TypePostSurvey";
import TypeDestinationSurvey from "./Type/TypeDestinationSurvey";
import TypeTrends from "./Type/TypeTrends";
import TypeTrendsSite from "./Type/TypeTrendsSite";
import TypeTrendsPost from "./Type/TypeTrendsPost";
import TypeTrendsTime from "./Type/TypeTrendsTime";
import Loader from "../../../../components/Loader";

export default function TypeGraphWebexReport({ data, people, reportType, analyseType, setDataGraph, setFormattedHeader, filteredPosts }) {  
    
    return (
        <div className="statistics-report">
            {data && people && reportType ? (
                reportType === "Enquête générale" ? (
                    <TypeGeneralSurvey data={data} setDataGraph={setDataGraph} setFormattedHeader={setFormattedHeader}/>
                ) : reportType === "Enquête par sites" ? (
                    <TypeLocalitySurvey data={data} setDataGraph={setDataGraph} setFormattedHeader={setFormattedHeader}/>
                ) : reportType === "Enquête par poste" ? (
                    <TypePostSurvey data={data} people={people} setDataGraph={setDataGraph} setFormattedHeader={setFormattedHeader} filteredPosts={filteredPosts}/>
                ) : reportType === "Enquête par origine d'appels" ? (
                    <TypeDestinationSurvey data={data} setDataGraph={setDataGraph} setFormattedHeader={setFormattedHeader}/>
                ) : reportType === "Analyse des tendances" ? (
                    <TypeTrends data={data} people={people} setDataGraph={setDataGraph} setFormattedHeader={setFormattedHeader}  filteredPosts={filteredPosts}/>
                ) : reportType === "Analyse des tendances par sites" ? (
                    <TypeTrendsSite data={data} people={people} setDataGraph={setDataGraph} setFormattedHeader={setFormattedHeader} filteredPosts={filteredPosts}/>
                ) : reportType === "Analyse des tendances par postes" ? (
                    <TypeTrendsPost data={data} people={people} setDataGraph={setDataGraph} setFormattedHeader={setFormattedHeader} filteredPosts={filteredPosts}/>
                ): reportType === "Analyse temporelle (HH, JJ...)" && (
                    <TypeTrendsTime data={data} analyseType={analyseType} setDataGraph={setDataGraph} setFormattedHeader={setFormattedHeader}/>
                )
            ) : (
                <Loader/>
            )}
            
        </div>
    ) 
}