import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

export default function SummaryClient({ mode, networkId }) {
  const { apiUrl } = useContext(AuthContext);
  const {t} = useTranslation();

  const getSummaryClientOrganisation = async () => {
    const res = await axios.get(`${apiUrl}meraki/summaryClients/organisation`, {
      withCredentials: true,
    });
    return res.data;
  };

  const summaryClientOrganisation = useQuery({
    queryKey: ["summaryClientorganisation"],
    queryFn: getSummaryClientOrganisation,
    enabled: mode === "organisation",
  });

  const getSummaryClientNetwork = async () => {
    const res = await axios.get(
      `${apiUrl}meraki/summaryClients/network/${networkId}`,
      {
        withCredentials: true,
      }
    );
    return res.data;
  };

  const summaryClientNetwork = useQuery({
    queryKey: ["summaryClientNetwork"],
    queryFn: getSummaryClientNetwork,
    enabled: mode === "network",
  });

  if(summaryClientNetwork.isFetched && summaryClientNetwork.data.errors) {
    return null;
  }
  

  return (
    <>
      {mode === "organisation" ? (
        !summaryClientOrganisation.isLoading ? (
          <>
            <section className="summary">
              <h2 className="summary-title"> {t('Usage')} <span> {t('(sur le dernier jour)')} </span></h2>
              <div className="summary-content">
                <div className="summary-item">
                  <h2>{t('Données')} </h2>
                  <p> {Math.round(summaryClientOrganisation.data.usage.overall.total / 1000)}Go</p>
                </div>
                <div className="summary-item">
                  <h2> {t('Données téléchargées')}</h2>
                  <p>{Math.round(summaryClientOrganisation.data.usage.overall.downstream /1000)}Go
                  </p>
                </div>
                <div className="summary-item">
                  <h2> {t('Données transférées')}</h2>
                  <p>{Math.round(summaryClientOrganisation.data.usage.overall.upstream / 1000)}Go</p>
                </div>
              </div>
            </section>
            <section className="summary">
              <h2 className="summary-title"> Clients<span> {t('(sur le dernier jour)')} </span></h2>
              <div className="summary-content">
                <div className="summary-item">
                  <h2> {t('Nombre de clients')} </h2>
                  <p> {summaryClientOrganisation.data.counts.total} </p>
                </div>
                <div className="summary-item">
                  <h2> {t('Usage moyen par client')}</h2>
                  <p>
                    {
                      summaryClientOrganisation.data.usage.average / 10000 >= 1
                      ? `${(summaryClientOrganisation.data.usage.average / 1000).toFixed(2)} Go`
                      : `${summaryClientOrganisation.data.usage.average.toFixed(2)} Mo`}{" "}
                  </p>
                </div>
              </div>
            </section>
          </>
        ) : (
          <Loader />
        )
      ) : !summaryClientNetwork.isLoading ? (
        <>
          <section className="summary">
            <h2 className="summary-title">{t('Usage')} <span> {t('(sur le dernier jour)')} </span></h2>
            <div className="summary-content">
              <div className="summary-item">
                <h2> {t('Nombre de clients')} </h2>
                <p> {summaryClientNetwork.data.counts.total} </p>
              </div>
              <div className="summary-item">
                <h2> {t('Usage moyen par client')}</h2>
                <p>
                {summaryClientNetwork.data.usages.average / 1000000 >= 1
                ? `${(summaryClientNetwork.data.usages.average / 1000000).toFixed(2)} Go`
                : `${(summaryClientNetwork.data.usages.average / 1000).toFixed(2)} Mo`}
                </p>
              </div>
            </div>
          </section>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
