import {
  faBarcode,
  faHourglass,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { PaloSchema } from "../../components/Forms/schema/YupSchema";
import { LicenceSchema } from "../../components/Forms/schema/YupSchema";
import Form from "../../components/Forms/Form";
import Update from "../../components/Update";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import RowEquipements from "../Equipements/RowEquipements";
import { useTranslation } from "react-i18next";

export default function RowEquipementPalo({ item, sites }) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  //import context
  const { apiUrl, user } = useContext(AuthContext);

  const [isCisco, setIsCisco] = useState(false);

  // Request to get all licences and set a new array
  const getAllLicences = async () => {

    const res = await axios.get(`${apiUrl}license/palo/`, {
      method: "GET",
      withCredentials: true,
    })
    return res.data;
  };

  const licences = useQuery({
    queryKey: ["licences"],
    queryFn: getAllLicences,
  })


  // Delete certificat
  const deletePalo = async () => {
    const res = await axios(`${apiUrl}eqptpalo/${item.id}/delete`, {
      method: "delete",
      withCredentials: true,
    });
    return res.data;
  };

  const deletingPalo = useMutation({
    mutationFn: deletePalo,
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: ["eqtPalo"] });
    },
  });

  const formContentNewLicense = [
    { id: "nom_license", type: "text", title: "Licence" },
    { id: "auth_code", type: "text", title: "Auth code" },
    { id: "date_fin", type: "date", title: "date d'expiration" },
  ];

  const createNewLicence = async (data) => {
    const updatedData = {
      ...data,
      palo: item.id,
    };

    const res = await axios(`${apiUrl}license/palo/new`, {
      method: "post",
      withCredentials: true,
      data: updatedData,
    })


    return res.data
  };

  const formContentUpdate = [
    {
      id: "serial_number",
      type: "text",
      title: "Numéro de série",
      value: item.serial_number,
    },
    {
      id: "device_name",
      type: "text",
      title: "Nom de l'appareil",
      value: item.device_name,
    },
    { id: "ip", type: "text", title: "Adresse Ip", value: item.ip },
    {
      id: "site",
      type: "select",
      values:
        sites &&
        sites.map((site) => {
          return { value: site.id, label: site.nom };
        }),
      title: "Site",
      value: item.site && item.site.id,
    },
  ];

  const UpdatePalo = async (data) => {
    const res = await axios(`${apiUrl}eqptpalo/${item.id}/edit`, {
      method: "put",
      withCredentials: true,
      data: {
        holding: user.holding,
        entreprise: user.entreprise,
        ...data,
      },
    });
    queryClient.resetQueries({ queryKey: ["eqtPalo"] });
    return res.data;
  };

  const chooseRow = () => {
    if (item.famille && item.famille.constructeur === "CISCO") {
      setIsCisco(true)
    } else if (item.famille && item.famille.constructeur === "PALOALTO") {
      setIsCisco(false)
    }
  }

  useEffect(() => {
    chooseRow()
  }, [])

  return (
    <>
      {!isCisco ? <>
        <div className="palo">
          {!licences.isLoading &&
            licences.data.map((licence, index) => {
              if (item.serial_number === licence.serial_number) {
                return (
                  <div className="palo-licence" key={index}>
                    <div className="palo-item">
                      <span className="palo-title">
                        {t('Licence associée')}
                        <FontAwesomeIcon icon={faTag} className="palo-icon" />
                      </span>

                      <span className="palo-content">{licence.nom_license}</span>
                    </div>
                    <div className="palo-item">
                      <span className="palo-title">
                        {t('Auth code')}
                        <FontAwesomeIcon icon={faBarcode} className="palo-icon" />
                      </span>

                      <span className="palo-content">{licence.auth_code}</span>
                    </div>
                    <div className="palo-item">
                      <span className="palo-title">
                        {t('Expiration')}
                        <FontAwesomeIcon
                          icon={faHourglass}
                          className="palo-icon"
                        />
                      </span>

                      <span className="palo-content">{licence.date_fin}</span>
                    </div>
                  </div>
                );
              }
            })}
        </div>
        <section className="palo-row-content-buttons">
          <Form
            formContent={formContentNewLicense}
            schema={LicenceSchema}
            submitFunction={createNewLicence}
            title="Ajouter une licence"
            action="new"
          />
          <Update
            formContent={formContentUpdate}
            schema={PaloSchema}
            submitFunction={UpdatePalo}
            deleteFunction={deletingPalo.mutate}
          />
        </section>
      </> : <RowEquipements item={item} referrer={'firewall'} />} </>
  );
}
