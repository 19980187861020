import { useContext } from "react";
import Globalview from "../../components/Globalview";
import Loader from "../../components/Loader";
import RowIncidents from "./RowIncidents";
import { Incidentscolumns } from "../../components/headerContent";
import IncidentsActiveFilter from "./IncidentsActiveFilter";
import TableComponent from "../../components/TableComponent";
import { AuthContext } from "../../context/AuthContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import NumberCard from "../../components/Card/NumberCard";
import { useTranslation } from "react-i18next";

export default function TableIncidents({
  incidents,
  handleDisplayAllTickets,
  incidentsFilteredByGraph,
  isChartClicked,
}) {

  // Import context
  const { apiUrl, user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const {t} = useTranslation();

  // Handle activation of incidents
  const [selectedIncidents, setSelectedIncidents] = useState([]);
  const [isActive, setIsActive] = useState(null);
  const [isCheckBox, setIsCheckBox] = useState(false);
  const [incidentsFilteredByActive, setIncidentsFilteredByActive] = useState(
    []
  );
  const [resetValue, setResetValue] = useState(false);

  const filterIncidentsByIsActive = () => {
    let newIncidentsArray;
    newIncidentsArray = !isChartClicked
      ? incidents.data.filter((incident) => {
          return incident.is_active === isActive;
        })
      : incidentsFilteredByGraph.filter((incident) => {
          return incident.is_active === isActive;
        });

    setIncidentsFilteredByActive(newIncidentsArray);
  };

  useEffect(() => {
    !incidents.isLoading && filterIncidentsByIsActive();
  }, [isActive, incidents.data, incidentsFilteredByGraph]);

  // Display incidents by status
  const [incidentsByStatus, setIncidentsByStatus] = useState([]);
  const [valueType, setValueType] = useState("all");
  const activeCounts = {
    "Non résolu": 0,
    Résolu: 0,
  };

  const incidentsFilterByStatus = () => {
    const filterByStatus = incidents.data.filter((item) => {
      switch (true) {
        case valueType === "Résolu":
          return item.statuscode === "5";
          break;
        case valueType === "Non résolu":
          return item.statuscode !== "5";
          break;
      }
    });
    setIncidentsByStatus(filterByStatus);
  };


  useEffect(() => {
    !incidents.isFetching && incidentsFilterByStatus();
  }, [valueType]);

  // function to update active request
  const updateActiveRequest = async () => {
    if (selectedIncidents.length > 0) {
      const promises = selectedIncidents.map((item) => {
        const { date_last_exec, sla, entreprise_id, ...updatedItem } = item;
        const updatedData = {
          ...updatedItem,
          is_active: isActive === "1" ? 0 : 1,
          entreprise: user.entreprise,
        };

        return axios(`${apiUrl}incident/${item.ticketnumber}/edit`, {
          method: "put",
          withCredentials: true,
          data: updatedData,
        });
      });

      const responses = await Promise.all(promises);
      return responses;
    }
  };

  const updateActiveIncidents = useMutation({
    mutationFn: updateActiveRequest,
    onSuccess: () => {
      queryClient.invalidateQueries(["incidents"]);
      setIsActive(null);
      setSelectedIncidents([]);
    },
  });

  useEffect(() => {
    isActive !== null && setIsCheckBox(true);
  }, [isActive]);

  return (
    <>
      <section className="page-section">
        <NumberCard
          title="Tickets"
          number={
            isActive !== null ? incidentsFilteredByActive.length : isChartClicked 
            ? incidentsFilteredByGraph.length : incidentsByStatus.length > 0 
            ? incidentsByStatus.length : incidents.data.length
          }
        />

        {!isChartClicked && (
          <Globalview
            data={incidents.data}
            objectKey="statuscode"
            countObject={activeCounts}
            setValueType={setValueType}
            click
            resetValue={resetValue} 
            setResetValue={setResetValue}
          />
        )}

        {valueType !== "all" && (
          <button
            className="button button-new"
            onClick={() => {
              setValueType("all"), 
              setResetValue(true);
            }}
          >
            {" "}
            {t('Réinitialiser')}
          </button>
        )}
        {!isChartClicked && user.roles.includes("ROLE_SUPERADMIN") && (
          <IncidentsActiveFilter
            setIsActive={setIsActive}
            updateActiveIncidents={updateActiveIncidents}
            isActive={isActive}
            selectedIncidents={selectedIncidents}
          />
        )}
      </section>
      {!updateActiveIncidents.isLoading ? (
        <>
          <div className="table-leftbtn">
            <button
              className="button button-new"
              onClick={() => {
                handleDisplayAllTickets();
              }}
            >
              {t('Retour au graphique')}
            </button>
          </div>
          <TableComponent
            tableData={
              isActive !== null
                ? incidentsFilteredByActive
                : isChartClicked
                ? incidentsFilteredByGraph
                : incidentsByStatus.length > 0
                ? incidentsByStatus
                : incidents.data
            }
            tableColumns={Incidentscolumns}
            rowComponent={RowIncidents}
            checkbox={isCheckBox}
            selectedIncidents={selectedIncidents}
            setSelectedIncidents={setSelectedIncidents}
          />
        </>
      ) : (
        <Loader />
      )}{" "}
    </>
  );
}
