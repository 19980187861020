import React from "react";
import TypeCallQueue from "./Type/TypeCallQueue";
import TypePostCallQueue from "./Type/TypePostCallQueue";
import Loader from "../../../../../components/Loader";

export default function TypeGraphWebexRapportCallQueue({ data, allCalls, callQueue, people, reportType, analyseType, setDataGraph, setFormattedHeader  }) {  
    return (
        <div className="statistics-report">
            {data && reportType ? (
                reportType === "Analyse par groupement" ? (
                    <TypeCallQueue data={data} allCalls={allCalls} callQueue={callQueue} setDataGraph={setDataGraph} setFormattedHeader={setFormattedHeader}/>
                ): reportType === "Analyse des postes au sein des groupements" && (
                    <TypePostCallQueue data={data} callQueue={callQueue} people={people} analyseType={analyseType} setDataGraph={setDataGraph} setFormattedHeader={setFormattedHeader}/>
                )
            ) : (
                <Loader/>
            )}
            
        </div>
    ) 
}