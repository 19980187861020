import { faCircleCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Loader from "../Loader";
import MultipleForm from "./MultipleForm";
import { useTranslation } from "react-i18next";

/**
 *
 * @param {function} submitFunction - function to send when the form is submit
 * @param {array} formContent - content of the form to display inputs and labels
 * @param {object} schema - Yup schema to control form
 * @param {string} title - Title of form button submit
 * @param {string} action - new/update/delete to change class of the submit btn and opening form button
 * @param {function} setHoldingSelected - to update holding and get entreprise in header form
 */
export default function Form({
  submitFunction,
  formContent,
  schema,
  title,
  action,
  setHoldingSelected,
  notConfirmMessage,
  stateQueryRow
}) {
  const queryClient = useQueryClient();
  const {t} = useTranslation();

  // Stock form from props
  const [formData, setFormData] = useState([]);

  // Handle when form is open
  const [formOpen, setFormOpen] = useState(false);

  // Confirmation message
  const [createdMessage, setCreatedMessage] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  if(stateQueryRow !== undefined) {
    notConfirmMessage = true;
  }

  useEffect(() => {
    setFormData(formContent);
  }, [formContent]);

  // control form
  const { register, handleSubmit, formState, reset } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // handle errors
  const { errors } = formState;
  const onInvalid = (errors) => {
    for (let key in errors) {
      setErrorMessage(key.message);
    }
  };

  const { mutate, isSuccess, isError, isLoading, error } = useMutation({
    mutationFn: (data) => submitFunction(data),
    onSuccess: () => {
      queryClient.invalidateQueries();
      setCreatedMessage(action === "new" ? "Créé" : "Modifié");
      {notConfirmMessage && 
        handleFormClose();
      }
    },
  });

  const handleFormClose = () => {
    setFormOpen(false);
    setCreatedMessage("");
    if (setHoldingSelected !== undefined) {
      setHoldingSelected(null);
    }
    reset();
  };

  if(schema._nodes[0] === "entreprise") {
    return (
      <div className="menu-dropdown">
        <button
          className={`button button-${action}`}
          onClick={() => {
            setFormOpen(true);
          }}
        >
          {t(title)}
        </button>
        {isLoading ? (
          <div className={`dialog-form ${formOpen && "dialog-form_open"}`}>
            <Loader />
          </div>
        ) : (
        <div className={`dialog-form ${formOpen && "dialog-form_open"}`}>
          <button
            className="button button-delete dialog-form_close"
            onClick={handleFormClose}
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
          {createdMessage ? (
            <div className="dialog-form_success">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="dialog-form_success-icon"
              />
              <h2>{t(createdMessage)}</h2>
            </div>
          ) : (
            <MultipleForm
              handleSubmit={handleSubmit}
              mutate={mutate}
              onInvalid={onInvalid}
              register={register}
              formData={formData}
              isError={isError}
              errors={errors}
              error={error}
              action={action}
            />
          )}
        </div>
        )}
      </div>
    )
  } else {
    return (
      <>
        {isLoading ? (
          <div className={`dialog-form ${formOpen && "dialog-form_open"}`}>
            <Loader />
          </div>
        ) : (
        <div className={`dialog-form ${formOpen && "dialog-form_open"}`}>
          <button
            className="button button-delete dialog-form_close"
            onClick={handleFormClose}
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
          {createdMessage ? (
            <div className="dialog-form_success">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="dialog-form_success-icon"
              />
              <h2>{t(createdMessage)}</h2>
            </div>
          ) : (
            <MultipleForm
              handleSubmit={handleSubmit}
              mutate={mutate}
              onInvalid={onInvalid}
              register={register}
              formData={formData}
              isError={isError}
              errors={errors}
              error={error}
              action={action}
            />
          )}
        </div>
        )}
        <button
          className={`button button-${action}`}
          onClick={() => {
            setFormOpen(true);
          }}
        >
          {t(title)}
        </button>
      </>
    );
  }
}
