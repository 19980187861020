import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import { SubstitutionSchema } from "./Forms/schema/YupSchema";
import Form from "./Forms/Form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FiLogOut, FiUser, FiUsers } from "react-icons/fi";
import { GiEarthAmerica } from "react-icons/gi";
import { useCookies } from 'react-cookie';
import i18n from "../i18n";

export default function Header() {
  const { user, apiUrl, entrepriseRoles } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [userHolding, setUserHolding] = useState({});
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const [, , removeCookie] = useCookies(['isLogin']);
  const navigate = useNavigate();

  // Query to get all holdings
  const holding = useQuery({
    queryKey: ["holding"],
    queryFn: async () => {
      if (user) {
        try {
          const res = await axios(`${apiUrl}commerce/holding`, {
            method: "get",
            withCredentials: true,
          });
          return res.data;
        } catch (error) {
        }
      }
    },
    enabled: user ? true : false,
  });

  useEffect(() => {
    if (user.roles.includes("ROLE_SUPERADMIN") || user.roles.includes("ROLE_COMMERCIAL")) {
      const holdingFiltered = holding.data && holding.data.find((holding) => holding.id === user.holding);
      setUserHolding(holdingFiltered);
    }
  }, [holding.data, user.holding, user.roles]);

  // Query to get enterprises
  const entreprise = useQuery({
    queryKey: ["entreprisesHeader"],
    queryFn: async () => {
      if (userHolding && userHolding.id) {
        try {
          const res = await axios(`${apiUrl}commerce/${userHolding.id}/entreprise`, {
            method: "get",
            withCredentials: true,
          });
          return res.data;
        } catch (error) {
        }
      }
      return [];
    },
  });

  // refetch entreprise when we have the data
  useEffect(() => {
    if (user && entreprise.data !== undefined && entreprise !== undefined) {
      entreprise.refetch();
    }
  }, [user, entreprise]);

  // get all entreprise
  const userEntreprise = useQuery({
    queryKey: ["userEntreprise"],
    queryFn: async () => {
      if (holding.isFetched && holding.data.length > 0) {
        const userEntrepriseData = entreprise.data.find((entreprise) => entreprise.id === user.entreprise);
        if(userEntrepriseData){
          return userEntrepriseData
        }else {
          return { nom: userEntreprise.data.nom }
        }
      } else {
        return { nom: "Loading..." };
      }
    },
    enabled: true,
    onError: (error) => {
      console.error("Error during userEntreprise query:", error);
    },
  });

  useEffect(() => {
    if (holding.data !== undefined && userHolding !== undefined && userEntreprise !== undefined) {
      userEntreprise.refetch();
    }
  }, [userEntreprise, userEntreprise.data, holding.data, userHolding]);

  // update entreprise request
  const updateUserHolding = async (data) => {
    try {
      const res = await axios(`${apiUrl}commerce`, {
        method: "put",
        withCredentials: true,
        data: data,
      });

      userEntreprise.data.nom = "Loading...";

      queryClient.invalidateQueries(["sites"]);
      entrepriseRoles.refetch();

      navigate("/");
      return res.data;
    } catch (error) {
    }
  };

  // form for change entreprise
  const formContent = [
    {
      id: "holding",
      type: "select",
      values: holding.data
        ? [
          { value: "", label: " - Sélectionnez une holding - " },
          ...holding.data.map((holding) => ({
            value: holding.id,
            label: holding.nom,
          })),
        ].sort((a, b) => a.label.localeCompare(b.label))
        : [],
      title: "Holding",
      change: async (e) => {
        await setUserHolding({id: e.target.value});
      },
    },
    {
      id: "entreprise",
      type: "select",
      values: entreprise.data
        ? [
          { value: "", label: " - Sélectionnez une entreprise - " },
          ...entreprise.data.map((entreprise) => ({
            value: entreprise.id,
            label: entreprise.nom,
          })),
        ].sort((a, b) => a.label.localeCompare(b.label))
        : [],
      title: "Entreprise",
    },
  ];

  // Mutation for logout
  const logout = useMutation({
    mutationFn: async () => {
      try {
        const res = await axios(`${apiUrl}api/logout`, {
          method: "post",
          withCredentials: true,
        });
        removeCookie('isLogin');
        return res.data;
      } catch (error) {
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["user"]);
      window.location = "/";
    },
  });

  useEffect(() => {
    if (window.localStorage.getItem("langue") === null) {
      setCurrentLanguage("fr");
    } else {
      i18n.changeLanguage(window.localStorage.getItem("langue"));
      setCurrentLanguage(window.localStorage.getItem("langue"));
    }
  }, []);

  const handleLanguage = () => {
    if (i18n.language === "fr") {
      i18n.changeLanguage("en");
      setCurrentLanguage("en");
      window.localStorage.setItem("langue", "en");
    } else {
      i18n.changeLanguage("fr");
      setCurrentLanguage("fr");
      window.localStorage.setItem("langue", "fr");
    }
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="header-logo">
          <Link to={"/"}>
            <img src="/elitlogo.png" alt="Logo d'elit-technologies" />
          </Link>
        </div>

        {user ? (
          <ul className="header-nav">
            {(user && user.roles.includes("ROLE_SUPERADMIN")) ||
              user.roles.includes("ROLE_COMMERCIAL") ? (
              <Form
                formContent={formContent}
                schema={SubstitutionSchema}
                submitFunction={updateUserHolding}
                notConfirmMessage={true}
                title={userEntreprise.isLoading ? "Loading..." : userEntreprise.data.nom}
                action="update"
                holding={!holding.isLoading ? holding.data : []}
              />
            ) : null}
            {user && (
              <li className="header-nav_item">
                <NavLink className="button button-icon" to={"/users"}>
                  {user.roles.includes("ROLE_SUPERADMIN") ? (
                    <FiUsers />
                  ) : (
                    <FiUser />
                  )}
                </NavLink>
              </li>
            )}

            <li>
              <button className="button button-icon" onClick={handleLanguage}>
                <GiEarthAmerica /> {currentLanguage}
              </button>
            </li>
            <li className="header-nav_item">
              <button
                className="button button-icon"
                aria-label="déconnexion"
                onClick={logout.mutate}
              >
                <FiLogOut />
              </button>
            </li>
          </ul>
        ) : (
          <h2>Connectez-vous</h2>
        )}
      </div>
    </header>
  );
}