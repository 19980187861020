import axios from "axios";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { EquipementSchema } from "../../components/Forms/schema/YupSchema";
import { useNavigate } from "react-router-dom";
import Update from "../../components/Update";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Loader from "../../components/Loader";
import {
  faClockRotateLeft,
  faHandHolding,
  faHourglassEnd,
  faQuestionCircle,
  faShield,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import IconsStatus from "../../components/IconStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export default function RowEquipements({ item, sites, referrer }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  //import context
  const { apiUrl, user } = useContext(AuthContext);

  const getContract = async () => {
    const res = await axios.get(`${apiUrl}contract/byserial`, {
      withCredentials: true,
    });

    if (res.data === "No data") return [res.data];

    return res.data.map((contract) => {
      const allSerial = contract.serial_numbers.split(",");
      return { ...contract, serial_numbers: allSerial }
    });
  };

  const contract = useQuery({
    queryKey: ["contract"],
    queryFn: getContract,
  });


  //Update
  const updateEquipment = () => {
    setIsLoading(true);
    axios(`${apiUrl}equipement/updateAPI/${item.serial_number}`, {
      method: "get",
      withCredentials: true,
    })
      .then((res) => {
        queryClient.refetchQueries({ queryKey: ["equipements"] });
      })
      .catch(err => { })
      .finally(() => setIsLoading(false));
  };

  // Delete
  const deleteEquipement = async () => {
    const res = await axios(`${apiUrl}equipement/${item.id}/delete`, {
      method: "delete",
      withCredentials: true,
    });
    return res.data;
  };

  const deletingEqt = useMutation({
    mutationFn: deleteEquipement,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["equipements"] });
    },
  });

  // Content to create new equipement
  const formContentUpdate = [
    {
      id: "serial_number",
      type: "text",
      title: "Numéro de série",
      value: item.serial_number && item.serial_number,
    },
    { id: "ip", type: "text", title: "IP", value: item.ip && item.ip },
    {
      id: "device_name",
      type: "text",
      title: "Nom de l'appareil",
      value: item.device_name && item.device_name,
    },
    {
      id: "software_version",
      type: "text",
      title: "Version du logiciel",
      value: item.software_version && item.software_version,
    },
    { id: "BU", type: "text", title: "BU", value: item.bu && item.bu },
    {
      id: "site",
      type: "select",
      values:
        sites &&
        sites.map((site) => {
          return { value: site.id, label: site.nom };
        }),
      title: "Site",
      value: item.site && item.site.id || item.site_id,
    },
  ];

  const updateEquipement = async (data) => {
    // Update the data object with converted date strings
    const updatedData = {
      ...data,
      holding: user.holding,
      entreprise: user.entreprise,
      is_active: 1,
      sn_valid: 1,
    };
    const res = await axios(`${apiUrl}equipement/${item.id}/edit`, {
      method: "put",
      withCredentials: true,
      data: updatedData,
    });

    return res.data;
  }

  const handleClickEquipement = (to) => {
      navigate(to);
  }

  if (contract.data && contract.data.includes("No data")) {
    return <div className="table-update" key={item.id}>
      <Update
        schema={EquipementSchema}
        submitFunction={updateEquipement}
        deleteFunction={deletingEqt.mutate}
        updateEquipment={updateEquipment}
        formContent={formContentUpdate}
      />
    </div>
  }

  return (
    <>
      {!isLoading && !contract.isLoading ? (
        <>
          {contract.data.map((contract, index) => {
            if (contract.serial_numbers.includes(item.serial_number)) {
              return <div className="contract" key={index}>
                <div className="contract-header">
                  <span className="contract-title">
                    <FontAwesomeIcon
                      icon={faTag}
                      className="contract-icon"
                    />
                    {t('Numéro du contrat')}
                  </span>
                  <span className="contract-title">
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className="contract-icon"
                    />
                    {t('Status')}
                  </span>
                  <span className="contract-title">
                    <FontAwesomeIcon
                      icon={faClockRotateLeft}
                      className="contract-icon"
                    />
                    {t('Début du contrat')}
                  </span>
                  <span className="contract-title">
                    <FontAwesomeIcon
                      icon={faHourglassEnd}
                      className="contract-icon"
                    />
                    {t('Fin du contrat')}
                  </span>
                  <span className="contract-title">
                    <FontAwesomeIcon
                      icon={faShield}
                      className="contract-icon"
                    />
                    {t('Garantie constructeur')}
                  </span>
                  <span className="contract-title">
                    <FontAwesomeIcon
                      icon={faHandHolding}
                      className="contract-icon"
                    />
                    {t('Niveau de services')}
                  </span>
                </div>
                <div className="contract-body">
                  {!contract.contract_number.includes("Contract") ? 
                  <>
                    <a className="contract-content equipement-serial_number-contract" id={`${contract.contract_number}`} onClick={() => handleClickEquipement(`/smartnet?contractId=${contract.contract_number}&referrer=${referrer}`)}>
                      {contract.contract_number}
                    </a>
                    <span className="contract-content">
                      <IconsStatus value={contract.contract_lineStatus} />
                    </span>
                    <span className="contract-content">
                      {contract.startDate}
                    </span>
                    <span className="contract-content">
                      <IconsStatus value={contract.endDate} color={new Date(contract.endDate) > new Date() ? "green" : "red"} />
                    </span>
                    <span className="contract-content">
                      {contract.warrantyEndDate}
                    </span>
                    <span className="contract-content">
                      {contract.serviceDescription}
                    </span>
                  </> : <span className="contract-content_error"> {t('Contrat signé avec un autre partenaire ou sans contract')}</span>}
                </div>
              </div>
            }
          })
          }
          <div className="table-update" key={item.id}>
            <Update
              schema={EquipementSchema}
              submitFunction={updateEquipement}
              deleteFunction={deletingEqt.mutate}
              updateEquipment={updateEquipment}
              formContent={formContentUpdate}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
