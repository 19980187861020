import {
  faCalendar,
  faDisplay,
  faHouseUser,
  faMicrophone,
  faPeopleRoof,
  faPhoneVolume,
  faRotate,
  faSignal,
  faUserGroup,
  faVideo,
  faVolumeHigh,
} from "@fortawesome/free-solid-svg-icons";
import { faChromecast, faGoogle } from "@fortawesome/free-brands-svg-icons";
import {
  AddToQueue,
  Air,
  AirplayRounded,
  ConnectedTv,
  IntegrationInstructions,
  Output,
  RecordVoiceOver,
  ScreenShare,
  SettingsInputHdmi,
  Share,
  Thermostat,
  WaterDamage,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import WebexCategorie from "./WebexCategorie";

/**
 * 
 * @param {object} data - device object which contain every status 
 */
export default function WebexData({ data }) {

  // Multiple status data
  const [micros, setMicros] = useState([]);
  const [cameras, setCameras] = useState([]);
  const [peripherals, setPeripherals] = useState([]);
  const [videoInputConnector, setvideoInputConnector] = useState([]);
  const [videoSignal, setVideoSignal] = useState([]);
  const [videoOutputConnector, setvideoOutputConnector] = useState([]);
  const [uptime, setUptime] = useState(null);

   /**
   * 
   * @returns a time in days
   */
  const convertUptime = (data) => {
    const seconds = Number(data.systemUptime);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(seconds / 3600)
    const days = Math.floor(seconds / (3600 * 24));
    const dayDisplay = days > 0 ? days + (days == 1 ? " day " : " days ") : hours > 0 ? hours + "h" : minutes > 0 ? minutes + "mn" : seconds + "s";
    return dayDisplay
  };


  // sort data to display categories
  const webexStatus = {

    resume: [
      {value: data.rooms, title: "Personnes présentes", multiple: false, number: true, iconType: "fontAwesome", icon: faHouseUser},
      {value: data.booking, title: "Réservation", multiple: false, number: false, iconType: "fontAwesome", icon: faCalendar},
      {value: data.calls, title: "Appels actifs", multiple: false, number: true, iconType: "fontAwesome", icon: faPhoneVolume},

    ],
    environnement: [
      {value: data.roomsCapacity, title: "Capacité de la pièce", multiple: false, number: true, iconType: "fontAwesome", icon: faPeopleRoof},
      {value: data.rooms, title: "Personnes présentes", multiple: false, number: true, iconType: "fontAwesome", icon: faHouseUser},
      {value: data.ambientNoise, title: "Niveau de son ambiant", metrics: "dbA", multiple: false, number: true, iconType: "Mui", icon: <RecordVoiceOver />},
      {value: data.airQuality, title: "Qualité de l'air", multiple: false, number: true, iconType: "Mui", icon: <Air />},
      {value: data.relativeHumidity, title: "Humidité relative", metrics: "%", multiple: false, number: true, iconType: "Mui", icon: <WaterDamage />},
      {value: data.ambientTemperature, title: "Température ambiante", metrics: "°C", multiple: false, number: true, iconType: "Mui", icon: <Thermostat />},
    ],

    systemState: [
      {value: micros, title: "Microphones", multiple: true, number: false, iconType: "fontAwesome", icon: faMicrophone},
      {value: cameras, title: "Cameras", multiple: true, number: false, iconType: "fontAwesome", icon: faVideo},
      {value: peripherals, title: "Péripheriques", multiple: true, number: false, iconType: "Mui", icon: <AddToQueue />},
      {value: videoSignal, title: "Etat du signal vidéo", multiple: true, number: false, iconType: "fontAwesome", icon: faSignal},
      {value: data.cameraSpeakerTrack, title: "Suivi voix caméra", multiple: false, number: false, iconType: "fontAwesome", icon: faVolumeHigh},

    ],
    informations: [
      {value: data.videoMonitors, title: "Moniteurs vidéo", multiple: false, number: true, iconType: "fontAwesome", icon: faDisplay},
      {value: videoSignal, title: "Etat du signal vidéo", multiple: true, number: false, iconType: "fontAwesome", icon: faSignal},
      {value: uptime, title: "Dernier redémarage", multiple: false, number: true, iconType: "fontAwesome", icon: faRotate},

    ],
    inputOutput: [
      {value: videoInputConnector, title: "Connecteur d'entrée vidéo", multiple: true, number: false, iconType: "Mui", icon: <SettingsInputHdmi />},
      {value: videoOutputConnector, title: "Connecteur de sortie vidéo", multiple: true, number: false, iconType: "Mui", icon: <Output />},

    ],
    services: [
    {value: data.google, title: "Google Meet", multiple: false, number: false, iconType: "fontAwesome", icon: faGoogle},
    {value: data.miracast, title: "Miracast", multiple: false, number: false, iconType: "fontAwesome", icon: faChromecast},
    {value: data.airplay, title: "Airplay", multiple: false, number: false, iconType: "Mui", icon: <AirplayRounded />},
    {value: data.teams, title: "Teams", multiple: false, number: false, iconType: "fontAwesome", icon: faUserGroup},
    {value: data.webEngine, title: "Moteur web", multiple: false, number: false, iconType: "Mui", icon: <IntegrationInstructions />},
    {value: data.webEngineSignage, title: "Affichage Dynamique", multiple: false, number: false, iconType: "Mui", icon: <ConnectedTv />},
    {value: data.webRtc, title: "WebRTC", multiple: false, number: false, iconType: "Mui", icon: <Share />},
    {value: data.webexToken, title: "Webex Token", multiple: false, number: true, iconType: "Mui", icon: <ScreenShare />},
    ]
  }


  /**
   * Create a carousel with multiple status
   * @param {array} array - webex array response
   * @param {string} keyStatus - key for display value in page
   * @param {string} name - Name raccorded to the value
   * @param {function} set - useState set fonction to stock data
   */
  const statusMultiple = (array, keyStatus, name, set) => {
    if (!data.error && Array.isArray(array)) {
      const isStatus = array.filter((el) => el[keyStatus]);
      const newArray = isStatus.map((element) => ({
        name: element[name],
        status: element[keyStatus],
      }));
      set(newArray);
    } else {
      set([]);
    }
  };

  useEffect(() => {
    setUptime(convertUptime(data));
  }, [data]);

  useEffect(() => {
    statusMultiple(data.micro, "ConnectionStatus", "", setMicros);
    statusMultiple(data.camera, "Connected", "Model", setCameras);
    statusMultiple(data.peripherals, "Status", "Name", setPeripherals);
    statusMultiple(data.videoInputConnector, "Connected", "Type", setvideoInputConnector );
    statusMultiple(data.videoInputConnector, "SignalState", "Type", setVideoSignal );
    statusMultiple(data.videoOuputConnector, "Connected", "MonitorRole", setvideoOutputConnector);
  }, []);


  


  return (
    <>
      <h3 className="table-accordion_title"> Statuts de {data.name}</h3>

      {!data.error ? (
            <>
            <div className="webex-container">
            <WebexCategorie array={webexStatus.resume} categorie="Résumé" />
            <WebexCategorie array={webexStatus.systemState} categorie="Etat du système" />
            </div>
            <div className="webex-container">
            </div>
            <div className="webex-container">
            <WebexCategorie array={webexStatus.inputOutput} categorie="Entrées/Sorties" />
            <WebexCategorie array={webexStatus.environnement} categorie="Environnement" />
            </div>
            <div className="webex-resume">
            <WebexCategorie array={webexStatus.services} categorie="Services" />
            </div>
            </>
      ) : (
        <div className="table-accordion_data-error"> {data.error}</div>
      )}
    </>
  );
}
