import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { AuthContext } from "../../context/AuthContext";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useCookies } from 'react-cookie';
import i18n from "../../i18n";

export default function Login() {
  const { apiUrl, refetch } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [loginStatus, setLoginStatus] = useState(null);
  const [cookies, setCookie] = useCookies(['isLogin']);
  const {t} = useTranslation();

  useEffect(() => {
    const currentLanguage = window.localStorage.getItem("langue");

    if(currentLanguage === null) i18n.changeLanguage("fr")

    if(currentLanguage === "en") {
      i18n.changeLanguage("en")
    } else {
      i18n.changeLanguage("fr")
    }
  }, [])

  const ValidationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Merci de saisir votre email")
      .email("Merci de saisir un email valide"),

    password: Yup.string().required("Merci de saisir votre mot de passe"),
  });

  const { register, handleSubmit, formState : {errors}, setError } = useForm({
    mode: "onBlur",
    resolver: yupResolver(ValidationSchema),
  });

  // const getWebexCallingHistory = async() => {
  //   try {
  //     const res = await axios.get(`${apiUrl}webex/historyCall`, {
  //       withCredentials: true,
  //     });
  //     return res.data;
  //   } catch(err) {
  //   }
  // };

  // const webexCallingHistory = useQuery({
  //   queryKey: ["webexCallingHistory"],
  //   queryFn: () => getWebexCallingHistory(),
  //   enabled: false 
  // });

  const loginFn = async (data) => {
    try {
      const res = await axios({
        method: "post",
        url: `${apiUrl}api/login_check`,
        data: data,
        withCredentials: true,
      });
      refetch();
      setCookie('isLogin', true, { maxAge: 3600, secure: true });
      setLoginStatus(null);
      return res.data;
    }catch (error) {
      setLoginStatus(error);
    }
  };

  const login = useMutation({
    mutationFn: (data) => loginFn(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["user"]);
      // webexCallingHistory.refetch();
    }
  });

  const handleInputChange = () => {
    setLoginStatus(null);
  };

  return (
    <>
      <article className="page page-login">
        <h1 className="page-login_title">Elit customer Portal</h1>
        <form
          className="form form-login"
          onSubmit={handleSubmit(login.mutate)}
        >
          <div className="form-input_container" onChange={handleInputChange}>
            <label htmlFor="email">E-mail</label>
            <input type="email" id="email" {...register("username")}/>
            {errors.username && (
              <small className="error">{t(errors.username?.message)}</small>
            )}
          </div>
          <div className="form-input_container" onChange={handleInputChange}>
            <label htmlFor="password">{t("Mot de passe")}</label>
            <input type="password" id="password" {...register("password")} autoComplete="on"/>
            {errors.password && (
              <small className="error">{t(errors.password?.message)}</small>
            )}
          </div>
          <button type="submit" className="button button-new">
            {t("Connexion")}
          </button>
          {loginStatus !== null ? (
            <small className="error">{t(`Identifiants invalides`)}</small>
          ): null}
        </form>
        <img src="/assets/elitlogo.jpg" alt="Logo d'elit" />
      </article>
    </>
  );
}
