import React from "react";
import { useNavigate } from "react-router-dom";

export default function RowTeamAgentInsight({ item }) {
    const navigate = useNavigate();

    const handleClickEquipement = (agentId) => {
        navigate(`/agentinsight?agentId=${agentId}`)
    }

    if(item.agents.length === 0) {
        return(
            <p className="error-message-agents-ingight">Aucune données disponible</p>
        )
    }
   
    return (
        <article>
            {item.agents.map((item, index) => (
                <div key={index} className="agent-insight-content">
                    <div className="agent-insight-content-row">
                        <a className="agent-insight-content-row-name" onClick={() => handleClickEquipement(item.agent_id)}>{item.agent_name}</a>
                    </div>
                </div>
            ))}
        </article>
    )
}