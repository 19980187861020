import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import CarousselNav from "../../components/Card/CarousselNav";
import MicrosoftMessage from "./MicrosoftMessage";
import MicrosoftOverview from "./MicrosoftOverview";
import ArrowSphereAll from "./ArrowSphereAll";
import ArrowSphereClient from "./ArrowSphereClient";
import ButtonArrow from "../../components/ButtonArrow";

export default function Microsoft() {
    const { user } = useContext(AuthContext);
    const [currentSlide, setCurrentSlide] = useState(0);
    const valuesType = ["Santé des services", "Centre de message", "Licenses Client", user.roles.includes("ROLE_SUPERADMIN") ? "Licenses All" : ''];
    // const valuesType = ["Santé des services", "Centre de message"];

    return (
        <article className="page">
            <section className="page-section">
                <div className="page-logo">
                    <img src="./assets/microsoft.png" alt="Logo de Microsoft" />
                    <h1 className="page-title">Microsoft</h1>
                    <ButtonArrow url={"https://portal.office.com/"} />
                </div>
                <CarousselNav arrayValues={valuesType} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} />
            </section>
            {currentSlide === 0 && <MicrosoftOverview />}
            {currentSlide === 1 && <MicrosoftMessage />}
            {currentSlide === 2 && <ArrowSphereClient />}
            {user.roles.includes("ROLE_SUPERADMIN") && currentSlide === 3 && (
                <ArrowSphereAll />
            )}
        </article>
    )
}