import React from "react";
import { ArrowRightAltRounded } from "@mui/icons-material";
import Loader from "./Loader";

export default function StatisticsNumber({ title, secondTitle, status, firstData, secondData, pourcentage, mesure }) {
    const formatData = (data, mesure) => {
        if (mesure === "K" && data > 1000) {
            return (data / 1000).toFixed(1) + mesure;
        } else {
            return data;
        }
    };
 
    return (
        <>
            {firstData !== null ? (
                <div className="statistics-number">
                    <div className="statistics-number-title">
                        <h2>{title}</h2>
                    </div>
                    <div className="statistics-number-total">
                        <p>{formatData(firstData, mesure)}{mesure !== 'K' ? mesure : ''}</p>
                        <div className={status === 'stable' && pourcentage !== "--" ? 'stable' : status === 'baisse' ? 'baisse' : status === 'hausse' ? 'hausse' : ''}>
                            {pourcentage !== "--" && (
                                <span >
                                    <ArrowRightAltRounded />
                                </span>
                            )}
                            {pourcentage}{pourcentage !== "--" && (<>%</>)}
                        </div>
                    </div>
                    <div className="statistics-number-description">
                        <p>{secondTitle}{formatData(secondData, mesure)}{mesure !== 'K' && secondData ? mesure : ''}</p>
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </>
    )
}