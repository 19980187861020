import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import Loader from "../../components/Loader";
import {
  MerakiClientscolumns,
  MerakiDevicecolumns,
} from "../../components/headerContent";
import { useQuery } from "@tanstack/react-query";
import TableComponent from "../../components/TableComponent";
import { useTranslation } from "react-i18next";

export default function MerakiClients({ networkId }) {
  const { apiUrl, user } = useContext(AuthContext);
  const {t} = useTranslation();

  const getClients = async () => {
    const res = await axios.get(`${apiUrl}meraki/clients/${networkId}`, {
      withCredentials: true,
    });
    if(res.data.errors) {
      return ["No data"]
    } else {
      return res.data
    }
  };

  const clients = useQuery({
    queryKey: ["clients"],
    queryFn: getClients,
  });


  return (
    <>
      {!clients.isLoading ? (
        <>
            {clients.data.includes("No data") ? (
              <h1 className="page-title"> {('Pas de clients')} </h1>
            ) : (
              <TableComponent
                tableColumns={MerakiClientscolumns}
                tableData={clients.isFetched ? clients.data : []}
                checkbox={false}
                isExpanded={false}
              />
            )}{" "}
        </>
      ) : (
        <Loader />
      )}{" "}
    </>
  );
}
