import { faApple, faWindows } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableCell, TableRow } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import MultipleStatus from "../../components/MultipleStatus";
import IconsStatus from "../../components/IconStatus";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { useQueryClient } from "@tanstack/react-query";

export default function RowPoly({item}) {

    const {apiUrl} = useContext(AuthContext)
    const queryClient = useQueryClient();

    const handleReboot = () => {
        axios(`${apiUrl}poly/${item.id}`, {
            method: "get", 
            withCredentials: true,
        })
        .then(res => {
            queryClient.invalidateQueries("polycom")
        })
        .catch(err => {})
    }

    return (
        <>
        <div className="table-update">
            <button className="button button-delete" onClick={handleReboot}>reboot</button>
        </div>
        </>
    )
}