import { useTranslation } from "react-i18next";

export function convertMinutesToHoursAndMinutes(minutesString) {
  // Convert the string to a number
  const minutes = parseInt(minutesString, 10);

  // Calculate the hours and minutes
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  // Construct a string representing the result
  const resultString = `${hours} H ${remainingMinutes} mn`;

  // Return the result
  return resultString;
}


// Converting date
export const convertDate = (date, setDate) => {

  if (date !== null) {
    const convertDate = new Date(date);
    const day = convertDate.getDate().toString().padStart(2, "0");
    const month = (convertDate.getMonth() + 1).toString().padStart(2, "0");
    const year = convertDate.getFullYear().toString();

    const frExpiration = `${day}/${month}/${year}`
    setDate(frExpiration)
  } else {
    setDate("")
  }
}

export const convertDateFormat = (date) => {

  if (date !== null) {
    const convertDate = new Date(date);
    const day = convertDate.getDate().toString().padStart(2, "0");
    const month = (convertDate.getMonth() + 1).toString().padStart(2, "0");
    const year = convertDate.getFullYear().toString();

    const frExpiration = `${day}/${month}/${year}`
    return frExpiration;
  } else {
    return "";
  }
}

// Converting date
export const convertDateAndReturn = (date) => {

  if (date !== null) {
    const convertDate = new Date(date);
    const day = convertDate.getDate().toString().padStart(2, "0");
    const month = (convertDate.getMonth() + 1).toString().padStart(2, "0");
    const year = convertDate.getFullYear().toString();

    const frExpiration = `${year}-${month}-${day}`
    return frExpiration
  } else {
    return ""
  }
}


export const convertDateHoursMn = (date, minutes = null) => {

  if (date !== null) {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hour = String(inputDate.getHours()).padStart(2, "0");
    const minute = String(inputDate.getMinutes()).padStart(2, "0");
    const second = String(inputDate.getSeconds()).padStart(2, "0");
    const outputDate = minutes
      ? `${year}-${month}-${day} ${hour}:${minute}:${second}`
      : `${year}-${month}-${day}`;
    return outputDate;
  } else {
    return null;
  }

};

export const convertDateHoursMnSec = (date) => {

  if (date !== null) {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hour = String(inputDate.getHours()).padStart(2, "0");
    const minute = String(inputDate.getMinutes()).padStart(2, "0");
    const second = String(inputDate.getSeconds()).padStart(2, "0");
    const outputDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    return outputDate;
  } else {
    return null;
  }

};

export function convertTimestamp(timestamp) {
  const date = new Date(timestamp);

  const jour = date.getDate().toString().padStart(2, '0');
  const mois = (date.getMonth() + 1).toString().padStart(2, '0');
  const annee = date.getFullYear().toString();
  const heure = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  const seconde = date.getSeconds().toString().padStart(2, '0');

  const format = `${jour}-${mois}-${annee} ${heure}:${minute}:${seconde}`;

  return format;
}

export const elpasedTime = (date, setDate) => {
  const { t } = useTranslation();
  if (date !== null) {
    const expirationDate = new Date(date);
    const now = new Date();
    const timeDiff = now.getTime() - expirationDate.getTime();

    if (timeDiff < 60 * 1000) { // less than 1 minute
      setDate("Now");
    } else if (timeDiff < 60 * 60 * 1000) { // less than 1 hour
      const minutesDiff = Math.floor(timeDiff / (60 * 1000));
      setDate(`${minutesDiff}mn`);
    } else if (timeDiff < 24 * 60 * 60 * 1000) { // less than 1 day
      const hoursDiff = Math.floor(timeDiff / (60 * 60 * 1000));
      const minutesDiff = Math.floor((timeDiff % (60 * 60 * 1000)) / (60 * 1000));
      setDate(`${hoursDiff}H${minutesDiff}mn`);
    } else { // more than 1 day
      const daysDiff = Math.floor(timeDiff / (24 * 60 * 60 * 1000));
      setDate(`${daysDiff}${t('j')}`);
    }
  } else {
    setDate("");
  }
};

export const elpasedTimeAndReturn = (date) => {
  const { t } = useTranslation();
  if (date !== null) {
    const expirationDate = new Date(date);
    const now = new Date();
    const timeDiff = now.getTime() - expirationDate.getTime();

    if (timeDiff < 60 * 1000) { // less than 1 minute
      return t("A l'instant");
    } else if (timeDiff < 60 * 60 * 1000) { // less than 1 hour
      const minutesDiff = Math.floor(timeDiff / (60 * 1000));
      return `${minutesDiff}mn`;
    } else if (timeDiff < 24 * 60 * 60 * 1000) { // less than 1 day
      const hoursDiff = Math.floor(timeDiff / (60 * 60 * 1000));
      const minutesDiff = Math.floor((timeDiff % (60 * 60 * 1000)) / (60 * 1000));
      return `${hoursDiff}H${minutesDiff}mn`;
    } else { // more than 1 day
      const daysDiff = Math.floor(timeDiff / (24 * 60 * 60 * 1000));
      return `${daysDiff}${t('j')}`;
    }
  } else {
    return ""
  }
};

export const timeRemaining = (date) => {
  const { t } = useTranslation();

  if (date !== null) {
    const expirationDate = new Date(date);
    const now = new Date();
    const timeDiff = expirationDate.getTime() - now.getTime();

    if (timeDiff <= 0) {
      return t("A l'instant");
    } else if (timeDiff < 60 * 1000) {
      return t("A l'instant");
    } else if (timeDiff < 60 * 60 * 1000) {
      const minutesDiff = Math.floor(timeDiff / (60 * 1000));
      return `${minutesDiff} ${t('mn')}`;
    } else if (timeDiff < 24 * 60 * 60 * 1000) {
      const hoursDiff = Math.floor(timeDiff / (60 * 60 * 1000));
      const minutesDiff = Math.floor((timeDiff % (60 * 60 * 1000)) / (60 * 1000));
      return `${hoursDiff} ${t('H')} ${minutesDiff} ${t('mn')}`;
    } else {
      const daysDiff = Math.floor(timeDiff / (24 * 60 * 60 * 1000));
      return `${daysDiff} ${t('jours')}`;
    }
  } else {
    return "";
  }
};

export const calculateYearsDifference = (startDate, endDate) => {
  const { t } = useTranslation();
  const start = new Date(startDate);
  const end = new Date(endDate);

  const yearsDiff = end.getFullYear() - start.getFullYear();
  const startMonth = start.getMonth();
  const endMonth = end.getMonth();

  if (endMonth < startMonth || (endMonth === startMonth && end.getDate() < start.getDate())) {
    return `${yearsDiff} ${t('years')}`;
  }

  return `${yearsDiff} ${t('years')}`;;
};

export const convertTimestampFormatZ = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const format = `${year}-${month}-${day}T${hours}:${minutes}Z`;

  return format;
};

export const exportHoursDate = (date) => {
  if (date !== null) {
    const convertDate = new Date(date);
    const year = String(convertDate.getUTCFullYear());
    const month = String(convertDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(convertDate.getUTCDate()).padStart(2, '0');
    const hours = String(convertDate.getUTCHours()).padStart(2, '0');
    const minutes = String(convertDate.getUTCMinutes()).padStart(2, '0');

    const frExpiration = `${year}-${month}-${day} ${hours}:${minutes}`;
    return frExpiration
  } else {
    return ""
  }
};

export const dateProvisioning = (date) => {
  if (date !== null) {
    const convertDate = new Date(date);
    const year = String(convertDate.getUTCFullYear());
    const month = String(convertDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(convertDate.getUTCDate()).padStart(2, '0');

    const frExpiration = {year: year, month: month, day: day};
    return frExpiration
  } else {
    return ""
  }
};

// Converting date
export const convertDateInMonths = (date) => {

  if (date !== null) {
    const convertDate = new Date(date);
    const month = (convertDate.getMonth() + 1).toString().padStart(2, "0");
    const year = convertDate.getFullYear().toString();

    const frExpiration = `${year}-${month}`;
    return frExpiration;
  } else {
    return "";
  }
}
