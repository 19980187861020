import React from "react";

export default function StatusMessage({ statusReservation, placeInfo }) {
    return (
        <>
            {statusReservation === true ? (
                <div className="status-reservation success-reservation">Votre réservation à bien été enregistré</div>
            ) : statusReservation === false && (
                <div className="status-reservation error-reservation">Une erreur est survenue lors de la réservation pour la place {placeInfo.place_parking}</div>
            )}
        </>
    );
};