import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { CertificateSchema } from "../../components/Forms/schema/YupSchema";
import Update from "../../components/Update";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Loader from "../../components/Loader";

export default function TimelineCertificates({ item, setState, stateQueryRow, setStateQueryRow }) {
    const { apiUrl, user } = useContext(AuthContext);
    const queryClient = useQueryClient();

    const deleteCertificate = async () => {
        const res = await axios(`${apiUrl}certificats/${item.id}/delete`, {
            method: "delete",
            withCredentials: true,
        });
        return res.data;
    };

    const deletingCertificat = useMutation({
        mutationFn: deleteCertificate,
        onSuccess: () => {
            setState(false);
            queryClient.resetQueries({ queryKey: ["certificats"] });
            setStateQueryRow({ state: true, name: item.name, source: item.source, type: 'delete' });
        },
    });

    const formContentUpdate = [
        {
            id: "certificat",
            type: "text",
            title: "Certificat",
            value: item.name,
        },
        { id: "type", type: "text", title: "Type", value: item.type },
        { id: "fonction", type: "text", title: "Fonction / Serial_Number", value: item.serial_number },
        { id: "plaque", type: "text", title: "Plaque", value: item.plaque },
        {
            id: "expiration",
            type: "date",
            title: "Expiration",
            value: item.endDate && item.endDate.split("T")[0],
        },
    ];

    const UpdateCertificat = async (data) => {
        const res = await axios(`${apiUrl}certificats/${item.id}/edit`, {
            method: "put",
            withCredentials: true,
            data: {
                holding: user.holding,
                entreprise: user.entreprise,
                ...data,
            },
        });
        try {
            queryClient.resetQueries({ queryKey: ["certificats"] });
            setStateQueryRow({ state: true, name: item.name, source: item.source, type: 'update' });
            return res.data;
        }catch(err) {}
        
    };

    return (
        <>
            {!deletingCertificat.isLoading ? (
                <div className="table-update" key={item.id}>
                    <Update
                        formContent={formContentUpdate}
                        schema={CertificateSchema}
                        submitFunction={UpdateCertificat}
                        deleteFunction={deletingCertificat.mutate}
                        stateQueryRow={stateQueryRow}
                    />
                </div>
            ) : (
                <Loader/>
            )}
        </>
    );
}
