import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import Loader from '../../components/Loader';
import { AuthContext } from '../../context/AuthContext';
import { UmbrellaCategoryColumn} from '../../components/headerContent';
import { useQuery } from '@tanstack/react-query';
import TableComponent from '../../components/TableComponent';
import { useTranslation } from 'react-i18next';



export default function CategoryTop() {

    const {apiUrl} = useContext(AuthContext);

    const [days, setDays] = useState(7);
    const [limit, setLimit] = useState(10);
    const {t} = useTranslation();


    //Request
    const getTopCategory = async () => {
        const res = await axios(`${apiUrl}umbrella/top_category/${days}/${limit}`, {
            method: "get", 
            withCredentials: true
        })
        const categoryForTable = res.data.data.map(item => {
            return ({
                category: item.category.label,
                requests: item.count
            })
        })
        return categoryForTable;

    }

    const categories = useQuery({
        queryKey: ["categories"],
        queryFn: getTopCategory
    })

    useEffect(() => {
       categories.refetch()
    }, [days, limit]);
    


    return (
        <div className='umbrella-category'>
            <section className="page-section">
          <div className="page-section_form">
            <form className="form">
              <div className="form-input_container limit">
                <select
                  className="only"
                  id="days"
                  onChange={(e) => setDays(e.target.value)}
                >
                  <option value="7">7 </option>
                  <option value="15">15 </option>
                  <option value="30"> 30 </option>
                </select>
                <label htmlFor="days">{t('derniers jours')}</label>
              </div>
            </form>
            <form className="form">
              <div className="form-input_container limit">
                <select
                  className="only"
                  id="days"
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
                <label htmlFor="days">{t('derniers évènements')}</label>
              </div>
            </form>
          </div>
      </section>
           { !categories.isFetching ? 
               <TableComponent
                tableColumns={UmbrellaCategoryColumn}
                tableData={categories.data}
                checkbox={false}
                />
    : <Loader />}
           
        </div>
    )
}