import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Loader from "../../components/Loader";
import TableComponent from "../../components/TableComponent";
import { AuthContext } from "../../context/AuthContext";
import { UmbrellaTraficColumn } from "../../components/headerContent";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export default function Trafic() {
  const { apiUrl } = useContext(AuthContext);
  const [days, setDays] = useState(7);
  const [limit, setLimit] = useState(10);
  const {t} = useTranslation();


  //Request
  const getTraficBlocked = async () => {
    const res = await axios(`${apiUrl}umbrella/blocked_traffic/${days}/${limit}`, {
      method: "get",
      withCredentials: true,
    })
        const arrayForTable = res.data.data.map((item) => {
          let labelIds = {};
          item.identities.forEach((identitie) => {
            if (identitie.type.type === "directory_user") {
              labelIds[identitie.type.type] = identitie.label;
            } else if (identitie.type.type === "directory_computer") {
              labelIds[identitie.type.type] = identitie.label;
            } else if (identitie.type.type === "network") {
              labelIds[identitie.type.type] = identitie.label;
            }
          });

          return {
            external_ip: item.externalip,
            date: item.date,
            internal_ip: item.internalip,
            categorie: item.policycategories[0].label,
            domain: item.domain,
            identities: labelIds,
          };
        });

       return arrayForTable
      }

      const trafic = useQuery({
        queryKey: ["trafic"],
        queryFn: getTraficBlocked
      })

  useEffect(() => {
    trafic.refetch()
  }, [days, limit]);

  

  return (
    <>
      <section className="page-section">
          <div className="page-section_form">
            <form className="form">
              <div className="form-input_container limit">
                <select
                  className="only"
                  id="days"
                  onChange={(e) => setDays(e.target.value)}
                >
                  <option value="7">7 </option>
                  <option value="15">15 </option>
                  <option value="30"> 30 </option>
                </select>
                <label htmlFor="days">{t('derniers jours')}</label>
              </div>
            </form>
            <form className="form">
              <div className="form-input_container limit">
                <select
                  className="only"
                  id="days"
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
                <label htmlFor="days">{t('derniers évènements')}</label>
              </div>
            </form>
          </div>
      </section>
      {!trafic.isFetching ? (
        <section className="page-table">
          <TableComponent
            tableColumns={UmbrellaTraficColumn}
            tableData={trafic.data}
            checkbox={false}
          />
        </section>
      ) : (
        <section className="page-table">
          <Loader />
        </section>
      )}
    </>
  );
}
