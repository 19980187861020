import React, { useState, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import RowMerakiLicence from "./RowMerakiLicence";
import { MerakiLicenceColumn } from "../../components/headerContent";
import TableComponent from "../../components/TableComponent";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { MerakiLicencePerDeviceColumn } from "../../components/headerContent";
import Loader from "../../components/Loader";
import { convertDateAndReturn } from "../../components/utils";
import NumberCard from "../../components/Card/NumberCard";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function MerakiLicences() {
    const { apiUrl } = useContext(AuthContext);
    const [differencesLicences, setDifferencesLicences] = useState(false);
    const { t } = useTranslation();

    function calculateRemainingDays(startDate, duration) {
        let today = new Date()
        let startDateObj = new Date(startDate);
        let endDateObj = startDateObj.setDate(startDateObj.getDate() + duration);
        const expirationDate = convertDateAndReturn(endDateObj);

        return expirationDate;
    }


    const getLicencesSub = async () => {
        const res = await axios.get(`${apiUrl}meraki/licenses_global`, {
            withCredentials: true
        })
        return !res.data.includes("No data") ? res.data.map(item => {
            return {
                ...item,
                expiration: !item.expired ? calculateRemainingDays(item.startedAt, item.duration) : null
            }
        }) : res.data
    }

    const licencesSub = useQuery({
        queryKey: ["merakiLicencesSub"],
        queryFn: getLicencesSub
    })

    const getLicencesPerDevices = async () => {
        const res = await axios.get(`${apiUrl}meraki/licenses_per_devices`, {
            withCredentials: true
        })
        return !res.data.includes("No data") ? res.data.map(item => {
            return {
                ...item,
                expiration: !item.expired ? calculateRemainingDays(item.startedAt, item.duration) : null
            }
        }) : res.data
    }

    const licencePerDevices = useQuery({
        queryKey: ["licencesPerDevices"],
        queryFn: getLicencesPerDevices
    })

    const handleClickDifferenceLicence = state => {
        if (state === true) {
            setDifferencesLicences(true);
        } else {
            setDifferencesLicences(false);
        }
    };

    if (licencesSub.isError && licencePerDevices.isError || licencesSub.data && licencesSub.data.includes("No data") && licencePerDevices.data && licencePerDevices.data.includes("No data")) {
        return <article className="page">
            <h1 className="page-title">
                Aucun équipement Meraki
            </h1>
        </article>
    }

    return (
        <>
            {!licencesSub.isLoading && !licencePerDevices.isLoading ? (
                <article className="page">
                    <h1 className="page-title">{t('Licences')}</h1>
                    <section className="page-section">
                        {licencesSub.data !== undefined && licencePerDevices.data !== undefined ? (
                            <NumberCard title="Licences" number={licencesSub.data.length + licencePerDevices.data.length} />
                        ) : licencesSub.data !== undefined && licencePerDevices.data === undefined ? (
                            <NumberCard title="Licences" number={licencesSub.data.length} />
                        ) : (
                            <NumberCard title="Licences" number={licencePerDevices.data.length} />
                        )}
                    </section>
                    <div className="page-title return-link-meraki">
                        <Link to={"/meraki"} className="button button-new"> <FontAwesomeIcon icon={faChevronLeft} /> {t('Retour')}</Link>
                        {licencesSub.data !== undefined && licencePerDevices.data !== undefined &&
                            <div className="differences-licenses">
                                <div onClick={() => { handleClickDifferenceLicence(false) }} id="licence-global" className={`licence-types ${!differencesLicences ? "licence-current" : ""}`}>
                                    <h2>GLobal</h2>
                                </div>
                                <div onClick={() => { handleClickDifferenceLicence(true) }} id="licence-per-device" className={`licence-types ${differencesLicences ? "licence-current" : ""}`}>
                                    <h2>{t(`Per devices`)}</h2>
                                </div>
                            </div>
                        }
                    </div>
                    {licencesSub.data !== undefined && licencePerDevices.data !== undefined ? (
                        <div className="table-meraki-licences">
                            {!differencesLicences ? (
                                <TableComponent tableColumns={MerakiLicenceColumn} rowComponent={RowMerakiLicence} tableData={licencesSub.data} />
                            ) : (
                                <TableComponent tableColumns={MerakiLicencePerDeviceColumn} tableData={licencePerDevices.data} />
                            )}
                        </div>
                    ) : licencesSub.data !== undefined && licencePerDevices.data === undefined ? (
                        <TableComponent tableColumns={MerakiLicenceColumn} rowComponent={RowMerakiLicence} tableData={licencesSub.data} />
                    ) : (
                        <TableComponent tableColumns={MerakiLicencePerDeviceColumn} tableData={licencePerDevices.data} />
                    )}
                </article>
            ) : <Loader />}
        </>
    )
}