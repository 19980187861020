import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import Loader from "../../components/Loader";
import RowAmpTable from "./RowAmpTable";
import Globalview from "../../components/Globalview";
import { AmpColumn } from "../../components/headerContent";
import { useQuery } from "@tanstack/react-query";
import TableComponent from "../../components/TableComponent";
import NumberCard from "../../components/Card/NumberCard";
import { useTranslation } from "react-i18next";

export default function AmpTable({ displayArray }) {

  // Import context data
  const { apiUrl } = useContext(AuthContext);
  const {t} = useTranslation();

  const [ampByPolicy, setAmpByPolicy] = useState([]);
  const [valueType, setValueType] = useState("all");
  const [resetValue, setResetValue] = useState(false);

  // Request to get all equipements
  const getAllAmp = async () => {
    const res = await axios(`${apiUrl}amp/index`, {
      method: "GET",
      withCredentials: true,
    });

    return res.data;
  };

  const amp = useQuery({
    queryKey: ["amp"],
    queryFn: getAllAmp,
  });

  const handleFilterByPolicy = () => {
    const ampFilterByPolicy =
      amp.isFetched &&
      amp.data.devices.filter((item) => {
        if (valueType === "pending_start" || valueType === "not_isolated") {
          return item.isolation.status === valueType;
        } else {
          return item.policy.name === valueType;
        }
      });
    setAmpByPolicy(ampFilterByPolicy);
  };

  useEffect(() => {
    handleFilterByPolicy();
  }, [valueType]);

  const isolateCounts = { name: "Isolation" };
  const policyCounts = { name: "Policy" };

  if (amp.isError) {
    return (
      <article className="page">
        <h1 className="page-title">{t('Aucun équipement secure endpoint')}</h1>
      </article>
    );
  }

  return (
    <>
      {!amp.isLoading ? (
        <>
          <article className={!displayArray ? "page" : null}>
            {!displayArray && (
              <h1 className="page-title">{t('Ordinateurs Secure Endpoint')}</h1>
            )}
            <section className="page-section">
              <NumberCard
                title="Ordinateurs"
                number={
                  ampByPolicy.length > 0
                    ? ampByPolicy.length
                    : amp.data.devices.length
                }
              />
              {amp.isFetched && (
                <>
                  <Globalview
                    data={amp.data.devices}
                    countObject={policyCounts}
                    objectKey="policy"
                    secondKey="name"
                    setValueType={setValueType}
                    click
                    resetValue={resetValue} 
                    setResetValue={setResetValue}
                  />
                  <Globalview
                    data={amp.data.devices}
                    countObject={isolateCounts}
                    objectKey="isolation"
                    secondKey="status"
                    setValueType={setValueType}
                    click
                    resetValue={resetValue} 
                    setResetValue={setResetValue}
                  />
                </>
              )}
              {valueType !== "all" && (
                <button
                  className="button button-new page-reset"
                  onClick={() => {
                    setValueType("all"), 
                    setResetValue(true),
                    setNetworkChoose("all");
                  }}
                >
                  {" "}
                  Réinitialiser
                </button>
              )}
            </section>
            <section className="page-table">
              <TableComponent
                tableData={valueType === "all" ? amp.data.devices : ampByPolicy}
                tableColumns={AmpColumn}
                rowComponent={RowAmpTable}
                checkbox={false}
              />
            </section>
          </article>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
