import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { Link } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import TableComponent from "../../components/TableComponent";
import NumberCard from "../../components/Card/NumberCard";
import Globalview from "../../components/Globalview";
import { AuvikAlertscolumns } from "../../components/headerContent";
import RowAlertsAuvik from "./RowAlertsAuvik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { convertTimestampFormatZ } from "../../components/utils";
import { startOfDay, endOfDay, addDays } from 'date-fns';
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";

export default function AuvikAlerts() {
    const { apiUrl } = useContext(AuthContext);
    const params = useParams();
    const queryClient = useQueryClient();
    const tenantId = params.tenantId;
    const today = convertTimestampFormatZ(endOfDay(new Date(), 23));
    const yesterday = convertTimestampFormatZ(startOfDay(addDays(new Date(), -1), 0));
    const [alertsByType, setAlertsByType] = useState([]);
    const [resetValue, setResetValue] = useState(false);
    const [valueType, setValueType] = useState("all");
    const typeCounts = { name: "Type" };
    const { t } = useTranslation();

    const getAlertsAuvik = async () => {
        try {
            const res = await axios.get(`${apiUrl}auvik/alerts/${tenantId}/${yesterday}/${today}`, {
                withCredentials: true,
            });
            return res.data;
        } catch (error) {
        }
    };

    const alertsAuvik = useQuery({
        queryKey: ["alertsAuvik", tenantId],
        queryFn: getAlertsAuvik,
    });

    useEffect(() => {
        queryClient.invalidateQueries(["alertsAuvik", alertsAuvik.data]);
    }, [alertsAuvik.data]);

    const alertsFilterByType = () => {
        const filterByType = alertsAuvik.data.filter(item => {
            return item.Status === valueType;
        })
        setAlertsByType(filterByType);
    }

    useEffect(() => {
        if (valueType !== "all") {
            alertsFilterByType();
        }
    }, [valueType]);

    return (
        <>
            {alertsAuvik.data ? (
                <article className="page page-link-auvik">
                    <div className="page-title page-link-auvik-tenant">
                        <Link to={`/auvik/${tenantId}`} className="button button-new"><FontAwesomeIcon icon={faChevronLeft} /> {t('Retour')}</Link>
                    </div>
                    {alertsAuvik.data.data !== "No alerts found" ? (
                        <section className="auvik-alldevices">
                            <h2 className="auvik-alldevices-title">{t('Alert history')}</h2>
                            <section className="page-section">
                                <NumberCard logo={faCircleExclamation} number={alertsAuvik.data.length} title="Nombre d'alertes" color="#FA8334" stylesIcons="logo-firewall"/>
                                {alertsAuvik.data.length > 0 && <Globalview data={alertsAuvik.data} setValueType={setValueType} countObject={typeCounts} objectKey="Status" click resetValue={resetValue} setResetValue={setResetValue} />}
                                {valueType !== "all" && <button className="button button-new page-reset" onClick={() => { setValueType("all"), setResetValue(true); setAlertsByType([]) }}> {t('Réinitialiser')}</button>}
                            </section>
                            {alertsAuvik.data.length > 0 && <TableComponent tableData={alertsByType.length > 0 ? alertsByType : alertsAuvik.data} tableColumns={AuvikAlertscolumns} checkbox={false} rowComponent={RowAlertsAuvik} />}
                        </section>
                    ) : 
                    <h1 className="page-title alerts-not-found">{t("Aucune alerte trouvée")}</h1>
                    }
                </article>
            ) : <Loader />}
        </>
    );
}