import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";

export default function InterfacesStatistics({ tenantId, interfaces }) {
    const [filteredInterfaces, setFilteredInterfaces] = useState(null);

    useEffect(() => {
        if (interfaces) {
            const dataInterfacesFiltered = interfaces.data.filter(item =>
                !item.interfaceName.toLowerCase().includes('null')
                && item.interfaceName.toLowerCase().includes('')
            );
            const modifiedInterface = dataInterfacesFiltered.map(item => ({
                ...item,
                interfaceName: item.interfaceName.replace(/(\D*)(\d+\/\d+)/, '$1 Port $2'),
            }));    
            setFilteredInterfaces(modifiedInterface);
        }
    }, [interfaces]);

    return (
        <>
            {interfaces && interfaces.data && filteredInterfaces ? (
                <>
                    {filteredInterfaces.map((item, index) => (
                        <Link key={index} to={`/auvik/${tenantId}/${item?.id}`} className="link-interfaces-statistics">
                            <div className="network-container interfaces-container">
                                <div className="interfaces-container-content">
                                    <h2>{item?.interfaceName}</h2>
                                    <span className={`interfaces-container-content-status ${item?.operationalStatus === 'online' ? 'online' : (item?.operationalStatus === 'offline' ? 'offline' : 'other')}`}></span>
                                </div>
                            </div>
                        </Link>
                    ))}
                </>
            ): ( 
                <Loader/>
            )}
        </>
    );
}