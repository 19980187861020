import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import RowWebex from "./RowWebex";
import Loader from "../../components/Loader";
import Globalview from "../../components/Globalview";
import TableComponent from "../../components/TableComponent";
import { Webexcolumns } from "../../components/headerContent";
import NumberCard from "../../components/Card/NumberCard";
import ButtonArrow from "../../components/ButtonArrow";
import { useTranslation } from "react-i18next";

export default function Webex() {
  const { apiUrl, user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [devices, setDevices] = useState([]);
  const [filters, setFilters] = useState({ status: "all", type: "all" });
  const [devicesByFilters, setDevicesByFilters] = useState([]);
  const [resetValue, setResetValue] = useState(false);
  const { t } = useTranslation();

const getDevices = async () => {
  try {
    const res = await axios.get(`${apiUrl}webex/devices_live`, {
        withCredentials: true,
    });
    return res.data;
  } catch (error) {
  }
}

const devicesLive = useQuery({
  queryKey: ["deviceLive"],
  queryFn: getDevices,
});

useEffect(() => {
  queryClient.invalidateQueries(["deviceLive", devicesLive.data]);
  if(devicesLive.data) {
    const dataArray = !devicesLive.data.errors ? devicesLive.data.items : [];

    setDevices(dataArray.map(item => ({
      id: item.id,
      name: item.displayName,
      status: item.connectionStatus,
      product: item.product,
      type: item.type,
      ip: item.ip,
      capabilities: item.capabilities,
      permissions: item.permissions,
    })));

    setFilters({ status: "all", type: "all" });
  }
}, [devicesLive.data, user.entreprise]);

  const statusCounts = { name: "Statut" };
  const typeCounts = { name: "Type" };

  const filterDevices = () => {
    const filteredDevices = devices.filter(item =>
      (filters.status === "all" || item.status === filters.status) &&
      (filters.type === "all" || item.type === filters.type)
    );
    setDevicesByFilters(filteredDevices);
  };  

  useEffect(() => {
    filterDevices();
  }, [filters]);

  const handleResetFilters = () => {
    setFilters({ status: "all", type: "all" });
    setResetValue(true);
  };

  return (
    <>
      {devicesLive.isLoading ? (
        <Loader />
      ) : devicesLive.data && devices.length <= 0 ? (
        <article className="page">
          <h1 className="page-title">
            {t('Aucun équipement Webex')}
          </h1>
        </article>
      ) : (
        <article className="page">
          <section className="page-logo">
            <img src="./assets/webex_logo.png" alt="Logo de Cisco Webex" />
            <h1 className="page-title">Webex</h1>
            <ButtonArrow url={'https://admin.webex.com/'} />
          </section>
          <section className="page-section">
            <NumberCard title="Appareils" number={
              filters.status === "all"
                ? devices.length
                : devicesByFilters.length > 0 && filters.status !== "all"
                  ? devicesByFilters.length
                  : devicesByFilters.length
            } />
            {["status", "type"].map((key, index) => (
              <Globalview
                key={index}
                data={devices}
                countObject={key === "status" ? statusCounts : typeCounts}
                objectKey={key}
                setValueType={value => setFilters({ ...filters, [key]: value })}
                click={true}
                resetValue={resetValue}
                setResetValue={setResetValue}
              />
            ))}
            {filters !== "all" &&
              <button className="button button-new page-reset" onClick={handleResetFilters}>
                {t('Réinitialiser')}
              </button>
            }
          </section>
          <TableComponent
            tableData={
              filters.status === "all"
                ? devicesByFilters
                : devicesByFilters.length > 0 && filters.status !== "all"
                  ? devicesByFilters
                  : [{ message: t('Aucun appareil ne correspond aux filtres sélectionnés.') }]}
            tableColumns={Webexcolumns}
            rowComponent={RowWebex}
            checkbox={false}
          />
        </article>
      )}
    </>
  );
}