import React, { useContext, useEffect, useState, useRef } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { useQuery, useQueries } from "@tanstack/react-query";
import ChartData from "../../components/ChartData";
import { DateRangePicker } from 'rsuite';
import { startOfDay, endOfDay, addDays, subDays, subMonths, format } from 'date-fns';
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

export default function AgentInsightHistory({ data, agentId, teamArray, setTicketsByAgents, setTicketsClicked, setNameAgent }) {
    const { apiUrl } = useContext(AuthContext);
    const [dayTotalTime, setDayTotalTime] = useState({});
    const [labelTicketDays, setLabelTicketDays] = useState([]);
    const { afterToday } = DateRangePicker;
    const today = endOfDay(new Date());
    const lastMonthStart = startOfDay(subMonths(new Date(), 1));
    const [date, setDate] = useState([lastMonthStart, today]);
    const { t } = useTranslation();

    const Ranges = [
        {
            label: t('Last seven days'),
            value: [startOfDay(subDays(new Date(), 6), 0), today]
        },
        {
            label: t('Le mois dernier'),
            value: [startOfDay(subMonths(new Date(), 1)), today]
        }
    ];

    const getDates = () => {
        if (Array.isArray(date) && date.length === 2) {
            return {
                startDate: startOfDay(date[0]),
                endDate: endOfDay(date[1]),
            };
        }
        return { startDate: null, endDate: null };
    };

    const getTicketsStatsByAgent = async () => {
        const res = await axios.get(`${apiUrl}halo/ticket_stats_by_agent/${agentId}`, {
            withCredentials: true
        });
        return res.data;
    };

    const ticketsStatsByAgent = useQuery({
        queryKey: ["ticketsStatsByTeam", agentId],
        queryFn: getTicketsStatsByAgent,
    });

    const getTicketsStatsByTeam = async (name_team) => {
        const res = await axios.get(`${apiUrl}halo/ticket_stats_by_team/${name_team}`, {
            withCredentials: true
        });
        return res.data;
    };

    const teamStatsQueries = useQueries({
        queries: (Array.isArray(teamArray) ? teamArray : []).map((team) => ({
            queryKey: ["ticketsStatsByTeam", team],
            queryFn: () => getTicketsStatsByTeam(team),
        }))
    });

    const getTicketsAgent = async () => {
        const res = await axios.get(`${apiUrl}halo/get_time_by_agents/${agentId}`, {
            withCredentials: true
        });
        return res.data;
    };

    const ticketsAgent = useQuery({
        queryKey: ["ticketsAgent", agentId],
        queryFn: getTicketsAgent,
    });

    const getFilteredData = (data) => {
        if (!data || !Array.isArray(date) || date.length !== 2) {
            return {};
        }
        const { startDate, endDate } = getDates();

        return Object.keys(data).reduce((acc, key) => {
            const currentDate = new Date(key);
            if (currentDate >= startDate && currentDate <= endDate) {
                acc[key] = data[key];
            }
            return acc;
        }, {});
    };

    useEffect(() => {
        if (ticketsAgent.isSuccess && ticketsAgent.data && ticketsAgent.data.data) {
            setTicketsByAgents(ticketsAgent.data.data);
            setNameAgent(ticketsAgent.data.name);
            const teamLabels = new Set();

            // Transforme les données brutes
            const transformedData = ticketsAgent.data.data.reduce((acc, current) => {
                if (!acc[current.day]) {
                    acc[current.day] = {};
                }

                current.tickets.forEach((ticket) => {
                    const teamKey = ticket.team.replace(/_/g, " ");
                    teamLabels.add(teamKey);

                    if (!acc[current.day][teamKey]) {
                        acc[current.day][teamKey] = 0;
                    }
                    acc[current.day][teamKey] += ticket.time_spent;
                });

                return acc;
            }, {});

            // Met à jour les états avec les données transformées et filtrées
            setLabelTicketDays(Array.from(teamLabels));
            setDayTotalTime(getFilteredData(transformedData));
        }
    }, [ticketsAgent.isSuccess, ticketsAgent.data, date]);

    const structuredDataOpenedTickets = {};
    const structuredDataAgentOpenedTickets = {};
    const structuredDataClosedTickets = {};
    const structuredDataAgentClosedTickets = {};
    const labels = [ticketsStatsByAgent.data && ticketsStatsByAgent.data.name];

    // Organiser les données des équipes par mois et équipe
    teamStatsQueries.forEach((query, teamIndex) => {
        if (query.data) {
            const teamName = teamArray[teamIndex];
            labels.push(teamName);
            query.data.data.forEach((entry) => {
                const month = entry.month;

                if (!structuredDataOpenedTickets[month]) {
                    structuredDataOpenedTickets[month] = {};
                }

                structuredDataOpenedTickets[month][teamName] = parseInt(entry.opened_tickets, 10);

                if (!structuredDataClosedTickets[month]) {
                    structuredDataClosedTickets[month] = {};
                }

                structuredDataClosedTickets[month][teamName] = parseInt(entry.closed_tickets, 10);
            });
        }
    });

    // Organiser les données d'Alexandre par mois
    ticketsStatsByAgent.data?.data.forEach((query) => {
        if (query) {
            const teamName = ticketsStatsByAgent.data.name;
            const month = query.month;

            if (!structuredDataAgentOpenedTickets[month]) {
                structuredDataAgentOpenedTickets[month] = {};
            }

            structuredDataAgentOpenedTickets[month][teamName] = parseInt(query.opened_tickets, 10);

            if (!structuredDataAgentClosedTickets[month]) {
                structuredDataAgentClosedTickets[month] = {};
            }

            structuredDataAgentClosedTickets[month][teamName] = parseInt(query.opened_tickets, 10);
        }
    });

    // Fusionner les données de structuredDataOpenedTickets et structuredDataAgentOpenedTickets
    const mergedDataOpenned = { ...structuredDataOpenedTickets };
    const mergedDataClosed = { ...structuredDataClosedTickets };

    Object.keys(structuredDataAgentOpenedTickets).forEach((month) => {
        if (!mergedDataOpenned[month]) {
            mergedDataOpenned[month] = {}; // Si le mois n'existe pas, on l'initialise
        }

        Object.keys(structuredDataAgentOpenedTickets[month]).forEach((teamName) => {
            mergedDataOpenned[month][teamName] = structuredDataAgentOpenedTickets[month][teamName];
        });
    });

    Object.keys(structuredDataAgentClosedTickets).forEach((month) => {
        if (!mergedDataClosed[month]) {
            mergedDataClosed[month] = {}; // Si le mois n'existe pas, on l'initialise
        }

        Object.keys(structuredDataAgentClosedTickets[month]).forEach((teamName) => {
            mergedDataClosed[month][teamName] = structuredDataAgentClosedTickets[month][teamName];
        });
    });

    const handleGraphClick = (event, chartRef) => {
        const chartInstance = chartRef;
        const activePoints = chartInstance.getElementsAtEventForMode(
            event,
            "nearest",
            { intersect: true },
            true
        );
        if (activePoints.length > 0) {
            const firstPoint = activePoints[0];
            const datasetIndex = firstPoint.datasetIndex;
            const dataIndex = firstPoint.index;
            const label = chartInstance.data.labels[dataIndex];
            const group = chartInstance.data.datasets[datasetIndex].label;
            setTicketsClicked({"day": label, "groupements": group})
        }
    };

    if(ticketsStatsByAgent.data === undefined && ticketsAgent.data === undefined  && teamStatsQueries === undefined ) {
        return (
            <div className="error-message-agents-ingight">Aucune statistique graphique n'est disponible</div>
        )
    }

    return (
        <>
            {ticketsStatsByAgent.data && ticketsAgent.data && teamStatsQueries && mergedDataOpenned && mergedDataClosed && dayTotalTime ? (
                <section className="agent-insight-chart">
                    <div className="form-input_container">
                        <DateRangePicker
                            ranges={Ranges}
                            cleanable={false}
                            character=" / "
                            value={date}
                            onChange={setDate}
                            format="yyyy-MM-dd"
                            shouldDisableDate={afterToday()}
                            defaultValue={[lastMonthStart, today]}
                            locale={{
                                sunday: t('Su'),
                                monday: t('Mo'),
                                tuesday: t('Tu'),
                                wednesday: t('We'),
                                thursday: t('Th'),
                                friday: t('Fr'),
                                saturday: t('Sa'),
                                hours: t('Hours'),
                            }}
                        />
                    </div>
                    <ChartData
                        dataForChart={dayTotalTime}
                        allLabels={labelTicketDays}
                        isStacked={true}
                        titleChart={'Graphique des tickets par jours'}
                        handleGraphClick={handleGraphClick}
                        typeOfChart={'bar'}
                        addUnit={true}
                        automaticColors={true}
                    />
                    <ChartData
                        dataForChart={mergedDataOpenned}
                        allLabels={labels}
                        titleChart={t("Graphique des tickets ouverts")}
                        typeOfChart={"line"}
                        maxHeight={400}
                        automaticColors={true}
                        interactionStatus={true}
                    />
                    <ChartData
                        dataForChart={mergedDataClosed}
                        allLabels={labels}
                        titleChart={t("Graphique des tickets clos")}
                        typeOfChart={"line"}
                        maxHeight={400}
                        automaticColors={true}
                        interactionStatus={true}
                    />
                </section>
            ) : (
                <Loader />
            )}
        </>
    )
}