import { useContext } from "react"
import { AuthContext } from "../../context/AuthContext"
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Loader from "../../components/Loader";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightArrowLeft, faWifi } from "@fortawesome/free-solid-svg-icons";


export default function HealthCard({ type, networkId, status }) {
    const { apiUrl } = useContext(AuthContext);

    const getHealth = async () => {
        const res = await axios.get(`${apiUrl}meraki/health/${type}/${networkId}`, {
            withCredentials: true
        })
        return res.data
    }

    const health = useQuery({
        queryKey: ["health", type],
        queryFn: getHealth,
    })

    useEffect(() => {
        health.refetch()
    }, [type, networkId])

    if (health.isFetched && health.data.errors) {
        return null;
    }

    return (
        <>
            {!health.isFetching ? (
                health.data[0] !== 'No data' && health.data.count.total !== 0 && (
                    <div className="health-item">
                        <h2 className="health-item_title"> <FontAwesomeIcon icon={type === "switch" ? faArrowRightArrowLeft : faWifi} /> {type}</h2>
                        <p className="health-item_value">
                            <span className="health-item_span" style={health.data.count.healthy < health.data.count.total ? { backgroundColor: "red" } : { backgroundColor: "green" }}>
                                {health.data.count.healthy} / {health.data.count.total}
                            </span>
                        </p>
                    </div>
                )
            ) : (
                <div className="health-item"><Loader /></div>
            )}
            {status !== undefined && status.data > 0 && (
                <div className="health-item">
                    <h2 className="health-item_title">{status.type}</h2>
                    <p className="health-item_value">
                        <span className="health-item_span" style={
                            status.type === "Online" ? { backgroundColor: "green" } :
                                status.type === "Offline" || status.type === "Alerting" ? { backgroundColor: "red" } :
                                    status.type === "Dormant" ? { backgroundColor: "lightgrey" } :
                                        status.type === "Autres" && { backgroundColor: "#997adb" }
                        }>
                            {status.data}
                        </span>
                    </p>
                </div>
            )}
        </>
    )
}