
import { useEffect, useState } from "react";
import ChartData from "../../components/ChartData";
import { convertDateAndReturn, convertDateInMonths } from "../../components/utils";
import { useTranslation } from "react-i18next";

/**
 * 
 * @param {array} incidents
 * @param {string} currentSlide - number of the slide displayed
 * @param {function} handleGraphClick - toggle to display an array when user's click on a bar
 * @param {string} timeFilter - value to display tickets on a period
 */
export default function ChartIncidents({ incidents, currentSlide, handleGraphClick, timeFilter, setTicketNumber }) {
  const [dataForChart, setDataForChart] = useState({});
  const [allLabels, setAllLabels] = useState([]);
  const [titleChart, setTitleChart] = useState("");
  const [isStacked, setIsStacked] = useState(false);
  const [type, setType] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    getIncidentsGraph();
  }, [currentSlide, incidents, timeFilter]);

  // Fonction pour gérer les données du graphique
  const getIncidentsGraph = () => {
    let data = [];
    let title = "";
    let label = "";
    let filter = "";
    let chartType = "";

    const valuesGraph = [
      "graph_type",
      "graph_priority",
      "graph_sla",
      "graph_ho",
      "graph_subjectIncident",
      "graph_subjectDemande",
      "graph_subjectIncidentsByResolve",
      "graph_subjectDemandeByResolve",
      "graph_numberOfOpenedChangePerMonth",
      "graph_numberOfClosedChangePerMonth",
      "graph_numberOfOpenedIncidentPerMonth",
      "graph_numberOfClosedIncidentPerMonth",
      "graph_camembert",
      "graph_camembert_date"
    ];
    const graphType = timeFilter === "months" && currentSlide === "12" ? valuesGraph[13] : valuesGraph[currentSlide];

    if (Array.isArray(incidents[graphType])) {
      // Déterminer les données et les paramètres de filtrage en fonction de la catégorie sélectionnée
      switch (graphType) {
        case "graph_type":
          data = incidents.graph_type.filter(item => item.casetypecode !== null);
          label = "casetypecode";
          title = t("Nombre d'incidents par type de demande");
          filter = "resolveby";
          chartType = "bar";
          break;
        case "graph_priority":
          data = incidents.graph_priority.filter(item => item.prioritycode !== null);
          label = "prioritycode";
          title = t("Répartition des sévérités par incidents");
          filter = "resolveby";
          chartType = "bar";
          break;
        case "graph_sla":
          data = incidents.graph_sla.filter(item => item.sla !== null);
          label = "sla";
          title = t("Nombre d'incidents par type de SLA");
          filter = "resolveby";
          chartType = "bar";
          break;
        case "graph_ho":
          data = incidents.graph_ho.filter(item => item.HOElit !== null && item.HOall !== null);
          label = null;
          title = t("Temps moyen de traitement des incidents (heures)");
          filter = "resolveby";
          chartType = "bar";
          break;
        case "graph_subjectIncident":
          data = incidents.graph_subjectIncident.filter(item => item.subject !== null);
          label = "subject";
          title = t("Répartition des sujets des incidents par demandeur");
          filter = "primarycontactid_yomifullname";
          chartType = "bar";
          break;
        case "graph_subjectDemande":
          data = incidents.graph_subjectDemande.filter(item => item.subject !== null);
          label = "subject";
          title = t("Répartition des sujets des changements par demandeur");
          filter = "primarycontactid_yomifullname";
          chartType = "bar";
          break;
        case "graph_subjectIncidentsByResolve":
          data = incidents.graph_subjectIncidentsByResolve.filter(item => item.subject !== null);
          label = "subject";
          title = t("Répartition des sujets des incidents par date");
          filter = "resolveby";
          chartType = "bar";
          break;
        case "graph_subjectDemandeByResolve":
          data = incidents.graph_subjectDemandeByResolve.filter(item => item.subject !== null);
          label = "subject";
          title = t("Répartition des sujets des changements par date");
          filter = "resolveby";
          chartType = "bar";
          break;
        case "graph_numberOfOpenedChangePerMonth":
          data = incidents.graph_numberOfOpenedChangePerMonth.filter(item => item.casetypecode !== null);
          label = "casetypecode";
          title = t("Nombre de changements ouverts par mois");
          filter = "createdon";
          chartType = "line";
          break;
        case "graph_numberOfClosedChangePerMonth":
          data = incidents.graph_numberOfClosedChangePerMonth.filter(item => item.casetypecode !== null);
          label = "casetypecode";
          title = t("Nombre de changements clos par mois");
          filter = "resolveby";
          chartType = "line";
          break;
        case "graph_numberOfOpenedIncidentPerMonth":
          data = incidents.graph_numberOfOpenedIncidentPerMonth.filter(item => item.casetypecode !== null);
          label = "casetypecode";
          title = t("Nombre d'incidents ouverts par mois");
          filter = "createdon";
          chartType = "line";
          break;
        case "graph_numberOfClosedIncidentPerMonth":
          data = incidents.graph_numberOfClosedIncidentPerMonth.filter(item => item.casetypecode !== null);
          label = "casetypecode";
          title = t("Nombre d'incidents clos par mois");
          filter = "resolveby";
          chartType = "line";
          break;
        case "graph_camembert":
          data = incidents.graph_camembert;
          label = "name";
          title = t("Nombre de tickets par sites");
          filter = "ticketnumber";
          chartType = "doughnut";
          break;
        case "graph_camembert_date":
          data = incidents.graph_camembert_date;
          label = "name";
          title = t("Nombre de tickets par sites");
          filter = "ticketnumber";
          chartType = "doughnut";
          break;
        default:
          data = [];
          label = "";
          title = "";
          filter = "";
          chartType = "";
          break;
      }
      HandleDataForChart(data, label, title, filter, chartType);

      if (graphType === "graph_camembert_date" || graphType === "graph_camembert") {
        const ticketNumber = incidents[graphType].map(item => item.ticketnumber);
        const total = ticketNumber.reduce((sum, item) => sum + parseInt(item, 10), 0);
        setTicketNumber(total);
      } else if (graphType === "graph_ho") {
        const hoElit = incidents["graph_ho"].map(item => item.HOElit);
        const hoAll = incidents["graph_ho"].map(item => item.HOall);
        const hoElitIsAllZeroOrNull = hoElit.every(value => value === "0" || value === null);
        const hoAllIsAllZeroOrNull = hoAll.every(value => value === "0" || value === null);
        if (incidents[graphType].length > 0 && !hoElitIsAllZeroOrNull || !hoAllIsAllZeroOrNull) {
          const ticketNumber = incidents["graph_type"].map(item => item.count);
          const total = ticketNumber.reduce((sum, item) => sum + parseInt(item, 10), 0);
          setTicketNumber(total);
        } else {
          setTicketNumber(0);
        }
      } else {
        const ticketNumber = incidents[graphType].map(item => item.count);
        const total = ticketNumber.reduce((sum, item) => sum + parseInt(item, 10), 0);
        setTicketNumber(total);
      }
    };
  }

  // Create chart
  const HandleDataForChart = (data, label, title, filter, type) => {
    setType(type)
    // stacked or not
    if (!label) {
      setIsStacked(false);
    } else {
      setIsStacked(true);
    }

    // init data for chart
    let groupedData = {};
    let allLabels = [];

    if (title === "Nombre de tickets par sites") {
      const dataFormatted = data.reduce((acc, item) => {
        acc[item.name] = Number(item.ticketnumber);  // Convertir ticketnumber en nombre
        return acc;
      }, {});
      groupedData = dataFormatted;
      setAllLabels(Object.keys(dataFormatted));
    } else {
      const dataFormated = data.map(item => {
        const subjectFormated = item.subject && item.subject.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        return { ...item, subject: subjectFormated }
      });
      // Transform data in object
      dataFormated.forEach((item) => {
        // if filter is a data
        if (filter !== "primarycontactid_yomifullname") {

          let filterByTime = ["months", "week"].includes(timeFilter) ? convertDateAndReturn(item[filter]) : convertDateInMonths(item[filter])

          // for elasped time
          if (label === null) {
            const HOelit = item.HOElit;
            const HOall = item.HOall;

            if (!groupedData[filterByTime]) groupedData[filterByTime] = {};
            if (!groupedData[filterByTime]["Heures ouvrées Elit"]) {
              groupedData[filterByTime]["Heures ouvrées Elit"] = HOelit
              if (!allLabels.includes("Heures ouvrées Elit")) allLabels.push("Heures ouvrées Elit");
            } else {
              groupedData[filterByTime]["Heures ouvrées Elit"] += HOelit
            }

            if (!groupedData[filterByTime]["Heures ouvrées totales"]) {
              groupedData[filterByTime]["Heures ouvrées totales"] = HOall
              if (!allLabels.includes("Heures ouvrées totales")) allLabels.push("Heures ouvrées totales");
            } else {
              groupedData[filterByTime]["Heures ouvrées totales"] += HOall
            }
          } else if (!groupedData[filterByTime]) {
            groupedData[filterByTime] = {};
          }
          if (!groupedData[filterByTime][item[label]]) {
            groupedData[filterByTime][item[label]] = item.count
            if (!allLabels.includes(item[label])) allLabels.push(item[label]);
          } else {
            groupedData[filterByTime][item[label]]++
          }
        } else {
          if (item.contact !== null) {
            if (!groupedData[item.contact]) {
              groupedData[item.contact] = {};
            }
            if (!groupedData[item.contact][item[label]]) {
              groupedData[item.contact][item[label]] = item.count;
              if (!allLabels.includes(item[label])) allLabels.push(item[label]);
            } else {
              groupedData[item.contact][item[label]]++;
            }
          }
        }
        setAllLabels(allLabels.filter(Boolean));
      });
    }

    // Round value for elapsed time
    if (label === null) {
      Object.keys(groupedData).forEach(item => {
        groupedData[item]["Heures ouvrées Elit"] = Math.round(groupedData[item]["Heures ouvrées Elit"])
        groupedData[item]["Heures ouvrées totales"] = Math.round(groupedData[item]["Heures ouvrées totales"])
      })
    }
    // Set Chart
    setDataForChart(groupedData);
    setTitleChart(title);
  };

  return (
    <>
      {dataForChart !== null && Object.keys(dataForChart).length > 0 ?
        <ChartData
          dataForChart={dataForChart}
          allLabels={allLabels}
          isStacked={isStacked}
          titleChart={titleChart}
          handleGraphClick={handleGraphClick}
          typeOfChart={type}
          setNumberMaxTicksLimitY={true}
        /> : <h1 className="page-title incidents-error-title">{t("Aucune donnée")}</h1>
      }
    </>
  );
}
