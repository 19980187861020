import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/**
 *
 * @param {string} value - to display value
 */
export default function IconsStatus({ value, ticketText, color }) {
  const [className, setClassName] = useState(null);
  const { t } = useTranslation();

  const statusMap = {
    green: [
      "Opérationnel",
      "Rapport d'incident publié",
      "Résolu",
      "Service restauré",
      "Faux positif",
      "Question",
      "connected",
      "connected_with_issues",
      "Connected",
      "Free",
      "FreeUntil",
      "True",
      "Available",
      "Staring",
      "Active",
      "Started",
      "On",
      "OK",
      "YES",
      "OUI",
      "Protect",
      "isolated",
      "SLA RESPECTE",
      "VA",
      "Encrypted",
      "online",
      "true",
      "Online",
      "5",
      "ACTIVE",
      "resolved"
    ],
    yellow: [
      "Basse S4",
      "Open",
      "Probleme",
      "Vérification",
      "Correction",
      "normal",
      "alerting",
      "Alerting",
    ],
    orange: [
      "connected_with_issues",
      "Restauration",
      "Stopped",
      "BookedUntil",
      "Unstable",
      "Domain Controller",
      "Haute S2",
      "Incident",
      "alerting",
      "high",
      "Non résolu",
      "warning",
    ],
    red: [
      "NotConnected",
      "disconnected",
      "offline_expired",
      "False",
      "Unavailable",
      "Off",
      "Stopped",
      "Disabled",
      "Inactive",
      "NotFound",
      "NO",
      "NON",
      "not_isolated",
      "Audit",
      "Moyenne S3",
      "SLA DEPASSE",
      "alerting",
      "false",
      "Incident hors-contrat",
      "offline",
      "Interruption",
      "Dégradation",
      "critical",
      "Offline",
      "EXPIRED",
    ],
    darkRed: ["Critique S1"],
    grey: [
      "unknown",
      "Unknown",
      "Not supported",
      "dormant",
      "Question",
      "Investigation suspendue",
      "indisponible",
    ],
    blue: ["Changement", "Changement mineur", "Machines W7", "created"],
    marine: ["Changement majeur"]
  };

  useEffect(() => {
    // set className to have the right color
    Object.entries(statusMap).forEach(([key, status]) => {
      if (status.includes(value)) {
        setClassName(key);
      }
    });
  }, [value]);

  // format value to display with uppercase and no underscrore
  const formatValue = () => {
    let valueForm =
      typeof value === "string"
        ? value.charAt(0).toUpperCase() + value.slice(1).replaceAll("_", " ")
        : value;
    if (valueForm === "Connected with issues") {
      valueForm = t("Issues");
    } else if (valueForm === "Domain Controller") {
      valueForm = t("Domain ctrl");
    } else {
      return t(valueForm);
    }
    return t(valueForm);
  };

  return (
    <>
      <p className={`${color ? color : className ? className : "text"}`}>
        <span className="status-value">
          {ticketText ? t(ticketText) : formatValue()}
        </span>
      </p>
    </>
  );
}
