import React, { useEffect, useState } from "react";
import Loader from "../../../../../components/Loader";
import { useTranslation } from "react-i18next";

export default function TypeLocalitySurvey({ data, setDataGraph, setFormattedHeader }) {
    const [localitySurvey, setLocalitySurvey] = useState();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        handleGraphLocalitySurvey(data);
    }, [data]);

    const formatDuration = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
    };

    const handleGraphLocalitySurvey = (data) => {
        let localities = {};
        data.filter(item => {
            const location = item.location || 'Unknown';
            if (!localities[location]) {
                localities[location] = {
                    Outgoing: {
                        total: 0,
                        durationTotal: 0,
                        averageduration: 0,
                        response: 0,
                        averageresponse: 0,
                        noresponse: 0
                    },
                    Incoming: {
                        total: 0,
                        durationTotal: 0,
                        averageduration: 0,
                        response: 0,
                        averageresponse: 0,
                        noresponse: 0
                    }
                };
            }
            if (item.direction === "TERMINATING") {
                localities[location].Incoming.total += 1;
                localities[location].Incoming.durationTotal += Math.floor(parseInt(item.duration) || 0);
                if (item.answerindicator === "Yes") {
                    localities[location].Incoming.response += 1;
                    localities[location].Incoming.averageresponse += Math.floor(parseInt(item.ringduration) || 0);
                } else if (item.answerindicator === "No") {
                    localities[location].Incoming.noresponse += 1;
                }
            } else if (item.direction === "ORIGINATING") {
                localities[location].Outgoing.total += 1;
                localities[location].Outgoing.durationTotal += Math.floor(parseInt(item.duration) || 0);
                if (item.answerindicator === "Yes") {
                    localities[location].Outgoing.response += 1;
                    localities[location].Outgoing.averageresponse += Math.floor(parseInt(item.ringduration) || 0);
                } else if (item.answerindicator === "No") {
                    localities[location].Outgoing.noresponse += 1;
                }
            }
        });

        for (let location in localities) {
            let Outgoing = localities[location].Outgoing;
            let Incoming = localities[location].Incoming;

            Outgoing.averageduration = Outgoing.total > 0 ? Outgoing.durationTotal / Outgoing.total : 0;
            Outgoing.averageresponse = Outgoing.response > 0 ? Outgoing.averageresponse / Outgoing.response : 0;

            Incoming.averageduration = Incoming.total > 0 ? Incoming.durationTotal / Incoming.total : 0;
            Incoming.averageresponse = Incoming.response > 0 ? Incoming.averageresponse / Incoming.response : 0;

            Outgoing.durationTotal = formatDuration(Outgoing.durationTotal);
            Outgoing.averageduration = formatDuration(Outgoing.averageduration);
            Outgoing.averageresponse = formatDuration(Outgoing.averageresponse);

            Incoming.durationTotal = formatDuration(Incoming.durationTotal);
            Incoming.averageduration = formatDuration(Incoming.averageduration);
            Incoming.averageresponse = formatDuration(Incoming.averageresponse);
        }
        setLocalitySurvey(localities);
        const formattedData = formatForExcel(localities);
        setDataGraph(formattedData);
    }

    const formatForExcel = (data) => {
        // En-têtes des colonnes
        const headers = ["", "Sortants", "Entrants"];

        const keysMapping = [
            { key: "response", label: "Nombre d'appels répondu" },
            { key: "averageresponse", label: "Durée moyenne pour répondre" },
            { key: "noresponse", label: "Nombre d'appels non répondu" },
            { key: "durationTotal", label: "Durée totale d'appels" },
            { key: "averageduration", label: "Durée moyenne d'appels" },
        ];

        // Format des données pour Excel : première ligne = headers
        const excelData = [headers];
    
        // Parcourir chaque zone (Courbevoie, Lyon, etc.)
        Object.keys(data).forEach(zone => {
            // Ajouter une ligne pour la zone
            excelData.push([`Zone: ${zone}`, "", ""]);

            // Parcourir chaque clé et créer les lignes associées
            keysMapping.forEach(({ key, label }) => {
                const row = [
                    label,  // Utiliser la traduction pour le label
                    data[zone].Outgoing[key] !== undefined ? data[zone].Outgoing[key] : null,  // Valeur pour Sortants
                    data[zone].Incoming[key] !== undefined ? data[zone].Incoming[key] : null   // Valeur pour Entrants
                ];
                excelData.push(row);  // Ajouter la ligne à excelData
            });
        });

        setFormattedHeader(true);

        return excelData;
    };

    if (localitySurvey && Object.keys(localitySurvey).length === 0) {
        return (
            <div>Aucune donnée disponible</div>
        );
    }

    return (
        <div className="localitySurvey">
            {localitySurvey ? (
                <div>
                    <table className="localitySurvey-sortants">
                        <thead>
                            <tr className="sortant"><th colSpan={6}>{t("Sortants")}</th></tr>
                            <tr className="sortant-head">
                                <th title="Zone">{t("Zone")}</th>
                                <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(localitySurvey).map((location, index) => (
                                <tr className="sortant-body" key={index}>
                                    <td>{location}</td>
                                    <td>{localitySurvey[location].Outgoing.response}</td>
                                    <td>{localitySurvey[location].Outgoing.averageresponse}</td>
                                    <td>{localitySurvey[location].Outgoing.noresponse}</td>
                                    <td>{localitySurvey[location].Outgoing.durationTotal}</td>
                                    <td>{localitySurvey[location].Outgoing.averageduration}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <table className="localitySurvey-entrants">
                        <thead>
                            <tr className="entrant"><th colSpan={5}>{t("Entrants")}</th></tr>
                            <tr>
                                <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(localitySurvey).map((location, index) => (
                                <tr key={index}>
                                    <td>{localitySurvey[location].Incoming.response}</td>                            
                                    <td>{localitySurvey[location].Incoming.averageresponse}</td>
                                    <td>{localitySurvey[location].Incoming.noresponse}</td>
                                    <td>{localitySurvey[location].Incoming.durationTotal}</td>                             
                                    <td>{localitySurvey[location].Incoming.averageduration}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <Loader/>
            )}
        </div>
    );
}