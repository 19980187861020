import Select from "./Select";
import Input from "./Input";
import { useTranslation } from "react-i18next";

/**
 * 
 * @param {function} handleSubmit - submit function of react hook form
 * @param {function} mutate - from useQuery - mutate data and refetch
 * @param {function} onInvalid - handle errors
 * @param {function} register - from react hook form
 * @param {boolean} isError - from useQuery
 * @param {array} errors - form errors from react hook form
 * @param {object} error - if request useQuery has an error
 * @param {function} setHoldingSelected - to stock holding select for header form
 */
export default function MultipleForm({
  handleSubmit,
  mutate,
  onInvalid,
  register,
  formData,
  isError,
  errors,
  error,
  action,
}) {

  const {t} = useTranslation();
  return (
    <form className="form" onSubmit={handleSubmit(mutate, onInvalid)}>
      {formData.length > 0 &&
        formData.map((item) => {
          return (
            <div className="form-input_container" key={item.id}>
              <label htmlFor={item.id}>{t(item.title)}</label>
              {item.type === "select" && item !== undefined ? (
                <Select
                  item={item}
                  register={register}
                />
              ) : (
                <Input item={item} register={register} />
              )}
              {errors[item.id] && (
                <small className="error">{t(errors[item.id].message)}</small>
              )}
            </div>
          );
        })}
      <button className="button button-new">
        {action === "new"
          ? t("Créer")
          : action === "update"
          ? t("Modifier")
          : t("Supprimer")}
      </button>
      {isError ? <small className="error">{t(error?.message)}</small> : null}
    </form>
  );
}
