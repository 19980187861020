import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import ButtonArrow from "../../components/ButtonArrow";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import AuvikStatisticsCard from "./AuvikStatisticsCard.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { convertTimestampFormatZ } from '../../components/utils.js';
import Loader from "../../components/Loader";

export default function Auvik() {
  const { apiUrl } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [alertsPerSite, setAlertsPerSite] = useState({});
  const [alertsStatePopup, setAlertsStatePopup] = useState(false);
  const { t } = useTranslation();

  const getTenantsLive = async () => {
    try {
      const res = await axios.get(`${apiUrl}auvik/tenants_live`, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
    }
  };

  const tenantsLive = useQuery({
    queryKey: ["auvik"],
    queryFn: getTenantsLive,
  });

  const getWebhookAlerts = async () => {
    try {
      const res = await axios.get(`${apiUrl}webhook/auvik/alerts`, {
        withCredentials: true,
      });
      const filtered = filterData(res.data);
      return filtered;
    } catch (error) {
    }
  };

  const webhookAlerts = useQuery({
    queryKey: ["webhook"],
    queryFn: getWebhookAlerts,
  });

  const filterData = (data) => {
    const filtered = {};
  
    data.forEach(entry => {
      const { device, alertDescription, date } = entry;
  
      if (!filtered[device] || new Date(date) > new Date(filtered[device].date)) {
        filtered[device] = {
          ...entry,
          status: alertDescription === "This network element went offline but is now online again" ? 'online' : 'offline',
          date: date
        };
      }
    });
  
    const filteredData = Object.values(filtered).filter(entry => entry.status !== 'online');
    return filteredData;
  };

  useEffect(() => {
    queryClient.invalidateQueries(["auvik", tenantsLive.data]);
  }, [tenantsLive.data]);

  useEffect(() => {
    if (webhookAlerts.data) {
      const alertsPerSiteCounts = webhookAlerts.data.reduce((counts, alert) => {
        const { companyName } = alert;
        counts[companyName] = (counts[companyName] || 0) + 1;
        counts['all'] = (counts['all'] || 0) + 1;
        return counts;
      }, {});
      setAlertsPerSite(alertsPerSiteCounts);
      setAlertsStatePopup(true);
    }
  }, [webhookAlerts.data]);

  const handleClickWebhook = () => {
    setAlertsStatePopup(false);
  }

  if (tenantsLive.data && tenantsLive.data[0].data !== undefined || tenantsLive === undefined) {
    return <article className="page">
      <h1 className="page-title"> {t('Pas de données Auvik disponibles')}</h1>
    </article>
  }

  return (
    <>
      {!tenantsLive.isLoading ?
        <>
          <article className="page">
            {webhookAlerts.data && webhookAlerts.data.length > 0 && webhookAlerts.data[0] !== 'No data' && (
              <div className={`${alertsStatePopup === false ? 'alerts-webhook-close' : ''} alerts-webhook-container`}>
                <button onClick={() => handleClickWebhook()} className="close-alerts-status-length">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" className="svg-inline--fa fa-xmark " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"></path>
                    </svg>
                </button>
                <FontAwesomeIcon icon={faCircleExclamation} color="rgb(255, 187, 0)"/>
                Vous avez <strong>{webhookAlerts.data.length}</strong> alertes
              </div>
            )}
            <section className="page-logo">
              <img src="./assets/auvik_logo.png" alt="Logo de Auvik" />
              <h1 className="page-title">Auvik</h1>
              <ButtonArrow url={'https://auth.auvik.com/'} />
            </section>
            <section className="statistics-auvik auvik">
              <h2>{t('Sites')}</h2>
              {tenantsLive.data &&
                <section className="statistics-auvik-container">
                  {tenantsLive.data.map((tenant) => (<AuvikStatisticsCard key={tenant.auvik_id} firstTenant={tenantsLive.data.length > 1 ? tenantsLive.data[0] : null} statisticsAuvik={tenant} alerts={alertsPerSite} />))}
                </section>
              }
            </section>
          </article>
        </>
        : <Loader />
      }
    </>
  );
}