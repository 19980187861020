import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Loader from "../../components/Loader";
import TableComponent from "../../components/TableComponent";
import { AuthContext } from "../../context/AuthContext";
import {UmbrellaIdentiteColumn} from "../../components/headerContent";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export default function IdentitieTop() {
  const { apiUrl} = useContext(AuthContext);
  const [days, setDays] = useState(7);
  const [limit, setLimit] = useState(10);
  const {t} = useTranslation();

  // Request
  const getIdentities = async() => {
    const res = await axios(`${apiUrl}umbrella/top_identity/${days}/${limit}`, {
      method: "get",
      withCredentials: true,
    })

    if(res.data.data.error) {
      return []
    }

        const identitiesForTable = await res.data.data.map((item) => {
          return {
            requests: item.requests,
            allowed: item.counts.allowedrequests,
            blocked: item.counts.blockedrequests,
            identity_type: item.identity.type.label,
            identity_name: item.identity.label,
            identity_deleted: item.identity.deleted,
          };
        });

        return identitiesForTable
     
  };

  const identities = useQuery({
    queryKey: ["identities"],
    queryFn: getIdentities
  })

  useEffect(() => {
    identities.refetch()
  }, [ days, limit]);

  return (
    <>
      <section className="page-section">
          <div className="page-section_form">
            <form className="form">
              <div className="form-input_container limit">
                <select
                  className="only"
                  id="days"
                  onChange={(e) => setDays(e.target.value)}
                >
                  <option value="7">7 </option>
                  <option value="15">15 </option>
                  <option value="30"> 30 </option>
                </select>
                <label htmlFor="days">{t('derniers jours')}</label>
              </div>
            </form>
            <form className="form">
              <div className="form-input_container limit">
                <select
                  className="only"
                  id="days"
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
                <label htmlFor="days">{t('derniers évènements')}</label>
              </div>
            </form>
          </div>
      </section>
      {!identities.isFetching ? (

          <TableComponent
            tableColumns={UmbrellaIdentiteColumn}
            tableData={identities.data}
            checkbox={false}
          />

      ) : (
        <Loader />
      )}
    </>
  );
}
