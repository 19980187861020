import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next"

/**
 * 
 * @param {string} title - of the card
 * @param {number} number - lenght of the table to display
 * @param {string} logo - url if you want to display an img
 * @param {string} color - color logo
 * @param {string} stylesIcons -background logo
 */
export default function NumberCard({title, number, logo, color, stylesIcons, styleCard}) {
    const {t} = useTranslation();

    return (
        <div className={`card card-${styleCard}`}>
            {logo && stylesIcons && color &&
                <div className={`card-background-logo card-${stylesIcons}`}>
                    <FontAwesomeIcon icon={logo} color={color} />
                </div>
            }
            <h2 className="card-title">
                {t(title)}
            </h2>
            <p className="card-number">{number}</p>
        </div>
    )
}