import React, { useEffect, useContext, useState, useRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import TypeSwitch from "./InterfacesType/TypeSwitch";
import InterfacesStatistics from "./InterfacesStatistics";
import AuvikAlertsDevice from "./AuvikAlertsDevice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";

export default function RowAuvik({ item }) {
    const { apiUrl } = useContext(AuthContext)
    const params = useParams();
    const queryClient = useQueryClient();
    const [displayAlerts, setDisplayAlerts] = useState(false);
    const tenantId = params.tenantId;
    const { t } = useTranslation();

    const getInterfaces = async () => {
        const res = await axios.get(`${apiUrl}auvik/interface/${tenantId}/${item.id}`, {
            withCredentials: true,
        });
        return res.data;
    };

    const interfaces = useQuery({
        queryKey: ["interfaces", item.id],
        queryFn: () => getInterfaces('interfaces'),
    });
    
    useEffect(() => {
        queryClient.invalidateQueries(["interfaces", interfaces.data]);

        const allRows = document.querySelectorAll('.table-row');
        const allExpandedRows = document.querySelectorAll('.table-row_expanded');
        const allAccordionRows = document.querySelectorAll('.table-row_expanded_accordion');
    
        allRows.forEach((row, index) => {
          row.style.backgroundColor = index % 2 === 0 ? '#F3F5F9' : '#EBEDF3';
        });
    
        allAccordionRows.forEach((row, index) => {
          const aboveRowColor = getComputedStyle(allExpandedRows[index]).backgroundColor;
          row.style.backgroundColor = aboveRowColor;
        });
    }, [interfaces.data]);

    const handleDisplayAlerts = () => {
        setDisplayAlerts(true);
    };

    if(interfaces.data && interfaces.data[0].data !== undefined || interfaces === undefined) {
        return <article className="page">
          <h1 className="page-title"> {t('Pas de données disponibles')}</h1>
        </article>
    }

    return (
        <div className="auvik-charts">
            <div className="auvik-charts-container">
                <div className="auvik-charts-container-interfaces">
                    {interfaces.isFetched ? (
                        <>
                            <div className="alerts-auvik-device">
                                <div onClick={handleDisplayAlerts} className="card-background-logo card-logo-interfaces-alerts alert-auvik-device">
                                    <FontAwesomeIcon icon={faCircleExclamation} color="rgb(255, 187, 0)"/>
                                </div>
                            </div>
                            {item.deviceType === "stack" || item.deviceType === "controller" || item.deviceType === "switch" || item.deviceType === "l3Switch" ? (
                                <TypeSwitch tenantId={tenantId} interfaces={interfaces} interfaceModel={item} />
                            ) : (
                                <div className="interfaces-svg-none">
                                    <InterfacesStatistics tenantId={tenantId} interfaces={interfaces} />
                                </div>
                            )}
                            <AuvikAlertsDevice device={item} refreshData={displayAlerts} setDisplayAlerts={setDisplayAlerts}/>
                        </>
                    ) : (
                        <Loader />
                    )}
                </div>
            </div>
        </div>
    );
}