import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { useQueries } from "@tanstack/react-query";
import ChartData from "../../components/ChartData";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

export default function ChartTeamAgentInsight({ data }) {
    const { apiUrl } = useContext(AuthContext);
    const {t} = useTranslation();

    const getTicketsStatsByTeam = async (name_team) => {
        const res = await axios.get(`${apiUrl}halo/ticket_stats_by_team/${name_team}`, {
            withCredentials: true
        });
        return res.data;
    };

    const teamStatsQueries = useQueries({
        queries: (Array.isArray(data) ? data : []).map((team) => ({
            queryKey: ["ticketsStatsByTeam", team.name],
            queryFn: () => getTicketsStatsByTeam(team.name),
        }))
    });

    const isLoading = teamStatsQueries.some(query => query.isLoading);
    const isError = teamStatsQueries.some(query => query.isError);

    if (isLoading) return <Loader />;
    if (isError) return <div>{t("Error loading team stats")}</div>;
    // Structurer les données en regroupant par mois et ajoutant chaque équipe à chaque mois
    const structuredDataOpenedTickets = {};
    const structuredDataClosedTickets = {};
    const labels = [];

    teamStatsQueries.forEach((query, teamIndex) => {
        if (query.data) {
            const teamName = data[teamIndex].name;
            labels.push(teamName);
            query.data.data.forEach((entry) => {
                const month = entry.month;

                if (!structuredDataOpenedTickets[month]) {
                    structuredDataOpenedTickets[month] = {}; // Initialise la clé pour chaque mois
                }

                structuredDataOpenedTickets[month][teamName] = parseInt(entry.opened_tickets, 10);

                if (!structuredDataClosedTickets[month]) {
                    structuredDataClosedTickets[month] = {}; // Initialise la clé pour chaque mois
                }

                structuredDataClosedTickets[month][teamName] = parseInt(entry.closed_tickets, 10);
            });
        }
    });

    if(structuredDataClosedTickets === undefined && structuredDataOpenedTickets === undefined) {
        return (
            <div className="error-message-agents-ingight">Aucune statistique graphique n'est disponible</div>
        )
    }

    return (
        <section>
            {structuredDataClosedTickets && structuredDataOpenedTickets ? (
                <> 
                    <ChartData
                        dataForChart={structuredDataOpenedTickets}
                        allLabels={labels}
                        titleChart={t("Graphique des tickets ouverts")}
                        typeOfChart={"line"}
                        maxHeight={400}
                        automaticColors={true}
                        interactionStatus={true}
                    />
                    <ChartData
                        dataForChart={structuredDataClosedTickets}
                        allLabels={labels}
                        titleChart={t("Graphique des tickets clos")}
                        typeOfChart={"line"}
                        maxHeight={400}
                        automaticColors={true}
                        interactionStatus={true}
                    />
                </>
            ) : (
                <Loader/>
            )}
           
</section>
    )
}