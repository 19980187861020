import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";

import { SmartAccountColumn, VirtualAccountColumn } from "../../components/headerContent";
import CarousselNav from "../../components/Card/CarousselNav";
import Loader from "../../components/Loader";
import { useQuery } from "@tanstack/react-query";
import TableComponent from "../../components/TableComponent";

export default function SmartAccount() {

    const valuesType = ["Accounts", "Virtual accounts"];
    const [currentSlide, setCurrentSlide] = useState(0);

    const { apiUrl, user } =
    useContext(AuthContext);

    const getAccount = async () => {
        const res = await axios(`${apiUrl}smart_account/`, {
            method: "get", 
            withCredentials: true
        })
            const account = res.data.accounts.map(account => {
                return {
                    ...account,
                    roles: account.roles.map(role => {return role.role})
                }
            })
            return account
    }

    const account = useQuery({
        queryKey: ["account"],
        queryFn: getAccount, 
    })

    const getVirtualAccount = async () => {
       const res = await axios(`${apiUrl}smart_account/virtual_account`, {
            method: "get", 
            withCredentials: true
        })
        const virtual = res.data.virtualAccounts
        return virtual
    }

    const virtualAccount = useQuery({
        queryKey: ["virtualAccount"],
        queryFn: getVirtualAccount, 
    })

    

    return (
        <>
        {!account.isLoading ? 
        <article className="page">
            <h1 className="page-title">Smart account</h1>
            <CarousselNav arrayValues={valuesType} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} />
            <section className="page-table">
            {currentSlide === 0 && <TableComponent tableColumns={SmartAccountColumn} tableData={account.data} checkbox={false}/>}
            {currentSlide === 1 && <TableComponent tableColumns={VirtualAccountColumn} tableData={virtualAccount.data} checkbox={false}/>}
            </section>
        </article> : <Loader />
        }
        </>
        
    )
}