import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Loader from "../../components/Loader";
import TableComponent from "../../components/TableComponent";
import { AuthContext } from "../../context/AuthContext";
import { UmbrellaDestinationColumns } from "../../components/headerContent";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export default function DestinationTop() {
  const { apiUrl} = useContext(AuthContext);
  const [days, setDays] = useState(7);
  const [limit, setLimit] = useState(10);
  const {t} = useTranslation();

  // Request
  const getDestinations = async () => {
    const res = await axios(`${apiUrl}umbrella/top_destination/${days}/${limit}`, {
      method: "get",
      withCredentials: true,
    })
        const destinationsForTable = res.data.data.map((item) => {
          let categories = item.categories
            .map((categorie) => {
              return categorie.label;
            })
            .join(", ");
          return {
            domain: item.domain,
            requests: item.count,
            categories: categories,
            allowed: item.counts.allowedrequests,
            blocked: item.counts.blockedrequests,
          };
        });

        return destinationsForTable
     
  };

  const destinations = useQuery({
    queryKey: ["destinations"],
    queryFn: getDestinations
  })

  useEffect(() => {
    destinations.refetch()
  }, [days, limit]);

  return (
    <div className="umbrella-destination">
       <section className="page-section">
          <div className="page-section_form">
            <form className="form">
              <div className="form-input_container limit">
                <select
                  className="only"
                  id="days"
                  onChange={(e) => setDays(e.target.value)}
                >
                  <option value="7">7 </option>
                  <option value="15">15 </option>
                  <option value="30"> 30 </option>
                </select>
                <label htmlFor="days">{t('derniers jours')}</label>
              </div>
            </form>
            <form className="form">
              <div className="form-input_container limit">
                <select
                  className="only"
                  id="days"
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
                <label htmlFor="days">{t('derniers évènements')}</label>
              </div>
            </form>
          </div>
      </section>
      {!destinations.isFetching ? (
          <TableComponent
            tableColumns={UmbrellaDestinationColumns}
            tableData={destinations.data}
            checkbox={false}
          />
      ) : (
        <Loader />
      )}
    </div>
  );
}
