import axios from "axios";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Loader from "../../components/Loader";
import {
    faCircleInfo,
    faListOl,
    faPencil,
    faWeightScale,
} from "@fortawesome/free-solid-svg-icons";
import IconsStatus from "../../components/IconStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export default function TimelineSubscription({ item, setStateQueryRow }) {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { apiUrl, user } = useContext(AuthContext);
    const queryClient = useQueryClient();

    const getSub = async () => {
        const res = await axios.get(`${apiUrl}subscription/ccw/${item.name}`, {
            withCredentials: true,
        });
        return res.data.items;
    };

    const sub = useQuery({
        queryKey: ["sub", item.name],
        queryFn: getSub,
    });

    const updateSub = async () => {
        setIsLoading(true);
        const res = await axios.get(`${apiUrl}subscription/update/${item.name}`, {
            withCredentials: true,
        });
        return res.data;
    };

    const updatingSub = useQuery({
        queryKey: ["updateSub", item.name],
        queryFn: updateSub,
        onSuccess: () => {
            setIsLoading(false);
            queryClient.resetQueries({ queryKey: ["subscription"] });
            setStateQueryRow({ state: true, name: item.name, source: item.source, type: 'reset' });
        },
        enabled: false
    });

    return (
        <>
            {!sub.isLoading && !isLoading ? (
                sub.data !== undefined ? (
                    <>
                        <div className="contract">
                                <>
                                    <div className="contract-header">
                                        <span className="contract-title">
                                            <FontAwesomeIcon
                                                icon={faPencil}
                                                className="contract-icon"
                                            />
                                            {t('Description')}
                                        </span>
                                        <span className="contract-title">
                                            <FontAwesomeIcon
                                                icon={faWeightScale}
                                                className="contract-icon"
                                            />
                                            {t('Unité de mesure du prix')}
                                        </span>
                                        <span className="contract-title">
                                            <FontAwesomeIcon
                                                icon={faListOl}
                                                className="contract-icon"
                                            />
                                            {t('Quantité')}
                                        </span>
                                        <span className="contract-title">
                                            <FontAwesomeIcon
                                                icon={faCircleInfo}
                                                className="contract-icon"
                                            />
                                            {t('Type de quantité')}
                                        </span>
                                    </div>
                            
                                    {sub.data.map((sub, index) => {
                                        return <div className="contract-body" key={index}>
                                            <span className="contract-content">
                                                {t(sub.description)}
                                            </span>
                                            <span className="contract-content">
                                                {t(sub.pricingUnitOfMeasure)}
                                            </span>
                                            <span className="contract-content">
                                                <IconsStatus value={sub.quantity} />
                                            </span>
                                            <span className="contract-content">
                                                {t(sub.typeOfQuantity)}
                                            </span>
                                        </div>
                                    })}
                                </>
                        </div>
                        <div className="table-update" key={item.name}>
                            <button className="button button-new" onClick={() => updatingSub.refetch()}>{t('Mettre à jour')}</button>
                        </div>
                    </>
                ) : (
                    <div>{t("Aucune données disponible")}</div>
                )
            ) : (
                <Loader />
            )}
        </>
    );
}
