import { TableCell } from "@mui/material";
import Update from "../../components/Update";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import {
  UserEditSchema,
  passwordSchema,
} from "../../components/Forms/schema/YupSchema";
import Form from "../../components/Forms/Form";
import { useQuery } from "@tanstack/react-query";

export default function RowUsers({ item, holding, allRoles }) {

  const [holdingSelected, setHoldingSelected] = useState(item.holding_id);
  const { apiUrl } = useContext(AuthContext);

  useEffect(() => {
    if (holdingSelected === null) {
      setHoldingSelected(item.holding_id)
    }
  }, [holdingSelected])


  // get all entreprise
  const getEntreprise = async () => {
    const res = await axios(`${apiUrl}commerce/${holdingSelected}/entreprise`, {
      method: "get",
      withCredentials: true,
    });
    return res.data;
  };

  const entreprise = useQuery({
    queryKey: ["entreprises"],
    queryFn: getEntreprise,
    enabled: false,
  });

  useEffect(() => {
    if (holdingSelected !== undefined) {
      entreprise.refetch();
    }
  }, [holdingSelected]);


  const formContentUpdate = [
    { id: "email", type: "email", title: "Email", value: item.email },
    { id: "prenom", type: "text", title: "Prénom", value: item.prenom },
    { id: "nom", type: "text", title: "Nom", value: item.nom },
    {
      id: "holding",
      type: "select",
      values:
        holding &&
        [
          ...(holding &&
            holding.map((holding) => ({
              value: holding.id,
              label: holding.nom,
            }))),
        ].sort((a, b) => {
          return a === b ? 0 : a.label < b.label ? -1 : 1;
        }),
      title: "Holding",
      change: (e) => setHoldingSelected(e.target.value),
    },
    {
      id: "entreprise",
      type: "select",
      values: entreprise.data ? [
        ...entreprise.data.map((entreprise) => ({
          value: entreprise.id,
          label: entreprise.nom,
        })),
      ].sort((a, b) => {
        return a === b ? 0 : a.label < b.label ? -1 : 1;
      }) : [],
      title: "Entreprise",
      change: false,
    },
    {
      id: "roles",
      type: "select",
      values: allRoles.map((role) => role),
      multipleSelected: true,
      value: item.roles,
      title: "Rôles",
    },
  ];

  const updateUser = async (data) => {
    const updateData = {
      ...data,
      roles: data.roles.length === 0 ? item.roles : data.roles,
    };
    delete updateData.rolesDisplay;
    const res = await axios(`${apiUrl}user/${item.id}/edit`, {
      method: "put",
      withCredentials: true,
      data: updateData,
    });
    return res.data;
  };

  const deleteUser = async () => {
    const res = await axios(`${apiUrl}user/${item.id}/delete`, {
      method: "delete",
      withCredentials: true,
    });

    window.location.reload();
    
    return res.data;
  };

  const formPassword = [
    { id: "first", type: "password", title: "Nouveau mot de passe" },
    { id: "second", type: "password", title: "Confirmer le mot de passe" },
  ];

  const editPassword = async (data) => {
    const res = await axios(`${apiUrl}user/${item.id}/editPassword`, {
      method: "put",
      withCredentials: true,
      data: {
        password: {
          first: data.first,
          second: data.second,
        },
      },
    });
    return res.data;
  };

  return (
    <>
      <div className="update ">
        <TableCell className="table-row_cell">
          <Form
            formContent={formPassword}
            schema={passwordSchema}
            submitFunction={editPassword}
            title={"Modifier le mot de passe"}
            titleBis="Modifer le mot de passe"
            action="update"
            setHoldingSelected={setHoldingSelected}
          />
        </TableCell>
        <Update
          formContent={formContentUpdate}
          schema={UserEditSchema}
          submitFunction={updateUser}
          deleteFunction={deleteUser}
          setHoldingSelected={setHoldingSelected}
        />
      </div>
    </>
  );
}
