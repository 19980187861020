import React, { useEffect, useState } from "react";
import ChartsWebex from "../../Charts/ChartsWebex";
import Loader from "../../../../../components/Loader";
import { StackedLineChart, StackedBarChart } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function TypeTrendsTime({ data, analyseType, setDataGraph, setFormattedHeader }) {
    const [typeTrends, setTypeTrends] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTypeChart, setSelectedTypeChart] = useState('trends-bar');
    const { t, i18n } = useTranslation();
  
    useEffect(() => {
        if (data.length > 0) {
            handleGraphTypeTrends(data, analyseType);
        }
    }, [data, analyseType]);

    const handleGraphTypeTrends = (data, analyseType) => {
        const sortedData = data.sort((a, b) => new Date(a.starttime) - new Date(b.starttime));
        const callCounts = {};
        const uniqueKeys = new Set();

        // Helper function to format date based on analyseType
        const formatDate = (date) => {
            const d = new Date(date);
            switch (analyseType) {
                case "Heures":
                    return `${d.getHours()}:00`;
                case "Jours de la semaine":
                    return d.toLocaleDateString('fr-FR', { weekday: 'long' });
                case "Semaine":
                    const weekNumber = getWeekNumber(d);
                    return `${d.getFullYear()}-W${weekNumber}`;
                case "Trimestres":
                    const quarter = Math.floor((d.getMonth() + 3) / 3);
                    return `${d.getFullYear()}-T${quarter}`;
                case "Mois":
                    return d.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' });
                default:
                    return d.toLocaleDateString();
            }
        };

        // Generate the complete set of keys based on analyseType
        const allKeys = generateAllKeys(analyseType);

        sortedData.forEach(item => {
            const callKey = formatDate(item.starttime);
            uniqueKeys.add(callKey);
            if (callCounts[callKey]) {
                callCounts[callKey]++;
            } else {
                callCounts[callKey] = 1;
            }
        });

        let resultData;

        if (analyseType === "Heures") {
            const sortedKeys = allKeys;
            resultData = sortedKeys.map(key => ({
                key,
                totalCalls: callCounts[key] || 0,
                data: data.filter(item => formatDate(item.starttime) === key)
            }));
        } else if (analyseType === "Jours de la semaine") {
            const sortedKeys = allKeys;
            resultData = sortedKeys.map(key => ({
                key,
                totalCalls: callCounts[key] || 0,
                data: data.filter(item => formatDate(item.starttime) === key)
            }));
        } else if (analyseType === "Semaine") {
            const maxWeek = Object.keys(callCounts).reduce((maxWeek, currentWeek) => {
                return callCounts[currentWeek] > callCounts[maxWeek] ? currentWeek : maxWeek;
            }, Object.keys(callCounts)[0]);

            const maxWeekData = data.filter(item => formatDate(item.starttime) === maxWeek);
            const daysInMaxWeek = new Set(maxWeekData.map(item => new Date(item.starttime).toLocaleDateString()));

            resultData = Array.from(daysInMaxWeek).map(day => ({
                key: day,
                totalCalls: maxWeekData.filter(item => new Date(item.starttime).toLocaleDateString() === day).length,
                data: maxWeekData.filter(item => new Date(item.starttime).toLocaleDateString() === day)
            }));
        } else if (analyseType === "Trimestres") {
            const sortedKeys = allKeys;
            resultData = sortedKeys.map(key => ({
                key,
                totalCalls: callCounts[key] || 0,
                data: data.filter(item => formatDate(item.starttime) === key)
            }));
        } else if (analyseType === "Mois") {
            const sortedKeys = allKeys;
            resultData = sortedKeys.map(key => ({
                key,
                totalCalls: callCounts[key] || 0,
                data: data.filter(item => formatDate(item.starttime) === key)
            }));
        } else {
            resultData = Array.from(uniqueKeys).map(key => ({
                key,
                totalCalls: callCounts[key] || 0,
                data: data.filter(item => formatDate(item.starttime) === key)
            }));
        }

        const aggregatedData = aggregateData(resultData, analyseType);

        setTypeTrends(aggregatedData);
        const formattedData = formatForExcel(aggregatedData);
        setDataGraph(formattedData);
    };

    const formatForExcel = (data) => {
        // En-têtes des colonnes
        const headers = ["", "Sortants", "Entrants", "Total"];

        const keysMapping = [
            { key: "response", label: "Nombre d'appels répondu" },
            { key: "averageresponse", label: "Durée moyenne pour répondre" },
            { key: "noresponse", label: "Nombre d'appels non répondu" },
            { key: "durationTotalFormatted", label: "Durée totale d'appels" },
            { key: "averageduration", label: "Durée moyenne d'appels" },
        ];
        const keysMappingTotal = [
            { key: "responseTotal", label: "Nombre total des appels répondu" },
            { key: "durationTotalFormatted", label: "Durée totale des appels" },
        ];

        const keysMappingTotalEndLineOutgoing = [
            { keyOutgoing: "responseOutgoing", keyIncoming: "responseIncoming", label: "Nombre d'appels répondu" },
            { keyOutgoing: "averageresponseOutgoing",keyIncoming: "averageresponseIncoming", label: "Durée moyenne pour répondre" },
            { keyOutgoing: "noresponseOutgoing", keyIncoming: "noresponseIncoming", label: "Nombre d'appels non répondu" },
            { keyOutgoing: "durationOutgoing", keyIncoming: "durationIncoming", label: "Durée totale d'appels" },
            { keyOutgoing: "averagedurationOutgoing", keyIncoming: "averagedurationIncoming", label: "Durée moyenne d'appels" },
            { keyTotal: "responseTotal", label: "Nombre totale des appels répondu"},
            { keyTotal: "durationTotal", label: "Durée totale des appels"}
        ];

        // Format des données pour Excel : première ligne = headers
        const excelData = [headers];
    
        // Parcourir chaque date dans aggregatedResults
    // Parcourir chaque date dans aggregatedResults
    data.aggregatedResults.forEach(item => {
        // Ajouter une ligne avec la date
        excelData.push([`Date: ${item.key}`, "", "", ""]);

        // Ajouter les données de chaque clé pour Sortants, Entrants et Total
        keysMapping.forEach(({ key, label }) => {
            const row = [
                label,
                item.Outgoing ? item.Outgoing[key] : null, // Sortants
                item.Incoming ? item.Incoming[key] : null, // Entrants
                null // Total initial pour cette ligne
            ];
            excelData.push(row);
        });

        // Ajouter les totaux pour cette date
        keysMappingTotal.forEach(({ key, label }) => {
            const row = [
                label,
                null, // Pas de données pour Sortants
                null, // Pas de données pour Entrants
                item.Total ? item.Total[key] : null // Total
            ];
            excelData.push(row);
        });
    });
        // Ajouter une ligne avec la date
        excelData.push([`Total`, "", "", ""]);

        // Ajouter les données de chaque clé pour Sortants, Entrants et Total
        keysMappingTotalEndLineOutgoing.forEach(({ keyOutgoing, keyIncoming, keyTotal, label }) => {
            const row = [
                label,
                data.Total ? data.Total[keyOutgoing] : null, // Sortants
                data.Total ? data.Total[keyIncoming] : null, // Entrants
                data.Total ? data.Total[keyTotal] : null // Total initial pour cette ligne
            ];
            excelData.push(row);
        });

        setFormattedHeader(true);

        return excelData;
    };

    const generateAllKeys = (analyseType) => {
        const keys = [];
        const now = new Date();

        switch (analyseType) {
            case "Heures":
                for (let i = 0; i < 24; i++) {
                    keys.push(`${i}:00`);
                }
                break;
            case "Jours de la semaine":
                keys.push("lundi", "mardi", "mercredi", "jeudi", "vendredi");
                break;
            case "Trimestres":
                for (let i = 1; i <= 4; i++) {
                    keys.push(`${now.getFullYear()}-T${i}`);
                }
                break;
            case "Mois":
                for (let i = 0; i < 12; i++) {
                    const monthDate = new Date(now.getFullYear(), i, 1);
                    keys.push(monthDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' }));
                }
                break;
            default:
                break;
        }

        return keys;
    };

    const aggregateData = (resultData, analyseType) => {
        const aggregatedResults = resultData.map(({ key, data: keyData }) => {
            const Outgoing = {
                total: 0,
                durationTotal: 0,
                averageduration: 0,
                response: 0,
                averageresponse: 0,
                noresponse: 0
            };

            const Incoming = {
                total: 0,
                durationTotal: 0,
                averageduration: 0,
                response: 0,
                averageresponse: 0,
                noresponse: 0
            };

            const Total = {
                durationTotal: 0,
                responseTotal: 0
            }

            keyData.forEach(item => {
                if (item.direction === "TERMINATING") {
                    Incoming.total += 1;
                    Incoming.durationTotal += Math.floor(parseInt(item.duration) || 0);
                    if (item.answerindicator === "Yes") {
                        Incoming.response += 1;
                        Incoming.averageresponse += Math.floor(parseInt(item.ringduration) || 0);
                    } else if (item.answerindicator === "No") {
                        Incoming.noresponse += 1;
                    }
                } else if (item.direction === "ORIGINATING") {
                    Outgoing.total += 1;
                    Outgoing.durationTotal += Math.floor(parseInt(item.duration) || 0);
                    if (item.answerindicator === "Yes") {
                        Outgoing.response += 1;
                        Outgoing.averageresponse += Math.floor(parseInt(item.ringduration) || 0);
                    } else if (item.answerindicator === "No") {
                        Outgoing.noresponse += 1;
                    }
                }
            });

            Total.responseTotal = Outgoing.response + Incoming.response + Outgoing.noresponse + Incoming.noresponse;
            Total.durationTotal = Outgoing.durationTotal + Incoming.durationTotal;

            Outgoing.averageduration = Outgoing.total > 0 ? formatDuration(Outgoing.durationTotal / Outgoing.total) : "00:00:00";
            Outgoing.averageresponse = Outgoing.response > 0 ? formatDuration(Outgoing.averageresponse / Outgoing.response) : "00:00:00";
            Outgoing.durationTotalFormatted = formatDuration(Outgoing.durationTotal);

            Incoming.averageduration = Incoming.total > 0 ? formatDuration(Incoming.durationTotal / Incoming.total) : "00:00:00";
            Incoming.averageresponse = Incoming.response > 0 ? formatDuration(Incoming.averageresponse / Incoming.response) : "00:00:00";
            Incoming.durationTotalFormatted = formatDuration(Incoming.durationTotal);

            Total.durationTotalFormatted = formatDuration(Total.durationTotal);

            return {
                key,
                Outgoing,
                Incoming,
                Total
            };
        });

        const Total = {
            durationOutgoing: aggregatedResults.reduce((sum, { Outgoing }) => sum + Outgoing.durationTotal, 0),
            averagedurationOutgoing: sumDurations(aggregatedResults.map(({ Outgoing }) => Outgoing.averageduration)),
            responseOutgoing: aggregatedResults.reduce((sum, { Outgoing }) => sum + Outgoing.response, 0),
            averageresponseOutgoing: sumDurations(aggregatedResults.map(({ Outgoing }) => Outgoing.averageresponse)),
            noresponseOutgoing: aggregatedResults.reduce((sum, { Outgoing }) => sum + Outgoing.noresponse, 0),
            durationIncoming: aggregatedResults.reduce((sum, { Incoming }) => sum + Incoming.durationTotal, 0),
            averagedurationIncoming: sumDurations(aggregatedResults.map(({ Incoming }) => Incoming.averageduration)),
            responseIncoming: aggregatedResults.reduce((sum, { Incoming }) => sum + Incoming.response, 0),
            averageresponseIncoming: sumDurations(aggregatedResults.map(({ Incoming }) => Incoming.averageresponse)),
            noresponseIncoming: aggregatedResults.reduce((sum, { Incoming }) => sum + Incoming.noresponse, 0),
            durationTotal: aggregatedResults.reduce((sum, { Outgoing, Incoming }) => sum + Outgoing.durationTotal + Incoming.durationTotal, 0),
            responseTotal: aggregatedResults.reduce((sum, { Outgoing, Incoming }) => sum + Outgoing.response + Incoming.response + Outgoing.noresponse + Incoming.noresponse, 0),
        };

        Total.durationOutgoing = formatDuration(Total.durationOutgoing);
        Total.durationIncoming = formatDuration(Total.durationIncoming);
        Total.durationTotal = formatDuration(Total.durationTotal);

        return {
            aggregatedResults,
            Total
        };
    };

    const parseDuration = (duration) => {
        const [hours, minutes, seconds] = duration.split(':').map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    };

    const formatDuration = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
    };

    const sumDurations = (durations) => {
        const totalSeconds = durations.reduce((sum, duration) => sum + parseDuration(duration), 0);
        return formatDuration(totalSeconds);
    };

    // Helper function to get the week number of a date
    const getWeekNumber = (d) => {
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        return weekNo;
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleTypeChartChange = (newTypeChart) => {
        setSelectedTypeChart(newTypeChart);
    };

    if (!typeTrends) {
        return (
            <div>Aucune données disponible</div>
        )
    }

    return (
        <div className="typeTrends">
            {typeTrends ? (
                <section className="typeTrends-element">
                    <div className="pagination">
                        <button
                            onClick={() => paginate(currentPage > 1 ? currentPage - 1 : currentPage)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <button
                            onClick={() =>
                                paginate(currentPage + 1)
                            }
                            disabled={currentPage === Math.ceil(2)}
                        >
                            Next
                        </button>
                    </div>
                    {currentPage === 1 ? (
                        <div className="typeTrends-element-charts">
                            <div className="page-chart-webex-total-content-buttons">
                                <div className="page-chart-webex-total-buttons">
                                    <button className={`${selectedTypeChart === 'trends-bar' ? 'button-target-data' : ''}`} onClick={() => handleTypeChartChange('trends-bar')}><StackedBarChart /></button>
                                    <button className={`${selectedTypeChart === 'trends-line' ? 'button-target-data' : ''}`} onClick={() => handleTypeChartChange('trends-line')}><StackedLineChart /></button>
                                </div>
                            </div>
                        {/* Sortant - Nombre d'appels répondus */}
                        {Object.values(
                            typeTrends.aggregatedResults.reduce((acc, item) => {
                                acc[item.key] = item.Outgoing.total;
                                return acc;
                            }, {})
                        ).some(value => value !== 0) && (
                            <div>
                                <ChartsWebex
                                    dataForChart={typeTrends.aggregatedResults.reduce((acc, item) => {
                                        acc[item.key] = item.Outgoing.total;
                                        return acc;
                                    }, {})}
                                    allLabels={Object.values(typeTrends.aggregatedResults).map(item => item.key)}
                                    typeOfChart={selectedTypeChart}
                                    titleChart={t("Sortant - Nombre d'appels répondus")}
                                />
                            </div>
                        )}
                    
                        {/* Sortant - Durée totale des appels sortants */}
                        {Object.values(
                            typeTrends.aggregatedResults.reduce((acc, item) => {
                                acc[item.key] = item.Outgoing.durationTotal;
                                return acc;
                            }, {})
                        ).some(value => value !== 0) && (
                            <div>
                                <ChartsWebex
                                    dataForChart={typeTrends.aggregatedResults.reduce((acc, item) => {
                                        acc[item.key] = item.Outgoing.durationTotal;
                                        return acc;
                                    }, {})}
                                    allLabels={Object.values(typeTrends.aggregatedResults).map(item => item.key)}
                                    typeOfChart={selectedTypeChart}
                                    titleChart={t("Sortant - Durée totale des appels sortants")}
                                    mesurementUnit={'formatDuration'}
                                />
                            </div>
                        )}
                    
                        {/* Entrant - Nombre d'appels répondus */}
                        {Object.values(
                            typeTrends.aggregatedResults.reduce((acc, item) => {
                                acc[item.key] = item.Incoming.total;
                                return acc;
                            }, {})
                        ).some(value => value !== 0) && (
                            <div>
                                <ChartsWebex
                                    dataForChart={typeTrends.aggregatedResults.reduce((acc, item) => {
                                        acc[item.key] = item.Incoming.total;
                                        return acc;
                                    }, {})}
                                    allLabels={Object.values(typeTrends.aggregatedResults).map(item => item.key)}
                                    typeOfChart={selectedTypeChart}
                                    titleChart={t("Entrant - Nombre d'appels répondus")}
                                />
                            </div>
                        )}
                    
                        {/* Entrant - Durée totale des appels entrants */}
                        {Object.values(
                            typeTrends.aggregatedResults.reduce((acc, item) => {
                                acc[item.key] = item.Incoming.durationTotal;
                                return acc;
                            }, {})
                        ).some(value => value !== 0) && (
                            <div>
                                <ChartsWebex
                                    dataForChart={typeTrends.aggregatedResults.reduce((acc, item) => {
                                        acc[item.key] = item.Incoming.durationTotal;
                                        return acc;
                                    }, {})}
                                    allLabels={Object.values(typeTrends.aggregatedResults).map(item => item.key)}
                                    typeOfChart={selectedTypeChart}
                                    titleChart={t("Entrant - Durée totale des appels entrants")}
                                    mesurementUnit={'formatDuration'}
                                />
                            </div>
                        )}
                    
                        {/* Total - Durée totale des appels */}
                        {Object.values(
                            typeTrends.aggregatedResults.reduce((acc, item) => {
                                acc[item.key] = item.Total.durationTotal;
                                return acc;
                            }, {})
                        ).some(value => value !== 0) && (
                            <div>
                                <ChartsWebex
                                    dataForChart={typeTrends.aggregatedResults.reduce((acc, item) => {
                                        acc[item.key] = item.Total.durationTotal;
                                        return acc;
                                    }, {})}
                                    allLabels={Object.values(typeTrends.aggregatedResults).map(item => item.key)}
                                    typeOfChart={selectedTypeChart}
                                    titleChart={t("Total - Durée totale des appels")}
                                    mesurementUnit={'formatDuration'}
                                />
                            </div>
                        )}
                    
                        {/* Total - Réponse totale des appels */}
                        {Object.values(
                            typeTrends.aggregatedResults.reduce((acc, item) => {
                                acc[item.key] = item.Total.responseTotal;
                                return acc;
                            }, {})
                        ).some(value => value !== 0) && (
                            <div>
                                <ChartsWebex
                                    dataForChart={typeTrends.aggregatedResults.reduce((acc, item) => {
                                        acc[item.key] = item.Total.responseTotal;
                                        return acc;
                                    }, {})}
                                    allLabels={Object.values(typeTrends.aggregatedResults).map(item => item.key)}
                                    typeOfChart={selectedTypeChart}
                                    titleChart={t('Total - Réponse totale des appels')}
                                />
                            </div>
                        )}
                    </div>                    
                    ) : (
                        <>
                            <div className="typeTrends-element-firstData">
                                <table className="typeTrends-sortants">
                                    <thead>
                                        <tr className="sortant"><th colSpan={6}>{t("Sortants")}</th></tr>
                                        <tr className="sortant-head">
                                            <th>{t("Date")}</th>
                                            <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                            <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                            <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                            <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                            <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {typeTrends.aggregatedResults.map((item, itemIndex) => (
                                            <tr className="sortant-body" key={itemIndex}>
                                                <td>{item.key}</td>
                                                <td>{item.Outgoing.response}</td>
                                                <td>{item.Outgoing.averageresponse}</td>
                                                <td>{item.Outgoing.noresponse}</td>
                                                <td>{item.Outgoing.durationTotalFormatted}</td>
                                                <td>{item.Outgoing.averageduration}</td>
                                            </tr>
                                        ))}
                                        <tr className="sortant-body typeTrends-total-content">
                                            <td>Total</td>
                                            <td>{typeTrends.Total.responseOutgoing}</td>
                                            <td>{typeTrends.Total.averageresponseOutgoing}</td>
                                            <td>{typeTrends.Total.noresponseOutgoing}</td>
                                            <td>{typeTrends.Total.durationOutgoing}</td>
                                            <td>{typeTrends.Total.averagedurationOutgoing}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="typeTrends-entrants">
                                    <thead>
                                        <tr className="entrant"><th colSpan={6}>{t("Entrants")}</th></tr>
                                        <tr>
                                            <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                            <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                            <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                            <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                            <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {typeTrends.aggregatedResults.map((item, itemIndex) => (
                                            <tr key={itemIndex}>
                                                <td>{item.Incoming.response}</td>
                                                <td>{item.Incoming.averageresponse}</td>
                                                <td>{item.Incoming.noresponse}</td>
                                                <td>{item.Incoming.durationTotalFormatted}</td>
                                                <td>{item.Incoming.averageduration}</td>
                                            </tr>
                                        ))}
                                        <tr className="typeTrends-total-content">
                                            <td>{typeTrends.Total.responseIncoming}</td>
                                            <td>{typeTrends.Total.averageresponseIncoming}</td>
                                            <td>{typeTrends.Total.noresponseIncoming}</td>
                                            <td>{typeTrends.Total.durationIncoming}</td>
                                            <td>{typeTrends.Total.averagedurationIncoming}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="typeTrends-total">
                                    <thead>
                                        <tr className="total"><th colSpan={5}>{t("Total")}</th></tr>
                                        <tr className="typeTrends-total-head">
                                            <th title="Nombre totale d'appels">{t("Nombre totale d'appels")}</th>
                                            <th title="Durée totale des appels">{t("Durée totale des appels")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {typeTrends.aggregatedResults.map((item, itemIndex) => (
                                            <tr key={itemIndex}>
                                                <td>{item.Total.responseTotal}</td>
                                                <td>{item.Total.durationTotalFormatted}</td>
                                            </tr>
                                        ))}
                                        <tr className="typeTrends-total-content">
                                            <td>{typeTrends.Total.responseTotal}</td>
                                            <td>{typeTrends.Total.durationTotal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </section>
            ) : (
                <Loader/>
            )}
        </div>
    );
}