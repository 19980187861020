import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

export default function TopDevice() {
  const { apiUrl } = useContext(AuthContext);
  const { t } = useTranslation();

  const getTopDevices = async () => {
    const res = await axios.get(`${apiUrl}meraki/topDevices`, {
      withCredentials: true,
    });
    return res.data;
  };

  const topDevices = useQuery({
    queryKey: ["topDevices"],
    queryFn: getTopDevices,
  });


  return (
    <>
      {!topDevices.isLoading ? (
        <section className="client">
          <h2 className="client-title">
            {t('Top devices par usage')}
            <span> {t('(sur le dernier jour)')} </span>
          </h2>
          <table className="client-table">
            <thead>
              <tr>
                <th>Client</th>
                <th>Network</th>
                <th>{t('Usage')}</th>
                <th>{t('% Usage')}</th>
              </tr>
            </thead>
            <tbody>
              {topDevices.data.map((device, index) => (
                <tr key={index}>
                  <td>{device.name}</td>
                  <td>{device.network.name}</td>
                  <td>
                    {device.usage.total / 1000 >= 1
                      ? `${(device.usage.total / 1000).toFixed(2)} Go`
                      : `${device.usage.total.toFixed(2)} Mo`}
                  </td>
                  <td>
                    <div className="client-usage">
                      <div
                        className="client-percentage"
                        style={{ width: `${Math.floor(device.usage.percentage)}%` }}
                      ></div>
                      <h3>{Math.round(device.usage.percentage * 100) / 100} %</h3>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      ) : (
        <Loader />
      )}
    </>
  );
}
