import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import TableComponent from "../../components/TableComponent";
import Loader from "../../components/Loader";
import { microsoftMessageColumn } from "../../components/headerContent";
import RowMicrosoftMessage from "./RowMicrosoftMessage";
import { useTranslation } from "react-i18next";

export default function MicrosoftMessage() {
    const { apiUrl } = useContext(AuthContext);
    const [allServices, setAllServices] = useState([]);
    const [messagesByServices, setMessagesByServices] = useState([]);
    const [serviceSelected, setServicesSelected] = useState([]);
    const { t } = useTranslation();

    const getMessageCenter = async () => {
        const res = await axios.get(`${apiUrl}microsoft/messageCenter`, { withCredentials: true })
        return res.data.value.sort((a, b) => new Date(b.lastModifiedDateTime) - new Date(a.lastModifiedDateTime));
    }

    const messageCenter = useQuery({
        queryKey: ["messageCenter"],
        queryFn: getMessageCenter
    });

    useEffect(() => {
        if (messageCenter.isFetched) {
            let services = [];
            messageCenter.data.forEach(element => {
                element.services.forEach(service => {
                    services.push(service);
                })
            });
            setAllServices([...new Set(services)]);
        }
    }, [messageCenter.isFetched, messageCenter.data])

    const handleServicesFiltered = () => {
        const messagesfiltered = messageCenter.data.filter((message) => (
            message.services.some(item => serviceSelected.includes(item))
        ))

        setMessagesByServices(messagesfiltered)
    }

    useEffect(() => {
        if (serviceSelected !== null && !messageCenter.isLoading) {
            handleServicesFiltered();
        } else {
            setMessagesByServices([])
        }
    }, [serviceSelected, messageCenter.isLoading])

    const handleSelectedServices = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions).map((option) => option.value);
        setServicesSelected(selectedOptions);
    };

    return (
        <>
            {!messageCenter.isLoading ?
                <>
                    <form className="form form-leftbtn">
                        <div className="form-input_container" >
                            <label htmlFor="type">Service</label>
                            <select
                                className="only"
                                id="type"
                                onChange={(e) => {
                                    handleSelectedServices(e)
                                }}
                                multiple
                                style={{ height: "10rem", width: "20rem" }}
                            >
                                <option value="">{t('Tous')}</option>
                                {allServices.length > 0 && allServices.map((service) => (
                                    <option key={service} value={service}>{service}</option>
                                ))}
                            </select>
                        </div>
                    </form>
                    <TableComponent
                        tableColumns={microsoftMessageColumn}
                        tableData={messagesByServices.length > 0 ? messagesByServices : messageCenter.data}
                        checkbox={false}
                        rowComponent={RowMicrosoftMessage}
                    />
                </>
                : <Loader />}
        </>
    )
}