import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

/**
 *
 * @param {array} data
 * @param {array} percentage - array of each part of the line (object {percentage, color, value})
 * @param {string} type - to redirect on the right page and display the rigth title
 */
export default function HomeLine({ data, percentage, type }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const totalPercentage = percentage.reduce((acc, item) => acc + item.percentage, 0);
  const adjustedPercentages = percentage.map(item => ({
    ...item,
    percentage: (item.percentage / totalPercentage) * 100
  }));

  return (
    <>
      {percentage.length > 0 && (
        <div className="homeline" onClick={() => navigate(`/${type}`)}>
          <h2>
            <span>
              {data.length}{" "}
              {type !== "webex"
                ? t("équipements")
                : t("équipements collaboration")}
            </span>
          </h2>
          <div className="homeline-item_bar">
            {percentage.map((item) => {
              return (
                item.percentage > 0 && (
                  <div
                    key={item.value}
                    className="homeline-item_bar-container"
                    style={{
                      width: (item.percentage * 100) / data.length + "%",
                      backgroundColor: item.color,
                    }}
                  >
                  </div>
                )
              );
            })}
          </div>
          <div className="homeline-legend">
            {percentage.map((item, index) => {
              return (
                item.percentage > 0 && (
                  <div className="homeline-legend_item" key={item.value}>
                    <div>
                      <span
                        className="homeline-legend_round"
                        style={{ backgroundColor: item.color }}
                      ></span>
                      <span className="homeline-legend_text">
                        {" "}
                        {t(item.value)}
                      </span>
                    </div>
                    <div className="homeline-item_bar-percentage">
                      <span className="homeline-item_value">
                        {item.percentage}
                      </span>
                    </div>
                    <div className="homeline-item_value-percentage">
                      <span>
                        {Math.round(adjustedPercentages[index].percentage.toFixed(2))}%
                      </span>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
