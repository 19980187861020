import React, { useEffect, useState } from "react";
import Loader from "../../../../../components/Loader";
import { useTranslation } from "react-i18next";

export default function TypePostSurvey({ data, people, setDataGraph, setFormattedHeader, filteredPosts }) {
    const [postSurvey, setPostSurvey] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPostPage, setCurrentPostPage] = useState({});
    const [postItemsPerPage, setPostItemPerPage] = useState(5);
    const itemsPerPage = 5;
    const { t, i18n } = useTranslation();

    const uniquePosts = [...new Set(people.flat().map(item => item.phone.work || item.phone.mobile))].filter(callingnumber => {
        const callinglineid = data.find(item => item.callingnumber === callingnumber)?.callinglineid;
        return callinglineid;
    });

    const finalPosts = filteredPosts.posts.length > 0 ? uniquePosts.filter(post => filteredPosts.posts.includes(post)) : uniquePosts;

    useEffect(() => {
        if (data.length > 0) {
            handleGraphPostSurvey(data);
            setCurrentPostPage(1);
        }
    }, [data]);

    const formatDuration = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
    };

    const handleGraphPostSurvey = (data) => {
        let postData = finalPosts.map(post => ({
            post,
            callinglineid: "",
            Outgoing: {
                total: 0,
                durationTotal: 0,
                averageduration: 0,
                response: 0,
                averageresponse: 0,
                noresponse: 0
            },
            Incoming: {
                total: 0,
                durationTotal: 0,
                averageduration: 0,
                response: 0,
                averageresponse: 0,
                noresponse: 0
            },
            Total: {
                durationTotal: 0,
                responseTotal: 0
            },
            data: []
        }));
        data.forEach(item => {
            const post = finalPosts.find(post => post === item.callingnumber);
            const postCalled = finalPosts.find(post => post === item.callednumber);
            if (post) {
                const postIndex = postData.findIndex(p => p.post === post);
                const person = people.flat().find(person => person.phone.work === post || person.phone.mobile === post);
                if (person) {
                    postData[postIndex].callinglineid = `${person.firstName} ${person.lastName}`;
                }
            }

            if (post && (filteredPosts.calltype !== '' ? filteredPosts.calltype.includes('sortants') : post)) {
                const postIndex = postData.findIndex(p => p.post === post);
                postData[postIndex].data.push(item);
                postData[postIndex].Outgoing.total += 1;
                postData[postIndex].Outgoing.durationTotal += Math.floor(parseInt(item.duration) || 0);
                if (item.answerindicator === "Yes") {
                    postData[postIndex].Outgoing.response += 1;
                    postData[postIndex].Outgoing.averageresponse += Math.floor(parseInt(item.ringduration) || 0);
                } else if (item.answerindicator === "No") {
                    postData[postIndex].Outgoing.noresponse += 1;
                }
            }

            if (postCalled && (filteredPosts.calltype !== '' ? filteredPosts.calltype.includes('entrants') : postCalled)) {
                const postIndex = postData.findIndex(p => p.post === postCalled);
                postData[postIndex].data.push(item);
                postData[postIndex].Incoming.total += 1;
                postData[postIndex].Incoming.durationTotal += Math.floor(parseInt(item.duration) || 0);
                if (item.answerindicator === "Yes") {
                    postData[postIndex].Incoming.response += 1;
                    postData[postIndex].Incoming.averageresponse += Math.floor(parseInt(item.ringduration) || 0);
                } else if (item.answerindicator === "No") {
                    postData[postIndex].Incoming.noresponse += 1;
                }
            }
        });

        postData.forEach(post => {
            let Outgoing = post.Outgoing;
            let Incoming = post.Incoming;
            let Total = post.Total;

            Outgoing.averageduration = Outgoing.total > 0 ? Outgoing.durationTotal / Outgoing.total : 0;
            Outgoing.averageresponse = Outgoing.response > 0 ? Outgoing.averageresponse / Outgoing.response : 0;

            Incoming.averageduration = Incoming.total > 0 ? Incoming.durationTotal / Incoming.total : 0;
            Incoming.averageresponse = Incoming.response > 0 ? Incoming.averageresponse / Incoming.response : 0;

            Total.responseTotal = Outgoing.response + Incoming.response + Outgoing.noresponse + Incoming.noresponse;
            Total.durationTotal = formatDuration(Outgoing.durationTotal + Incoming.durationTotal);

            Outgoing.durationTotal = formatDuration(Outgoing.durationTotal);
            Outgoing.averageduration = formatDuration(Outgoing.averageduration);
            Outgoing.averageresponse = formatDuration(Outgoing.averageresponse);

            Incoming.durationTotal = formatDuration(Incoming.durationTotal);
            Incoming.averageduration = formatDuration(Incoming.averageduration);
            Incoming.averageresponse = formatDuration(Incoming.averageresponse);
        });

        postData = postData.filter(post => {
            return post.Outgoing.total > 0 || post.Incoming.total > 0 || post.data.length > 0;
        });

        setPostSurvey(postData);
        const formattedData = generateExcelData(postData);
        setDataGraph(formattedData);
    };

    const generateExcelData = (posts) => {
        const formattedData = [];

        // 1ère ligne: entête pour Sortant, Entrant, Total
        formattedData.push(["", "Sortant", "Entrant", "Total", "", "", "", "", "", ""]);

        // Itérer sur chaque poste (post)
        posts.forEach(post => {
            // 2ème ligne: Nom du poste (callinglineid ou post)
            formattedData.push([post.callinglineid || post.post, "", "", "", "", ""]);

            formattedData.push([
                "Nombre d'appels",
                post.Outgoing.total || 0, // Sortant
                post.Incoming.total || 0, // Entrant
                "" // Pas de total global disponible
            ]);

            // Nombre d'appels répondus
            formattedData.push([
                "Nombre d'appels répondu",
                post.Outgoing.response || 0, // Sortant
                post.Incoming.response || 0, // Entrant
                post.Total.responseTotal || 0 // Total
            ]);

            // Durée totale des appels
            formattedData.push([
                "Durée totale d'appels",
                post.Outgoing.durationTotal || "00:00:00", // Sortant
                post.Incoming.durationTotal || "00:00:00", // Entrant
                post.Total.durationTotal || "00:00:00" // Total
            ]);

            // Durée moyenne pour répondre
            formattedData.push([
                "Durée moyenne pour répondre",
                post.Outgoing.averageresponse || "00:00:00", // Sortant
                post.Incoming.averageresponse || "00:00:00", // Entrant
                "" // Pas de valeur pour le total
            ]);

            // Nombre d'appels non répondus
            formattedData.push([
                "Nombre d'appels non répondu",
                post.Outgoing.noresponse || 0, // Sortant
                post.Incoming.noresponse || 0, // Entrant
                "" // Pas de valeur pour le total
            ]);

            // Durée moyenne des appels
            formattedData.push([
                "Durée moyenne d'appels",
                post.Outgoing.averageduration || "00:00:00", // Sortant
                post.Incoming.averageduration || "00:00:00", // Entrant
                "" // Pas de valeur pour le total
            ]);

            // 4ème ligne: entête des détails des appels (pour chaque appel dans post.data)
            formattedData.push([
                "Date", "Durée", "Poste", "Nom poste", "Numéro", "Nom", "Origine", "Résultat", "Réponse", "Type"
            ]);

            // Détails des appels (une ligne par appel)
            post.data.forEach(call => {
                formattedData.push([
                    call.starttime || "", // Date de début de l'appel
                    call.duration || "0", // Durée de l'appel en secondes
                    call.callingnumber || "", // Numéro appelant
                    post.callinglineid || "", // Nom du poste (callinglineid)
                    call.callednumber || "", // Numéro appelé
                    call.calledlineid || "", // Nom appelé
                    getCallType(call.calltype), // Type d'appel
                    call.answerindicator || "No", // Résultat (Yes/No)
                    call.ringduration || "0", // Durée de sonnerie
                    call.direction || "" // Type (Entrant ou Sortant)
                ]);
            });

            // Ligne vide pour séparer les postes
            formattedData.push([]);
        });
        setFormattedHeader(true);
        return formattedData;
    }

    const getCallType = (callType) => {
        switch (callType) {
            case 'SIP_ENTERPRISE': return 'Interne';
            case 'SIP_NATIONAL': return 'National';
            case 'SIP_MOBILE': return 'Mobile';
            case 'SIP_INTERNATIONAL': return 'International';
            case 'SIP_PREMIUM': return 'Premium';
            case 'SIP_INBOUND': return 'Entrant';
            case 'SIP_OPERATOR': return 'Opérateur';
            case 'SIP_TOLLFREE': return 'Sans frais';
            case 'SIP_MEETING': return 'Réunion';
            case 'UNKNOWN': return 'Inconnu';
            default: return callType;
        }
    }

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const paginatePost = (postIndex, pageNumber) => {
        setCurrentPostPage({ ...currentPostPage, [postIndex]: pageNumber });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const changePostItemPerPage = (change) => {
        setPostItemPerPage(prevValue => {
            const newValue = prevValue + change;
            if (newValue < 1) return 1;
            if (newValue > 15) return 15;
            return newValue;
        });
    };

    if (postSurvey.length === 0) {
        return (
            <div>Aucune données disponible</div>
        )
    }

    return (
        <div className="postSurvey">
            {postSurvey.length > 0 ? (
                <>
                    <div className="pagination-surveyPost">
                        <button
                            onClick={() => paginate(currentPage > 1 ? currentPage - 1 : currentPage)}
                            disabled={currentPage === 1}
                        >
                            {t("Précédent")}
                        </button>
                        <button
                            onClick={() =>
                                paginate(
                                    currentPage < Math.ceil(postSurvey.length / itemsPerPage)
                                        ? currentPage + 1
                                        : currentPage
                                )
                            }
                            disabled={currentPage === Math.ceil(postSurvey.length / itemsPerPage)}
                        >
                            {t("Suivant")}
                        </button>
                    </div>
                    {postSurvey.slice(indexOfFirstItem, indexOfLastItem).map((post, postIndex) => (
                        <section className="postSurvey-element" key={post.post}>
                            <div>
                                <table className="postSurvey-sortants">
                                    <thead>
                                        <tr className="sortant"><th colSpan={6}>{t("Sortants")}</th></tr>
                                        <tr className="sortant-head">
                                            <th title="Poste">{t("Poste")}</th>
                                            <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                            <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                            <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                            <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                            <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="sortant-body">
                                            <td>{post.callinglineid}</td>
                                            <td>{post.Outgoing.response}</td>
                                            <td>{post.Outgoing.averageresponse}</td>
                                            <td>{post.Outgoing.noresponse}</td>
                                            <td>{post.Outgoing.durationTotal}</td>
                                            <td>{post.Outgoing.averageduration}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="postSurvey-entrants">
                                    <thead>
                                        <tr className="entrant"><th colSpan={5}>{t("Entrants")}</th></tr>
                                        <tr>
                                            <th title="Nombre d'appels répondu">{t("Nombre d'appels répondu")}</th>
                                            <th title="Durée moyenne pour répondre">{t("Durée moyenne pour répondre")}</th>
                                            <th title="Nombre d'appels non répondu">{t("Nombre d'appels non répondu")}</th>
                                            <th title="Durée totale d'appels">{t("Durée totale d'appels")}</th>
                                            <th title="Durée moyenne d'appels">{t("Durée moyenne d'appels")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{post.Incoming.response}</td>
                                            <td>{post.Incoming.averageresponse}</td>
                                            <td>{post.Incoming.noresponse}</td>
                                            <td>{post.Incoming.durationTotal}</td>
                                            <td>{post.Incoming.averageduration}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="postSurvey-total">
                                    <thead className="postSurvey-total-head">
                                        <tr className="total"><th colSpan={5}>{t("Total")}</th></tr>
                                        <tr>
                                            <th title="Durée totale des appels">{t("Durée totale des appels")}</th>
                                            <th title="Nombre totale d'appels">{t("Nombre totale d'appels")}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="postSurvey-total-body">
                                        <tr>
                                            <td>{post.Total.durationTotal}</td>
                                            <td>{post.Total.responseTotal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p className="postSurvey-post-title">Détails des appels :</p>
                            <div>
                                <table className="postSurvey-post">
                                    <thead>
                                        <tr className="postSurvey-post-head">
                                            <th>{t("Date")}</th>
                                            <th>{t("Durée")}</th>
                                            <th>{t("Poste")}</th>
                                            <th>{t("Nom poste")}</th>
                                            <th>{t("Numéro")}</th>
                                            <th>{t("Nom")}</th>
                                            <th>{t("Origine")}</th>
                                            <th>{t("Résultat")}</th>
                                            <th title="Durée de sonnerie">{t("Durée de sonnerie")}</th>
                                            <th>{t("Type")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {post.data.slice((currentPostPage[post.post] || 1) * postItemsPerPage - postItemsPerPage,
                                            (currentPostPage[post.post] || 1) * postItemsPerPage).map((item, idx) => (
                                                <tr className="postSurvey-post-body" key={idx}>
                                                    <td>{item.starttime}</td>
                                                    <td>{item.duration}</td>
                                                    <td>{item.callingnumber}</td>
                                                    <td>{item.callinglineid}</td>
                                                    <td>{item.callednumber}</td>
                                                    <td>{item.calledlineid}</td>
                                                    <td>
                                                        {
                                                            item.calltype === 'SIP_ENTERPRISE' ? 'Interne' :
                                                                item.calltype === 'SIP_NATIONAL' ? 'National' :
                                                                    item.calltype === 'SIP_MOBILE' ? 'Mobile' :
                                                                        item.calltype === 'UNKNOWN' ? 'Inconnu' :
                                                                            item.calltype === 'SIP_PREMIUM' ? 'Prenium' :
                                                                                item.calltype === 'SIP_INTERNATIONAL' ? 'International' :
                                                                                    item.calltype === 'SIP_INBOUND' ? 'Entrant' :
                                                                                        item.calltype === 'SIP_OPERATOR' ? 'Opérateur' :
                                                                                            item.calltype === 'SIP_TOLLFREE' ? 'Sans frais' :
                                                                                                item.calltype === 'SIP_MEETING' ? 'Réunion' :
                                                                                                    item.calltype
                                                        }
                                                    </td>
                                                    <td>{item.answerindicator}</td>
                                                    <td>{item.ringduration}</td>
                                                    <td>{item.direction}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination-surveyPost-post">
                                <button
                                    onClick={() =>
                                        paginatePost(post.post, (currentPostPage[post.post] || 1) > 1 ? (currentPostPage[post.post] || 1) - 1 : (currentPostPage[post.post] || 1))
                                    }
                                    disabled={(currentPostPage[post.post] || 1) === 1}
                                >
                                    {t("Précédent")}
                                </button>
                                <button
                                    onClick={() =>
                                        paginatePost(post.post, (currentPostPage[post.post] || 1) < Math.ceil(post.data.length / postItemsPerPage) ? (currentPostPage[post.post] || 1) + 1 : (currentPostPage[post.post] || 1))
                                    }
                                    disabled={(currentPostPage[post.post] || 1) === Math.ceil(post.data.length / postItemsPerPage)}
                                >
                                    {t("Suivant")}
                                </button>
                                <div className="surveyPost-post-numbers">
                                    <button onClick={() => changePostItemPerPage(-1)} disabled={postItemsPerPage === 1}>
                                        -
                                    </button>
                                    <span>{postItemsPerPage}</span>
                                    <button onClick={() => changePostItemPerPage(+1)} disabled={postItemsPerPage === 15}>
                                        +
                                    </button>
                                </div>
                            </div>
                        </section>
                    ))}
                </>
            ) : (
                <Loader />
            )}
        </div>
    );
}
