
/**
 * 
 * @param {object} item - object of formData
 * @param {function} register - register of react hook form
 */
export default function Input({ item, register }) {
  return (
    <input
      type={item.type}
      id={item.id}
      {...register(item.id)}
      placeholder={item.placeholder ? item.placeholder : ""}
      defaultValue={item["value"] && item.value}
    />
  );
}
