import axios from "axios";
import { useContext, useEffect, useState } from "react";
import IconsStatus from "../../components/IconStatus";
import Loader from "../../components/Loader";
import { convertDateAndReturn } from "../../components/utils";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

export default function RowMicrosoftOverview({ item }) {
  const { apiUrl } = useContext(AuthContext);
  const [issuesLog, setIssuesLog] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const getServicesIssues = () => {
    axios.get(`${apiUrl}microsoft/servicesIssues/${item.service}`, { withCredentials: true, })
      .then(res => {
        const response = res.data.issues.map((issue) => ({
          title: issue.title,
          isResolved: issue.isResolved,
          dateStart: convertDateAndReturn(issue.startDateTime),
          dateEnd: convertDateAndReturn(issue.endDateTime),
          classification: issue.classification,
        }));

        const sortedResponse = response.sort((a, b) => new Date(b.dateStart) - new Date(a.dateStart));
        setIssuesLog(sortedResponse);
      })
      .catch(err => { })
  };

  useEffect(() => {
    getServicesIssues()
  }, [item.id])

  useEffect(() => {
    if (issuesLog.length > 0) {
      setIsLoading(false);
    }
  }, [issuesLog])

  return (
    <>
      {!isLoading ?
        <div className="microsoft">
          <div className="microsoft-header">
            <p className="microsoft-header_title">Issue</p>
            <p className="microsoft-header_classification">Classification</p>
            <p className="microsoft-header_start">{t('Date de début')}</p>
            <p className="microsoft-header_end">{t('Date de fin')}</p>
            <p className="microsoft-header_resolved">{t('Résolu')}</p>
          </div>
          {issuesLog.map((issue, index) => {
            return (
              <div className="microsoft-item" key={index}>
                <p className="microsoft-item_title">{issue.title}</p>
                <p className="microsoft-item_classification">{issue.classification}</p>
                <p className="microsoft-item_start">{issue.dateStart}</p>
                <p className="microsoft-item_end">{issue.dateEnd}</p>
                <div className="microsoft-item_resolved"><IconsStatus value={issue.isResolved.toString()} /></div>
              </div>
            )
          })}
        </div>
        : <Loader />}
    </>
  );
}
