import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import AllDevices from "./AllDevices";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

export default function AuvikDevice() {
  const { apiUrl } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const params = useParams();
  const tenantId = params.tenantId;

  const getTenantsLive = async () => {
    try {
      const res = await axios.get(`${apiUrl}auvik/tenants_live`, {
        withCredentials: true,
      });
      return res.data;
    } catch (error) {
    }
  };

  const tenantsLive = useQuery({
    queryKey: ["auvik"],
    queryFn: getTenantsLive,
  });

  useEffect(() => {
    queryClient.invalidateQueries(["auvik", tenantsLive.data]);
  }, [tenantsLive.data]);

  const isTenantIdValid = tenantsLive.data && tenantsLive.data.some(tenant => tenant.auvik_id === tenantId);

  useEffect(() => {
    if (isTenantIdValid === false) {
      navigate("/Auvik");
    }
  }, [isTenantIdValid, history]);

  return(
    <>
      {isTenantIdValid && (
        <article className="page">
          <AllDevices tenantId={tenantId}/>
        </article>
      )}
    </>
  )
}