import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import Lastmenu from "./Lastmenu";
import { AuthContext } from "../../context/AuthContext";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";


/**
 * 
 * @param {array} submenu - list of submenu 
 * @param {number} index - of item in submenu array
 */
export default function Submenu({
  submenu,
  index,
  secondDrop,
  thirdDrop,
}) {
  // import role and control
  const {t} = useTranslation();
  const { user, entrepriseRoles } = useContext(AuthContext);
  const shouldDisplayMenu = submenu.role
    ? submenu.role.some((role) => entrepriseRoles.data.includes(role) || user.roles.includes(role))
    : true;

  return (
    <>
      {shouldDisplayMenu && submenu.path && (
        <li className="submenu-item">
          {submenu.path.includes("http") ? (
            <a href={submenu.path} target={"_blank"} className="submenu-link">
              {t(submenu.title)}
            </a>
          ) : (
            <div className="submenu-link">
            <NavLink to={submenu.path} className="submenu_link">
            {t(submenu.title)}
            </NavLink>
            </div>
          )}
          {submenu.lastmenu ? (
            <ul
              className={`lastmenu-list ${
                thirdDrop === `${secondDrop}${index}`
                  ? "lastmenu-list_open"
                  : ""
              }`}
            >
              {submenu.lastmenu.map((lastmenu, index) => {
                return (
                  <Lastmenu lastmenu={lastmenu} index={index} key={index} />
                );
              })}
            </ul>
          ) : null}
        </li>
      )}
    </>
  );
}
