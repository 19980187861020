import React, { useState } from "react";
import { ClearRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function EmailPopup({ onSubmit, onCancel }) {
    const [email, setEmail] = useState("");
    const { t, i18n } = useTranslation();
    const handleSubmit = () => {
        onSubmit(email);
    };

    return (
        <div className="popup-filtering-report-webex-statistics-email popup-filtering-report-webex-statistics">
            <h2>{t("Saisir l'adresse email")}</h2>
            <div className="container-search-bar-filtering-report-webex-email">
                
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t("Saisir l'adresse email")}
                    className="input-webex input-email-webex"
                />
                <button className="button-clear-email-webex" onClick={() => setEmail("")}><ClearRounded /></button>
            </div>
            <div className="container-buttons-popup-filtering-report-webex">
                <button onClick={handleSubmit}>{t("Envoyer")}</button>
                <button onClick={onCancel}>{t("Annuler")}</button>
            </div>
        </div>
    );
}

export default EmailPopup;