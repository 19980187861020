import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { convertTimestampFormatZ } from "../../components/utils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ChartUnicast from "./Charts/ChartUnicast";
import ChartBandwidth from "./Charts/ChartBandwidth";
import ChartInterfaces from "./Charts/ChartInterfaces";
import { DateRangePicker } from 'rsuite';
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";

export default function InterfaceAuvik() {
    const { apiUrl } = useContext(AuthContext)
    const params = useParams();
    const tenantId = params.tenantId;
    const interfaceId = params.interfaceId;
    const { allowedMaxDays, afterToday, combine } = DateRangePicker;
    const today = endOfDay(new Date(), 23);
    const yesterday = startOfDay(addDays(new Date(), -1), 0);
    const [date, setDate] = useState([yesterday, today]);
    const { t } = useTranslation();

    const Ranges = [
        {
            label: t('Today'),
            value: [startOfDay(new Date(), 0), today]
        },
        {
            label: t('Yesterday'),
            value: [yesterday, endOfDay(addDays(new Date(), -1), 23)]
        },
        {
            label: t('Last seven days'),
            value: [startOfDay(subDays(new Date(), 6), 0), today]
        }
    ];

    const getDates = () => {
        const [startDate, endDate] = date.map(convertTimestampFormatZ);
        return { startDate, endDate };
    };

    const { startDate, endDate } = getDates();

    const getInterfaceData = async (metricName) => {
        const res = await axios.get(`${apiUrl}auvik/interfaceStat/${metricName}/${tenantId}/${interfaceId}/${startDate}/${endDate}`, {
            withCredentials: true,
        });
        return res.data;
    };

    const bandwidth = useQuery({
        queryKey: ["bandwidth", date, interfaceId],
        queryFn: () => getInterfaceData('bandwidth'),
    });

    const utilization = useQuery({
        queryKey: ["utilization", date, interfaceId],
        queryFn: () => getInterfaceData('utilization'),
    });

    const packetLoss = useQuery({
        queryKey: ["packetLoss", date, interfaceId],
        queryFn: () => getInterfaceData('packetLoss'),
    });

    const packetDiscard = useQuery({
        queryKey: ["packetDiscard", date, interfaceId],
        queryFn: () => getInterfaceData('packetDiscard'),
    });

    const packetUnicast = useQuery({
        queryKey: ["packetUnicast", date, interfaceId],
        queryFn: () => getInterfaceData('packetUnicast'),
    });

    const packetMulticast = useQuery({
        queryKey: ["packetMulticast", date, interfaceId],
        queryFn: () => getInterfaceData('packetMulticast'),
    });

    const packetBroadcast = useQuery({
        queryKey: ["packetBroadcast", date, interfaceId],
        queryFn: () => getInterfaceData('packetBroadcast'),
    });

    return (
        <div className="charts-content">
            <div className="link-interface">
                <Link to={`/auvik/${tenantId}`} className="button button-new"><FontAwesomeIcon icon={faChevronLeft} /> {t('Retour')}</Link>
            </div>
            <section className="statistics-auvik">
                <h2>Interface</h2>
                <div className="auvik-charts">
                    {bandwidth.data && utilization.data && packetUnicast.data && packetMulticast.data && packetBroadcast.data && packetLoss.data && packetDiscard.data ? (
                        <div className="auvik-charts-container">
                            <div className="auvik-charts-container-page-section-form">
                                <div className="form-input_container">
                                    <DateRangePicker
                                        ranges={Ranges}
                                        cleanable={false}
                                        character=" / "
                                        value={date}
                                        onChange={setDate}
                                        format="yyyy-MM-dd HH:mm"
                                        shouldDisableDate={combine(allowedMaxDays(7), afterToday())}
                                        defaultValue={[yesterday, today]}
                                        locale={{
                                            sunday: t('Su'),
                                            monday: t('Mo'),
                                            tuesday: t('Tu'),
                                            wednesday: t('We'),
                                            thursday: t('Th'),
                                            friday: t('Fr'),
                                            saturday: t('Sa'),
                                            hours: t('Hours'),
                                        }}
                                    />
                                </div>
                            </div>
                            {bandwidth.data[0]?.data ? (
                                <h2 className="page-title">{t("Aucune donnée de bande passante trouvée")}</h2>
                            ) : (
                                <>
                                    <ChartBandwidth dataBandwidth={bandwidth.data} />
                                </>
                            )}
                            {packetUnicast.data[0].data || packetMulticast.data[0].data || packetBroadcast.data[0].data ? (
                                <h2 className="page-title">{t("Aucune donnée de Icats trouvée")}</h2>
                            ) : (
                                <>
                                    <ChartUnicast packetUnicast={packetUnicast.data} packetMulticast={packetMulticast.data} packetBroadcast={packetBroadcast.data} />
                                </>
                            )}
                            {utilization.data[0].data ? (
                                <h2 className="page-title">{t("Aucune donnée de Icats trouvée")}</h2>
                            ) : (
                                <>
                                    <ChartInterfaces datasInterface={utilization.data} measurementUnit={'%'} titleChartInterface={"Utilisation de l'appareil"} />
                                </>
                            )}
                            {packetLoss.data[0].data ? (
                                <h2 className="page-title">{t("Aucune donnée de Icats trouvée")}</h2>
                            ) : (
                                <>
                                    <ChartInterfaces datasInterface={packetDiscard.data} measurementUnit={'/s'} titleChartInterface={"Perte de paquets de l'appareil"} />
                                </>
                            )}
                            {packetDiscard.data[0].data ? (
                                <h2 className="page-title">{t("Aucune donnée de Icats trouvée")}</h2>
                            ) : (
                                <>
                                    <ChartInterfaces datasInterface={packetDiscard.data} measurementUnit={'/s'} titleChartInterface={"Paquets rejetés de l'appareil"} />
                                </>
                            )}
                        </div>
                    ) : (
                        <Loader />
                    )}
                </div>
            </section>
        </div>
    );
}