import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PiCaretDown, PiCaretUp } from "react-icons/pi";

/**
 *
 * @param {array} arrayValues - array of value to filter data
 * @param {number} currentSlide - index of the value to filter data
 * @param {function} setCurrentSlide - state function to update currentSlide and change filter value
 */
export default function CarousselNav({
  arrayValues,
  currentSlide,
  setCurrentSlide,
}) {
  // to toggle list
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="caroussel">
      <div className="caroussel-view">
        <p>{t(arrayValues[currentSlide])}</p>
        <button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {!isOpen ? <PiCaretDown /> : <PiCaretUp />}
        </button>
      </div>
      <div className={`caroussel-list ${isOpen ? "caroussel-list-open" : ""}`}>
        <ul>
          {arrayValues.map((value, index) => {
            if (index !== currentSlide) {
              return (
                <li key={value}>
                  <button
                    onClick={() => {
                      setCurrentSlide(index);
                      setIsOpen(false);
                    }}
                  >
                    {t(value)}
                  </button>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
}
